import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Button from "./Button";
import { downloadInstructions } from "../../feature/apis/instructionsBtn/downloadInstructions";

const KYCInstructionsBtn: React.FC = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const handleInstructionsClick = async () => {
    // // console.log("Instructions Btn Clicked");
    try {
      setShowSpinner(true);
      await downloadInstructions();
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      //  // console.error("Failed to download instructions:", error);
    }
  };

  return (
    <>
      <Row className=" mt-2">
        <Col>
          {showSpinner ? (
            <Spinner animation="border" variant="primary" size="sm" />
          ) : (
            <Button
              label="Instructions"
              variant="outline-primary"
              onClick={() => {
                handleInstructionsClick();
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default KYCInstructionsBtn;
