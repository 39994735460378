import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getAllLocalBankAction } from "../action/getAllLocalBankAction";

const initialState: CreateAppData = {
  data: [],
  loading: false,
  error: null,
};

const getAllLocalBankSlice = createSlice({
  name: "getAllLocalBankSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLocalBankAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllLocalBankAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result.result;
      })
      .addCase(getAllLocalBankAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getAllLocalBankReducer = getAllLocalBankSlice.reducer;
