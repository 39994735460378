import { RouteObject } from "react-router-dom";
import Login from "../pages/Login";
import Opsloginpage from "../pages/Opsloginpage";
import GuestUserLoginPage from "../pages/GuestUserLoginPage";

const authRoutesConfig: RouteObject[] = [
  // {
  //   path: "/",
  //   element: <Login />,
  // },
  // moved guest login here to not let guest users go yo "/" route in case of session expiry
  {
    path: "/",
    element: <GuestUserLoginPage />,
  },
  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   exact: true,
  // },
  // Add more routes for other modules here
];

export default authRoutesConfig;
