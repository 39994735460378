import { createAsyncThunk } from "@reduxjs/toolkit";
import { GuestCreateAppType } from "../../../common/types/GuestCreateAppType";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import { createApplicationGuest } from "../../apis/createApplicationGuest";

export const createGuestApplication = createAsyncThunk(
  "guestCreateAppAction",
  async (customerData: GuestCreateAppType) => {
    const response = await createApplicationGuest(customerData, getSessionId());
    // console.log("response from api action--", response);
    return response;
  }
);
