import axios from "axios";
import { getSessionId } from "../../../../../utils/tokenHandlers";
export const updateSignedApplication = (customerId: number, docId: number) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/tradexwbench.customer/${customerId}`,
      {
        params: {
          context: {},
          data: {
            signed_application_form_attachment_ids: [docId],
          },
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      // console.log(response);
      return response;
    })
    .catch((err) => err);
};
