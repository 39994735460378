import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";
import { VerificationDetailsType } from "../redux/types/VerificationDetailsType";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export const UpdateStatusRemarks = (formData: VerificationDetailsType) => {
  const sessionId = getSessionId();
  const docId = localStorage.getItem("docId");
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/tradexwbench.customer_kyc_document/${docId}/`,
      {
        params: {
          context: {},
          data: formData,
        },
      },
      {
        params: {
          session_id: sessionId,
        },
      }
    )
    .then((response) => {
      // console.warn("id here-->", docId);
      return response;
    })
    .catch((err) => err);
};
