import React, { useState, useEffect } from "react";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import Input from "../../../../../components/common/Input";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import DatePicker from "../../../../../components/common/DatePicker";
import Button from "../../../../../components/common/Button";
import CheckBoxes from "../../../../../components/common/CheckBoxes";
// import "./AddKeyIndividual.css";
import { useNavigate, useParams } from "react-router-dom";
import IndividualDesignation from "../../../../../components/common/dropdowns/IndividualDesignation";
import Gender from "../../../../../components/common/dropdowns/Gender";
import { KeyPersonDetailsType } from "./types/KeyPersonDetailsType";
import KeyPersonType from "../../../../../components/common/KeyPersonType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createKeyPersonAction } from "../redux/action/createKeyPersonAction";
import { getKeyPersonAction } from "../redux/action/getKeyPersonAction";
import { updateKeyPersonAction } from "../redux/action/updateKeyPersonAction";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  CKYC_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
} from "../../../../constants/regexPatterns";
import SectionHeading from "../../../../../components/common/SectionHeading";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";

const OpsViewKeyPersonPage = () => {
  let { appId, keyPersonId } = useParams();
  let customerId = Number(appId);
  const dispatch = useDispatch<AppDispatch>();

  const keyPersonSelector: KeyPersonDetailsType = useSelector(
    (state: RootState) => state.opsPpIndApp.getKeyPerson.data
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<KeyPersonDetailsType>();

  const navigate = useNavigate();

  const [selectedKeyPersonType, setSelectedKeyPersonType] = useState<number[]>(
    []
  );
  const [isMinorDOB, setIsMinorDOB] = useState(false);
  const dob = watch("date_of_birth");
  const isBeneficialOwner = selectedKeyPersonType.includes(573);
  const isEntityAdmin = !selectedKeyPersonType.includes(575);
  const isMinor = watch("is_minor");
  const isPartner = watch("designation") == "partner";
  const passportExp = watch("passport_expiry_date");
  const designation = watch("designation");

  const handleAddClick = () => {
    navigate("/guest/Keyindividuallist"); // Replace with the actual path
  };

  useEffect(() => {
    if (keyPersonSelector.first_name) {
      reset({
        designation: keyPersonSelector.designation,
        if_others: keyPersonSelector.if_others,
        first_name: keyPersonSelector.first_name,
        last_name: keyPersonSelector.last_name,
        middle_name: keyPersonSelector.middle_name,
        mother_name: keyPersonSelector.mother_name,
        gender: keyPersonSelector.gender,
        date_of_birth: keyPersonSelector.date_of_birth,
        is_politically_exposed: keyPersonSelector.is_politically_exposed,
        is_minor: keyPersonSelector.is_minor,
        guardian_name: keyPersonSelector.guardian_name,
        guardian_relation: keyPersonSelector.guardian_relation,
        nationality_country_id: keyPersonSelector.nationality_country_id,
        tax_id: keyPersonSelector.tax_id,
        passport_expiry_date: keyPersonSelector.passport_expiry_date,
        passport_number: keyPersonSelector.passport_number,
        employee_id: keyPersonSelector.employee_id,
        din: keyPersonSelector.din,
        ckycno: keyPersonSelector.ckycno,
        ownership_percentage: keyPersonSelector.ownership_percentage,
        partnership_percentage: keyPersonSelector.partnership_percentage,
        key_person_address_line_1: keyPersonSelector.key_person_address_line_1,
        key_person_address_line_2: keyPersonSelector.key_person_address_line_2,
        key_person_address_city: keyPersonSelector.key_person_address_city,
        key_person_address_district:
          keyPersonSelector.key_person_address_district,
        key_person_address_pincode:
          keyPersonSelector.key_person_address_pincode,
        key_person_address_state: keyPersonSelector.key_person_address_state,
        key_person_address_country_id:
          keyPersonSelector.key_person_address_country_id,
        phone: keyPersonSelector.phone,
        email: keyPersonSelector.email,
        key_person_type_ids: keyPersonSelector.key_person_type_ids,
        tax_residency_country_id: keyPersonSelector.tax_residency_country_id,
        phone_country_code: keyPersonSelector.phone_country_code,
      });
      setSelectedKeyPersonType(keyPersonSelector.key_person_type_ids);
    }
  }, [keyPersonSelector]);

  useEffect(() => {
    if (dob) {
      const currentDate = new Date();
      const birthDate = new Date(dob);
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      const isUnder18 =
        ageDiff < 18 ||
        (ageDiff === 18 && currentDate.getMonth() < birthDate.getMonth());

      // console.log("Is under 18:", isUnder18);
      if (isUnder18 === true) {
        setIsMinorDOB(true);
        setValue("is_minor", true);
      } else if (isUnder18 === false) {
        setIsMinorDOB(false);
      }
    }
  }, [dob]);

  useEffect(() => {
    dispatch(getKeyPersonAction(Number(keyPersonId)));
  }, []);
  useEffect(() => {
    setTimeout(() => {
      dispatch(getKeyPersonAction(Number(keyPersonId)));
    }, 1000);
  }, []);

  const handleKeyPersonDetailsSubmit: SubmitHandler<KeyPersonDetailsType> = (
    keyPersonDetails
  ) => {
    keyPersonDetails.customer_id = customerId;
    keyPersonDetails.key_person_type_ids = selectedKeyPersonType;
    keyPersonDetails.key_person_address_country_id = Number(
      keyPersonDetails.key_person_address_country_id
    );
    keyPersonDetails.nationality_country_id = Number(
      keyPersonDetails.nationality_country_id
    );
    if (!passportExp) {
      keyPersonDetails.passport_expiry_date = false;
    } else {
      keyPersonDetails.passport_expiry_date = passportExp;
    }
    // console.log(keyPersonDetails);
    dispatch(
      updateKeyPersonAction({
        keyPersonId: Number(keyPersonId),
        keyPersonDetails,
      })
    ).then(() => {
      navigate(`/key-person-details/${customerId}`);
    });
  };
  return (
    <>
      <NavbarVTX />
      <Header />

      <Container>
        <Row>
          <Col md={20} className="myCol">
            <SectionHeading text="VIEW KEY PERSON DETAILS" />
          </Col>
        </Row>

        <Form>
          <Row className="myrow">
            <Col sm={5} style={{ marginLeft: "32%" }}>
              <KeyPersonType
                name="key_person_type_ids"
                label=""
                register={register}
                disabled
                // required
                // requiredMessage="Key Person Is required"
                // error={errors.key_person_type_ids?.message}
                selectedKeyPersonType={selectedKeyPersonType}
                setSelectedKeyPersonType={setSelectedKeyPersonType}
                setValue={setValue}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <IndividualDesignation
                label="Designation"
                name="designation"
                register={register}
                // required
                // requiredMessage="designation is required"
                // error={errors.designation?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Nationality"
                name="nationality_country_id"
                register={register}
                // required
                // requiredMessage="nationality is required"
                // error={errors.nationality_country_id?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 1"
                name="key_person_address_line_1"
                register={register}
                // placeholder="Enter Address line 1"
                // required
                // regexPattern={ADDRESS_REGEX}
                // requiredMessage="Address line 1 is required"
                // error={errors.key_person_address_line_1?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <DisplayBox display={designation === "others"}>
                <Input
                  label={"If Others,"}
                  name={"if_others"}
                  register={register}
                  // required={designation === "others"}
                  // placeholder="Designation if others"
                  // error={errors.if_others?.message}
                  disabled
                />
              </DisplayBox>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="First name"
                name="first_name"
                register={register}
                // placeholder="Enter first name"
                // required
                // regexPattern={ALPHABETS_REGEX}
                // requiredMessage="first name is required"
                // error={errors.first_name?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="PAN/TAX Id"
                name="tax_id"
                register={register}
                // regexPattern={ALPHANUMERIC_REGEX}
                // placeholder="Enter Pan/tax"
                // required
                // requiredMessage="tax id is required"
                // error={errors.tax_id?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 2"
                name="key_person_address_line_2"
                register={register}
                // regexPattern={ADDRESS_REGEX}
                // placeholder="Enter Address line 2"
                // required
                // requiredMessage="Address line 2 is required"
                // error={errors.key_person_address_line_2?.message}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Middle name"
                name="middle_name"
                register={register}
                // regexPattern={ALPHABETS_REGEX}
                // placeholder="Enter middle name"
                // required
                // requiredMessage="middle name is required"
                // error={errors.middle_name?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Passport No"
                name="passport_number"
                register={register}
                // regexPattern={ALPHANUMERIC_REGEX}
                // placeholder="Enter passport no"
                // required
                // requiredMessage="passport number is required"
                // error={errors.passport_number?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="City/Town/Village"
                name="key_person_address_city"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                // placeholder="Enter city/town/village"
                // required
                // requiredMessage="city is required"
                // error={errors.key_person_address_city?.message}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Last name"
                name="last_name"
                register={register}
                // placeholder="Enter last name"
                // required
                // regexPattern={ALPHABETS_REGEX}
                // requiredMessage="last name is required"
                // error={errors.last_name?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <DatePicker
                label="Passport Expiry"
                name="passport_expiry_date"
                register={register}
                // required
                // requiredMessage="passport expiry date is required"
                //error={errors.passport_expiry_date?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="District/City"
                name="key_person_address_district"
                register={register}
                // placeholder="Enter district/city"
                // required
                // regexPattern={ALPHABETS_REGEX}
                // requiredMessage="district is required"
                // error={errors.key_person_address_district?.message}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <DatePicker
                label="Date of birth"
                name="date_of_birth"
                register={register}
                //isDateOfBirth={true}
                // required
                // requiredMessage="date of birth is required"
                // error={errors.date_of_birth?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Employee Id"
                name="employee_id"
                //regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                // placeholder="Enter employee id"
                // required
                // requiredMessage="employee id is required"
                //error={errors.employee_id?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="State/Country"
                name="key_person_address_state"
                register={register}
                // placeholder="Enter state/country"
                // required
                // regexPattern={ALPHABETS_REGEX}
                // requiredMessage="state is required"
                // error={errors.key_person_address_state?.message}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Gender
                label="Gender"
                name="gender"
                register={register}
                // required
                // requiredMessage="gender is required"
                //error={errors.gender?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <PhoneInputWithDropdown
                label="CellPhone"
                name="phone"
                register={register}
                isdCodeName={"phone_country_code"}
                isdCodeRegister={register}
                placeholder="Enter your Mobile"
                disabled
                // error={errors.phone?.message}
                // required
                // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Country"
                name="key_person_address_country_id"
                register={register}
                // required
                // requiredMessage="country is required"
                // error={errors.key_person_address_country_id?.message}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Mother name"
                name="mother_name"
                register={register}
                // placeholder="Enter mother name"
                // required
                // regexPattern={ALPHABETS_REGEX}
                // requiredMessage="mother name is required"
                // error={errors.mother_name?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="CKYC No"
                name="ckycno"
                register={register}
                //placeholder="Enter ckyc"
                //regexPattern={CKYC_REGEX}
                // required
                // requiredMessage="ckycno is required"
                //error={errors.ckycno?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Pincode"
                name="key_person_address_pincode"
                register={register}
                // placeholder="Enter pincode"
                // required
                // regexPattern={PINCODE_REGEX}
                // requiredMessage="pincode is required"
                // error={errors.key_person_address_pincode?.message}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Ownership %"
                name="ownership_percentage"
                register={register}
                // placeholder="Enter ownership %"
                // required
                // requiredMessage="ownership % is required"
                // error={errors.ownership_percentage?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Tax Residency"
                name="tax_residency_country_id"
                register={register}
                // required
                // requiredMessage="din is required"
                //error={errors.din?.message}
                disabled
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Email"
                name="email"
                register={register}
                // placeholder="Enter email"
                // required
                // regexPattern={EMAIL_REGEX}
                // requiredMessage="email is required"
                // error={errors.email?.message}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="DIN/DPIN"
                name="din"
                //regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                // placeholder="Enter DIN/DPIN"
                // required
                // requiredMessage="din is required"
                //error={errors.din?.message}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <DisplayBox display={isBeneficialOwner || isMinorDOB}>
                <CheckBoxes
                  label="Is Minor  ?"
                  name="is_minor"
                  register={register}
                  //required
                  // error={errors.is_minor?.message}
                  disabled
                />
              </DisplayBox>
            </Col>

            <Col sm={4}>
              <DisplayBox display={isMinor}>
                <Input
                  label="Guardian relationship"
                  name="guardian_relation"
                  register={register}
                  //regexPattern={ALPHABETS_REGEX}
                  //placeholder="Enter guardian relationship"
                  // required={isMinor}
                  // requiredMessage="guardian relation is required"
                  //error={errors.guardian_relation?.message}
                  disabled
                />
              </DisplayBox>
            </Col>

            <Col sm={4}>
              <DisplayBox display={isMinor}>
                <Input
                  label="Guardian name"
                  name="guardian_name"
                  register={register}
                  //regexPattern={ALPHABETS_REGEX}
                  // placeholder="Enter guardian name"
                  // required={isMinor}
                  //   requiredMessage="guardian name is required"
                  //   error={errors.guardian_name?.message}
                  disabled
                />
              </DisplayBox>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={4}>
              <DisplayBox display={isEntityAdmin}>
                <CheckBoxes
                  label="Is Politically exposed person  ?"
                  name="is_politically_exposed"
                  register={register}
                  //required
                  disabled
                  // error={errors.is_politically_exposed?.message}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}>
              <DisplayBox display={isPartner}>
                <Input
                  label="Partnership(%)"
                  name="partnership_percentage"
                  register={register}
                  //   placeholder="Partnarship(%)"
                  //   error={errors.partnership_percentage?.message}
                  disabled
                />
              </DisplayBox>
            </Col>
            <Col sm={4}></Col>
          </Row>
          <Row>
            <Col md={20} className="text-end">
              <Button
                label="Save & Next"
                type="submit"
                onClick={() => {
                  navigate(`/key-person-details/${customerId}`);
                }}
              ></Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default OpsViewKeyPersonPage;
