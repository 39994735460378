import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEnrichApiToken } from "../../../../../apis/thirdParty/getEnrichApiToken";
import { getGstDetailsAction } from "./getGstDetailsAction";

export const getEnrichApiTokenAction = createAsyncThunk(
  "getEnrichApiTokenAction",
  async (gstNumber: string, thunkAPI) => {
    return getEnrichApiToken().then((response) => {
      // console.log("Enrich API response", response);
      thunkAPI.dispatch(
        getGstDetailsAction({
          gstNumber,
          orgId: response.data.data.associatedOrgs[0].organisation.id,
          token: response.data.data.token,
        })
      );
      return response.data;
    });
  }
);
