import React, { useState, useEffect } from "react";
import { Col, Row, Dropdown, Form } from "react-bootstrap";
import { getListingInfo } from "../../feature/apis/listOfValues/getListingInfo";
import { getSessionId } from "../../utils/tokenHandlers";

interface Option {
  label: string;
  value: number;
}

interface ListingInfoValueObj {
  id: number;
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

interface StockExchangeDropdownProps {
  selectedValues: number[]; // Pass the selected values as a prop
  onSelectedValuesChange: (selectedValues: number[]) => void; // Callback function to update selected values
  name: string;
  label: string;
  required?: boolean;
  requiredMessage?: string;
  defaultValue?: number;
  error?: any;
  register: any;
  setValue: any;
  watch: any;
  disabled?: boolean;
}

const ListingInfoDropdown: React.FC<StockExchangeDropdownProps> = ({
  name,
  label,
  selectedValues,
  onSelectedValuesChange,
  required = false,
  requiredMessage = "This field is required",
  defaultValue,
  error,
  setValue,
  register,
  disabled = false,
}) => {
  const token = getSessionId();

  const [selectedValuesArr, setSelectedValuesArr] = useState<any[]>([]);

  const [listingInfoValues, setListingInfoValues] = useState<
    ListingInfoValueObj[]
  >([]);

  useEffect(() => {
    getListingInfo(token).then((data) => {
      //  // console.log("LISTING INFO", data.result.result);
      setListingInfoValues(data.result.result);
      // listingInfoValues.map((option) => {
      //   // console.log("IDDDD", option.id);
      // });
    });
    // // console.log("SELECTED VALUES", selectedValues);
  }, []);

  const [updatedSelectedValues, setUpdatedSelectedValues] = useState<any[]>([
    ...selectedValuesArr,
    ...selectedValues,
  ]);

  useEffect(() => {
    // // console.log("Listing UseEffect");
  }, [selectedValuesArr]);

  const handleSelect = (value: number) => {
    // Create a new array with the selected values
    //const updatedSelectedValues = [...selectedValuesArr, ...selectedValues];

    //setUpdatedSelectedValues([...selectedValuesArr, ...selectedValues]);

    // Check if the value is already in the array
    const index = updatedSelectedValues.indexOf(value);

    if (index === -1) {
      updatedSelectedValues.push(value);
      setUpdatedSelectedValues([...updatedSelectedValues]);
    } else {
      // If already in the array, remove it
      updatedSelectedValues.splice(index, 1);
      setUpdatedSelectedValues([...updatedSelectedValues]);
    }

    // Update the state with the new array
    setSelectedValuesArr(updatedSelectedValues);
    // // console.log(updatedSelectedValues);
    setValue(name, updatedSelectedValues);
    onSelectedValuesChange(updatedSelectedValues);
  };

  const constructDropdownText = () => {
    if (updatedSelectedValues.length === 0) {
      return "Select Stock Exchanges";
    } else if (updatedSelectedValues.length === 1) {
      // If only one value is selected, show its lov_display_value
      const selectedValue = listingInfoValues.find(
        (option) => option.id === updatedSelectedValues[0]
      );
      return selectedValue ? selectedValue.lov_display_value : "";
    } else {
      // If two or more values are selected
      const selectedValuesText = updatedSelectedValues
        .map(
          (value) =>
            listingInfoValues.find((option) => option.id === value)
              ?.lov_display_value
        )
        .join(", ");

      if (selectedValuesText.length > 45) {
        // If selected text is more than 45 characters, show the first 45 characters followed by ...
        return `${selectedValuesText.substring(0, 45)}...`;
      } else {
        // Otherwise, show the combined text of the selected values
        return selectedValuesText;
      }
    }
  };

  return (
    <Form.Group as={Col}>
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Dropdown
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
          >
            {/* <Dropdown.Toggle id="dropdown-multiselect" className="custom-input">
              {updatedSelectedValues.length === 0
                ? "Select Stock Exchanges"
                : `${updatedSelectedValues.length} Selected`}
            </Dropdown.Toggle> */}
            <Dropdown.Toggle id="dropdown-multiselect" className="custom-input">
              {constructDropdownText()} {/* Use the constructed text here */}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ width: "90%", maxHeight: "200px", overflowY: "auto" }}
            >
              {selectedValues &&
                listingInfoValues &&
                listingInfoValues.map((option) => (
                  <Form.Check
                    key={option.id}
                    type="checkbox"
                    id={option.id.toString()}
                    label={option.lov_display_value}
                    defaultChecked={selectedValues.includes(option.id)}
                    onClick={() => handleSelect(option.id)}
                    disabled={disabled}
                  />
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default ListingInfoDropdown;
