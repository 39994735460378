import axios from "axios";

export const getDueDiligenceDoc = (docId: number, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/one/customer.due_diligence_document/${docId}`,
      {
        params: {
          filter: `[]`,
          query:
            "{id, customer_id, customer_due_diligence_document_type, customer_due_diligence_document_name, customer_due_diligence_attachment_ids, maker_due_diligence_status, maker_due_diligence_comments, maker_due_diligence_attachment_ids, checker_due_diligence_status,checker_due_diligence_comments,checker_due_diligence_attachment_ids}",
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
