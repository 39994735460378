import { useEffect, useState } from "react";
import { Col, Row, Container, Modal, Card, Form } from "react-bootstrap";
import Input from "../../../../../components/common/Input";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateAddressType } from "./types/CreateAddressType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import { addressTypes } from "../../../../constants/constants";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import { getAddressAction } from "../redux/action/getAddressAction";
import { updateAddressAction } from "../redux/action/updateAddressAction";
import { getAppAction } from "../redux/action/getAppAction";
import { getGstAddressAction } from "../redux/action/getGstAddressAction";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import NavbarVTX from "../../../../../components/common/Navbar";
import { getAllAddressAction } from "../redux/action/getAllAddressAction";
import TextArea from "../../../../../components/common/Textarea";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
} from "../../../../constants/regexPatterns";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import Button from "../../../../../components/common/Button";
import SectionHeading from "../../../../../components/common/SectionHeading";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";

const OpsViewAddressPage: React.FC = () => {
  let { appId, address_Id, addressType } = useParams();
  let addressId = Number(address_Id);
  let customerId = Number(appId);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateAddressType>({});

  const dispatch = useDispatch<AppDispatch>();

  const updateAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.updateAddress.data
  );

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data
  );

  const isIndia = getAppSelector.business_registration_country_id === 448;

  const getGstAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getGstAddress.data
  );

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  const getAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAddress
  );
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    null
  );

  const getAllAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllAddress
  );

  const handleAddressDetailsSubmit: SubmitHandler<CreateAddressType> = (
    addressDetails
  ) => {
    const addressDetailsData = {
      ...addressDetails,
      country_id: Number(addressDetails.country_id),
    };
    // console.log(addressDetailsData);
    dispatch(
      updateAddressAction({ addressId, addressDetails: addressDetailsData })
    ).then((response) => {
      // console.log("UPDATE ADDRES RESPO", response);

      if (getAppSelector.business_registration_country_id == 448) {
        if (getAppSelector.business_participant_type == "Financier") {
          navigate(`/india/financier/business-details/${customerId}`);
        } else {
          if (
            getAppSelector.business_constitution_type == "LLC" ||
            getAppSelector.business_constitution_type == "LLP" ||
            getAppSelector.business_constitution_type == "PLC"
          ) {
            navigate(`/india/corporate/business-details/${customerId}`);
          } else {
            navigate(`/india/non-corporate/business-details/${customerId}`);
          }
        }
      } else {
        if (getAppSelector.business_participant_type == "Financier") {
          navigate(`/foreign/financier/business-details/${customerId}`);
        } else {
          navigate(`/foreign/business-details/${customerId}`);
        }
      }

      // navigate(
      //   `/ops/business-details/${getAddressSelector.data.result.customer_id}`
      // );
    });
  };

  const selectedAddressTypeCol =
    addressType == "Registered"
      ? "add_address_types_registered"
      : "add_address_types_communication";

  const selectedAddressType = watch(selectedAddressTypeCol);

  const isApiAddress =
    selectedAddressType == "Copy Register Address" ||
    selectedAddressType == "Copy GSTIN address";

  const regAddress = getAllAddressSelector.data.filter(
    (address: any) => address.type === "Registered"
  );

  useEffect(() => {
    // console.log("API ADDRESS", isApiAddress);
    // console.log(selectedAddressType);
    if (selectedAddressType === "Copy Register Address") {
      setValue("line_1", regAddress[0].line_1);
      setValue("line_2", regAddress[0].line_2);
      setValue("city", regAddress[0].city);
      setValue("district", regAddress[0].district);
      setValue("state", regAddress[0].state);
      setValue("country_id", regAddress[0].country_id);
      setValue("pincode", regAddress[0].pincode);
    } else if (selectedAddressType == "Copy GSTIN address") {
      setValue("line_1", getGstAddressSelector.result[0].bno);
      setValue("line_2", getGstAddressSelector.result[0].bnm);
      setValue("city", getGstAddressSelector.result[0].st);
      setValue("district", getGstAddressSelector.result[0].loc);
      setValue("state", getGstAddressSelector.result[0].stcd);
      setValue("country_id", 448);
      setValue("pincode", getGstAddressSelector.result[0].pncd);
    }
    // else if (selectedAddressType == "Add Address Manually") {
    //   // console.log("RESETssssss");
    //   setValue("line_1", "");
    //   setValue("line_2", "");
    //   setValue("city", "");
    //   setValue("district", "");
    //   setValue("state", "");
    //   setValue("pincode", "");
    // }
  }, [selectedAddressType]);

  useEffect(() => {
    dispatch(getAddressAction(addressId));
  }, []);

  useEffect(() => {
    // console.log("GET ADDRESS SELECTOR", getAddressSelector);
    if (getAddressSelector.data.result) {
      reset({
        type: getAddressSelector.data.result.type,
        line_1: getAddressSelector.data.result.line_1,
        line_2: getAddressSelector.data.result.line_2,
        city: getAddressSelector.data.result.city,
        district: getAddressSelector.data.result.district,
        state: getAddressSelector.data.result.state,
        pincode: getAddressSelector.data.result.pincode,
        country_id: getAddressSelector.data.result.country_id,
        phone: getAddressSelector.data.result.phone,
        phone_country_code: getAddressSelector.data.result.phone_country_code,
        email: getAddressSelector.data.result.email,
        business_billing_gstn:
          getAddressSelector.data.result.business_billing_gstn,
        business_billing_preference_email:
          getAddressSelector.data.result.business_billing_preference_email,
        business_communication_preference_email:
          getAddressSelector.data.result
            .business_communication_preference_email,
      });
      // setValue("country_id", getAddressSelector.data.result.country_id);
      // setValue(
      //   "phone_country_code",
      //   getAddressSelector.data.result.phone_country_code
      // );
    }
  }, [getAddressSelector]);

  useEffect(() => {
    // console.log("UE 2");
    setTimeout(() => {
      reset({
        country_id: getAddressSelector.data.result.country_id,
        phone_country_code: getAddressSelector.data.result.phone_country_code,
      });
      // setValue("country_id", getAddressSelector.data.result.country_id);
      // setValue(
      //   "phone_country_code",
      //   getAddressSelector.data.result.phone_country_code
      // );
    }, 500);
  }, []);

  useEffect(() => {
    if (getAppSelector.business_gst) {
      dispatch(getGstAddressAction(getAppSelector.business_gst)).then(
        (response) => {
          // console.log("RESPONSE", response);
        }
      );
    }
  }, [dispatch, getAppSelector]);

  useEffect(() => {
    reset({ type: addressType });
    dispatch(getAllAddressAction(customerId));
  }, []);

  // const copyGSTIN = async () => {
  //   // console.log(getAppSelector.business_gst);
  //   reset({
  //     business_billing_gstn: getAppSelector.business_gst,
  //   });
  // };
  return (
    <>
      <Container>
        {/* Modal for adding address */}
        <NavbarVTX />
        <Row>
          <SectionHeading text="VIEW ADDRESS" />
        </Row>
        {/* <Row>
          {isIndia && (
            <>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <Form.Check
                        type="radio"
                        value={"Copy GSTIN address"}
                        {...register(selectedAddressTypeCol, {
                          required: "Please select an option",
                        })}
                      />
                      {getGstAddressSelector.result &&
                        `${getGstAddressSelector.result[0].bno}, ${getGstAddressSelector.result[0].bnm}, ${getGstAddressSelector.result[0].st}, ${getGstAddressSelector.result[0].loc}, ${getGstAddressSelector.result[0].stcd}, ${getGstAddressSelector.result[0].pncd}`}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                {getAllAddressSelector.data.length > 0 && (
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <Form.Check
                          type="radio"
                          value={"Copy Register Address"}
                          {...register(selectedAddressTypeCol, {
                            required: "Please select an option",
                          })}
                        //  disabled
                        />
                        {`${regAddress[0].line_1} ${regAddress[0].line_2} ${regAddress[0].city} ${regAddress[0].country_id.name}`}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </>
          )}
          <Col md={4}>
            <Card>
              <Card.Body>
                <Form.Check
                  type="radio"
                  value={"Add Address Manually"}
                  {...register(selectedAddressTypeCol, {
                    required: "Please select an option",
                  })}
                  defaultChecked
                />
                <Card.Text>Add new address</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}

        <Form
          style={{ width: "119%" }}
          onSubmit={handleSubmit(handleAddressDetailsSubmit)}
        >
          <Row style={{ marginTop: "30px" }}>
            <Col sm={5} className="d-flex align-items-center">
              <Dropdown
                label="Address Type"
                name="type"
                options={addressTypes}
                register={register}
                //required
                disabled
                //defaultValue={state.data.business_unit_location}
                //error={errors.business_unit_location?.message}
              />
            </Col>
            <Col sm={5}>
              {getAppSelector.is_counterparty === true && (
                <p style={{ fontSize: "smaller", color: "red" }}>
                  {getAppSelector.business_participant_type === "Seller"
                    ? "Following fields are not applicable for Counter Party Applications"
                    : "For Counter Party Buyer, please fill only communication preferences"}
                </p>
              )}
              <TextArea
                label="Send notification on Email"
                name="business_communication_preference_email"
                register={register}
                // required
                // requiredMessage="email is required"
                // error={errors.business_communication_preference_email?.message}
                disabled
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Address line 1"
                name="line_1"
                register={register}
                // required
                //  regexPattern={ADDRESS_REGEX}
                // requiredMessage="Address is required"
                // error={errors.line_1?.message}
                disabled
              />
            </Col>
            <Col sm={5}>
              <TextArea
                label="Send VTX Fees Invoice On Email"
                name="business_billing_preference_email"
                register={register}
                //required
                // requiredMessage="Email is required"
                // error={errors.business_billing_preference_email?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Address line 2"
                name="line_2"
                register={register}
                // required
                //regexPattern={ADDRESS_REGEX}
                //requiredMessage="Address is required"
                // error={errors.line_2?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              {/* <Input
                  label="Address line 3"
                  name="line_3"
                  register={register}
                  required
                  requiredMessage="Address is required"
                  //error={errors.line_3?.message}
                /> */}
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="City"
                name="city"
                register={register}
                //required
                // regexPattern={ALPHANUMERIC_REGEX}
                //requiredMessage="city is required"
                //error={errors.city?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="District/ City Area"
                name="district"
                register={register}
                // required
                // regexPattern={ALPHABETS_REGEX}
                // requiredMessage="district is required"
                // error={errors.district?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="State/ County"
                name="state"
                register={register}
                // required
                // regexPattern={ALPHABETS_REGEX}
                // requiredMessage="state is required"
                // error={errors.state?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <CountryDropdown
                label="Country"
                name="country_id"
                register={register}
                // required
                // defaultValue={`${getAddressSelector.data.country_id}`}
                //error={errors.type?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Pin/ Zip Code"
                name="pincode"
                register={register}
                // required
                // regexPattern={PINCODE_REGEX}
                // requiredMessage="pincode is required"
                // error={errors.pincode?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <Input
                label="Email"
                name="email"
                register={register}
                // required
                // regexPattern={EMAIL_REGEX}
                // requiredMessage="email is required"
                // error={errors.email?.message}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5} className="d-flex align-items-center">
              <PhoneInputWithDropdown
                label="CellPhone"
                name="phone"
                register={register}
                isdCodeName={"phone_country_code"}
                isdCodeRegister={register}
                disabled
                regexPattern={PHONE_REGEX}
                // placeholder="Enter your Cellphone"
                // error={errors.phone?.message}
                // required
                // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
              />
              {/* <Input
                label="Phone/Mobile No"
                name="phone"
                register={register}
                required
                regexPattern={PHONE_REGEX}
                requiredMessage="phone is required"
                error={errors.phone?.message}
              /> */}
            </Col>
          </Row>
          <Row>
            {getAppSelector.is_counterparty === true && (
              <p style={{ fontSize: "smaller", color: "red" }}>
                Billing GSTN are not applicable for Counter Party Applications
              </p>
            )}
            {isIndia && (
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Billing GSTIN"
                  name="business_billing_gstn"
                  register={register}
                  //   required={isIndia}
                  //   requiredMessage="Billing GSTN is required"
                  //   error={errors.business_billing_gstn?.message}
                  disabled
                />
                {/* <Col sm={2}>
        <Button label="Copy GSTN" variant="outline-primary"></Button>
      </Col> */}
              </Col>
            )}
            {/* {isIndia && (
              <Col sm={2}>
                <Button
                  label="Copy GSTIN"
                  type="button"
                  variant="outline-primary"
                  onClick={copyGSTIN}
                  disabled
                ></Button>
              </Col>
            )} */}
          </Row>
          {/* <Button label="Add Address" onClick={handleAddAddress} /> */}
          <Button label="Save Details" type="submit"></Button>
        </Form>
      </Container>
    </>
  );
};

export default OpsViewAddressPage;
