import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteDocumentRecord } from "../../apis/deleteDocumentRecord";

export const deleteDocument = createAsyncThunk(
  "deleteDocument",
  async (docId: number) => {
    return deleteDocumentRecord(docId).then((response: any) => {
      // console.warn("in delete doc api action");
      return response.data;
    });
  }
);
