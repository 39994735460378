import { createSlice } from "@reduxjs/toolkit";
import { UpdateAppData } from "../types/UpdateAppType";
import { updateAddressAction } from "../action/updateAddressAction";
import { updateCorrBankAction } from "../action/updateCorrBankAction";

const initialState: UpdateAppData = {
  data: {},
  loading: false,
  error: null,
};

const updateCorrBankSlice = createSlice({
  name: "updateCorrBankSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCorrBankAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCorrBankAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateCorrBankAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const updateCorrBankReducer = updateCorrBankSlice.reducer;
