import axios from "axios";

export const getAppAccessOTP = (guest_user_arn: string) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}get/user/application`, {
      params: {
        application_no: guest_user_arn,
      },
    })
    .then((response) => {
      //  // console.log("in api bro-->", guest_user_arn);
      //  // console.log("api response-->", response);
      return response;
    })
    .catch((err) => err);
};
