import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { deleteDueDiligenceDocAction } from "../action/deleteDueDiligenceDocAction";

const initialState: CreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const deleteDueDiligenceDocSlice = createSlice({
  name: "deleteDueDiligenceDocSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteDueDiligenceDocAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDueDiligenceDocAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteDueDiligenceDocAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const deleteDueDiligenceDocReducer = deleteDueDiligenceDocSlice.reducer;
