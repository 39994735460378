import { createSlice } from "@reduxjs/toolkit";
import { updateAppAction } from "../action/updateAppAction";
import { UpdateAppData } from "../types/UpdateAppType";
import { updateApplicationAction } from "../action/updateApplicationListAction";

const initialState: UpdateAppData = {
  data: {},
  loading: false,
  error: null,
};

const applicationViewSlice = createSlice({
  name: "ApplicationListView",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateApplicationAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateApplicationAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateApplicationAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const updateAppListReducer = applicationViewSlice.reducer;
