import axios from "axios";
import { CorrBankType } from "../../../../guestUser/common/types/GuestCorrBankDetails";

export const createManyCorrBank = (
  corrBanks: CorrBankType[],
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/create/many/tradexwbench.customer_settlement_correspondent_bank`,
      {
        params: {
          context: {},
          data: corrBanks,
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
