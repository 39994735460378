import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { createAppAction } from "../action/createAppAction";
import { getAllAppAction } from "../action/getAllAppAction";
import { getAllAppActionStageData } from "../types/AllApplicationStageType";
import { getAllApplicationStageAction } from "../action/getAllApplicationStageAction";

const initialState: getAllAppActionStageData = {
  data: [],
  loading: false,
  error: null,
};

const getAllApplicationStageSlice = createSlice({
  name: "getAllApplicationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllApplicationStageAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllApplicationStageAction.fulfilled, (state, action) => {
        //// console.log("action payload ---> ", action);
        state.loading = false;
        state.data = action.payload.result.result;
      })
      .addCase(getAllApplicationStageAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getAllApplicationStageReducer =
  getAllApplicationStageSlice.reducer;
