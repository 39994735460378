import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import Header from "../../../../components/common/Header";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../../../components/common/Button";
import KeypersonTablelist from "../../common/components/AddKeyIndividuals/KeypersonTablelist";
import { useNavigate } from "react-router-dom";

const GuestKeyIndividualPage: React.FC = () => {
  const navigate = useNavigate();
  const handleAddClick = () => {
    navigate("/guest/ApplicationSigingPage"); // Replace with the actual path
  };

  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
      <KeypersonTablelist/>
        <Row>
          <Col md={20} className="text-end">
            <Button label="Submit" onClick={handleAddClick}></Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GuestKeyIndividualPage;
