export const businessUnitLocation = [
  {
    label: "DTA",
    value: "dta",
  },
  {
    label: "SEZ",
    value: "sez",
  },
];

export const starTradingAccount = [
  {
    label: "One Star Export House",
    value: "onestarexporthouse",
  },
  {
    label: "Two Star Export House",
    value: "twostarexporthouse",
  },
  {
    label: "Three Star Export House",
    value: "threestarexporthouse",
  },
  {
    label: "Four Star Export House",
    value: "fourstarexporthouse",
  },
  {
    label: "Five Star Export House",
    value: "fivestarexporthouse",
  },
  {
    label: "No Star Export House",
    value: "nostarexporthouse",
  },
];

export const participationRoleOptions = [
  { label: "Seller", value: "Seller" },
  { label: "Buyer", value: "Buyer" },
  { label: "Financier", value: "Financier" },
];

export const ftnrOptions = [
  { label: "FTR", value: "FTR" },
  { label: "FTNR", value: "FTNR" },
];

export const addressTypes = [
  { label: "Registered Address", value: "Registered" },
  { label: "Communication Address", value: "Communication" },
];

export const VerificationStatus = [
  { label: "Success", value: "Success" },
  { label: "Failure", value: "Failure" },
];
