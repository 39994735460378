import axios from "axios";

export const guestOTP = (
  guest_user_name: string,
  guest_email: string,
  guest_user_mobile: string,
  token: string | undefined
) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}send/otp/user/`, {
      params: {
        email: guest_email,
        name: guest_user_name,
        phone: guest_user_mobile,
        login: "",
        db: `${process.env.REACT_APP_DB_NAME}`,
        password: "",
        token: token,
      },
    })
    .then((response) => {
      // Log guest_email after getting the response
      // // console.log(
      //   "Guest Email:",
      //   guest_email,
      //   "Guest Name:",
      //   guest_user_name,
      //   "Guest Mobile:",
      //   guest_user_mobile
      // );
      return response;
    })
    .catch((err) => err);
};
