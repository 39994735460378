import React from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useForm } from "react-hook-form";
import {
  MdOutlineModeEditOutline,
  MdOutlineDeleteOutline,
} from "react-icons/md";
import Button from "../../../../../components/common/Button";
import { FormData } from "../../types/GuestIndBuyerSeller";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

const GuestKeyIndDetails: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/guest/AddKeyIndividual"); // Replace with the actual path
  };
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const handleDeleteRow = () => {
    setSelectedRowIndex(null);
  };

  return (
    <Form>
      <Row>
        <Col md={20} className="myCol">
          <SectionHeading text="Key Persons/ Authorised officials/ Administrators and Beneficial Owners, etc" />
        </Col>

        <Row>
          <Col md={20} className="text-end">
            <Button
              label=" + Add"
              onClick={handleAddClick}
              variant="outline-primary"
            ></Button>
          </Col>
        </Row>
        <Table striped bordered size="sm" className="table-container">
          <thead>
            <tr className="table-header">
              <th>NAME</th>
              <th>EMAIL</th>
              <th>KEY PERSONS</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>
                {" "}
                <MdOutlineModeEditOutline
                  className="table-actions"
                  onClick={handleAddClick}
                />
                <MdOutlineDeleteOutline
                  className="table-actions"
                  onClick={() => setSelectedRowIndex(1)}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <MdOutlineModeEditOutline
                  className="table-actions"
                  onClick={handleAddClick}
                />
                <MdOutlineDeleteOutline
                  className="table-actions"
                  onClick={() => setSelectedRowIndex(1)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={selectedRowIndex !== null}
        onHide={() => setSelectedRowIndex(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete key individual?</Modal.Body>
        <Modal.Footer>
          <Button label="Cancel" onClick={() => setSelectedRowIndex(null)} />
          <Button label="Delete" onClick={handleDeleteRow} />
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default GuestKeyIndDetails;
