import axios from "axios";
import { getSessionId } from "../../../../utils/tokenHandlers";

export const createOneDocAPI = async (
  selectedType: string,
  customerId: number,
  attachmentids: number,
  selectedPersonId: number | null,
  docName: string | null,
  selectedDropdownItem: string | null
) => {
  const sessionid = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/create/one/tradexwbench.customer_kyc_document/`,
      {
        params: {
          context: { from_key_person: true },
          data: {
            document_type: localStorage.getItem("docType"),
            document_name: selectedDropdownItem,
            // customer_id: customerId,
            document_name_text: docName,
            attachment_ids: [attachmentids],
            customer_key_person_id: selectedPersonId,
            is_keyperson: true,
          },
        },
      },
      {
        params: {
          session_id: sessionid,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
