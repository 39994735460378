import {
  AnyAction,
  Dispatch,
  ThunkAction,
  ThunkDispatch,
  configureStore,
} from "@reduxjs/toolkit";
import { authReducer } from "../feature/auth/redux/slice";
import { opsPpIndAppReducer } from "../feature/opsUser/ppApplication/ppIndia/redux/slice";
import { guestPPAppReducer } from "../feature/guestUser/ppApplication/redux/slice/guestIndex";
import { authGuestReducer } from "../feature/auth/redux/slice/guestIndex";
import { verificationAppReducer } from "../feature/verificationStage/redux/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    opsPpIndApp: opsPpIndAppReducer,
    guestPPApp: guestPPAppReducer,
    guestAuth: authGuestReducer,
    verification: verificationAppReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction> &
  Dispatch<AnyAction>;
export type AppThunk = ThunkAction<any, RootState, any, any>;
