import { createAsyncThunk } from "@reduxjs/toolkit";
import { logoutConCurrrentLogin } from "../../apis/logoutConCurrrentLogin";

export const logoutConCurrLoginAction = createAsyncThunk(
  "logoutConCurrLoginAction",
  async (userId: number) => {
    const response = await logoutConCurrrentLogin(userId);
    return response;
  }
);
