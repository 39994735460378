import { Col, Container, Form, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../components/common/Input";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import BankAccountType from "../../../../../components/common/dropdowns/BankAccountType";
import { BankDetailsType } from "./types/BankDetailsType";
import LocalBankCurrencies from "../../../../../components/common/dropdowns/LocalBankCurrencies";
import Button from "../../../../../components/common/Button";
import CorrBankAccountDetails from "../../../../guestUser/common/components/BankDetails/CorrBankAccountDetails";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createLocalBankAction } from "../redux/action/createLocalBankAction";
import { useState, useEffect } from "react";
import { getLocalBankAction } from "../redux/action/getLocalBankAction";
import { getAllCorrBankAction } from "../redux/action/getAllCorrBankAction";
import EditCorrBankAccountDetails from "../../../../guestUser/common/components/BankDetails/EditCorrBankAccountDetails";
import { updateLocalBank } from "../apis/updateLocalBank";
import { updateLocalBankAction } from "../redux/action/updateLocalBankAction";
import SectionHeading from "../../../../../components/common/SectionHeading";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import {
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
  SWIFTCODE_REGEX,
} from "../../../../constants/regexPatterns";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import ViewCorrBankAccountDetails from "../../../../guestUser/common/components/BankDetails/ViewCorrBankAccountDetails";
import BankRadioButton from "../../../../../components/common/BankDeclaration";
import BankAccountTypeWOthers from "../../../../../components/common/dropdowns/BankAccountTypeWOthers";

const OpsViewBankDetails: React.FC = () => {
  let { appId, bankId } = useParams();
  let localBankId = Number(bankId);
  let customerId = Number(appId);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let role = getAppSelector.data.business_participant_type;
  const localBankDetails = useSelector(
    (state: RootState) => state.opsPpIndApp.getLocalBank.data
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<BankDetailsType>();

  // const [corrBanks, setCorrBanks] = useState([]);

  const accountNumber = watch("bank_account_number");
  const bankAccountType = watch("account_type");
  const confirmAccountNumber = watch("re_enter_bank_account_number");

  useEffect(() => {
    reset({
      bank_name: localBankDetails.bank_name,
      bank_account_name: localBankDetails.bank_account_name,
      branch_name: localBankDetails.branch_name,
      bank_account_number: localBankDetails.bank_account_number,
      re_enter_bank_account_number:
        localBankDetails.re_enter_bank_account_number,
      swift_code: localBankDetails.swift_code,
      ifsc_code: localBankDetails.ifsc_code,
      account_type: localBankDetails.account_type,
      bank_address_line_1: localBankDetails.bank_address_line_1,
      bank_address_line_2: localBankDetails.bank_address_line_2,
      bank_address_pincode:
        localBankDetails.bank_address_pincode === "0"
          ? " "
          : localBankDetails.bank_address_pincode,
      bank_address_state: localBankDetails.bank_address_state,
      bank_address_city: localBankDetails.bank_address_city,
      bank_address_country_id: localBankDetails.bank_address_country_id,
      bank_address_district: localBankDetails.bank_address_district,
      bank_official_email: localBankDetails.bank_official_email,
      bank_official_mobile: localBankDetails.bank_official_mobile,
      bank_official_name: localBankDetails.bank_official_name,
      bank_official_phone: localBankDetails.bank_official_phone,
      currency_id: localBankDetails.currency_id,
      is_default_bank: localBankDetails.is_default_bank,
      undertaking_confirm: localBankDetails.undertaking_confirm,
    });
    setTimeout(() => {
      reset({
        bank_address_country_id: localBankDetails.bank_address_country_id,
        account_type: localBankDetails.account_type,
        other_account_type: localBankDetails.other_account_type,
        undertaking_confirm: localBankDetails.undertaking_confirm,
      });
    }, 500);
  }, [localBankDetails]);

  useEffect(() => {
    dispatch(getLocalBankAction(localBankId));
  }, []);

  const handleBankDetailsSubmit: SubmitHandler<BankDetailsType> = (data) => {
    if (accountNumber !== confirmAccountNumber) {
      setError("re_enter_bank_account_number", {
        type: "manual",
        message: "Account Number do not match",
      });
      return;
    }

    const bankDetails = {
      ...data,
      bank_address_pincode: Number(data.bank_address_pincode),
      bank_address_country_id: Number(data.bank_address_country_id),
      currency_id: Number(data.currency_id),
    };

    dispatch(
      updateLocalBankAction({ localBankId, updateLocalBankData: bankDetails })
    ).then((response) => {
      if (response.payload.result.error) {
        alert(response.payload.result.error.data.message);
      } else {
        alert("Bank Updated!");
      }
    });
  };

  return (
    <>
      <Container>
        <NavbarVTX />
        <Header />
        <Row>
          <Row>
            <Row>
              <Col md={20} className="mt-4" style={{ color: "#214382" }}>
                <SectionHeading text="VIEW SETTLEMENT BANK ACCOUNT" />
              </Col>
            </Row>
          </Row>
          <Row>
            <Row>
              <Col md={20} className="bank-account-col">
                <SectionHeading text="LOCAL BANK DETAILS" />
              </Col>
            </Row>

            <Form style={{ width: "119%" }}>
              <Row style={{ marginTop: "30px" }}>
                <Col sm={6} className="d-flex align-items-center">
                  <LocalBankCurrencies
                    label="Local bank currency"
                    name="currency_id"
                    register={register}
                    // required
                    // requiredMessage="Currency is required"
                    // error={errors.currency_id?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Bank name"
                    name="bank_name"
                    register={register}
                    // placeholder="Enter bank name"
                    // required
                    // regexPattern={ALPHABETS_REGEX}
                    // requiredMessage="Bank Name is required"
                    // error={errors.bank_name?.message}
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  {getAppSelector.data.is_counterparty === true && (
                    <p style={{ fontSize: "smaller", color: "red" }}>
                      Following fields are not applicable for Counter Party
                      Applications
                    </p>
                  )}
                  <Input
                    label="Address line 1"
                    name="bank_address_line_1"
                    register={register}
                    // placeholder="Enter address line 1"
                    // regexPattern={ALPHANUMERIC_REGEX}
                    // required
                    // requiredMessage="email is required"
                    //error={errors.bank_address_line_1?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "1px" }}>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Branch name"
                    name="branch_name"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    // placeholder="Enter branch name"
                    // required
                    // requiredMessage="Address is required"
                    // error={errors.branch_name?.message}
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    label="Address line 2"
                    name="bank_address_line_2"
                    register={register}
                    // regexPattern={ALPHANUMERIC_REGEX}
                    // placeholder="Enter address line 2"
                    // required
                    // requiredMessage="Email is required"
                    // error={errors.bank_address_line_2?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Bank account no/IBAN"
                    name="bank_account_number"
                    register={register}
                    // placeholder="Enter bank account no/IBAN"
                    // required
                    // requiredMessage="Address is required"
                    // error={errors.bank_account_number?.message}
                    disabled
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="District/City Area"
                    name="bank_address_district"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    // placeholder="Enter district"
                    // required
                    // requiredMessage="city is required"
                    //error={errors.bank_address_district?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Re-enter Bank account no/IBAN"
                    type="password"
                    name="re_enter_bank_account_number"
                    register={register}
                    // placeholder="Enter re-enter bank account no/IBAN"
                    // required
                    // requiredMessage="Bank account number is required"
                    // error={errors.re_enter_bank_account_number?.message}
                    disabled
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="State/County"
                    name="bank_address_state"
                    register={register}
                    // placeholder="Enter state"
                    // regexPattern={ALPHABETS_REGEX}
                    // required
                    // requiredMessage="state is required"
                    //error={errors.bank_address_state?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Name as per bank account"
                    name="bank_account_name"
                    register={register}
                    //placeholder="Enter name as per bank account"
                    // required
                    // requiredMessage="Bank account name is required"
                    // error={errors.bank_account_name?.message}
                    disabled
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <CountryDropdown
                    label="Country"
                    name="bank_address_country_id"
                    register={register}
                    // required
                    // requiredMessage="pincode is required"
                    // error={errors.bank_address_country_id?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="IFSC/Sort Code/ABA"
                    name="ifsc_code"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    // placeholder="Enter IFSC/Sort code/ABA"
                    // required
                    // requiredMessage="IFSC/Sort code/ABA is required"
                    // error={errors.ifsc_code?.message}
                    disabled
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="City/Town/Locality"
                    name="bank_address_city"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    // required
                    // requiredMessage="city is required"
                    //error={errors.city?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Swift code / BIC"
                    name="swift_code"
                    register={register}
                    // regexPattern={SWIFTCODE_REGEX}
                    // placeholder="Enter swift code"
                    // required
                    // requiredMessage="country is required"
                    // error={errors.swift_code?.message}
                    disabled
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Pin Code/ Postal Code/ Zip"
                    name="bank_address_pincode"
                    register={register}
                    //regexPattern={PINCODE_REGEX}
                    //placeholder="Enter pincode/zipcode"
                    // required
                    // requiredMessage="email is required"
                    //error={errors.bank_address_pincode?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <BankAccountTypeWOthers
                    label="Account Type"
                    name="account_type"
                    register={register}
                    // required
                    // requiredMessage="Account type is required"
                    // error={errors.account_type?.message}
                    disabled
                  />
                </Col>
                {bankAccountType === "Others" && (
                  <Input
                    label={"If Others,"}
                    name={"other_account_type"}
                    register={register}
                    disabled
                  />
                )}
              </Row>

              <Row>
                <Col md={20} className="bank-account-col">
                  <SectionHeading text="BANK OFFICIALS DETAILS" />
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                {getAppSelector.data.is_counterparty === true && (
                  <p style={{ fontSize: "smaller", color: "red" }}>
                    Following fields are not applicable for Counter Party
                    Applications
                  </p>
                )}
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official Name"
                    name="bank_official_name"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    // placeholder="Enter official name"
                    // error={errors.bank_official_name?.message}
                    disabled
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official Phone"
                    name="bank_official_phone"
                    register={register}
                    // regexPattern={PHONE_REGEX}
                    // placeholder="Enter official phone"
                    // error={errors.bank_official_phone?.message}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official email"
                    name="bank_official_email"
                    register={register}
                    // regexPattern={EMAIL_REGEX}
                    // placeholder="Enter official email"
                    // error={errors.bank_official_email?.message}
                    disabled
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    // regexPattern={PHONE_REGEX}
                    // placeholder="Enter official mobile"
                    // error={errors.bank_official_mobile?.message}
                    disabled
                  />
                  {/* <Input
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    placeholder="Enter official mobile"
                    error={errors.bank_official_mobile?.message}
                  /> */}
                </Col>
              </Row>
              {role !== "Financier" && (
                <BankRadioButton
                  label={""}
                  disabled
                  required
                  requiredMessage="Please select the Declaration"
                  error={errors.undertaking_confirm?.message}
                  name={"undertaking_confirm"}
                  register={register}
                  options={[
                    {
                      label:
                        "We undertake and confirm that the above-mentioned details are of  our Working capital account, or",
                      value: "Yes",
                    },
                    {
                      label:
                        "We undertake and confirm that we do not avail any working capital facility from any Bank, NBFC, or other financial institutions. The above details are of our operative account for carrying out day to day operations of our firm/ company.",
                      value: "No",
                    },
                  ]}
                />
              )}

              <Row className="mt-4">
                <Col md={20} className="text-end">
                  {/* <Button
                    label="Save & Next"
                    type="submit"
                    onClick={() => {
                      navigate(`/currency-bank-details/${customerId}`);
                    }}
                  ></Button> */}
                </Col>
              </Row>
            </Form>
            {/* <EditCorrBankAccountDetails /> */}
            <ViewCorrBankAccountDetails />
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default OpsViewBankDetails;
