import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { CreateAddressType } from "../../pages/types/CreateAddressType";
import { updateAddress } from "../../apis/updateAddress";
import {
  CPKeyPersonDetailsType,
  KeyPersonDetailsType,
} from "../../pages/types/KeyPersonDetailsType";
import { updateKeyPerson } from "../../apis/updateKeyPerson";
import { CPUpdateKeyPerson } from "../../apis/CPUpdateKeyPerson";

export const CPUpdateKeyPersonAction = createAsyncThunk(
  "updateKeyPersonAction",
  async ({
    keyPersonId,
    CPKeyPersonDetails,
  }: {
    keyPersonId: number;
    CPKeyPersonDetails: CPKeyPersonDetailsType;
  }) => {
    return CPUpdateKeyPerson(
      keyPersonId,
      CPKeyPersonDetails,
      getSessionId()
    ).then((response) => {
      return response.data;
    });
  }
);
