import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getParticipantType } from "../../feature/apis/listOfValues/getParticipantType";
import { getSessionId } from "../../utils/tokenHandlers";

interface ParticipantTypeProps {
  name: string;
  label: string;
  required?: boolean;
  requiredMessage?: string;
  defaultValue?: string;
  register: any; // Use proper type for register function
  error?: any; // Add error prop
}

interface ParticipantTypeData {
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

const ParticipantType: React.FC<ParticipantTypeProps> = ({
  name,
  label,
  register,
  required = false,
  requiredMessage = "This field is required",
  defaultValue,
  error,
}) => {
  const token = getSessionId();

  const [participantTypeValues, setParticipantTypeValues] = useState<
    ParticipantTypeData[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [dataFetched, setDataFetched] = useState(false);

  const fetchParticipantTypeWithRetry = async (
    token: string,
    retries = 3,
    delay = 1000
  ): Promise<void> => {
    try {
      const response = await getParticipantType(token);
      if (response && response.result && response.result.result) {
        setParticipantTypeValues(response.result.result);
        setDataFetched(true);
        setFetchError(null);
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      if (retries > 0) {
        setTimeout(
          () => fetchParticipantTypeWithRetry(token, retries - 1, delay),
          delay
        );
      } else {
        setFetchError("Failed to fetch participant types");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!dataFetched && token) {
      fetchParticipantTypeWithRetry(token);
    }
  }, [token, dataFetched]);

  return (
    <Form.Group as={Col} className="">
      <Row className="align-items-center">
        <Form.Label column md={4} className="col-form-label">
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
          </span>
        </Form.Label>
        <Col md={8} className="d-flex">
          {loading ? (
            <p>Loading...</p>
          ) : fetchError ? (
            <p className="text-danger">{fetchError}</p>
          ) : (
            participantTypeValues.map((participantTypeValue, index) => (
              <Form.Check
                key={index}
                className="form-label"
                inline
                label={participantTypeValue.lov_display_value}
                type="radio"
                name={name}
                value={participantTypeValue.lov_value}
                {...register(name, {
                  required: required ? requiredMessage : false,
                })}
                defaultValue={defaultValue}
              />
            ))
          )}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default ParticipantType;
