import { Container, Row, Col } from "react-bootstrap";
import OpsLogin from "./Opslogin/OpsLogin";
import HowItWorks from "../components/HowItWorks";
import NavbarVTX from "../../../components/common/Navbar";

const Opsloginpage = () => {
  return (
    <Container>
      <NavbarVTX />
      <Row>
        <Col className="d-flex justify-content-center align-items-center">
          <HowItWorks />
        </Col>
        <Col md={6}>
          <OpsLogin />
        </Col>
      </Row>
    </Container>
  );
};

export default Opsloginpage;
