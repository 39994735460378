import { Reducer, ReducersMapObject, combineReducers } from "@reduxjs/toolkit";
import { guestPPAppType } from "../types/guestPPAppType";
import { uploadAttachmentReducer } from "./uploadAttachmentSlice";
import { uploadKYCReducer } from "./uploadKYCSlice";
import { guestCreateAppReducer } from "./guestCreateAppSlice";
import { headerDetailsReducer } from "./headerDetailsSlice";

export const guestPPAppReducerMap: ReducersMapObject<guestPPAppType> = {
  uploadAttachment: uploadAttachmentReducer,
  uploadKYC: uploadKYCReducer,
  guestCreateApp: guestCreateAppReducer,
  headerDetails: headerDetailsReducer,
};

export const guestPPAppReducer: Reducer<guestPPAppType> =
  combineReducers<guestPPAppType>(guestPPAppReducerMap);
