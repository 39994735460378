import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { deleteKeyPerson } from "../../apis/deleteKeyPerson";

export const deleteKeyPersonAction = createAsyncThunk(
  "deleteKeyPersonAction",
  async (keyPersonId: number) => {
    return deleteKeyPerson(keyPersonId, getSessionId()).then(
      (response: any) => {
        return response.data;
      }
    );
  }
);
