import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadIndividualKYC } from "../../apis/uploadIndividualKYC";

type IndividualDocArgs = {
  docName: string;
  image: string | Blob;
};

export const uploadIndividualAttachment = createAsyncThunk(
  "uploadIndividualAttachment",
  async (args: IndividualDocArgs) => {
    const { docName, image } = args;
    const response = await uploadIndividualKYC(docName, image);
    return response;
  }
);
