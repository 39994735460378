import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getReferredBy } from "../../../feature/apis/referredBy/getReferredBy";

interface ReferredByDropdownProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  handleChange?: () => JSX.Element;
  defaultValue?: string;
  error?: string;
}

interface Participant {
  id: number;
  stage: string;
  business_name: string;
  application_uid: string;
}

const ReferredByDropdown: React.FC<ReferredByDropdownProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  handleChange,
  defaultValue,
  error,
}) => {
  const token = getSessionId();

  const [participants, setParticipants] = useState<Participant[]>([]);

  useEffect(() => {
    getReferredBy(token).then((response) => {
      // // console.log("COUNTRY API --> ", response);
      setParticipants(response.result.result);
      //// console.log("Country Id Type", typeof response.result.result[0].id);
    });
  }, []);

  // const DropdownComponent: React.FC<DropdownComponentProps> = ({ options, selectedValue, handleChange }) => {
  //   const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //     const newValue = event.target.value;
  //     handleChange(newValue); // Call the handleChange function with the new value
  //   };

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Select
            as="select"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            onChange={handleChange}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            defaultValue={defaultValue}
          >
            <option value="">Select Option</option>
            {participants.map((participant, index) => (
              <option key={index} value={participant.id}>
                {participant.business_name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default ReferredByDropdown;
