import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";
export interface FetchVerStatusRemarksParamsKeyPerson {
  selectedId: number;
  selectedDocId: any; // Adjust the type according to your needs
}
export const fetchVerStatusKeyPerson = ({
  selectedId,
  selectedDocId,
}: FetchVerStatusRemarksParamsKeyPerson) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer_kyc_document/`,
      {
        params: {
          filter: `[('customer_key_person_id','in',[${selectedId}]), ('document_type','=', '${localStorage.getItem(
            "selectedType"
          )}'), ('id', '=', ${selectedDocId})]`,
          query:
            "{id, document_type,document_name,customer_id,document_name_text,attachment_ids,customer_key_person_id,api_verification_status,  api_verification_remarks, maker_verification_status, maker_verification_comments,maker_verification_attachment_ids, maker_verification_attachment_status, maker_verification_attachment_comments, checker_verification_status, checker_verification_comments}",
        },
      },
      {
        params: {
          session_id: sessionId,
        },
      }
    )
    .then((response) => {
      // console.warn("iid in api-->", selectedId);
      // console.warn("type--->", localStorage.getItem("selectedType"));
      return response;
    })
    .catch((err) => err);
};
