import { createAsyncThunk } from "@reduxjs/toolkit";
import { createApplication } from "../../apis/createApplication";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { CreateIndAppType } from "../../pages/types/CreateIndAppType";
import { getCinFromPanAction } from "./getCinFromPanAction";

export const createAppAction = createAsyncThunk(
  "createAppAction",
  async (customerData: CreateIndAppType, thunkAPI) => {
    return createApplication(customerData, getSessionId()).then((response) => {
      // console.log("Create App Response ---> ", response);
      // thunkAPI.dispatch(
      //   getCinFromPanAction({
      //     pan: customerData.business_pan!,
      //     customerId: response.data.result,
      //   })
      // );
      return response.data;
    });
  }
);
