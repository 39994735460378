import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { uploadAttachment } from "../../apis/uploadAttachment";

export const uploadAttachmentAction = createAsyncThunk(
  "uploadAttachmentAction",
  async ({ fileName, file }: { fileName: string; file: any }) => {
    return uploadAttachment(fileName, file, getSessionId()).then((response) => {
      return response.data;
    });
  }
);
