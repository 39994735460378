import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllApp } from "../../apis/getAllApp";
import { getCounterParty } from "../../apis/getCounterParty";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { FilterParams } from "../../pages/ApplicationListView/ApplicationListViewCp";

// export const getCounterPartyAction = createAsyncThunk(
//   "getCounterPartyAction",
//   async () => {
//     return getCounterParty(getSessionId()).then((response: any) => {
//       return response.data;
//     });
//    // // console.log("result", res);
//   }
// );

export const getCounterPartyAction = createAsyncThunk(
  "getCounterPartyAction",
  async (filterParams: FilterParams) => {
    return getCounterParty(getSessionId(), filterParams).then(
      (response: any) => {
        return response.data;
      }
    );
    //  // console.log("result", res);
  }
);
