import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

export const getBizNameGSTINMaster = (gstinValue: string) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.temp_gst_details`,
      {
        params: {
          filter: `[['gstin', '=', '${gstinValue}']]`,
          query: "{gstin,tradeName,lgnm,bnm,st,bno,city,loc,stcd,pncd,ctb}",
        },
      },
      {
        params: {
          session_id: sessionId,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
