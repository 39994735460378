import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKeyPersonDocsData } from "../../apis/getKeyPersonDocsData";

type keyPersonArgs = {
  customerId: number;
  personId: number | null;
};

export const fetchKeyPersonDocs = createAsyncThunk(
  "fetchKeyPersonDocs",
  async (args: keyPersonArgs) => {
    const { customerId, personId } = args;
    const response = await getKeyPersonDocsData(customerId, personId);
    return response;
  }
);
