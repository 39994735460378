import { createAsyncThunk } from "@reduxjs/toolkit";
import { GuestAppAccessType } from "../types/GuestLoginType";
import { verifyAppAccessOTP } from "../../apis/verifyAppAccessOTP";

export const appAccessOTPVerify = createAsyncThunk(
  "appAccessOTPVerify",
  async ({ guest_user_arn, guest_user_otp }: GuestAppAccessType) => {
    const response = await verifyAppAccessOTP(guest_user_arn, guest_user_otp);
    // // console.warn("in action file broo", response);
    return response;
  }
);
