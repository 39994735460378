import React, { useEffect, useState } from "react";
import NavbarVTX from "../../../components/common/Navbar";
import { Col, Row } from "react-bootstrap";
import "../../../components/css/common.css";
import CommonHeader from "../../../components/common/CommonHeader";
import DocumentsVerification from "../components/documentsVerification";
import DetailsVerification from "../components/detailsVerification";
import { fetchHeaderDetails } from "../../guestUser/ppApplication/redux/action/fetchHeaderDetails";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import Header from "../../../components/common/Header";

const VerificationPage: React.FC = () => {
  const { appId } = useParams();
  const customerId = Number(appId);
  return (
    <>
      <>
        {/* {customerId} */}
        <NavbarVTX />
        <Header />
        <Row style={{ marginLeft: "10px" }} className="mt-3">
          <Col md={6} style={{ height: "100vh", overflowY: "auto" }}>
            <DocumentsVerification />
          </Col>
          <Col md={6} style={{ overflowY: "auto", height: "100vh" }}>
            <DetailsVerification />
          </Col>
        </Row>
      </>
    </>
  );
};

export default VerificationPage;
