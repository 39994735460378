import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { deleteDueDiligenceDoc } from "../../apis/deleteDueDiligenceDoc";

export const deleteDueDiligenceDocAction = createAsyncThunk(
  "deleteDueDiligenceDocAction",
  async (dueDiligenceDocId: number) => {
    return deleteDueDiligenceDoc(dueDiligenceDocId, getSessionId()).then(
      (response: any) => {
        return response.data;
      }
    );
  }
);
