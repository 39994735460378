import React, { useState } from "react";
import { Row, Col, Table, Container, Card, Form, Modal } from "react-bootstrap";
import {
  MdOutlineDriveFileRenameOutline,
  MdOutlineDeleteOutline,
} from "react-icons/md";
import Button from "../../../../../components/common/Button";
import SectionHeading from "../../../../../components/common/SectionHeading";
import Input from "../../../../../components/common/Input";
import { AddressDetailsType } from "../types/AddressDetailsType";
import { SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import IconInput from "../../../../../components/common/IconInput";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
const IFAddressDetails: React.FC = () => {
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<AddressDetailsType>();
  const [showModal, setShowModal] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    null
  );
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const handleDeleteRow = () => {
    // Implement logic to delete the selected row
    // You can use state or other methods to update the table data
    setSelectedRowIndex(null); // Close the modal
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleAddAddress = () => {
    // Implement logic to add address
    // You can use state or other methods to store the added address data
    closeModal();
  };
  const onSaveAddressDetails: SubmitHandler<AddressDetailsType> = (data) => {
    // console.log("Address Details:", data);
  };

  return (
    <>
      <Container>
        <Row>
          <Row>
            <Col md={12} className="text-center" style={{ color: "#214382" }}>
              <SectionHeading text="ADDRESS DETAILS" />
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <Button label="+ Registered Address" onClick={openModal}></Button>
              <Button
                label="+ Communication Address"
                onClick={openModal}
              ></Button>
            </Col>
          </Row>
          <Table striped bordered size="sm" style={{ padding: "10%" }}>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>TYPE</th>
                <th>ADDRESS</th>
                <th>EMAIL</th>
                <th style={{ width: "12%" }}>TELEPHONE</th>
                <th style={{ width: "10%" }}>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Registred Address</td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ color: "#214382" }}>
                  <IconInput
                    icon={
                      <MdOutlineDriveFileRenameOutline
                        style={{ width: "33px", height: "24px" }}
                      />
                    }
                    onClick={openModal}
                  />
                  <IconInput
                    icon={
                      <MdOutlineDeleteOutline
                        style={{ width: "33px", height: "24px" }}
                      />
                    }
                    onClick={() => setSelectedRowIndex(1)}
                  />
                </td>
              </tr>
              <tr>
                <td>Communication Address</td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ color: "#214382" }}>
                  <IconInput
                    icon={
                      <MdOutlineDriveFileRenameOutline
                        style={{ width: "33px", height: "24px" }}
                      />
                    }
                    onClick={openModal}
                  />
                  <IconInput
                    icon={
                      <MdOutlineDeleteOutline
                        style={{ width: "33px", height: "24px" }}
                      />
                    }
                    onClick={() => setSelectedRowIndex(1)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Form className="justify-content-center">
            <Row>
              <Col sm={6}>
                <Dropdown
                  label="Default Billing Address"
                  name="business_billing_address_type"
                  register={register}
                  options={[]}
                  required
                  requiredMessage="Billing Address is required"
                  error={errors.business_billing_address_type?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 text-end" md={12}>
                <Button
                  label="Save & Next"
                  type="button"
                  onClick={handleSubmit(onSaveAddressDetails)}
                />
              </Col>
            </Row>
          </Form>
          {/* Modal for adding address */}
          <Modal size="xl" show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
              <Row>
                <Col
                  md={20}
                  className="text-center mt-4"
                  style={{ color: "#214382" }}
                >
                  <h5>ADD ADDRESS</h5>
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Card
                    style={{
                      width: "96%",
                      backgroundColor:
                        selectedCardIndex === 1 ? "#f0f0f0" : "white",
                    }}
                    onClick={() =>
                      setSelectedCardIndex(selectedCardIndex === 0 ? null : 0)
                    }
                  >
                    <Card.Body>
                      <Card.Text>
                        <Form.Check
                          type="radio"
                          aria-label={`radio-${0}`}
                          checked={selectedCardIndex === 0}
                          onChange={() => setSelectedCardIndex(0)}
                        />
                        TATA MOTORS, BOMBAY HOUSE, 24 HOMI MODY STREET, 400001,
                        MUMBAI, MAHARASHTRA, INDIA
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    style={{
                      width: "96%",
                      backgroundColor:
                        selectedCardIndex === 1 ? "#f0f0f0" : "white",
                    }}
                    onClick={() =>
                      setSelectedCardIndex(selectedCardIndex === 1 ? null : 1)
                    }
                  >
                    <Card.Body>
                      <Card.Text>
                        <Form.Check
                          type="radio"
                          aria-label={`radio-${1}`}
                          checked={selectedCardIndex === 1}
                          onChange={() => setSelectedCardIndex(1)}
                        />
                        TATA MOTORS, TELCO ROAD, PIMPRI, NEAR KSB CHOWK, PIMPRI
                        CHINCWAD, 411018
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    style={{
                      width: "96%",
                      backgroundColor:
                        selectedCardIndex === 2 ? "#f0f0f0" : "white",
                    }}
                    onClick={() =>
                      setSelectedCardIndex(selectedCardIndex === 2 ? null : 2)
                    }
                  >
                    <Card.Body>
                      <Form.Check
                        type="radio"
                        aria-label={`radio-${2}`}
                        checked={selectedCardIndex === 2}
                        onChange={() => setSelectedCardIndex(2)}
                      />
                      <Card.Subtitle className="mb-2 text-muted">
                        Add new address
                      </Card.Subtitle>
                      <Card.Text></Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Form style={{ width: "119%" }}>
                <Row style={{ marginTop: "30px" }}>
                  <Col sm={5} className="d-flex align-items-center">
                    <Dropdown
                      label="Address type"
                      name="business_address_type"
                      register={register}
                      options={[
                        { label: "Registered", value: "registered" },
                        { label: "Communication", value: "communication" },
                      ]}
                      required
                      requiredMessage="Address type is required"
                    />
                  </Col>
                  <Col sm={5}>
                    <Input
                      label="Send notification on"
                      name="business_communication_preference_email"
                      register={register}
                      required
                      requiredMessage="email is required"
                      error={
                        errors.business_communication_preference_email?.message
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "1px" }}>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="Address line 1"
                      name="line_1"
                      register={register}
                      required
                      requiredMessage="Address is required"
                      error={errors.line_1?.message}
                    />
                  </Col>
                  <Col sm={5}>
                    <Input
                      label="Send VTX Fees Invoice On"
                      name="business_billing_preference_email"
                      register={register}
                      required
                      requiredMessage="Email is required"
                      error={errors.business_billing_preference_email?.message}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="Address line 2"
                      name="line_2"
                      register={register}
                      required
                      requiredMessage="Address is required"
                      error={errors.line_2?.message}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="Address line 3"
                      name="line_3"
                      register={register}
                      required
                      requiredMessage="Address is required"
                      error={errors.line_3?.message}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="City"
                      name="city"
                      register={register}
                      required
                      requiredMessage="city is required"
                      error={errors.city?.message}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="District/ City Area"
                      name="district"
                      register={register}
                      required
                      requiredMessage="district is required"
                      error={errors.district?.message}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="State/ County"
                      name="state"
                      register={register}
                      required
                      requiredMessage="state is required"
                      error={errors.state?.message}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <CountryDropdown
                      label="Country"
                      name="country_name"
                      register={register}
                      required
                      requiredMessage="country is required"
                      error={errors.country_name?.message}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="Pin/ Zip Code"
                      name="pincode"
                      register={register}
                      required
                      requiredMessage="pincode is required"
                      error={errors.pincode?.message}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="Email"
                      name="email"
                      register={register}
                      required
                      requiredMessage="email is required"
                      error={errors.email?.message}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="Telephone"
                      name="phone"
                      register={register}
                      required
                      requiredMessage="phone is required"
                      error={errors.phone?.message}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <Input
                      label="Billing GSTN"
                      name="billing_gstn"
                      register={register}
                      required
                      requiredMessage="billing gstn is required"
                      error={errors.billing_gstn?.message}
                    />
                  </Col>
                  <Col size="sm">
                    <Button label="Copy GSTN" />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                label="Cancel"
                type="button"
                variant="outline-primary"
                onClick={closeModal}
              />
              <Button label="Add Address" onClick={handleAddAddress} />
            </Modal.Footer>
          </Modal>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={selectedRowIndex !== null}
            onHide={() => setSelectedRowIndex(null)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete Registerd/Communication Address?
            </Modal.Body>
            <Modal.Footer>
              <Button
                label="Cancel"
                type="button"
                variant="outline-primary"
                onClick={() => setSelectedRowIndex(null)}
              />
              <Button label="Delete" onClick={handleDeleteRow} />
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

export default IFAddressDetails;
