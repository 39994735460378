import { Accordion, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import React from "react";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";
import { getCorrBank } from "../redux/action/getCorrBank";
import VerCorrBank from "./verCorrBank";

const VerLocalBank: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector(
    (state: RootState) => state.verification.verLocalBankDetails
  );
  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }

  const localBankStore: Array<{
    id: number;
    bank_name: string;
    branch_name: string;
    bank_account_name: string;
    bank_account_number: string;
    ifsc_code: string;
    iban: string;
    swift_code: string;
    account_type: string;
    other_account_type: string;
    bank_official_name: string;
    bank_official_email: string;
    bank_official_phone: string;
    bank_official_mobile: string;
    bank_address_line_1: string;
    bank_address_line_2: string;
    bank_address_city: string;
    bank_address_district: string;
    bank_address_state: string;
    bank_address_pincode: string;
    bank_address_country_id: { name: string };
    currency_id: { currency_code: string };
  }> = state.data.data.result.result;

  const handleCorrBankClick = (settlementBankId: number) => async () => {
    try {
      // Dispatch your getCorrBank action with the specific settlementBankId
      const result = await dispatch(getCorrBank(settlementBankId));
      if (result.payload) {
        // console.log("in main corr file-->", result.payload);
      }
    } catch (error) {
      // console.error("An error occurred", error);
    }
  };
  return (
    <>
      <Accordion>
        {localBankStore.map((localBank, index) => (
          <Accordion.Item key={index} eventKey={`L${index}`}>
            <Accordion.Header
              onClick={() => {
                // // console.log(`Settlement Bank ${index + 1} ID: ${localBank.id}`);
                const settlementBankId = `${localBank.id}`;
                // console.log("id-->", settlementBankId);
              }}
            >
              Settlement Bank {index + 1}
            </Accordion.Header>
            <Accordion.Body>
              {/* <Row className="m-2">Bank Account Details</Row> */}
              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Bank Name"
                    defaultValue={localBank.bank_name}
                  />
                </Col>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Branch Name"
                    defaultValue={localBank.branch_name}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Bank Account No"
                    defaultValue={localBank.bank_account_number}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Name as per Bank"
                    defaultValue={localBank.bank_account_name}
                  />
                </Col>
              </Row>

              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Swift Code"
                    defaultValue={localBank.swift_code}
                  />
                </Col>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Account Type"
                    defaultValue={
                      localBank.account_type === "Others"
                        ? localBank.other_account_type
                        : localBank.account_type
                    }
                  />
                </Col>
              </Row>

              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="IFSC"
                    defaultValue={localBank.ifsc_code}
                  />
                </Col>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Currency"
                    defaultValue={localBank.currency_id.currency_code}
                  />
                </Col>
              </Row>
              {/* <Row className="m-2">Branch Address</Row> */}
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Address Line 1"
                    defaultValue={localBank.bank_address_line_1}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Address Line 2"
                    defaultValue={localBank.bank_address_line_2}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="City"
                    defaultValue={localBank.bank_address_city}
                  />
                </Col>
                <Col md={6}>
                  <VerBusinessDetails
                    label="District"
                    defaultValue={localBank.bank_address_district}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="State/ County"
                    defaultValue={localBank.bank_address_state}
                  />
                </Col>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Country"
                    defaultValue={localBank.bank_address_country_id.name}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Pin/ ZIP code"
                    defaultValue={
                      localBank.bank_address_pincode === "0"
                        ? ""
                        : localBank.bank_address_pincode
                    }
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Bank Official Name"
                    defaultValue={localBank.bank_official_name}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Bank Official Email"
                    defaultValue={localBank.bank_official_email}
                  />
                </Col>
              </Row>
              <Row md={12}>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Official Phone"
                    defaultValue={localBank.bank_official_phone}
                  />
                </Col>
                <Col md={6}>
                  <VerBusinessDetails
                    label="Official Mobile"
                    defaultValue={localBank.bank_official_mobile}
                  />
                </Col>
              </Row>
              <Accordion>
                <Accordion.Item eventKey="C1">
                  <Accordion.Header onClick={handleCorrBankClick(localBank.id)}>
                    Correspondent Banks
                  </Accordion.Header>
                  <Accordion.Body>
                    <VerCorrBank />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default VerLocalBank;
