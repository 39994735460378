import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { deleteAddress } from "../../apis/deleteAddress";

export const deleteAddressAction = createAsyncThunk(
  "deleteAddressAction",
  async (addressId: number) => {
    return deleteAddress(addressId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
