import React, { useEffect } from "react";
import AddressDetails from "../components/AddressDetails";
import IFBusinessDetails from "../components/IFBusinessDetails";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { AddressDetailsType } from "./types/BusinessDetailsType";
import { updateAppAction } from "../redux/action/updateAppAction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import DefaultBillingAddress from "../../../../../components/common/dropdowns/DefaultBillingAddress";
import DefaultShippingAddress from "../../../../../components/common/dropdowns/DefaultShippingAddress";
import Button from "../../../../../components/common/Button";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import { getAppAction } from "../redux/action/getAppAction";

const OpsIFBusinessDetailspage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const navigate = useNavigate();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    watch: watch1,
    setValue: setValue1,
    control: control1,
    reset: reset1,
    formState: { errors },
  } = useForm<AddressDetailsType>({});

  const dispatch = useDispatch<AppDispatch>();

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let stage = getAppSelector.data.stage;

  const isCounterParty = getAppSelector.data.is_counterparty;

  useEffect(() => {
    setValue1(
      "business_billing_address_type_id",
      getAppSelector.data.business_billing_address_type_id
    );
    setValue1(
      "business_shipping_address_type_id",
      getAppSelector.data.business_shipping_address_type_id
    );
  }, [getAppSelector]);

  useEffect(() => {
    setTimeout(() => {
      setValue1(
        "business_billing_address_type_id",
        getAppSelector.data.business_billing_address_type_id
      );
      setValue1(
        "business_shipping_address_type_id",
        getAppSelector.data.business_shipping_address_type_id
      );
    }, 500);
  }, [getAppSelector]);

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, []);

  const handleAddressDetailsSubmit: SubmitHandler<any> = (
    addressBillingDetails
  ) => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      // console.log(addressBillingDetails);
      addressBillingDetails.business_billing_address_type_id = Number(
        addressBillingDetails.business_billing_address_type_id
      );
      addressBillingDetails.business_shipping_address_type_id = Number(
        addressBillingDetails.business_shipping_address_type_id
      );

      dispatch(
        updateAppAction({
          customerId,
          updateCustomerData: addressBillingDetails,
        })
      ).then((response) => {
        if (response.payload.result.error) {
          alert(response.payload.result.error.data.message);
        } else {
          navigate(`/currency-bank-details/${customerId}`);
        }
      });
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  return (
    <>
      <Container>
        <NavbarVTX />
        <Header />
        <IFBusinessDetails />
        <AddressDetails />
        <Form onSubmit={handleSubmit1(handleAddressDetailsSubmit)}>
          <Row className="">
            <Col sm={6}>
              {isCounterParty === true ? (
                <DefaultShippingAddress
                  label="Default Billing Address"
                  name="business_billing_address_type_id"
                  register={register1}
                  required
                  //defaultValue={state.data.business_shipping_address_type_id}
                  error={errors.business_billing_address_type_id?.message}
                  requiredMessage="Default Billing Address is required"
                />
              ) : (
                <DefaultBillingAddress
                  label="Default Billing Address"
                  name="business_billing_address_type_id"
                  register={register1}
                  required
                  //defaultValue={state.data.business_billing_address_type_id}
                  requiredMessage="Default Billing Address is required"
                  error={errors.business_billing_address_type_id?.message}
                />
              )}
              {}
            </Col>
            <Col sm={6}>
              <DefaultShippingAddress
                label="Default Shipping Address"
                name="business_shipping_address_type_id"
                register={register1}
                required
                //defaultValue={state.data.business_shipping_address_type_id}
                error={errors.business_shipping_address_type_id?.message}
                requiredMessage="Default Shipping Address is required"
              />
            </Col>
          </Row>
          <Row className="">
            {/* <Col sm={6}>
              <DefaultShippingAddress
                label="Default Shipping Address"
                name="business_shipping_address_type_id"
                register={register1}
                required
                //defaultValue={state.data.business_shipping_address_type_id}
                requiredMessage="Default Shipping Address is required"
                error={errors.business_shipping_address_type_id?.message}
              />
            </Col> */}
          </Row>
          <Row>
            <Col md={20} className="text-end">
              <Button label="Save & Next" type="submit"></Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default OpsIFBusinessDetailspage;
