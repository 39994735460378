interface Address {
  line_1: string;
  line_2: string;
  city: string;
  district: string;
  state: string;
  country_id: number;
  pincode: string;
}

// export function parseAddress(addressString: string): Address {
//   const arr = addressString.split(" ");
//   // console.log("ARR", arr);

//   let state = arr[arr.length - 4];
//   let pincode = arr[arr.length - 3];
//   let city = arr[arr.length - 5];
//   let district = city;
//   let country_id = 448;

//   arr.splice(arr.length - 4);

//   // Combining the remaining elements into line_1 and line_2
//   const line_1 = arr.slice(0, Math.ceil(arr.length / 2)).join(" ");
//   const line_2 = arr.slice(Math.ceil(arr.length / 2)).join(" ");

//   return {
//     line_1,
//     line_2,
//     city,
//     district,
//     state,
//     country_id,
//     pincode,
//   };
// }

export function parseAddress(addressString: string): Address {
  const arr = addressString.split(" ");

  // Extracting pincode
  const pincodeIndex = arr.findIndex((item) => /[0-9]{6}/.test(item));
  const pincode = pincodeIndex !== -1 ? arr[pincodeIndex] : "";

  // Extracting state (assuming state is always two characters long)
  const stateIndex = pincodeIndex > 1 ? pincodeIndex - 2 : -1;
  const state = stateIndex !== -1 ? arr[stateIndex] : "";

  // Extracting city (assuming city precedes state)
  const cityIndex = stateIndex > 1 ? stateIndex - 1 : -1;
  const city = cityIndex !== -1 ? arr[cityIndex] : "";

  // Combining the remaining elements into line_1 and line_2
  const remainingArr = arr.slice(0, cityIndex);
  const line_1 = remainingArr
    .slice(0, Math.ceil(remainingArr.length / 2))
    .join(" ");
  const line_2 = remainingArr
    .slice(Math.ceil(remainingArr.length / 2))
    .join(" ");

  return {
    line_1,
    line_2,
    city,
    district: city, // Assuming district is same as city
    state,
    country_id: 448, // Assuming constant country_id
    pincode,
  };
}
