import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { opsLoginAction } from "../../feature/auth/redux/action/opsLoginAction";
import { doLogin, getSessionId, getUserUid } from "../../utils/tokenHandlers";
import { getAllCountry } from "../../feature/apis/country/getAllCountry";
import { LogOut } from "../../feature/auth/apis/logout";
import { logoutConCurrLoginAction } from "../../feature/auth/redux/action/logoutConCurrLoginAction";

interface PhoneInputWithDropdownProps {
  label: string;
  name: string;
  register: any;
  isdCodeName: string;
  isdCodeRegister: any;
  required?: boolean;
  requiredMessage?: string;
  regexPattern?: RegExp;
  regexMessage?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  defaultValue?: string | number;
  error?: any;
  disabled?: boolean;
  placeholder?: string;
  dropdownDefaultValue?: string;
  inputDefaultValue?: string;
}

interface Country {
  id: number;
  name: string;
  code: string;
  isd_code: string;
  is_active: boolean;
}

const GuestLoginCellPhone: React.FC<PhoneInputWithDropdownProps> = ({
  label,
  name,
  register,
  isdCodeName,
  isdCodeRegister,
  required = false,
  requiredMessage = "This field is required",
  regexPattern,
  regexMessage = "Invalid Format",
  min,
  max,
  minLength,
  maxLength,
  dropdownDefaultValue, // New prop for dropdown default value
  inputDefaultValue, // New prop for input default value
  error,
  disabled = false,
  placeholder,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [gotSessionId, setGotSessionId] = useState(false);
  const [gotCountries, setGotCountries] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [sessionID, setSessionID] = useState("");

  useEffect(() => {
    dispatch(
      opsLoginAction({ username: "odooapi@vayana.com", password: "Vayana@123" })
    ).then((data) => {
      //   console.log("LOGIN RESPONSE", data);
      if (data.payload?.result.session_id) {
        setSessionID(data.payload?.result.session_id);
        setGotSessionId(true);
        // console.log(data.payload?.result);
        // alert(data.payload?.result);
      } else {
        alert("Something went wrong");
      }

      //   doLogin(data.payload, () => {
      //     // // console.log("login Success");
      //     // navigate("/ops/dashboard");
      //     setGotSessionId(true);
      //   });
    });
  }, [dispatch]);

  useEffect(() => {
    if (gotSessionId) {
      getAllCountry(sessionID)
        .then((response: any) => {
          if (response && response.result && response.result.result) {
            setCountries(response.result.result);
            setGotCountries(true);
            setSessionID("");
          } else {
            throw new Error("Failed to fetch countries");
          }
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
          alert("Unable to load country data. Please try again later.");
          return false; // Prevent further execution if within a form
        });
    }
  }, [gotSessionId]);

  useEffect(() => {
    if (gotCountries) {
      const userId = getUserUid();
      dispatch(logoutConCurrLoginAction(userId));
    }
  }, []);
  return (
    // <></>
    <Form.Group as={Col} className="align-items-center">
      <Row className="align-items-center">
        <Form.Label htmlFor={name} column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={3}>
          <Form.Select
            as="select"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            {...isdCodeRegister(isdCodeName, {
              required: required ? requiredMessage : false,
            })}
            disabled={disabled}
            defaultValue={dropdownDefaultValue} // Use 'defaultValue' here
          >
            <option value="">ISD</option>
            {countries.map((country, index) => (
              <option key={index} value={country.isd_code}>
                {country.isd_code}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={5} style={{ marginLeft: "-3%" }}>
          <Form.Control
            type="text"
            className={`custom-input ${error ? "is-invalid" : ""}`}
            {...register(name, {
              required: required ? requiredMessage : false,
              pattern: {
                value: regexPattern,
                message: regexMessage,
              },
              minLength: {
                value: minLength,
                message: `Minimum length is ${minLength} characters`,
              },
              maxLength: {
                value: maxLength,
                message: `Maximum length is ${maxLength} characters`,
              },
              min: {
                value: min,
                message: `Value must be greater than or equal to ${min}`,
              },
              max: {
                value: max,
                message: `Value must be less than or equal to ${max}`,
              },
              value: inputDefaultValue, // Use the prop for input default value
            })}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default GuestLoginCellPhone;
