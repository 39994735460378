import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getAllCurrencies } from "../../../feature/apis/currency/getAllCurrencies";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getAppAction } from "../../../feature/opsUser/ppApplication/ppIndia/redux/action/getAppAction";

interface CorrBankCurrenciesProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  handleChange?: () => JSX.Element;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
  corrBankCurrencies?: CorrBankCurrenciesData[];
}

interface CorrBankCurrenciesData {
  id: number;
  sr_no: number;
  currency_name: string;
  currency_code: string;
  master_status: string;
  transaction_status: string;
}

const CorrBankCurrencies: React.FC<CorrBankCurrenciesProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  handleChange,
  defaultValue,
  disabled,
  error,
  corrBankCurrencies,
}) => {
  const token = getSessionId();
  let { appId } = useParams();
  // let customerId = Number(appId);
  // const customerDetails = useSelector(
  //   (state: RootState) => state.opsPpIndApp.getApp
  // );

  // const appCurrencies = useSelector(
  //   (state: RootState) => state.opsPpIndApp.getApp.data.currency_ids
  // );

  // const dispatch = useDispatch<AppDispatch>();
  // const [corrBankCurrencyValues, setCorrBankCurrencyValues] = useState<
  //   CorrBankCurrenciesData[]
  // >([]);

  // useEffect(() => {
  //   if (customerDetails.data && customerDetails.data.currency_ids) {
  //     const corrBankCurrencies = corrBankCurrencyValues.filter((currency) =>
  //       customerDetails.data.currency_ids.includes(currency.id)
  //     );
  //     setCorrBankCurrencyValues(corrBankCurrencies);
  //     // console.log("All Corr Currencies--> ", corrBankCurrencies);
  //   }
  // }, [customerDetails, corrBankCurrencyValues]);

  // useEffect(() => {
  //   getAllCurrencies(token).then((response) => {
  //     // console.log("All Corr Currencies--> ", response);
  //     setCorrBankCurrencyValues(response.result.result);
  //     dispatch(getAppAction(customerId));
  //   });
  // }, []);

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Select
            as="select"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            onChange={handleChange}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            defaultValue={defaultValue}
            disabled={disabled}
          >
            <option value="">Select Option</option>
            {corrBankCurrencies?.map((corrBankCurrencyValue, index) => (
              <option key={index} value={corrBankCurrencyValue.id}>
                {corrBankCurrencyValue.currency_code}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default CorrBankCurrencies;
