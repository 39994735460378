import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import { fetchHeaderRecord } from "../../apis/fetchHeaderRecord";

export const fetchHeaderDetails = createAsyncThunk(
  "fetchHeaderDetails",
  async (customerId: number) => {
    const response = await fetchHeaderRecord(customerId, getSessionId());
    // // console.warn("in action file", response);
    return response;
  }
);
