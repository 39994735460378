import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getAllApplicationStatus } from "../../apis/getAllApplicationStatus";

type StageDataArgs = {
  appStage: string;
  currentPage: number;

};

export const getAllApplicationStageAction = createAsyncThunk(
  "getApplicationStage",
  async ( args: StageDataArgs ) => {
    const {appStage, currentPage} = args;
    const response = await getAllApplicationStatus( appStage, currentPage);
    return response.data;
  }
);
