import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

export const getAddressFromCin = (cin: string) => {
  const sessionid = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}business/fetch/cin/?session_id=${sessionid}`,
      {
        params: {
          karza_key: "2frub1Pqjf2FzgDb",
          cin: `${cin}`,
        },
      }
    )
    .then((response): any => response.data)
    .catch((err) => err);
};
