import React from "react";
import { Col, Container, Form, ListGroup, Row, Table } from "react-bootstrap";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import { useForm } from "react-hook-form";
import { VerificationDetailsType } from "../../../../verificationStage/redux/types/VerificationDetailsType";
import { MdCloudUpload, MdOutlineDeleteOutline, MdSave } from "react-icons/md";
import IconInput from "../../../../../components/common/IconInput";
import Input from "../../../../../components/common/Input";

const VerFieldsVerification: React.FC = () => {
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<VerificationDetailsType>({
    shouldUnregister: true,
  });

  const onSaveVerificationField = () => {
    // console.warn("saveClicked");
  };
  return (
    <>
      <Container>
        <Row>
          <p className="mt-3" style={{ color: "#214382" }}>
            FIELD VERIFICATION :
          </p>
        </Row>
        <Row>
          <Col>
            <Dropdown
              label="Section"
              name="section_dropdown"
              register={register}
              options={[
                { label: "Business Details", value: "business_details" },
                { label: "Address Details", value: "address_details" },
                { label: "Bank Details", value: "bank_details" },
                { label: "Key Person Details", value: "key_person_details" },
              ]}
              // error={errors.field_section_dropdown?.message}
              defaultValue="business_details"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Dropdown
              label="Field"
              name="field_dropdown"
              register={register}
              options={[
                { label: "Business Details", value: "business_details" },
                { label: "Address Details", value: "address_details" },
                { label: "Bank Details", value: "bank_details" },
                { label: "Key Person Details", value: "key_person_details" },
              ]}
              // error={errors.field_selection_dropdown?.message}
            />
          </Col>
        </Row>
        <Row>
          <Form>
            <Row>
              <Dropdown
                label="API Verification Status"
                name="api_verification_status"
                register={register}
                disabled
                error={errors.field_api_verification_status?.message}
                requiredMessage="API verification status is required"
                options={[
                  { label: "Success", value: "s" },
                  { label: "Failure", value: "f" },
                ]}
              />
            </Row>
            <Row>
              <Input
                label="API Verification Remarks"
                name="api_verification_remarks"
                register={register}
                disabled
                requiredMessage="API verification remarks is required"
                error={errors.field_api_verification_remarks?.message}
              />
            </Row>
            <Row>
              <Dropdown
                label="Verification Status"
                name="verification_status"
                register={register}
                error={errors.field_verification_status?.message}
                required
                requiredMessage="Verification Status is required"
                options={[
                  { label: "Success", value: "s" },
                  { label: "Failure", value: "f" },
                ]}
              />
            </Row>
            <Row>
              <Input
                label="Verification Remarks"
                name="verification_remarks"
                register={register}
                required
                requiredMessage="Verification Remark is required"
                error={errors.field_verification_remarks?.message}
              />
            </Row>
            <Row>
              <Form.Group controlId="formFileSm" className="mb-3">
                <Form.Label>Supporting Document :</Form.Label>
                <Form.Control type="file" size="sm" />
              </Form.Group>
              <Col
                className="text-end"
                style={{
                  marginTop: "-15px",
                }}
              >
                <IconInput
                  icon={<MdCloudUpload />}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <IconInput icon={<MdOutlineDeleteOutline />} />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-end">
                <IconInput
                  icon={<MdSave />}
                  onClick={handleSubmit(onSaveVerificationField)}
                />
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <p className="mt-3" style={{ color: "#214382" }}>
            COMMENTS HISTORY :
          </p>
        </Row>
        <Row>
          <ListGroup variant="flush" className="m-2">
            <ListGroup.Item
              style={{
                color: "#214382",
              }}
            >
              <p> 23-Jan-2023 USER1 - Maker</p>
              <i> Field quality is not good </i>
            </ListGroup.Item>
            <ListGroup.Item
              style={{
                color: "#214382",
              }}
            >
              <p> 25-Mar-2023 USER2 - Checker</p>
              <i> Field quality is very good </i>
            </ListGroup.Item>
          </ListGroup>
        </Row>
        <Row>
          <p className="mt-3" style={{ color: "#214382" }}>
            SUPPORTING DOCUMENTS :
          </p>
        </Row>
        <Row>
          <Col md={12}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <td>Doc</td>
                  <td>File</td>
                  <td>Comments</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td colSpan={2}>Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VerFieldsVerification;
