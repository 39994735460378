import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { deleteAddressAction } from "../action/deleteAddressAction";
// import { deleteKeyPersonAction } from "../action/deleteKeyPersonAction";
// import { deleteAddressAction } from "../action/deleteAddressAction";

const initialState: CreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const deleteAddressSlice = createSlice({
  name: "deleteAddressSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteAddressAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAddressAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteAddressAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const deleteAddressReducer = deleteAddressSlice.reducer;
