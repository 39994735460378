import React from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { getRegisteredAddress } from "../redux/action/getRegisteredAddress";
import VerRegisteredAddress from "./verRegisteredAddress";
import VerCommunicationAddress from "./verCommunicationAddress";
import { getCommunicationAddress } from "../redux/action/getCommunicationAddress";

const AddressDetailsVerification: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { appId } = useParams();
  const customerId = Number(appId);

  const handleRegisteredClick = async () => {
    // // console.warn("Reg clicked");
    try {
      const result = await dispatch(getRegisteredAddress(customerId));
      if (result.payload) {
        // console.log("in main file-->", result.payload);
      } else {
        // console.warn("Something went wrong!");
      }
    } catch (error) {
      // console.error("An error occured", error);
    }
  };

  const handleCommunicationClick = async () => {
    try {
      const result = await dispatch(getCommunicationAddress(customerId));
      if (result.payload) {
        // console.log("in main comm file-->", result.payload);
      } else {
        // console.warn("Something went wrong!");
      }
    } catch (error) {
      // console.error("An error occured", error);
    }
  };

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="A1">
          <Accordion.Header onClick={handleRegisteredClick}>
            Registered Address
          </Accordion.Header>
          <Accordion.Body>
            <VerRegisteredAddress />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="A2">
          <Accordion.Header onClick={handleCommunicationClick}>
            Communication Address
          </Accordion.Header>
          <Accordion.Body>
            <VerCommunicationAddress />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default AddressDetailsVerification;
