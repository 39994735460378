import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import ForeignFinancierKYC from "./components/ForeignFinancierKYC";
import Header from "../../../../components/common/Header";

const GuestForeignFinancierKYC: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <ForeignFinancierKYC />
    </>
  );
};

export default GuestForeignFinancierKYC;
