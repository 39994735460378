import { createSlice } from "@reduxjs/toolkit";
import { UpdateAppData } from "../types/UpdateAppType";
import { updateKeyPersonAction } from "../action/updateKeyPersonAction";

const initialState: UpdateAppData = {
  data: {},
  loading: false,
  error: null,
};

const updateKeyPersonSlice = createSlice({
  name: "updateKeyPersonSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateKeyPersonAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateKeyPersonAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateKeyPersonAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const updateKeyPersonReducer = updateKeyPersonSlice.reducer;
