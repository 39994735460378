import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CheckBoxes from "../../../../components/common/CheckBoxes";
import { Container, Row, Col, Form } from "react-bootstrap";
import SectionHeading from "../../../../components/common/SectionHeading";
import { useForm, SubmitHandler } from "react-hook-form";
import { getAllCurrencies } from "../../../apis/currency/getAllCurrencies";
import { getSessionId } from "../../../../utils/tokenHandlers";
import Button from "../../../../components/common/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { updateAppAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/updateAppAction";
import { useParams } from "react-router-dom";
import AlertPopup from "../../../../components/common/Alert/AlertPopup";

interface CurrencyProps {
  required?: boolean;
  requiredMessage?: string;
  defaultValues: number[];
  error?: any; // Add error prop
  setSelectedCurrencies: Dispatch<SetStateAction<number[]>>;
}

interface CurrencyData {
  id: number;
  sr_no: number;
  currency_name: string;
  currency_code: string;
  master_status: string;
  transaction_status: string;
}

type FormValues = {
  currency_ids: number[];
};

const Currencies: React.FC<CurrencyProps> = ({
  defaultValues: initialDefaultValues,
  setSelectedCurrencies,
}) => {
  const token = getSessionId();
  let { appId } = useParams();
  let customerId = Number(appId);
  const updateState = useSelector(
    (state: RootState) => state.opsPpIndApp.updateApp
  );
  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data
  );
  let stage = getAppSelector.stage;
  const dispatch = useDispatch<AppDispatch>();
  const { register, watch } = useForm<FormValues>();
  const currency_ids = watch("currency_ids");
  setSelectedCurrencies(currency_ids);
  const [allCurrencies, setAllCurrencies] = useState<CurrencyData[]>([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const defaultValues = initialDefaultValues || [];

  // Determine if the "Save" button should be enabled
  const isSaveDisabled =
    !currency_ids || currency_ids.length === 0 || !dataRetrieved;

  // const isSaveDisabled = currency_ids.length === 0;

  // useEffect(() => {
  //   if (updateState.data && updateState.data.result) {
  //     setShowSuccessAlert(true);
  //   }
  // }, [updateState]);

  const handleFormSubmit = () => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      //  // console.log("SELECTED CURRENCIES", currency_ids);
      //  // console.log("Currency CustomerId", customerId);
      setShowSuccessAlert(false);
      setShowWarningAlert(false);

      dispatch(
        updateAppAction({
          customerId: customerId,
          updateCustomerData: { currency_ids: currency_ids.map(Number) },
        })
      ).then((response: any) => {
        let result = response;
        //  // console.log("here", result);
        if (result.payload.result.error) {
          alert(result.payload.result.error.data.message);
        } else {
          setShowSuccessAlert(true);
        }
      });
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  useEffect(() => {
    getAllCurrencies(token).then((response) => {
      //  // console.log("pp currencies--", response);
      setAllCurrencies(response.result.result);
      //   // console.log("SetCurrPP---", allCurrencies);
      setSelectedCurrencies(defaultValues);
      //   // console.log("default--", defaultValues);
      setDataRetrieved(true);
    });
  }, []);

  return (
    <>
      {showWarningAlert && (
        <AlertPopup message="Please Select a Currency" variant="warning" />
      )}
      {showSuccessAlert && (
        <AlertPopup
          message="Currencies updated Successfully!"
          variant="success"
        />
      )}
      <Form>
        <Row>
          <Col
            md={20}
            className="text-center mt-4"
            style={{ color: "#214382" }}
          >
            <SectionHeading text="CURRENCIES" />
            <Row>
              <Col className="position-static">
                <Form>
                  {allCurrencies.map((currency, index) => (
                    <Form.Check
                      key={index}
                      inline
                      // onClick={handleCurrencyClick()}
                      label={currency.currency_code}
                      type="checkbox"
                      value={currency.id}
                      defaultChecked={defaultValues.includes(currency.id)}
                      {...register("currency_ids", {
                        shouldUnregister: false,
                        required: "Atlest One Currency should be selected",
                      })}
                    />
                  ))}
                </Form>
                <Row>
                  <Col md={20} className="text-end">
                    <Button
                      label="Save Details"
                      onClick={handleFormSubmit}
                      disabled={isSaveDisabled}
                    ></Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Currencies;

// import React, { useEffect, useState } from "react";
// import CheckBoxes from "../../../../components/common/CheckBoxes";
// import { Container, Row, Col, Form } from "react-bootstrap";
// import SectionHeading from "../../../../components/common/SectionHeading";
// import { useForm, SubmitHandler } from "react-hook-form";
// import { getAllCurrencies } from "../../../apis/currency/getAllCurrencies";
// import { getSessionId } from "../../../../utils/tokenHandlers";

// interface CurrencyProps {
//   required?: boolean;
//   requiredMessage?: string;
//   defaultValue?: string;
//   error?: any; // Add error prop
// }

// interface CurrencyData {
//   id: number;
//   sr_no: number;
//   currency_name: string;
//   currency_code: string;
//   master_status: string;
//   transaction_status: string;
// }

// type FormValues = {
//   currency_ids: number[];
// };

// const Currencies: React.FC<CurrencyProps> = () => {
//   const token = getSessionId();

//   const { register, handleSubmit, watch } = useForm<FormValues>();
//   const currency_ids = watch("currency_ids");

//   const [allCurrencies, setAllCurrencies] = useState<CurrencyData[]>([]);

//   const handleFormSubmit: SubmitHandler<FormValues> = (data, event) => {
//     event?.preventDefault();
//     // console.log(data.currency_ids);
//   };

//   useEffect(() => {
//     getAllCurrencies(token).then((response) => {
//       setAllCurrencies(response.result.result);
//     });
//   }, []);
//   return (
//     <Form>
//       <Row>
//         <Col md={20} className="text-center mt-4" style={{ color: "#214382" }}>
//           <SectionHeading text="CURRENCIES" />
//           <Row>
//             <Col className="position-static">
//               {/* <CheckBoxes
//               name="checkboxGroup"
//               label=""
//               options={[
//                 { label: "USD", value: "USD" },
//                 { label: "IND", value: "IND" },
//                 { labe l: "GBP", value: "GBP" },
//                 { label: "JPR", value: "JPR" },
//                 // Add more options as needed
//               ]}
//               register={register}
//               required
//             //   error={errors.checkboxGroup?.message}
//             /> */}
//               <Form onSubmit={handleSubmit(handleFormSubmit)}>
//                 {allCurrencies.map((currency, index) => (
//                   <Form.Check
//                     key={index}
//                     inline
//                     label={currency.currency_code}
//                     type="checkbox"
//                     value={currency.id}
//                     {...register("currency_ids", {
//                       shouldUnregister: false,
//                       required: "Atlest One Currency should be selected",
//                     })}
//                   />
//                 ))}
//                 <button type="submit">Submit</button>
//               </Form>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </Form>
//   );
// };

// export default Currencies;
