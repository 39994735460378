import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login } from "../../apis/login";
import { Login, LoginData } from "../types/LoginTypes";
import { opsLoginAction } from "../action/opsLoginAction";

const initialState: Login = {
  data: null,
  loading: false,
  error: null,
};

const opsLoginSlice = createSlice({
  name: "auth/opsLoginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(opsLoginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(opsLoginAction.fulfilled, (state, action) => {
        //// console.log(action.payload);
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(opsLoginAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const opsLoginReducer = opsLoginSlice.reducer;
