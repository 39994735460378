import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getAppAction } from "../action/getAppAction";

const initialState: CreateAppData = {
  data: {},
  loading: false,
  error: null,
};

const getAppSlice = createSlice({
  name: "getAppSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAppAction.fulfilled, (state, action) => {
        // console.log("action payload getApp---> ", action);
        state.loading = false;
        state.data = action.payload.result;
        // console.log("DATA ---> ", action.payload);
      })
      .addCase(getAppAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getAppReducer = getAppSlice.reducer;
