import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getAllAddressAction } from "../action/getAllAddressAction";

const initialState: CreateAppData = {
  data: [],
  loading: false,
  error: null,
};

const getAllAddressSlice = createSlice({
  name: "getAllAddressSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAddressAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAddressAction.fulfilled, (state, action) => {
        // console.log("All Address Slice ---> ", action);
        state.loading = false;
        state.data = action.payload.result.result;
      })
      .addCase(getAllAddressAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getAllAddressReducer = getAllAddressSlice.reducer;
