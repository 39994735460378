import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { CorrBankType } from "../../../../../guestUser/common/types/GuestCorrBankDetails";
import { updateCorrBank } from "../../apis/updateCorrBank";

export const updateCorrBankAction = createAsyncThunk(
  "updateCorrBankAction",
  async ({
    corrBankId,
    updateCorrBankData,
  }: {
    corrBankId: number;
    updateCorrBankData: CorrBankType;
  }) => {
    return updateCorrBank(corrBankId, updateCorrBankData, getSessionId()).then(
      (response) => {
        // console.log("Update Corr Bank response", response);
        return response.data;
      }
    );
  }
);
