import axios from "axios";

export const uploadAttachment = (
  fileName: string,
  file: any,
  sessionId: string | null
) => {
  // Create a FormData object to construct the form-data body
  const formData = new FormData();

  // Append key-value pairs to the FormData object
  formData.append("name", fileName);
  formData.append("type", "binary");
  formData.append("datas", file);

  return axios
    .post(`${process.env.REACT_APP_BASE_URL}api/upload/image`, formData, {
      params: {
        session_id: `${sessionId}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
