import { Accordion, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import React from "react";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";

const VerCommunicationAddress: React.FC = () => {
  const state = useSelector(
    (state: RootState) => state.verification.verCommAddressDetails
  );
  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );

  const shippingAddrId = getAppSelector.data.business_shipping_address_type_id;
  const billingAddrId = getAppSelector.data.business_billing_address_type_id;
  const isIndia = getAppSelector.data.business_registration_country_id === 448;

  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }

  const commAddressDetailsStore: Array<{
    id: number;
    line_1: string;
    line_2: string;
    city: string;
    district: string;
    state: string;
    pincode: string;
    country_id: { name: string };
    phone: string;
    phone_country_code: string;
    email: string;
    billing_gstn: string;
    business_billing_gstn: string;
    business_billing_preference_email: string;
    business_communication_preference_email: string;
  }> = state.data.data.result.result;

  return (
    <Accordion>
      {commAddressDetailsStore.map((address, index) => (
        <Accordion.Item key={index} eventKey={`C${index}`}>
          <Accordion.Header>Communication Address {index + 1}</Accordion.Header>
          <Accordion.Body>
            <Row md={12}>
              <Col md={12}>
                <VerBusinessDetails
                  label="Address Line 1"
                  defaultValue={address.line_1}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col md={12}>
                <VerBusinessDetails
                  label="Address Line 2"
                  defaultValue={address.line_2}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col md={6}>
                <VerBusinessDetails label="City" defaultValue={address.city} />
              </Col>
              <Col md={6}>
                <VerBusinessDetails
                  label="District"
                  defaultValue={address.district}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col md={6}>
                <VerBusinessDetails
                  label="State/ County"
                  defaultValue={address.state}
                />
              </Col>
              <Col md={6}>
                <VerBusinessDetails
                  label="Country"
                  defaultValue={address.country_id.name}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col md={12}>
                <VerBusinessDetails
                  label="Email"
                  defaultValue={address.email}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col md={6}>
                <VerBusinessDetails
                  label="PIN/ ZIP Code"
                  defaultValue={address.pincode}
                />
              </Col>
              <Col md={6}>
                <VerBusinessDetails
                  label="Cellphone"
                  defaultValue={address.phone_country_code + -+address.phone}
                />
              </Col>
            </Row>
            {isIndia && (
              <Row md={12}>
                <Col md={12}>
                  <VerBusinessDetails
                    label="Billing GSTIN"
                    defaultValue={address.business_billing_gstn}
                  />
                </Col>
              </Row>
            )}
            <Row md={12}>
              <Col md={12}>
                <VerBusinessDetails
                  label="Billing Preference"
                  defaultValue={address.business_billing_preference_email}
                />
              </Col>
            </Row>

            <Row md={12}>
              <Col md={12}>
                <VerBusinessDetails
                  label="Communication Preference"
                  defaultValue={address.business_communication_preference_email}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col md={6}>
                <VerBusinessDetails
                  label="Is Shipping Address"
                  defaultValue={address.id === shippingAddrId ? "Yes" : "No"}
                />
              </Col>
              <Col md={6}>
                <VerBusinessDetails
                  label="Is Billing Address"
                  defaultValue={address.id === billingAddrId ? "Yes" : "No"}
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default VerCommunicationAddress;
