import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCorrBank } from "../../apis/fetchCorrBank";

export const getCorrBank = createAsyncThunk(
  "getCorrBank",
  async (settlementBankId: number) => {
    const response = await fetchCorrBank(settlementBankId);
    // console.log("in corr bank action-->", response);
    return response;
  }
);
