import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { deleteLocalBank } from "../../apis/deleteLocalBank";

export const deleteLocalBankAction = createAsyncThunk(
  "deleteLocalBankAction",
  async (localBankId: number) => {
    return deleteLocalBank(localBankId, getSessionId()).then(
      (response: any) => {
        return response.data;
      }
    );
  }
);
