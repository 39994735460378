import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchLocalBank } from "../../apis/fetchLocalBank";

export const getLocalBank = createAsyncThunk(
  "getLocalBank",
  async (customerId: number) => {
    const response = await fetchLocalBank(customerId);
    // console.log("in local bank action-->", response);
    return response;
  }
);
