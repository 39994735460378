import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { updateApplication } from "../../apis/updateApplication";
import { BusinessDetailsType } from "../../pages/types/BusinessDetailsType";

export const updateAppAction = createAsyncThunk(
  "updateAppAction",
  async ({
    customerId,
    updateCustomerData,
  }: {
    customerId: number;
    updateCustomerData: BusinessDetailsType;
  }) => {
    return updateApplication(
      customerId,
      updateCustomerData,
      getSessionId()
    ).then((response: { data: any }) => {
      // console.log("response ---> ", response);
      return response.data;
    });
  }
);
