import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getCorrBanksOfCurrencyAction } from "../action/getCorrBanksOfCurrency";

const initialState: CreateAppData = {
  data: {},
  loading: false,
  error: null,
};

const getCorrBanksOfCurrencySlice = createSlice({
  name: "getCorrBanksOfCurrencySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCorrBanksOfCurrencyAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCorrBanksOfCurrencyAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result;
      })
      .addCase(getCorrBanksOfCurrencyAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getCorrBanksOfCurrencyReducer =
  getCorrBanksOfCurrencySlice.reducer;
