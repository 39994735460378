import React, { useEffect, useState } from "react";
import { Accordion, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { getKeyPersonDetails } from "../redux/action/getKeyPersonDetails";
import VerIndividual from "./verIndividual";
import Button from "../../../components/common/Button";
import { getUserRole } from "../../../utils/tokenHandlers";
import { SubmitHandler, useForm } from "react-hook-form";
import { SendBackComments } from "../redux/types/VerificationDetailsType";
import { getAppAction } from "../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import TextArea from "../../../components/common/Textarea";
import { updateStageAction } from "../../opsUser/ppApplication/ppIndia/redux/action/updateStageAction";
import { updateSendBackComments } from "../redux/action/updateSendBackComments";
import { fieldValidationAction } from "../../opsUser/ppApplication/ppIndia/redux/action/fieldValidation";

const IndividualVerification: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { appId } = useParams();
  const customerId = Number(appId);
  const navigate = useNavigate();
  const [sendBacktoMaker, setSendBacktoMaker] = useState(false);
  const [sendBacktoUser, setSendBacktoUser] = useState(false);
  const [viewMakerComments, setViewMakerComments] = useState(false);
  const useRole = getUserRole();
  const [showSpinner, setShowSpinner] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SendBackComments>({
    shouldUnregister: true,
  });
  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  const appStage = state.data.stage;

  const handleKeyPersonDetailsClick = async () => {
    // console.log("iid-->", customerId);
    // console.log("KP Clicked");
    try {
      const result = await dispatch(getKeyPersonDetails(customerId));
      if (result.payload) {
        // console.warn("in main file-->", result.payload);
      } else {
        // console.warn("Some thing went wrong");
      }
    } catch (error) {
      // console.error("An error occured", error);
    }
  };

  const handleVerSubmit: SubmitHandler<SendBackComments> = async (
    commentsData
  ) => {
    if (useRole === "maker") {
      setShowSpinner(true);
      // console.log(commentsData);
      dispatch(updateSendBackComments({ customerId, commentsData })).then(
        (response: any) => {
          if (response.payload.result === true) {
            navigate(`/duedilligence/${customerId}`);
            setShowSpinner(false);
          } else {
            alert("Something went wrong! Please contact admin");
            setShowSpinner(false);
          }
          // console.log("comments data-->", response);
        }
      );
    } else if (useRole === "checker") {
      if (sendBacktoMaker) {
        // alert("send maker");

        setShowSpinner(true);
        let stage = `move_to_maker_verification`;
        dispatch(updateSendBackComments({ customerId, commentsData })).then(
          (response: any) => {
            if (response.payload.result === true) {
              dispatch(fieldValidationAction({ customerId, stage })).then(
                (response) => {
                  // console.log("Api resp-->", response);
                  if (response.payload.error) {
                    alert(response.payload.error.data.message);
                    setShowSpinner(false);
                    // // console.warn(validationErr);
                    // openValidationModal(validationErr);
                  } else {
                    navigate(`/ops/dashboard`);
                    setShowSpinner(false);
                  }
                }
              );
            } else {
              setShowSpinner(false);
              alert("Something went wrong! Please contact admin");
            }
            // console.log("comments data-->", response);
          }
        );
      } else if (sendBacktoUser) {
        // alert("send user");
        setShowSpinner(true);
        let stage = `move_stage_to_in_form_filling`;
        dispatch(updateSendBackComments({ customerId, commentsData })).then(
          (response: any) => {
            if (response.payload.result === true) {
              dispatch(fieldValidationAction({ customerId, stage })).then(
                (response) => {
                  // console.log("Api resp-->", response);
                  if (response.payload.error) {
                    setShowSpinner(false);
                    alert(response.payload.error.data.message);
                    // // console.warn(validationErr);
                    // openValidationModal(validationErr);
                  } else {
                    navigate(`/ops/dashboard`);
                    setShowSpinner(false);
                  }
                }
              );
            } else {
              setShowSpinner(false);
              alert("Something went wrong! Please contact admin");
            }
            // console.log("comments data-->", response);
          }
        );
      } else {
        navigate(`/duedilligence/${customerId}`);
      }
      // setShowSpinner(false);
      // console.log(commentsData);
    }

    // navigate(`/verification/individual-details/${customerId}`);
  };

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    reset({
      in_verification_comments: state.data.in_verification_comments,
      in_form_filling_comments: state.data.in_form_filling_comments,
    });
  }, [reset, state]);

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="I1">
          <Accordion.Header onClick={handleKeyPersonDetailsClick}>
            Key Persons/ Authorised officials/ Administrators and Beneficial
            Owners, etc
          </Accordion.Header>
          <Accordion.Body>
            <VerIndividual />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row className="mt-3">
        <Col>
          {useRole === "maker" && (
            <TextArea
              label={"Add comments"}
              name={"in_form_filling_comments"}
              register={register}
              placeholder="Add Comments for Checker"
              required
              requiredMessage="Comments are required"
              error={errors.in_form_filling_comments?.message}
              disabled={
                appStage === "Expired" ||
                appStage === "Customer Registered" ||
                appStage === "In Customer Registration" ||
                appStage === "New / Draft" ||
                appStage === "In Form Signing"
              }
            />
          )}
          {useRole === "checker" && appStage === "In Checker Verification" && (
            <>
              <Button
                label={"Send back to Verification"}
                variant="outline-primary"
                onClick={() => {
                  setSendBacktoMaker(true);
                  setSendBacktoUser(false);
                  setViewMakerComments(false);
                }}
                disabled={
                  appStage === "Expired" ||
                  appStage === "Customer Registered" ||
                  appStage === "In Customer Registration"
                }
              />
              <Button
                label={"Send back to Form Filling"}
                variant="outline-primary"
                onClick={() => {
                  setSendBacktoMaker(false);
                  setSendBacktoUser(true);
                  setViewMakerComments(false);
                }}
                disabled={
                  appStage === "Expired" ||
                  appStage === "Customer Registered" ||
                  appStage === "In Customer Registration"
                }
              />
              <Button
                label={"View Maker Comments"}
                variant="outline-primary"
                onClick={() => {
                  setSendBacktoMaker(false);
                  setSendBacktoUser(false);
                  setViewMakerComments(true);
                }}
                disabled={
                  appStage === "Expired" ||
                  appStage === "Customer Registered" ||
                  appStage === "In Customer Registration"
                }
              />
            </>
          )}
          {sendBacktoMaker === true && (
            <Row className="mt-2">
              <TextArea
                label={"Add comments for Maker"}
                name={"in_verification_comments"}
                register={register}
                placeholder="Add Comments for Maker"
                required
                requiredMessage="Comments are required"
                error={errors.in_verification_comments?.message}
              />
            </Row>
          )}
          {sendBacktoUser === true && (
            <Row className="mt-2">
              <TextArea
                label={"Add/View(Maker) comments"}
                name={"in_form_filling_comments"}
                register={register}
                placeholder="Add Comments for User"
                required
                requiredMessage="Comments are required"
                error={errors.in_form_filling_comments?.message}
              />
            </Row>
          )}
          {viewMakerComments === true && (
            <Row className="mt-2">
              <TextArea
                label={"View Maker comments"}
                name={"in_form_filling_comments"}
                register={register}
                disabled
                // placeholder="Add Comments for User"
                // required
                // requiredMessage="Comments are required"
                // error={errors.in_form_filling_comments?.message}
              />
            </Row>
          )}

          {/* <Button
            label={
              useRole == "checker"
                ? "Send back to Maker"
                : "Send back for Correction"
            }
            variant="outline-primary"
            onClick={handleSendBack}
          /> */}
        </Col>
      </Row>
      {(useRole === "maker" || useRole === "checker") && (
        <Row>
          <Col className="text-end mt-3">
            {showSpinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>
                {appStage === "Sent back to maker" ||
                appStage === "In Checker Verification" ||
                appStage === "In Maker Verification" ? (
                  <Button
                    label="Submit & Next"
                    variant="primary"
                    onClick={handleSubmit(handleVerSubmit)}
                  />
                ) : (
                  <>
                    {appStage === "Customer Registered" ||
                    appStage === "In Customer Registration" ? (
                      <Button
                        label={"Save & Next"}
                        variant="primary"
                        onClick={() => {
                          navigate(`/duedilligence/${customerId}`);
                        }}
                      />
                    ) : (
                      <Button
                        label="Home"
                        variant="primary"
                        onClick={() => {
                          navigate(`/ops/dashboard`);
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default IndividualVerification;
