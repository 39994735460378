import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useState } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import {
  MdOutlineModeEditOutline,
  MdOutlineDeleteOutline,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import Button from "../../../../../components/common/Button";
import Modal from "react-bootstrap/Modal";
import CorrBankCurrencies from "../../../../../components/common/dropdowns/CorrBankCurrencies";
import { CorrBankType } from "../../types/GuestCorrBankDetails";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { createCorrBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/createCorrBankAction";
import { getAllCorrBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAllCorrBankAction";
import { updateCorrBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/updateCorrBankAction";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import { getAllCurrencies } from "../../../../apis/currency/getAllCurrencies";
import { getAppAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import { deleteLocalBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/deleteLocalBankAction";
import { deleteCorrBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/deleteCorrBankAction";
import IsDefaultBank from "../../../../../components/common/IsDefaultBank";
import { getCorrBanksOfCurrencyAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getCorrBanksOfCurrency";
import {
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  SWIFTCODE_REGEX,
} from "../../../../constants/regexPatterns";
import RadioButton from "../../../../../components/common/RadioButton";

interface EditCorrBankProps {
  setCorrBanks?: Dispatch<SetStateAction<never[]>>;
  corrBanks?: any[];
}

interface CorrBankCurrenciesData {
  id: number;
  sr_no: number;
  currency_name: string;
  currency_code: string;
  master_status: string;
  transaction_status: string;
}

const EditCorrBankAccountDetails: React.FC<EditCorrBankProps> = ({
  setCorrBanks,
}) => {
  const token = getSessionId();
  let { appId, bankId } = useParams();
  let localBankId = Number(bankId);
  let customerId = Number(appId);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<CorrBankType>({
    // defaultValues: {
    //   is_default_bank: false,
    // },
  });

  const dispatch = useDispatch<AppDispatch>();

  const [addCorrBank, setAddCorrBank] = useState(false);
  const [editCorrBankId, setEditCorrBankId] = useState<number>(0);
  const [deleteCorrBankId, setDeleteCorrBankId] = useState<number>(0);
  const [showModalWarning, setShowModalWarning] = useState(false);

  const handleDeleteRow = () => {
    dispatch(deleteCorrBankAction(deleteCorrBankId));
    setSelectedRowIndex(null); // Close the modal
  };
  const [showModal, setShowModal] = useState(false);

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const createCorrBankSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.createCorrBank
  );
  const corrBanks = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllCorrBank.data
  );
  const updateCorrBanks = useSelector(
    (state: RootState) => state.opsPpIndApp.updateCorrBank
  );

  const customerDetails = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );

  const deleteCorrBankSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.deleteCorrBank
  );

  const [corrBankCurrencyValues, setCorrBankCurrencyValues] = useState<
    CorrBankCurrenciesData[]
  >([]);

  const corrBanksOfCurrencyCount = useSelector(
    (state: RootState) => state.opsPpIndApp.getCorrBanksOfCurrency.data
  );

  const selectedCurrencyId = watch("currency_id");
  const isDefaultBankChecked = watch("is_default_bank");

  useEffect(() => {
    if (selectedCurrencyId) {
      dispatch(
        getCorrBanksOfCurrencyAction({
          customerId,
          currencyId: selectedCurrencyId,
        })
      );
    }
  }, [selectedCurrencyId]);

  useEffect(() => {
    if (isDefaultBankChecked === true) {
      // Check if 'true' is selected
      if (
        corrBanksOfCurrencyCount.result &&
        corrBanksOfCurrencyCount.result.length > 0 &&
        corrBanksOfCurrencyCount.result[0].id !== editCorrBankId
      ) {
        setDefaultBankWarningModal(true);
      }
    }
  }, [
    corrBanksOfCurrencyCount.result,
    editCorrBankId,
    isDefaultBankChecked,
    updateCorrBanks,
  ]);
  const [getCorrCurrencies, setGetCorrCurrencies] = useState(false);

  useEffect(() => {
    if (getCorrCurrencies === true) {
      if (customerDetails.data && customerDetails.data.currency_ids) {
        const corrBankCurrencies = corrBankCurrencyValues.filter((currency) =>
          customerDetails.data.currency_ids.includes(currency.id)
        );
        setCorrBankCurrencyValues(corrBankCurrencies);
        //  // console.log("All Corr Currencies--> ", corrBankCurrencies);
      }
    }
  }, [customerDetails, getCorrCurrencies === true]);

  useEffect(() => {
    getAllCurrencies(token).then((response) => {
      //  // console.log("All Corr Currencies--> ", response);
      setCorrBankCurrencyValues(response.result.result);
      dispatch(getAppAction(customerId));
      setGetCorrCurrencies(true);
    });
  }, []);

  useEffect(() => {
    dispatch(getAllCorrBankAction(localBankId));
  }, [createCorrBankSelector, updateCorrBanks, deleteCorrBankSelector]);

  const [defaultBankWarningModal, setDefaultBankWarningModal] =
    useState<boolean>(false);

  const [isDefaultValue, setIsDefaultValue] = useState<boolean>(false);
  const handleSwiftCodeChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    setValue("swift_code", value);
  };
  const editCorrBank = (corrBankId: number) => {
    // // console.log("DEFALUT WAR VAL", defaultBankWarningModal);
    setShowModal(true);
    setEditCorrBankId(corrBankId);
    setAddCorrBank(false);
    const corrBank = corrBanks.filter(
      (corrBank: any) => corrBank.id === corrBankId
    );
    //   // console.log(corrBank[0].is_default_bank);
    //   // console.log(corrBank[0].currency_id.id);
    // setIsDefaultValue(corrBank[0].is_default_bank);
    const isDefault = corrBank[0].is_default_bank;

    reset({
      currency_id: corrBank[0].currency_id.id,
      bank_name: corrBank[0].bank_name,
      bank_account_number: corrBank[0].bank_account_number,
      re_enter_bank_account_number: corrBank[0].re_enter_bank_account_number,
      sort_code: corrBank[0].sort_code,
      iban: corrBank[0].iban,
      swift_code: corrBank[0].swift_code,
      is_default_bank: isDefault === true ? "true" : "false",
    });
  };

  useEffect(() => {
    // // console.log("isDefaultBankChecked:", isDefaultBankChecked);
  }, [isDefaultBankChecked]);

  const changeDefaultBank = () => {
    const updateCorrBank = {
      is_default_bank: false,
    };
    dispatch(
      updateCorrBankAction({
        corrBankId: corrBanksOfCurrencyCount.result[0].id,
        updateCorrBankData: updateCorrBank,
      })
    );
    setDefaultBankWarningModal(false); // Close the modal
    setShowModalWarning(false);
    closeModal();
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleAddCorrBank = () => {
    reset({ bank_name: "" });
    setShowModal(true);
    setAddCorrBank(true);
    // setAddCorrBank(true);
  };

  const accountNumber = watch("bank_account_number");
  const confirmAccountNumber = watch("re_enter_bank_account_number");

  const handleCorrBankSubmit = (corrBankDetails: any) => {
    // setAddCorrBank(false);
    // setCorrBanks?.((prevCorrBanks): any => {
    //   // console.log([...prevCorrBanks, corrBankDetails]);
    //   return [...prevCorrBanks, corrBankDetails];
    // });
    // // console.log(corrBanks);

    if (accountNumber !== confirmAccountNumber) {
      setError("re_enter_bank_account_number", {
        type: "manual",
        message: "Account Number do not match",
      });
      return;
    }
    if (addCorrBank) {
      const createCorrBankDetails = {
        ...corrBankDetails,
        currency_id: Number(corrBankDetails.currency_id),
        customer_settlement_bank_id: localBankId,
        is_default_bank:
          corrBankDetails.is_default_bank === "true" ? true : false,
      };
      //   // console.log(createCorrBankDetails, "--corr");
      setAddCorrBank(false);
      dispatch(createCorrBankAction(createCorrBankDetails)).then((response) => {
        if (response.payload.error) {
          //  // console.log("hey", response.payload.error.data.message);
          alert(response.payload.error.data.message);
          setDefaultBankWarningModal(false);
          reset();
          setShowModal(false);
          reset();
        } else {
          reset();
          setShowModal(false);
          // reset();
        }
      });
    } else {
      const createCorrBankDetails = {
        ...corrBankDetails,
        currency_id: Number(corrBankDetails.currency_id),
        customer_settlement_bank_id: localBankId,
        is_default_bank:
          corrBankDetails.is_default_bank === "true" ? true : false,
      };
      //  // console.log(createCorrBankDetails, "--corr edit");
      dispatch(
        updateCorrBankAction({
          corrBankId: editCorrBankId,
          updateCorrBankData: createCorrBankDetails,
        })
      ).then((response) => {
        //   // console.log("response--", response);
        if (response.payload.result.error) {
          //   // console.log("here");
          // setShowModalWarning(true);
          //  // console.log(response.payload.result.error.data.message);
          alert(response.payload.result.error.data.message);
          setShowModal(false);
          reset();
        } else {
          setShowModal(false);
          reset();
        }
      });
    }
  };

  return (
    <Form>
      <Row>
        <Col md={20} className="text-end">
          <Button
            label=" + Add Correspondent Bank"
            onClick={handleAddCorrBank}
            variant="outline-primary"
          ></Button>
        </Col>
      </Row>
      <Table striped bordered size="xxs" style={{ padding: "10%" }}>
        <thead>
          <tr>
            <th style={{ width: "20%" }}>BANK & BRANCH NAME</th>
            <th>ACCOUNT NO</th>
            <th>CURRENCIES</th>
            <th>IS DEFAULT</th>

            <th style={{ width: "10%" }}>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {corrBanks?.map((corrBank: any) => (
            <tr>
              <td>{corrBank.bank_name}</td>
              <td>{corrBank.bank_account_number}</td>
              <td>{corrBank.currency_id.currency_code}</td>
              <td>{corrBank.is_default_bank === true ? "Yes" : "No"}</td>

              <td style={{ color: "#214382" }}>
                <MdOutlineModeEditOutline
                  onClick={() => editCorrBank(corrBank.id)}
                  style={{ width: "33px", height: "24px", cursor: "pointer" }}
                ></MdOutlineModeEditOutline>
                {/* <MdOutlineRemoveRedEye
                      onClick={() => viewCorrBank(corrBank.id)}
                      style={{ width: "33px", height: "24px" }}
                    ></MdOutlineRemoveRedEye> */}
                <MdOutlineDeleteOutline
                  onClick={() => {
                    setSelectedRowIndex(1);
                    setDeleteCorrBankId(corrBank.id);
                  }}
                  style={{
                    width: "33px",
                    height: "24px",
                    color: "red",
                    cursor: "pointer",
                  }}
                  // onClick={() => setSelectedRowIndex(1)}
                ></MdOutlineDeleteOutline>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal size="lg" show={showModal} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <Row>
            <Col md={20} className="mt-4" style={{ color: "#214382" }}>
              <h5>ADD CORRESPONDENT ACCOUNT</h5>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              md={20}
              className="text-center mt-4"
              style={{ color: "#214382" }}
            >
              <h6>CORRESPONDENT BANK</h6>
            </Col>
          </Row>
          <Form
            onSubmit={handleSubmit(handleCorrBankSubmit)}
            style={{ width: "119%" }}
          >
            <Row style={{ marginTop: "30px" }}>
              <Col sm={5} className="d-flex align-items-center">
                {/* {corrBankCurrencyValues.length > 0 && (
                  <CorrBankCurrencies
                    label="Currency"
                    name="currency_id"
                    register={register}
                    required
                    requiredMessage="currency is required"
                    error={errors.currency_id?.message}
                    corrBankCurrencies={corrBankCurrencyValues}
                  />
                )} */}
                <CorrBankCurrencies
                  label="Currency"
                  name="currency_id"
                  register={register}
                  required
                  requiredMessage="currency is required"
                  error={errors.currency_id?.message}
                  corrBankCurrencies={corrBankCurrencyValues}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Sort code"
                  name="sort_code"
                  register={register}
                  placeholder="Enter sort code"
                  // regexPattern={SWIFTCODE_REGEX}
                  requiredMessage="sort code is required"
                  error={errors.sort_code?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Bank name"
                  name="bank_name"
                  register={register}
                  placeholder="Enter bank name"
                  required
                  regexPattern={ALPHABETS_REGEX}
                  requiredMessage="Bank name is required"
                  error={errors.bank_name?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="IBAN"
                  name="iban"
                  register={register}
                  placeholder="Enter IBAN"
                  regexPattern={ALPHANUMERIC_REGEX}
                  // required
                  // requiredMessage="iban is required"
                  error={errors.iban?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Bank account number"
                  name="bank_account_number"
                  register={register}
                  placeholder="Enter bank account no"
                  // required
                  // requiredMessage="bank account number is required"
                  error={errors.bank_account_number?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Swift code"
                  name="swift_code"
                  register={register}
                  // regexPattern={SWIFTCODE_REGEX}
                  placeholder="Enter swift code"
                  required
                  requiredMessage="swift code is required"
                  error={errors.swift_code?.message}
                  onChange={handleSwiftCodeChange}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Re-enter Bank account number"
                  name="re_enter_bank_account_number"
                  type="password"
                  register={register}
                  placeholder="Enter re-enter bank account no"
                  // required
                  // requiredMessage="re enter bank account number is required"
                  error={errors.re_enter_bank_account_number?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <RadioButton
                  label="Is Default Bank"
                  name="is_default_bank"
                  register={register}
                  required
                  requiredMessage="Please select "
                  error={errors.is_default_bank?.message}
                  options={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                  ]}

                  // defaultValue={isDefaultValue === true ? "Yes" : "No"}
                />
              </Col>
            </Row>

            <Button
              label="Cancel"
              onClick={closeModal}
              variant="outline-primary"
            />
            <Button label="Save Details" type="submit" />
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={selectedRowIndex !== null}
        onHide={() => setSelectedRowIndex(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete CORRESPONDENT bank?
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button
            label="Cancel"
            onClick={() => setSelectedRowIndex(null)}
            variant="outline-primary"
          />
          <Button label="Delete" onClick={handleDeleteRow} />
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={
          defaultBankWarningModal &&
          corrBanksOfCurrencyCount.count &&
          isDefaultBankChecked &&
          corrBanksOfCurrencyCount.result[0].id != editCorrBankId
        }
        onHide={() => {
          setDefaultBankWarningModal(false);
          reset({ is_default_bank: false });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set As Default Bank</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to set this bank as default bank?
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="No"
            onClick={() => {
              setDefaultBankWarningModal(false);
              reset({ is_default_bank: false });
            }}
          />
          <Button label="Yes" onClick={() => changeDefaultBank()} />
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // show={showModalWarning}
        onHide={() => {
          setShowModalWarning(false);
          reset({ is_default_bank: false });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set As Default Bank</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to set this bank as default bank?
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="No"
            onClick={() => {
              setDefaultBankWarningModal(false);
              reset({ is_default_bank: false });
            }}
          />
          <Button label="Yes" onClick={() => changeDefaultBank()} />
        </Modal.Footer>
      </Modal>
      <Row>
        <Col md={20} className="text-end">
          <Button
            label="Save Details"
            type="submit"
            onClick={() => {
              navigate(`/currency-bank-details/${customerId}`);
            }}
          ></Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditCorrBankAccountDetails;
