import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import "./Alert.css";

interface AlertProps {
  message: string;
  variant: "success" | "danger" | "warning"; // Define variant prop
}

const AlertPopup: React.FC<AlertProps> = ({ message, variant }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  return (
    <>
      <Alert
        show={show}
        variant={variant} // Use the variant prop to set the alert variant
        className="smallAlert"
        onClose={() => setShow(false)}
        dismissible
      >
        <Alert.Heading>
          {variant === "danger"
            ? "Error"
            : variant === "warning"
            ? "Warning"
            : "Success"}
        </Alert.Heading>
        <p>{message}</p>
        <hr />
      </Alert>
    </>
  );
};

export default AlertPopup;
