import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getKeyPerson } from "../../apis/getKeyPerson";

export const getKeyPersonAction = createAsyncThunk(
  "getKeyPersonAction",
  async (keyPersonId: number) => {
    return getKeyPerson(keyPersonId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
