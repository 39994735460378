import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import Header from "../../../../components/common/Header";
import EntityUnlistedKYC from "./components/EntityUnlistedKYC";

const GuestEntityUnlistedKYC: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <EntityUnlistedKYC />
    </>
  );
};

export default GuestEntityUnlistedKYC;
