import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { createAddressAction } from "../action/createAddressAction";
import { createGstAddressAction } from "../action/createGstAddressAction";

const initialState: CreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const createGstAddressSlice = createSlice({
  name: "createAddressSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createGstAddressAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGstAddressAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createGstAddressAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const createGstAddressReducer = createGstAddressSlice.reducer;
