import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";

interface ButtonProps {
  label: string;
  type?: "button" | "submit" | "reset";
  className?: string;
  onClick?: () => void;
  variant?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  type = "button",
  className = "rounded-pill m-2",
  onClick,
  variant = "",
  disabled = false,
}) => {
  return (
    <BootstrapButton
      type={type}
      className={`custom-button ${className}`}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      style={{
        backgroundColor:
          variant === "outline-primary" ? "transparent" : "#214382",
        borderColor: "#214382",
        color: variant === "outline-primary" ? "#214382" : "white",
      }}
    >
      {label}
    </BootstrapButton>
  );
};

export default Button;
