export function arraysHaveSameValues<T>(array1: T[], array2: T[]): boolean {
  // Check if both arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Sort both arrays to ensure order doesn't affect the comparison
  const sortedArray1 = [...array1].sort();
  const sortedArray2 = [...array2].sort();

  // Compare each element of the sorted arrays
  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] != sortedArray2[i]) {
      return false;
    }
  }

  return true;
}
