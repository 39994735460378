import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import IndiaFinancierKYC from "./components/IndiaFinacierKYCDetails";
import Header from "../../../../components/common/Header";

const GuestIndiaFinancierKYC: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <IndiaFinancierKYC />
    </>
  );
};

export default GuestIndiaFinancierKYC;
