import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

export const fetchReferredByBusiness = (referred_by: number) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/one/tradexwbench.customer/${referred_by}/`,
      {
        params: {
          query:
            "{stage,application_uid, customer_contact_name, customer_contact_mobile, customer_contact_email, business_participant_type, business_registration_country_id{name}, business_gst, business_tanno,business_name, business_cin_no, business_registered_with, business_registration_place, is_two_factor, business_listing_info_ids{lov_display_value}, business_registration_date, business_commencement_date, business_legal_entity_identifier, business_website, business_nature, business_regulated_by, business_financier_type, business_issued_date, business_registration_number, business_registered_with_fgn, business_nature_activity, business_import_export_license_no, business_ckycno, business_unit_location, business_statusholder, business_incorporation_place, business_constitution_type, business_registered_by_fgn, business_financier_type_fgn,  currency_ids{currency_code}, business_tanno_issuing_authority, business_tax_type, business_tax_identification_number, business_pan, business_iecno, business_registration_certificate_no, business_no_of_years_of_relation_with_cp, is_counterparty, referred_by_vendor_dealer_id, business_is_cp_related_party, referred_by, designation,business_tax_type_other}",
        },
      },
      {
        params: {
          session_id: sessionId,
        },
      }
    )
    .then((response) => {
      //   // console.error("id-->", referred_by);
      return response;
    })
    .catch((err) => err);
};
