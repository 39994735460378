import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { CreateAddressType } from "../../pages/types/CreateAddressType";
import { updateAddress } from "../../apis/updateAddress";

export const updateAddressAction = createAsyncThunk(
  "updateAddressAction",
  async ({
    addressId,
    addressDetails,
  }: {
    addressId: number;
    addressDetails: CreateAddressType;
  }) => {
    return updateAddress(addressId, addressDetails, getSessionId()).then(
      (response) => {
        return response.data;
      }
    );
  }
);
