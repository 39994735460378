import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { updateLocalBank } from "../../apis/updateLocalBank";
import { BankDetailsType } from "../../pages/types/BankDetailsType";

export const updateLocalBankAction = createAsyncThunk(
  "updateLocalBankAction",
  async ({
    localBankId,
    updateLocalBankData,
  }: {
    localBankId: number;
    updateLocalBankData: BankDetailsType;
  }) => {
    return updateLocalBank(
      localBankId,
      updateLocalBankData,
      getSessionId()
    ).then((response) => {
      // console.log("UPDATE LOCAL BANK ", response);
      return response.data;
    });
  }
);
