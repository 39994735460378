import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getIndividualDesignation } from "../../../feature/apis/listOfValues/getIndividualDesignation";

interface IndividualDesignationProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  handleChange?: () => JSX.Element;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
}

interface IndividualDesignationData {
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

const IndividualDesignation: React.FC<IndividualDesignationProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  handleChange,
  defaultValue,
  disabled = false,
  error,
}) => {
  const token = getSessionId();

  const [individualDesignationValues, setIndividualDesignationValues] =
    useState<IndividualDesignationData[]>([]);

  useEffect(() => {
    getIndividualDesignation(token).then((response) => {
      setIndividualDesignationValues(response.result.result);
    });
  }, []);

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Select
            as="select"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            onChange={handleChange}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            disabled={disabled}
            defaultValue={defaultValue}
          >
            <option value="">Select Option</option>
            {individualDesignationValues.map(
              (individualDesignationValue, index) => (
                <option
                  key={index}
                  value={individualDesignationValue.lov_value}
                >
                  {individualDesignationValue.lov_display_value}
                </option>
              )
            )}
           
          </Form.Select>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default IndividualDesignation;
