import { createSlice } from "@reduxjs/toolkit";
import { GuestLogin } from "../types/GuestLoginType";
import { sendAppAccessOTP } from "../action/sendAppAccessOTP";

const initialState: GuestLogin = {
  data: null,
  loading: false,
  error: null,
  // successfull: false,
};

const guestAppAccessOTP = createSlice({
  name: "guestAppAccessOTP",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendAppAccessOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendAppAccessOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(sendAppAccessOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
      });
  },
});

export const guestAppAccessOTPReducer = guestAppAccessOTP.reducer;
