import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import Header from "../../../../components/common/Header";
import { Container, Row, Col } from "react-bootstrap";
import Currencies from "../../../auth/components/Currencies";
import Button from "../../../../components/common/Button";
import LocalBankDetailsPage from "../../common/components/BankDetails/LocalBankAccountDetails";
// import LocalBankList from "../common/components/BankDetails/LocalBankList";
const GuestCurrenciesBankKeyList: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <Currencies />
        {/* // <LocalBankList /> */}
        <LocalBankDetailsPage/>
        <Row>
          <Col md={20} className="text-end">
            <Button label="Submit"></Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GuestCurrenciesBankKeyList;
