import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateToken } from "../../apis/generateToken";

export const generateTokenAction = createAsyncThunk(
  "generateTokenAction",
  async () => {
    return generateToken().then((response: any) => {
      return response.data;
    });
  }
);
