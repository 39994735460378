import { createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../../apis/login";

export const opsLoginAction = createAsyncThunk(
  "opsLoginAction",
  async ({ username, password }: { username: string; password: string }) => {
    return login(username, password).then((response) => {
      return response.data;
    });
  }
);
