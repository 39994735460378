import Input from "../../../../../components/common/Input";
import ConstitutionType from "../../../../../components/common/dropdowns/ConstitutionType";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import TaxIssuedBy from "../../../../../components/common/dropdowns/TaxIssuedBy";
import { ALPHANUMERIC_REGEX } from "../../../../constants/regexPatterns";

const TaxDetailsForeign: React.FC<any> = ({ register, errors, watch }) => {
  const constitutionType = watch("business_constitution_type");
  const taxIdIssuedBy = watch("business_tax_type");
  return (
    <>
      <Input
        label="Business Name"
        name="business_name"
        required
        register={register}
        // placeholder="Enter business name"
        error={errors.business_name?.message}
        placeholder="Enter Business Name"
      />
      {/* <Dropdown
        label="Constitution Type"
        name="business_constitution_type"
        options={[
          { label: "Public Limited Company", value: "PLC" },
          { label: "Private Limited Company", value: "LLP" },
          { label: "Limited Liability Company", value: "LLC" },
          { label: "Partnership", value: "partnership" },
          { label: "Proprietorship", value: "proprietorship" },
          { label: "Others", value: "others" },
        ]}
        register={register}
        required
        requiredMessage="Constitution Type is required"
        error={errors.business_constitution_type?.message}
      />
      {constitutionType === "others" && (
        <Input
          label={"If Others"}
          name={"business_constitution_type_other"}
          register={register}
          placeholder="Enter other Constitution Type"
          required
          requiredMessage="Other Constitution is required"
        />
      )} */}
      <ConstitutionType
        label="Constitution Type"
        name="business_constitution_type"
        // options={[
        //   { label: "Public Limited Company", value: "PLC" },
        //   { label: "Private Limited Company", value: "LLP" },
        //   { label: "Limited Liability Company", value: "LLC" },
        //   { label: "Partnership", value: "partnership" },
        //   { label: "Proprietorship", value: "proprietorship" },
        //   { label: "Others", value: "others" },
        // ]}
        register={register}
        required
        requiredMessage="Constitution Type is required"
        error={errors.business_constitution_type?.message}
      />
      {constitutionType === "others" && (
        <Input
          label={"If Others"}
          name={"business_constitution_type_other"}
          register={register}
          placeholder="Enter Other Constitution Type"
          required
        />
      )}
      <Input
        label="TAX ID (TIN)"
        type="text"
        name="business_tax_identification_number"
        register={register}
        required
        regexPattern={ALPHANUMERIC_REGEX}
        requiredMessage="TAX ID is required"
        error={errors.business_tax_identification_number?.message}
        placeholder="Enter TAX ID"
      />

      <TaxIssuedBy
        label="TAX ID Issued By"
        name="business_tax_type"
        register={register}
        required
        requiredMessage="TAX ID Issued By is required"
        error={errors.business_tax_type?.message}
      />
      {taxIdIssuedBy === "Others" && (
        <Input
          label={"If Others"}
          name={"business_tax_type_other"}
          register={register}
          required
          placeholder="Enter other Tax Type"
        />
      )}

      <Input
        label="TAX Account No. (TAN)"
        type="text"
        name="business_tanno"
        register={register}
        // required
        regexPattern={ALPHANUMERIC_REGEX}
        requiredMessage="Tax Account Number is required"
        // error={errors.business_tanno?.message}
        placeholder="Enter TAN"
      />
      <Input
        label="TAN Issued By"
        type="text"
        name="business_tanno_issuing_authority"
        register={register}
        //required
        regexPattern={ALPHANUMERIC_REGEX}
        placeholder="Enter TAN Issued By"
        requiredMessage="TAN Issued By is required"
        error={errors.business_tanno_issuing_authority?.message}
      />
    </>
  );
};

export default TaxDetailsForeign;
