import React from "react";
import { Col, Form, Row } from "react-bootstrap";
interface DropdownProps {
  name: string;
  label: string;
  options: {
    id: number;
    sr_no: number;
    lov_type: string;
    lov_display_value: string;
    lov_value: string;
    is_active: boolean;
    parent_industry: string;
  }[];
  required?: boolean;
  requiredMessage?: string;
  defaultValue?: string;
  register: any;
  error?: any;
  disabled?: boolean;
}
const SubIndustryType: React.FC<DropdownProps> = ({
  name,
  label,
  options,
  register,
  required = false,
  requiredMessage = "This field is required",
  defaultValue,
  disabled,
  error,
}) => {
  return (
    <Form.Group as={Col}>
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>{" "}
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Select
            className="custom-input form-control"
            name={name}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            defaultValue={defaultValue}
            disabled={disabled}
          >
            <option value="">Select an option</option>
            {options.map((option, index) => (
              <option
                key={index}
                value={option.id}
                // selected={defaultValue === option.lov_display_value}
              >
                {option.lov_display_value}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};
export default SubIndustryType;
