import NavbarVTX from "../../../../components/common/Navbar";
import Header from "../../../../components/common/Header";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../../../components/common/Button";
import SectionHeading from "../../../../components/common/SectionHeading";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { getUserRole } from "../../../../utils/tokenHandlers";
import { useEffect } from "react";
import { getAppAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
const GuestApplicationSubmssionFormPage = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const navigate = useNavigate();
  const userRole = getUserRole();
  const dispatch = useDispatch<AppDispatch>();

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );

  const checkNavigate = () => {
    navigate(`/verification/business-details/${customerId}`);
  };

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, []);

  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, document.title, window.location.href);
      });
    };

    disableBackButton();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, []);

  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Col
            md={12}
            className="text-center mt-4"
            style={{ color: "#214382" }}
          >
            <SectionHeading text="THANKS FOR FILLING THE FORM" />
            <img
              src="https://img1.picmix.com/output/stamp/normal/3/4/8/4/1434843_2deda.gif"
              alt="Thank You GIF"
            />
          </Col>
        </Row>
        <p>
          Your application number is{" "}
          <u>{getAppSelector.data.application_uid}.</u> We are excited to have
          you onboard. Our team has already started to work on your application
          In the meantime, you can check the{" "}
          <a
            href="https://vayanatradexchange.com/faq/"
            target="_blank"
            rel="noreferrer"
          >
            FAQ Section
          </a>
        </p>

        <Row>
          <Col md={15} className="text-end">
            {(userRole == "Maker" || userRole == "Checker") && (
              <Button
                label="Submit Application"
                onClick={() => {
                  checkNavigate();
                }}
              ></Button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default GuestApplicationSubmssionFormPage;
