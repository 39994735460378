import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import ICEntityKYCDetails from "./components/ICEntityKYCDetails";
import Header from "../../../../components/common/Header";

const GuestICEntityKYCPage: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <ICEntityKYCDetails />
    </>
  );
};

export default GuestICEntityKYCPage;
