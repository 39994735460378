import axios from "axios";

export const generateToken = () => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}generate_token`, {
      params: {
        arg: "ITFS",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
