import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import EntityForeignKYC from "./components/EntityForeignKYC";
import Header from "../../../../components/common/Header";

const GuestEntityForeignKYC: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <EntityForeignKYC />
    </>
  );
};

export default GuestEntityForeignKYC;
