import React, { useEffect } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { getBusinessDetails } from "../redux/action/getBusinessDetails";
import { useParams } from "react-router-dom";
import { getAppAction } from "../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";

const VerIndividual: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  let { appId } = useParams();
  let customerId = Number(appId);
  useEffect(() => {
    // setValue("business_registered_with", "MCA");
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);
  const state = useSelector(
    (state: RootState) => state.verification.verIndividualDetails
  );
  const businessState = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  const businessDetailsStore = businessState.data;
  const isCP = businessDetailsStore.is_counterparty;
  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }

  const individualStore: Array<{
    id: number;
    customer_id: string;
    key_person_type_ids: {
      [x: string]: any;
      lov_display_value: string;
    };
    designation: string;
    if_others: string;
    partnership_percentage: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    related_person_type: string;
    father_spouse_name: string;
    mother_name: string;
    gender: string;
    date_of_birth: string;
    ownership_percentage: string;
    tax_id: string;
    employee_id: string;
    passport_number: string;
    passport_expiry_date: string;
    nationality_country_id: { name: string };
    tax_residency_country_id: { name: string };
    email: string;
    ckycno: string;
    is_politically_exposed: string;
    is_minor: boolean;
    is_beneficial_owner: string;
    is_admin: string;
    guardian_relation: string;
    guardian_name: string;
    key_person_address_line_1: string;
    key_person_address_line_2: string;
    key_person_address_city: string;
    key_person_address_district: string;
    key_person_address_state: string;
    key_person_address_pincode: string;
    key_person_address_country_id: { name: string };
    phone: string;
    phone_country_code: string;
    din: string;
  }> = state.data.data.result.result;

  return (
    <>
      <Accordion>
        {individualStore.map((individual, index) => (
          <Accordion.Item key={index} eventKey={`I${index}`}>
            <Accordion.Header>
              {individual.first_name} {individual.last_name}
            </Accordion.Header>
            <Accordion.Body>
              {isCP ? (
                <>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Type"
                        defaultValue={individual.key_person_type_ids
                          .map(
                            (type: { lov_display_value: any }) =>
                              type.lov_display_value
                          )
                          .join(", ")}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Designation"
                        defaultValue={
                          individual.designation === "others"
                            ? individual.if_others
                            : individual.designation
                        }
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="First Name"
                        defaultValue={individual.first_name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Middle Name"
                        defaultValue={individual.middle_name}
                      />
                    </Col>
                  </Row>

                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Last Name"
                        defaultValue={individual.last_name}
                      />
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Mother Name"
                        defaultValue={individual.mother_name}
                      />
                    </Col>
                  </Row> */}

                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Gender"
                        defaultValue={
                          individual.gender
                            ? individual.gender.charAt(0).toUpperCase() +
                              individual.gender.slice(1)
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Cellphone"
                        defaultValue={
                          individual.phone_country_code + -+individual.phone
                        }
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Email"
                        defaultValue={individual.email}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Type"
                        defaultValue={individual.key_person_type_ids
                          .map(
                            (type: { lov_display_value: any }) =>
                              type.lov_display_value
                          )
                          .join(", ")}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Designation"
                        defaultValue={
                          individual.designation
                            ? individual.designation.charAt(0).toUpperCase() +
                              individual.designation.slice(1)
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="First Name"
                        defaultValue={individual.first_name}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Middle Name"
                        defaultValue={individual.middle_name}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Last Name"
                        defaultValue={individual.last_name}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Mother Name"
                        defaultValue={individual.mother_name}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Gender"
                        defaultValue={
                          individual.gender
                            ? individual.gender.charAt(0).toUpperCase() +
                              individual.gender.slice(1)
                            : ""
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Date of Birth"
                        defaultValue={individual.date_of_birth}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Is Politically Exposed"
                        defaultValue={
                          individual.is_politically_exposed ? "Yes" : "No"
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Is Minor"
                        defaultValue={
                          individual.is_minor === true ? "Yes" : "No"
                        }
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Nationality"
                        defaultValue={individual.nationality_country_id.name}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="PAN/ TAX ID"
                        defaultValue={individual.tax_id}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Passport No"
                        defaultValue={individual.passport_number}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Passport Expiry"
                        defaultValue={individual.passport_expiry_date}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Employee ID"
                        defaultValue={individual.employee_id}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="DIN/ DPIN"
                        defaultValue={individual.din}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="CKYC No"
                        defaultValue={individual.ckycno}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Ownership %"
                        defaultValue={individual.ownership_percentage}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Cellphone"
                        defaultValue={
                          individual.phone_country_code + -+individual.phone
                        }
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Guardian Name"
                        defaultValue={individual.guardian_name}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Guardian Relation"
                        defaultValue={individual.guardian_relation}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Address Line 1"
                        defaultValue={individual.key_person_address_line_1}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Address Line 2"
                        defaultValue={individual.key_person_address_line_2}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="City"
                        defaultValue={individual.key_person_address_city}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="District"
                        defaultValue={individual.key_person_address_district}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="State/ County"
                        defaultValue={individual.key_person_address_state}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Country"
                        defaultValue={
                          individual.key_person_address_country_id.name
                        }
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Pincode"
                        defaultValue={individual.key_person_address_pincode}
                      />
                    </Col>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Tax Residency"
                        defaultValue={individual.tax_residency_country_id.name}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={12}>
                      <VerBusinessDetails
                        label="Email"
                        defaultValue={individual.email}
                      />
                    </Col>
                  </Row>
                  <Row md={12}>
                    <Col md={6}>
                      <VerBusinessDetails
                        label="Partnership %"
                        defaultValue={individual.partnership_percentage}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default VerIndividual;
