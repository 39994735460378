import axios from "axios";
import { UpdateAppData } from "../redux/types/UpdateAppType";
import { BusinessDetailsType } from "../pages/types/BusinessDetailsType";

export const updateApplication = (
  customerId: number,
  customerDetails: BusinessDetailsType,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/tradexwbench.customer/${customerId}`,
      {
        params: {
          context: {},
          data: customerDetails,
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      // console.log(response, ":chk2");
      // console.log(customerDetails, ":chl2");
      return response;
    })
    .catch((err) => err);
};
