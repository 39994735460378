import { Col, Row } from "react-bootstrap";
import Input from "../../../../../components/common/Input";
import RadioButton from "../../../../../components/common/RadioButton";

const CPBusinessDetailsFields: React.FC<any> = ({ register, errors }) => {
  const relatedPartyOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  return (
    <>
      <Row md={12} className="mt-2">
        <Col md={6}>
          <Input
            label="Years of Trade Relation"
            name="business_no_of_years_of_relation_with_cp"
            type="number"
            required
            register={register}
            placeholder="Enter Years of Trade Relation"
            error={errors.business_no_of_years_of_relation_with_cp?.message}
          />
        </Col>
        <Col md={6}>
          <Input
            label="Vendor/ Dealer ID"
            name="referred_by_vendor_dealer_id"
            type="text"
            register={register}
            placeholder="Enter Vendor/ Dealer ID"
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <RadioButton
            label="Is CP Related Party?"
            name="business_is_cp_related_party"
            required
            options={relatedPartyOptions}
            register={register}
           
            error={errors.business_is_cp_related_party?.message}
          />
        </Col>
      </Row>
    </>
  );
};

export default CPBusinessDetailsFields;
