import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import KeyIndividualKYC from "./components/KeyIndividualKYC";
import Header from "../../../../components/common/Header";

const GuestKeyIndividualKYC: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <KeyIndividualKYC />
    </>
  );
};

export default GuestKeyIndividualKYC;
