import React from "react";
import IFBusinessDetails from "../../../../opsUser/ppApplication/ppIndia/components/IFBusinessDetails";
import IFAddressDetails from "./IFAddressDetails";

import { Container } from "react-bootstrap";

const VerBusinessVerification: React.FC = () => {
  return (
    <>
      <IFBusinessDetails />
      <IFAddressDetails />
      {/* <GuestCurrenciesBankKeyList /> */}
    </>
  );
};

export default VerBusinessVerification;
