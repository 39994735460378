import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { deleteCorrBank } from "../../apis/deleteCorrBank";

export const deleteCorrBankAction = createAsyncThunk(
  "deleteCorrBankAction",
  async (corrBankId: number) => {
    return deleteCorrBank(corrBankId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
