import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getDueDiligenceDoc } from "../../apis/getDueDiligenceDoc";

export const getDueDiligenceDocAction = createAsyncThunk(
  "getDueDiligenceDocAction",
  async (docId: number) => {
    return getDueDiligenceDoc(docId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
