import React from "react";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import Input from "../../../../../components/common/Input";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import DatePicker from "../../../../../components/common/DatePicker";
import Button from "../../../../../components/common/Button";
import CheckBoxes from "../../../../../components/common/CheckBoxes";
import { FormData } from "../../types/GuestKeyIndividual";
import "./AddKeyIndividual.css";
import { useNavigate } from "react-router-dom";
import IndividualDesignation from "../../../../../components/common/dropdowns/IndividualDesignation";
import Gender from "../../../../../components/common/dropdowns/Gender";
const AddKeyIndividual: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const navigate = useNavigate();
  const handleAddClick = () => {
    navigate("/guest/Keyindividuallist"); // Replace with the actual path
  };
  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Col md={20} className="mainHeading">
            <h5>ADD KEY PERSON</h5>
          </Col>
        </Row>
        <Row className="myrow">
          <Col sm={4}>
            <CheckBoxes
              name="checkboxGroup"
              label=""
              options={[
                { label: "Key Person", value: "key person" },
                { label: "Authorised official", value: "authorised official" },
                {
                  label: "Entity Administrator",
                  value: "Entity Administrator",
                },
                { label: "Beneficial Owner", value: "Beneficial Owner" },
                { label: "Others", value: "others" },
                // Add more options as needed
              ]}
              register={register}
              required
              //   error={errors.checkboxGroup?.message}
            />
          </Col>
        </Row>
        <Form>
          <Row className="justify-content-center">
            <Col sm={4}>
              <IndividualDesignation
                label="Designation"
                name="designation"
                register={register}
                required
                requiredMessage="designation is required"
                error={errors.designation?.message}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Nationality"
                name="nationality_country_id"
                register={register}
                required
                requiredMessage="nationality is required"
                error={errors.nationality_country_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 1"
                name="key_person_address_line_1"
                register={register}
                placeholder="Enter Address line 1"
                required
                requiredMessage="Address line 1 is required"
                error={errors.key_person_address_line_1?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="First name"
                name="first_name"
                register={register}
                placeholder="Enter first name"
                required
                requiredMessage="first name is required"
                error={errors.first_name?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="PAN/TAX Id"
                name="tax_id"
                register={register}
                placeholder="Enter Pan/tax"
                required
                requiredMessage="tax id is required"
                error={errors.tax_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 2"
                name="key_person_address_line_2"
                register={register}
                placeholder="Enter Address line 2"
                required
                requiredMessage="Address line 2 is required"
                error={errors.key_person_address_line_2?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Middle name"
                name="middle_name"
                register={register}
                placeholder="Enter middle name"
                required
                requiredMessage="middle name is required"
                error={errors.middle_name?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Passport No"
                name="passport_number"
                register={register}
                placeholder="Enter passport no"
                required
                requiredMessage="passport number is required"
                error={errors.passport_number?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="City/Town/Village"
                name="key_person_address_city"
                register={register}
                placeholder="Enter city/town/village"
                required
                requiredMessage="city is required"
                error={errors.key_person_address_city?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Last name"
                name="last_name"
                register={register}
                placeholder="Enter last name"
                required
                requiredMessage="last name is required"
                error={errors.last_name?.message}
              />
            </Col>
            <Col sm={4}>
              <DatePicker
                label="Passport Expiry"
                name="passport_expiry_date"
                register={register}
                required
                requiredMessage="passport expiry date is required"
                error={errors.passport_expiry_date?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="District/City"
                name="key_person_address_district"
                register={register}
                placeholder="Enter district/city"
                required
                requiredMessage="district is required"
                error={errors.key_person_address_district?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Mother name"
                name="mother_name"
                register={register}
                placeholder="Enter mother name"
                required
                requiredMessage="mother name is required"
                error={errors.mother_name?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Employee Id"
                name="employee_id"
                register={register}
                placeholder="Enter employee id"
                required
                requiredMessage="employee id is required"
                error={errors.employee_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="State/Country"
                name="key_person_address_state"
                register={register}
                placeholder="Enter state/country"
                required
                requiredMessage="state is required"
                error={errors.key_person_address_state?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Gender
                label="Gender"
                name="gender"
                register={register}
                required
                requiredMessage="gender is required"
                error={errors.gender?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Cellphone"
                name="phone"
                register={register}
                placeholder="Enter cellphone"
                required
                requiredMessage="cellphone is required"
                error={errors.phone?.message}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Country"
                name="key_person_address_country_id"
                register={register}
                required
                requiredMessage="country is required"
                error={errors.key_person_address_country_id?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <DatePicker
                label="Date of birth"
                name="date_of_birth"
                register={register}
                required
                requiredMessage="date of birth is required"
                error={errors.date_of_birth?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Ckyc no"
                name="ckycno"
                register={register}
                placeholder="Enter ckyc"
                required
                requiredMessage="ckycno is required"
                error={errors.ckycno?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Pincode"
                name="key_person_address_pincode"
                register={register}
                placeholder="Enter pincode"
                required
                requiredMessage="pincode is required"
                error={errors.key_person_address_pincode?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Ownership %"
                name="ownership_percentage"
                register={register}
                placeholder="Enter ownership %"
                required
                requiredMessage="ownership % is required"
                error={errors.ownership_percentage?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="DIN/DPIN"
                name="din"
                register={register}
                placeholder="Enter DIN/DPIN"
                required
                requiredMessage="din is required"
                error={errors.din?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Email"
                name="email"
                register={register}
                placeholder="Enter email"
                required
                requiredMessage="email is required"
                error={errors.email?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              md={4}
              className="custom-col d-flex justify-content-center align-items-center"
            >
              <Form.Check label={<span>is minor?</span>} />
            </Col>

            <Col sm={4}>
              <Input
                label="Guardian relationship"
                name="guardian_relation"
                register={register}
                placeholder="Enter guardian relationship"
                // required={isMinor}
                requiredMessage="guardian relation is required"
                error={errors.guardian_relation?.message}
              />
            </Col>

            <Col sm={4}>
              <Input
                label="Guardian name"
                name="guardian_name"
                register={register}
                placeholder="Enter guardian name"
                // required={isMinor}
                requiredMessage="guardian name is required"
                error={errors.guardian_name?.message}
              />
            </Col>
          </Row>

          <Row>
            <Col
              md={4}
              className="custom-col d-flex justify-content-center align-items-center"
            >
              <Form.Check label={<span>is politicaly exposed ?</span>} />
            </Col>
            <Col sm={4}></Col>
            <Col sm={4}></Col>
          </Row>
          <Row>
            <Col md={20} className="text-end">
              <Button label="Submit" onClick={handleAddClick}></Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default AddKeyIndividual;
