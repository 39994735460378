import React, { useEffect, useState } from "react";
import { Col, Row, Form, FormSelect } from "react-bootstrap";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getApplicationStage } from "../../../feature/apis/listOfValues/getApplicationStage";

interface ApplicationStageProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  // handleChange?: () => JSX.Element;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

interface ApplicationStageData {
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

const ApplicationStage: React.FC<ApplicationStageProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  // handleChange,
  defaultValue,
  disabled,
  error,
  onChange,
}) => {
  const token = getSessionId();
  const [selectStage, setSelectStage] = useState("");

  const [applicationStageValues, setApplicationStageValues] = useState<
    ApplicationStageData[]
  >([]);

  useEffect(() => {
    getApplicationStage(token).then((response) => {
      // // console.log("application Stage --> ", response);
      setApplicationStageValues(response.result.result);
    });
  }, []);

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>{" "}
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Select
            as="select"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setSelectStage(selectedValue);
              if (onChange) {
                onChange(selectedValue);
              }
            }}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            defaultValue={defaultValue}
            disabled={disabled}
          >
            <option value="">Select Option</option>
            {applicationStageValues.map((applicationStageValues, index) => (
              <option key={index} value={applicationStageValues.lov_value}>
                {applicationStageValues.lov_display_value}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default ApplicationStage;
