import { Row, Col, Modal, Table, Card, Form } from "react-bootstrap";
import Input from "../../../../../components/common/Input";
import Button from "../../../../../components/common/Button";
import {
  MdOutlineDeleteOutline,
  MdOutlineDriveFileRenameOutline,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import { CreateAddressType } from "../pages/types/CreateAddressType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { getAllAddressAction } from "../redux/action/getAllAddressAction";
import { useNavigate, useParams } from "react-router-dom";
import { addressTypes } from "../../../../constants/constants";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import { createAddressAction } from "../redux/action/createAddressAction";
import { deleteAddressAction } from "../redux/action/deleteAddressAction";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import { getAppAction } from "../redux/action/getAppAction";

const AddressDetails: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAddressType>({});

  const dispatch = useDispatch<AppDispatch>();

  const allAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllAddress.data
  );

  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  let stage = state.data.stage;
  let constType = state.data.business_constitution_type;
  let role = state.data.business_participant_type;
  let country = state.data.business_registration_country_id;
  const deleteAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.deleteAddress
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    null
  );
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const [deleteAddressId, setDeleteAddressId] = useState(0);

  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showAddRegAddrWarning, setShowAddRegAddrWarning] = useState(false);

  const handleDeleteRow = () => {
    // Implement logic to delete the selected row
    // You can use state or other methods to update the table data
    setSelectedRowIndex(null); // Close the modal
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDeleteAddressTable = async (id: number) => {
    // console.log(id, "id----");
    const isRegAddress = allAddressSelector.filter(
      (address: any) => address.type === "Registered"
    );
    if (isRegAddress) {
      // alert("Cannot Delete Registered Address");
      setShowDeleteWarning(true);
      setTimeout(() => {
        setShowDeleteWarning(false);
      }, 1000);
    } else {
      setDeleteAddressId(id);
      setShowModal(true);
    }
  };

  const handleDeleteAddress = (addressId: number) => {
    // Implement logic to add address
    // You can use state or other methods to store the added address data
    dispatch(deleteAddressAction(addressId));
    dispatch(getAllAddressAction(customerId));
    setTimeout(() => {
      window.location.reload();
    }, 500);
    closeModal();
  };

  const handleAddressDetailsSubmit: SubmitHandler<CreateAddressType> = (
    addressDetails
  ) => {
    // console.log(addressDetails);
    const addressDetailsData = {
      ...addressDetails,
      customer_id: customerId,
      country_id: Number(addressDetails.country_id),
    };
    dispatch(createAddressAction(addressDetailsData));
  };

  const handleAddRegAddress = () => {
    const isRegAddress = allAddressSelector.filter(
      (address: any) => address.type === "Registered"
    );
    if (isRegAddress) {
      // alert("Cannot Delete Registered Address");
      setShowAddRegAddrWarning(true);
    } else {
      navigate(`/add-address/${"Registered"}/${customerId}`);
    }
  };

  useEffect(() => {
    dispatch(getAllAddressAction(customerId));
  }, [deleteAddressSelector]);

  return (
    <>
      <Row>
        <DisplayBox display={showDeleteWarning}>
          <AlertPopup
            variant="warning"
            message="Cannot delete Registered Address"
          />
        </DisplayBox>
        <DisplayBox display={showAddRegAddrWarning}>
          <AlertPopup
            variant="warning"
            message="Cannot Add 2 Registered Address"
          />
        </DisplayBox>

        <Col md={20} className="text-center mt-4" style={{ color: "#214382" }}>
          <SectionHeading text="ADDRESS DETAILS" />
        </Col>
      </Row>
      <DisplayBox
        display={
          country === 448 && (constType === "PLC" || role === "Financier")
        }
      >
        <h6 style={{ color: "lightcoral" }}>
          Please update your official business email and phone number by
          clicking on the edit button next to your registered address, ignore if
          already added.
        </h6>
      </DisplayBox>
      <Row>
        <Col md={20} className="text-end">
          {allAddressSelector.length > 0 &&
          allAddressSelector.filter(
            (address: any) => address.type === "Registered"
          ) ? null : (
            <Button
              label=" + Registered Address"
              variant="outline-primary"
              onClick={() => {
                if (
                  stage === "New / Draft" ||
                  stage === "Sent back to correction"
                ) {
                  navigate(`/add-address/${"Registered"}/${customerId}`);
                } else {
                  alert("Cannot edit application at this stage!");
                }
              }}
            ></Button>
          )}

          <Button
            label=" + Communication Address"
            variant="outline-primary"
            disabled={allAddressSelector.length === 0}
            onClick={() => {
              if (
                stage === "New / Draft" ||
                stage === "Sent back to correction"
              ) {
                navigate(`/add-address/${"Communication"}/${customerId}`);
              } else {
                alert("Cannot edit application at this stage!");
              }
            }}
          ></Button>
        </Col>
      </Row>
      <Table striped bordered size="sm" style={{ padding: "10%" }}>
        <thead>
          <tr>
            <th style={{ width: "20%" }}>TYPE</th>
            <th>ADDRESS</th>
            <th>EMAIL</th>
            <th style={{ width: "12%" }}>TELEPHONE</th>
            <th style={{ width: "10%" }}>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {allAddressSelector.length > 0 &&
            allAddressSelector
              .filter((address: any) => address.type === "Registered")
              .map((address: any) => (
                <tr>
                  <td>Registered Address</td>
                  <td>{`${address.line_1} ${address.line_2} ${address.city} ${address.district} ${address.state} ${address.country_id.name} ${address.pincode} `}</td>
                  <td>{address.email}</td>
                  <td>
                    {address.phone_country_code} - {address.phone}
                  </td>

                  <td style={{ color: "#214382" }}>
                    <MdOutlineDriveFileRenameOutline
                      style={{
                        width: "23px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (
                          stage === "New / Draft" ||
                          stage === "Sent back to correction"
                        ) {
                          if (address.type == "Registered") {
                            navigate(
                              `/edit-address/${customerId}/${"RegiMastered"}/${
                                address.id
                              }`
                            );
                          } else {
                            navigate(
                              `/edit-address/${customerId}/${"Communication"}/${
                                address.id
                              }`
                            );
                          }
                        } else {
                          alert("Cannot edit application at this stage!");
                        }
                      }}
                    ></MdOutlineDriveFileRenameOutline>
                    <MdOutlineRemoveRedEye
                      style={{
                        width: "23px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (address.type == "Registered") {
                          navigate(
                            `/view-address/${customerId}/${"Registered"}/${
                              address.id
                            }`
                          );
                        } else {
                          navigate(
                            `/view-address/${customerId}/${"Communication"}/${
                              address.id
                            }`
                          );
                        }
                      }}
                    />

                    {/* <MdOutlineDeleteOutline
                      style={{
                        width: "23px",
                        height: "22px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (
                          stage === "New / Draft" ||
                          stage === "Sent back to correction"
                        ) {
                          handleDeleteAddressTable(address.id);
                        } else {
                          alert("Cannot edit application at this stage!");
                        }
                      }}
                    ></MdOutlineDeleteOutline> */}
                  </td>
                </tr>
              ))}
          {allAddressSelector &&
            allAddressSelector
              .filter((address: any) => address.type === "Communication")
              .map((address: any) => (
                <tr>
                  <td>Communication Address</td>
                  <td>{`${address.line_1} ${address.line_2} ${address.city} ${address.district} ${address.state}  ${address.country_id.name} ${address.pincode}`}</td>
                  <td>{address.email}</td>
                  <td>
                    {" "}
                    {address.phone_country_code} - {address.phone}
                  </td>
                  <td style={{ color: "#214382" }}>
                    <MdOutlineDriveFileRenameOutline
                      style={{
                        width: "23px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (
                          stage === "New / Draft" ||
                          stage === "Sent back to correction"
                        ) {
                          if (address.type == "Registered") {
                            navigate(
                              `/edit-address/${customerId}/${"Registered"}/${
                                address.id
                              }`
                            );
                          } else {
                            navigate(
                              `/edit-address/${customerId}/${"Communication"}/${
                                address.id
                              }`
                            );
                          }
                        } else {
                          alert("Cannot edit application at this stage!");
                        }
                      }}
                    ></MdOutlineDriveFileRenameOutline>
                    <MdOutlineRemoveRedEye
                      style={{
                        width: "23px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (address.type == "Registered") {
                          navigate(
                            `/view-address/${customerId}/${"Registered"}/${
                              address.id
                            }`
                          );
                        } else {
                          navigate(
                            `/view-address/${customerId}/${"Communication"}/${
                              address.id
                            }`
                          );
                        }
                      }}
                    />
                    <MdOutlineDeleteOutline
                      style={{
                        width: "23px",
                        height: "22px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (
                          stage === "New / Draft" ||
                          stage === "Sent back to correction"
                        ) {
                          setDeleteAddressId(address.id);
                          setShowModal(true);
                        } else {
                          alert("Cannot edit application at this stage!");
                        }
                      }}
                    ></MdOutlineDeleteOutline>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this address?</Modal.Body>
        <Modal.Footer>
          <Button
            label="Cancel"
            onClick={() => setShowModal(false)}
            variant="outline-primary"
          />
          <Button
            label="Delete"
            onClick={() => handleDeleteAddress(deleteAddressId)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddressDetails;
