import React, { useEffect, useState } from "react";

import { Accordion, Col, Row, Spinner } from "react-bootstrap";
import IndiaFinancierBusinessVerification from "./indiaFinancierBusinessVerification";
// import AddressDetailsVerification from "./AddressDetailsVerification";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { getBusinessDetails } from "../redux/action/getBusinessDetails";
import ForeignFinancierBusinessVerification from "./foreignFinancierBusinessVerification";
import ForeignBusinessVerification from "./foreignBusinessVerification";
import IndiaCorporateBusinessVerification from "./indiaCorporateBusinessVerification";
import IndiaNonCorporateBusinessVerification from "./indiaNCBusinessVerification";
import AddressDetailsVerification from "./addressDetailsVerification";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";
import BankAccountsVerification from "./bankAccountsVerification";
import Button from "../../../components/common/Button";
import { getUserRole } from "../../../utils/tokenHandlers";
import { updateStage } from "../../opsUser/ppApplication/ppIndia/apis/updateStage";
import IndiaBasicDetails from "./indiaBasicDetails";
import ForeignBasicDetails from "./foreignBasicDetails";
import TextArea from "../../../components/common/Textarea";
import { SubmitHandler, useForm } from "react-hook-form";
import { SendBackComments } from "../redux/types/VerificationDetailsType";
import { error } from "console";
import { updateSendBackComments } from "../redux/action/updateSendBackComments";
import { getAppAction } from "../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import Dropdown from "../../../components/common/dropdowns/Dropdown";
import { updateStageAction } from "../../opsUser/ppApplication/ppIndia/redux/action/updateStageAction";
import { fieldValidationAction } from "../../opsUser/ppApplication/ppIndia/redux/action/fieldValidation";
import { fetchReferredByBusiness } from "../apis/fetchReferredByBusiness";

const DetailsVerification: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [businessCountry, setBusinessCountry] = useState("");
  const [participantType, setParticipantType] = useState("");
  const [constitutionType, setConstitutionType] = useState("");
  const [referredByName, setReferredByName] = useState("");
  const [selectedCurrencies, setSelectedCurrencies] = useState("");
  const [sendBacktoMaker, setSendBacktoMaker] = useState(false);
  const [sendBacktoUser, setSendBacktoUser] = useState(false);
  const [viewMakerComments, setViewMakerComments] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const { appId } = useParams();
  const customerId = Number(appId);
  const navigate = useNavigate();
  const useRole = getUserRole();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SendBackComments>({
    shouldUnregister: true,
  });
  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  const appStage = state.data.stage;
  const isGuestUser = state.data.is_guest_user;
  const isCounterParty = state.data.is_counterparty;

  const referredBy = state.data.referred_by;

  const handleVerSubmit: SubmitHandler<SendBackComments> = async (
    commentsData
  ) => {
    if (useRole === "maker") {
      setShowSpinner(true);
      // console.log(commentsData);
      dispatch(updateSendBackComments({ customerId, commentsData })).then(
        (response: any) => {
          if (response.payload.result === true) {
            setShowSpinner(false);
            navigate(`/verification/individual-details/${customerId}`);
          } else {
            // console.log(response, ":here");
            setShowSpinner(false);
            alert(response.payload.result.error.message);
          }
          // console.log("comments data-->", response);
        }
      );
    } else if (useRole === "checker") {
      if (sendBacktoMaker) {
        setShowSpinner(true);
        // alert("send maker");
        let stage = `move_to_maker_verification`;
        dispatch(updateSendBackComments({ customerId, commentsData })).then(
          (response: any) => {
            if (response.payload.result === true) {
              dispatch(fieldValidationAction({ customerId, stage })).then(
                (response) => {
                  // console.log("Api resp-->", response);
                  if (response.payload.error) {
                    alert(response.payload.error.data.message);
                    setShowSpinner(false);
                    // // console.warn(validationErr);
                    // openValidationModal(validationErr);
                  } else {
                    navigate(`/ops/dashboard`);
                    setShowSpinner(false);
                  }
                }
              );
            } else {
              setShowSpinner(false);
              alert("Something went wrong! Please contact admin");
            }
            // console.log("comments data-->", response);
          }
        );
      } else if (sendBacktoUser) {
        setShowSpinner(true);
        // alert("send user");
        let stage = `move_stage_to_in_form_filling`;
        dispatch(updateSendBackComments({ customerId, commentsData })).then(
          (response: any) => {
            if (response.payload.result === true) {
              // dispatch(updateStageAction({ customerId, stage })).then(
              //   (response: any) => {
              //     // console.log(response);
              //     navigate(`/ops/dashboard`);
              //   }
              // );
              dispatch(fieldValidationAction({ customerId, stage })).then(
                (response) => {
                  // console.log("Api resp-->", response);
                  if (response.payload.error) {
                    setShowSpinner(false);
                    alert(response.payload.error.data.message);
                    // // console.warn(validationErr);
                    // openValidationModal(validationErr);
                  } else {
                    navigate(`/ops/dashboard`);
                    setShowSpinner(false);
                  }
                }
              );
            } else {
              setShowSpinner(false);
              alert("Something went wrong! Please contact admin");
            }
            // console.log("comments data-->", response);
          }
        );
      } else {
        setShowSpinner(false);
        navigate(`/verification/individual-details/${customerId}`);
      }
      // console.log(commentsData);
    }

    // navigate(`/verification/individual-details/${customerId}`);
  };

  const handleSendBack = async () => {
    if (useRole == "maker") {
      const stage = "Sent back to correction";
      // console.log(stage);
      const sendBack = updateStage(customerId, stage);
      // console.log(sendBack);
      if (await sendBack) {
        navigate("/ops/dashboard");
      } else {
        alert("Something went wrong! Please contact admin");
      }
    } else {
      const stage = "Sent back to maker";
      // console.log(stage);
      const sendBack = updateStage(customerId, stage);
      // console.log(sendBack);
      if (await sendBack) {
        window.location.reload();
        alert("Application Sent Back to Maker!");
      } else {
        alert("Something went wrong! Please contact admin");
      }
    }
  };

  const handleBasicDetailsClick = async () => {
    await dispatch(getBusinessDetails(customerId)).then((response) => {
      const businessDetails = response.payload.data.result;
      setBusinessCountry(businessDetails.business_registration_country_id.name);
    });
  };

  const handleBusinessDetailsClick = async () => {
    // console.warn("Business Details Clicked");
    try {
      const result = await dispatch(getBusinessDetails(customerId));
      if (result.payload) {
        const businessDetails = result.payload.data.result;
        setBusinessCountry(
          businessDetails.business_registration_country_id.name
        );
        const currencies = businessDetails.currency_ids
          .map((currency: { currency_code: any }) => currency.currency_code)
          .join(", ");
        // const businessCountry =
        //   businessDetails.business_registration_country_id.name;
        setParticipantType(businessDetails.business_participant_type);
        setConstitutionType(businessDetails.business_constitution_type);
        setSelectedCurrencies(currencies);
        //   setConstitutionType(businessDetails.)
        // console.warn(participantType);
        // console.warn("Currencies: ", currencies);
        // setReferredBy(businessDetails.referred_by);
        // // console.log("Country-->", businessCountry);
        // // console.log("Participant Type-->", participantType);
        const getReferredBy = await fetchReferredByBusiness(referredBy).then(
          (resp) => {
            // console.warn(resp.data.result.business_name);
            setReferredByName(resp.data.result.business_name);
          }
        );
        // console.warn("dispatch result-->", result.payload.data.result);
      }
    } catch (error) {
      // console.error("An error occured", error);
    }
  };

  const handleAddressDetailsClick = async () => {
    // console.log("Address Details Clicked");
    // try {
    // }
  };

  const handleBankAccountsClick = () => {
    // console.log("Hello");
  };

  // useEffect(() => {
  //   if (referredBy) {
  //     dispatch(getAppAction(Number(referredBy))).then((resp) => {
  //       // console.warn(resp);
  //     });
  //   }
  // }, [dispatch, referredBy]);

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    reset({
      in_verification_comments: state.data.in_verification_comments,
      in_form_filling_comments: state.data.in_form_filling_comments,
    });
  }, [reset, state]);
  return (
    <>
      {}
      <Accordion>
        <Accordion.Item eventKey="4">
          <Accordion.Header onClick={handleBasicDetailsClick}>
            BASIC DETAILS
          </Accordion.Header>
          <Accordion.Body>
            {businessCountry === "India" ? (
              <IndiaBasicDetails />
            ) : (
              <ForeignBasicDetails />
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={handleBusinessDetailsClick}>
            BUSINESS DETAILS
          </Accordion.Header>
          <Accordion.Body>
            {businessCountry === "India" && participantType === "Financier" ? (
              <IndiaFinancierBusinessVerification
                referredByName={referredByName}
              />
            ) : businessCountry !== "India" &&
              participantType === "Financier" ? (
              <ForeignFinancierBusinessVerification
                referredByName={referredByName}
              />
            ) : businessCountry !== "India" ? (
              <ForeignBusinessVerification referredByName={referredByName} />
            ) : constitutionType === "PLC" ||
              constitutionType === "LLC" ||
              constitutionType === "LLP" ? (
              <IndiaCorporateBusinessVerification
                referredByName={referredByName}
              />
            ) : (
              <IndiaNonCorporateBusinessVerification
                referredByName={referredByName}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header onClick={handleAddressDetailsClick}>
            ADDRESS DETAILS
          </Accordion.Header>
          <Accordion.Body>
            <AddressDetailsVerification />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header onClick={handleBusinessDetailsClick}>
            CURRENCIES
          </Accordion.Header>
          <Accordion.Body>
            <Row md={12}>
              <Col md={12}>
                <VerBusinessDetails
                  label="Currencies"
                  defaultValue={selectedCurrencies}
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header onClick={handleBankAccountsClick}>
            BANK ACCOUNTS
          </Accordion.Header>
          <Accordion.Body>
            <BankAccountsVerification />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row className="mt-3">
        <Col>
          {useRole === "maker" && appStage === "Sent back to maker" && (
            <TextArea
              label="Checker Comments"
              name="in_verification_comments"
              register={register}
              disabled
            />
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {/* <p>{isGuestUser}</p> */}
          {useRole === "maker" && isGuestUser === false && (
            <TextArea
              label={"Add comments"}
              name={"in_form_filling_comments"}
              register={register}
              placeholder="Add Comments for Checker"
              required
              disabled={
                appStage === "Expired" ||
                appStage === "Customer Registered" ||
                appStage === "In Customer Registration" ||
                appStage === "New / Draft" ||
                appStage === "In Form Signing"
              }
              requiredMessage="Comments are required"
              error={errors.in_form_filling_comments?.message}
            />
          )}
          {useRole === "checker" && appStage === "In Checker Verification" && (
            <>
              {/* <Button
                label={"Send back to Maker"}
                variant="outline-primary"
                onClick={() => {
                  setSendBacktoMaker(true);
                  setSendBacktoUser(false);
                  setViewMakerComments(false);
                }}
                disabled={
                  appStage === "Expired" ||
                  appStage === "Customer Registered" ||
                  appStage === "In Customer Registration"
                }
              />
              <Button
                label={"Send back to User"}
                variant="outline-primary"
                onClick={() => {
                  setSendBacktoMaker(false);
                  setSendBacktoUser(true);
                  setViewMakerComments(false);
                }}
                disabled={
                  appStage === "Expired" ||
                  appStage === "Customer Registered" ||
                  appStage === "In Customer Registration"
                }
              /> */}
              <Button
                label={"View Maker Comments"}
                variant="outline-primary"
                onClick={() => {
                  setSendBacktoMaker(false);
                  setSendBacktoUser(false);
                  setViewMakerComments(true);
                }}
                disabled={
                  appStage === "Expired" ||
                  appStage === "Customer Registered" ||
                  appStage === "In Customer Registration"
                }
              />
            </>
          )}
          {sendBacktoMaker === true && (
            <Row className="mt-2">
              <TextArea
                label={"Add comments for Maker"}
                name={"in_verification_comments"}
                register={register}
                placeholder="Add Comments for Maker"
                required
                requiredMessage="Comments are required"
                error={errors.in_verification_comments?.message}
              />
            </Row>
          )}
          {sendBacktoUser === true && (
            <Row className="mt-2">
              <TextArea
                label={"Add comments for User"}
                name={"in_form_filling_comments"}
                register={register}
                placeholder="Add Comments for User"
                required
                requiredMessage="Comments are required"
                error={errors.in_form_filling_comments?.message}
              />
            </Row>
          )}
          {viewMakerComments === true && (
            <Row className="mt-2">
              <TextArea
                label={"View Maker comments"}
                name={"in_form_filling_comments"}
                register={register}
                disabled
                // placeholder="Add Comments for User"
                // required
                // requiredMessage="Comments are required"
                // error={errors.in_form_filling_comments?.message}
              />
            </Row>
          )}

          {/* <Button
            label={
              useRole == "checker"
                ? "Send back to Maker"
                : "Send back for Correction"
            }
            variant="outline-primary"
            onClick={handleSendBack}
          /> */}
        </Col>
      </Row>
      {(useRole === "maker" || useRole === "checker") && (
        <Row>
          <Col className="text-end mt-3">
            {showSpinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>
                {appStage === "Sent back to maker" ||
                appStage === "In Maker Verification" ||
                appStage === "In Checker Verification" ? (
                  <Button
                    label="Submit & Next"
                    variant="primary"
                    onClick={handleSubmit(handleVerSubmit)}
                  />
                ) : (
                  <Button
                    label="Submit & Next"
                    variant="primary"
                    onClick={() => {
                      navigate(
                        `/verification/individual-details/${customerId}`
                      );
                    }}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default DetailsVerification;
