import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getCounterPartyAction } from "../action/getCounterPartyAction";
import { getParticipantAction } from "../action/getParticipantsAction";

const initialState: CreateAppData = {
  data: [],
  loading: false,
  error: null,
};

const getParticipantSlice = createSlice({
  name: "getParticipantSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getParticipantAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getParticipantAction.fulfilled, (state, action) => {
        // console.log("action payload getApp---> ", action);
        state.loading = false;
        state.data = action.payload.result.result;
        // console.log("DATA ---> ", action.payload.result);
      })
      .addCase(getParticipantAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getParticipantReducer = getParticipantSlice.reducer;
