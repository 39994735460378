import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { localBankActiveState } from "../types/LocalBankActiveState";

const initialState: localBankActiveState = {
  isActive: false,
};

const localBankActiveSlice = createSlice({
  name: "localBankActiveSlice",
  initialState,
  reducers: {
    setLocalBankFormStatus: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
  },
});

export const { setLocalBankFormStatus } = localBankActiveSlice.actions;

export const localBankActiveReducer = localBankActiveSlice.reducer;
