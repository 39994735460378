import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCinFromPan } from "../../../../../apis/thirdParty/getCinFromPan";
import { getAddressFromCinAction } from "./getAddressFromCinAction";
import { updateAppAction } from "./updateAppAction";

export const getCinFromPanAction = createAsyncThunk(
  "getCinFromPanAction",
  async (
    { pan, customerId }: { pan: string; customerId: number },
    thunkAPI
  ) => {
    return getCinFromPan(pan).then((response: any) => {
      // console.log("CIN API response", response);
      // if (
      //   response.data.statusCode === 101 &&
      //   response.data.result.length !== 0
      // ) {
      //   thunkAPI.dispatch(
      //     updateAppAction({
      //       customerId,
      //       updateCustomerData: {
      //         is_cin_captured: true,
      //         business_registered_with:
      //           response.data.result[0].Date_of_Incorporation,
      //         business_cin_no: response.data.result[0].entityId,
      //       },
      //     })
      //   );
      //   thunkAPI.dispatch(
      //     getAddressFromCinAction({
      //       cin: response.data.result[0].entityId,
      //       customerId,
      //     })
      //   );
      // }
      return response.data;
    });
  }
);
