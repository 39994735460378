import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIndividualDocs } from "../../apis/getIndividualDocs";

type individualArgs = {
  customerId: number | null;
  selectedId: number | null;
};

export const fetchIndividualDocs = createAsyncThunk(
  "fetchIndividualDocs",
  async (args: individualArgs) => {
    const { customerId, selectedId } = args;
    const response = await getIndividualDocs(customerId, selectedId);
    return response;
  }
);
