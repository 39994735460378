import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchBusinessDetails } from "../../apis/fetchBusinessDetails";

export const getBusinessDetails = createAsyncThunk(
  "getBusinessDetails",
  async (customerId: number) => {
    const response = await fetchBusinessDetails(customerId);
    // // console.warn("in action file-->", response);
    return response;
  }
);
