import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAddressFromCin } from "../../../../../apis/thirdParty/getAddressFromCin";
import { parseAddress } from "../../../../../../utils/functions/parseAddress";
import { createAddressAction } from "./createAddressAction";

export const getAddressFromCinAction = createAsyncThunk(
  "getAddressFromCinAction",
  async ({ cin, customerId }: { cin: any; customerId: number }, thunkAPI) => {
    return getAddressFromCin(cin).then((response: any) => {
      // console.log("Address form CIN response out", response);
      // if (response["status-code"] === "101") {
      //   // console.log("Address form CIN response in");
      //   let address = response.result.Registered_Address;
      //   let parseAddressDetails = parseAddress(address);
      //   let addressDetailsData = {
      //     customer_id: customerId,
      //     type: "Registered",
      //     ...parseAddressDetails,
      //   };
      //   // console.log("Address Details", addressDetailsData);
      //   thunkAPI.dispatch(createAddressAction(addressDetailsData));
      // }
      return response;
    });
  }
);
