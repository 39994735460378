export const parseGstAddress = (gstAddress: any) => {
  const address = {
    gstin: gstAddress.gstin,
    tradeName: gstAddress.tradeNam,
    lgnm: gstAddress.lgnm,
    bnm: gstAddress.bnm,
    st: gstAddress.st,
    bno: gstAddress.bno,
    loc: gstAddress.loc,
    stcd: gstAddress.stcd,
    city: gstAddress.dst,
    pncd: gstAddress.pncd,
    ctb: gstAddress.ctb,
  };
  return address;
};
