import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getAddressAction } from "../action/getAddressAction";

const initialState: CreateAppData = {
  data: [],
  loading: false,
  error: null,
};

const getAddressSlice = createSlice({
  name: "getAddressSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddressAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAddressAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAddressAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getAddressReducer = getAddressSlice.reducer;
