import { createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateStatusRemarks } from "../../apis/updateStatusRemarks";
import { VerificationDetailsType } from "../types/VerificationDetailsType";

export const updateVerStatusRemarks = createAsyncThunk(
  "updateVerStatusRemarks",
  async (formData: VerificationDetailsType) => {
    return UpdateStatusRemarks(formData).then((response: any) => {
      // // console.warn("in action file");
      return response.data;
    });
  }
);
