import { Container, Row, Col } from "react-bootstrap";
import ApplicationAccess from "../components/ApplicationAccess";
import GuestUserLogin from "../components/GuestLogin";
import HowItWorks from "../components/HowItWorks";
import NavbarVTX from "../../../components/common/Navbar";

const GuestUserLoginPage: React.FC = () => {
  return (
    <>
      <Container>
        <NavbarVTX />
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <HowItWorks />
          </Col>
          <Col md={6}>
            <GuestUserLogin />
            <ApplicationAccess />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GuestUserLoginPage;
