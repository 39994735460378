import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import SectionHeading from "../../../components/common/SectionHeading";

const HowItWorks: React.FC = () => {
  return (
    <>
      <Row>
        <Row>
          <Col>
            <SectionHeading text="HOW IT WORKS" />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <h5>Upload - Bid - Get Financed - Repay</h5>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <Carousel>
              <Carousel.Item>
                <img src={"/carouselImages/caraousel001.png"} alt="Slide 1" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={"/carouselImages/caraousel002.png"} alt="Slide 2" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={"/carouselImages/caraousel003.png"} alt="Slide 3" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={"/carouselImages/caraousel004.png"} alt="Slide 4" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={"/carouselImages/caraousel005.png"} alt="Slide 5" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={"/carouselImages/caraousel006.png"} alt="Slide 6" />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default HowItWorks;
