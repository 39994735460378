import { RouteObject } from "react-router-dom";
import AddKeyIndividual from "../pages/AddKeyIndividual";
import EditKeyIndivdual from "../pages/EditKeyIndivdual";

const cpApplicationRoutesConfig: RouteObject[] = [
  {
    path: "cp/add/keyperson/:appId",
    element: <AddKeyIndividual />,
  },
  {
    path: "/:appId/cp/edit/keyperson/:keyPersonId",
    element: <EditKeyIndivdual />,
  },
];

export default cpApplicationRoutesConfig;
