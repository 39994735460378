import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKeyPersonIds } from "../../apis/getKeyPersonIds";

type keyPersonArgs = {
  customerId: number;
  personId: number | null;
};

export const fetchKeyPersonIds = createAsyncThunk(
  "fetchKeyPersonIds",
  async (args: keyPersonArgs) => {
    const { customerId, personId } = args;
    const response = await getKeyPersonIds(customerId, personId);
    return response;
  }
);
