import { getVerStatusRemarks } from "../action/getVerStatusRemarks";
import { BusinessDetailsData } from "../types/VerBusinessDetailsType";
import { createSlice } from "@reduxjs/toolkit";

const initialState: BusinessDetailsData = {
  data: null,
  loading: false,
  error: null,
  successfull: false,
};

const verStatusRemarksSlice = createSlice({
  name: "verStatusRemarksSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVerStatusRemarks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVerStatusRemarks.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        // // console.log("DATA createAppSlice", action);
      })
      .addCase(getVerStatusRemarks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const verStatusRemarksReducer = verStatusRemarksSlice.reducer;
