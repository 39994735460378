import React, { useState } from "react";
import {
  Col,
  Dropdown as ReactDropdown,
  DropdownButton,
  Form,
  Image,
  ListGroup,
  Nav,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Input from "../../../components/common/Input";
import { useForm } from "react-hook-form";
import { VerificationDetailsType } from "../redux/types/VerificationDetailsType";
import Dropdown from "../../../components/common/dropdowns/Dropdown";
import IconInput from "../../../components/common/IconInput";
import { MdCloudUpload, MdOutlineDeleteOutline, MdSave } from "react-icons/md";
import VerFieldsVerificationComponent from "../../guestUser/ppApplication/pages/components/VerFieldsVerification";

const VerDocumentsVerification: React.FC = () => {
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<VerificationDetailsType>({
    shouldUnregister: true,
  });
  const [selectedType, setSelectedType] = useState("Type");
  const [selectedName, setSelectedName] = useState(" ");
  const [showImage, setShowImage] = useState(false);

  // const [showSpinner, setShowSpinner] = useState(false);

  const handleTypeSelect = (selectedItem: string) => {
    setSelectedType(selectedItem);
    localStorage.setItem("Typeone", selectedItem);
    switch (selectedItem) {
      case "POA":
        setSelectedName("Passport");
        break;
      case "POI":
        setSelectedName("PAN");
        setShowImage(true);
        break;
      case "Others":
        setSelectedName("ITR");
        break;
      default:
        setSelectedName(" ");
        break;
    }
  };

  //   const handleNameSelect = (selectedItem: string) => {
  //     setSelectedName(selectedItem);
  //   };
  const [activeTab, setActiveTab] = useState("Documents");

  const onSaveVerification = () => {
    // console.warn("saveClicked");
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const documentsTab = () => {
    return (
      <>
        <Row>
          <Col md={6}>
            <DropdownButton
              id="document-type-dropdown"
              title={selectedType} // Use the selectedType state as the button title
              variant="outline-primary"
              style={{
                marginLeft: "15px",
              }}
            >
              <ReactDropdown.Item onClick={() => handleTypeSelect("COI")}>
                Certificate of Incorporation
              </ReactDropdown.Item>
              <ReactDropdown.Item onClick={() => handleTypeSelect("MAOA")}>
                Memorandum & Article of Association
              </ReactDropdown.Item>
              <ReactDropdown.Item onClick={() => handleTypeSelect("POA")}>
                Proof of Address Document
              </ReactDropdown.Item>
              <ReactDropdown.Item onClick={() => handleTypeSelect("POI")}>
                Proof of Identity Document
              </ReactDropdown.Item>
              <ReactDropdown.Item onClick={() => handleTypeSelect("EPD")}>
                Entity Proof Document
              </ReactDropdown.Item>
              <ReactDropdown.Item onClick={() => handleTypeSelect("CC")}>
                Cancelled Cheque
              </ReactDropdown.Item>
              <ReactDropdown.Item onClick={() => handleTypeSelect("IEC")}>
                Import Export Certificate
              </ReactDropdown.Item>
              <ReactDropdown.Item onClick={() => handleTypeSelect("FEMA")}>
                FEMA Declaration
              </ReactDropdown.Item>
              <ReactDropdown.Item onClick={() => handleTypeSelect("Others")}>
                Others
              </ReactDropdown.Item>
            </DropdownButton>
          </Col>
          <Col md={6}>
            <p className="m-1">{selectedName}</p>
          </Col>
        </Row>
        {showImage && (
          <Row className="mt-3">
            <Col md={6}>
              {/* Display your image here */}
              <Image
                src="https://th.bing.com/th/id/OIP.kGb1dBGcZ_oax7DPF-YN7AAAAA?pid=ImgDet&rs=1"
                rounded
                width={250}
                // height={800}
                // style={{
                //   border: "solid",
                //   cursor: "pointer",
                // }}
                // https://th.bing.com/th/id/OIP.kGb1dBGcZ_oax7DPF-YN7AAAAA?pid=ImgDet&rs=1
              />
            </Col>
          </Row>
        )}
        <Row>
          <p className="mt-3" style={{ color: "#214382" }}>
            DOCUMENT VERIFICATION : {localStorage.getItem("Typeone")}
          </p>
        </Row>
        <Form>
          <Row>
            <Dropdown
              label="API Verification Status"
              name="api_verification_status"
              register={register}
              disabled
              error={errors.api_verification_status?.message}
              requiredMessage="API verification status is required"
              options={[
                { label: "Success", value: "s" },
                { label: "Failure", value: "f" },
              ]}
            />
          </Row>
          <Row>
            <Input
              label="API Verification Remarks"
              name="api_verification_remarks"
              register={register}
              disabled
              error={errors.api_verification_remarks?.message}
              requiredMessage="API verification remarks is required"
            />
          </Row>
          <Row>
            <Dropdown
              label="Verification Status"
              name="verification_status"
              register={register}
              // error={errors.verification_status?.message}
              required
              requiredMessage="Verification Status is required"
              options={[
                { label: "Success", value: "s" },
                { label: "Failure", value: "f" },
              ]}
            />
          </Row>
          <Row>
            <Input
              label="Verification Remarks"
              name="verification_remarks"
              register={register}
              required
              requiredMessage="Verification Remark is required"
              // error={errors.verification_remarks?.message}
            />
          </Row>
          <Row>
            <Form.Group controlId="formFileSm" className="mb-3">
              <Form.Label>Supporting Document :</Form.Label>
              <Form.Control type="file" size="sm" />
            </Form.Group>
            <Col
              className="text-end"
              style={{
                marginTop: "-15px",
              }}
            >
              <IconInput
                icon={<MdCloudUpload />}
                style={{
                  marginRight: "10px",
                }}
              />
              <IconInput icon={<MdOutlineDeleteOutline />} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="text-end">
              <IconInput
                icon={<MdSave />}
                onClick={handleSubmit(onSaveVerification)}
              />
            </Col>
          </Row>
        </Form>
        <Row>
          <p className="mt-3" style={{ color: "#214382" }}>
            COMMENTS HISTORY :
          </p>
        </Row>
        <Row>
          <ListGroup variant="flush" className="m-2">
            <ListGroup.Item
              style={{
                color: "#214382",
              }}
            >
              <p> 23-Jan-2023 USER1 - Maker</p>
              <i> Image quality is not good </i>
            </ListGroup.Item>
            <ListGroup.Item
              style={{
                color: "#214382",
              }}
            >
              <p> 25-Mar-2023 USER2 - Checker</p>
              <i> Image quality is very good </i>
            </ListGroup.Item>
          </ListGroup>
        </Row>
        <Row>
          <p className="mt-3" style={{ color: "#214382" }}>
            SUPPORTING DOCUMENTS :
          </p>
        </Row>

        <Row>
          <Col md={12}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <td>Doc</td>
                  <td>File</td>
                  <td>Comments</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td colSpan={2}>Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Nav
        variant="pills"
        activeKey={activeTab}
        className="justify-content-between m-3 mt-0"
      >
        <Nav.Item>
          <Nav.Link
            eventKey="Documents"
            onClick={() => handleTabClick("Documents")}
          >
            Documents
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Fields" onClick={() => handleTabClick("Fields")}>
            Fields
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {activeTab === "Documents" && documentsTab()}
      {activeTab === "Fields" && <VerFieldsVerificationComponent />}
    </>
  );
};

export default VerDocumentsVerification;
