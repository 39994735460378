import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import EntityLLPKYC from "./components/EntityLLPKYC";
import Header from "../../../../components/common/Header";

const GuestEntityLLPKYC: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <EntityLLPKYC />
    </>
  );
};

export default GuestEntityLLPKYC;
