import axios from "axios";

export const getEnrichApiToken = () => {
  return axios
    .post(
      `https://sandbox.services.vayananet.com/theodore/apis/v1/authtokens/`,
      {
        handle: "sayali+11@vayana.com",
        password: "Abcd12345678!",
        handleType: "email",
        tokenDurationInMins: 5,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response): any => response)
    .catch((err) => err);
};
