import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import { Col, Row } from "react-bootstrap";
import VerDocumentsVerification from "../../../verificationStage/components/VerDocumentsVerification";
import VerBusinessVerification from "./components/VerBusinessVerification";
import "../../../../components/css/common.css";
import Header from "../../../../components/common/Header";

const GuestVerificationPage: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <Row style={{ marginLeft: "10px" }} className="mt-3">
        <Col md={3} style={{ height: "100vh", overflowY: "auto" }}>
          <VerDocumentsVerification />
        </Col>
        <Col md={9} style={{ overflowY: "auto", height: "100vh" }}>
          <VerBusinessVerification />
        </Col>
      </Row>
    </>
  );
};

export default GuestVerificationPage;
