import axios from "axios";
import { DueDiligenceType } from "../pages/types/KycDocType";

export const updateDueDiligenceDoc = (
  docId: number,
  dueDiligenceDetails: DueDiligenceType,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/customer.due_diligence_document/${docId}`,
      {
        params: {
          context: {},
          data: dueDiligenceDetails,
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      // console.log("DUE RESPONSE", response);
      return response;
    })
    .catch((err) => err);
};
