import axios from "axios";

export const getGstDetails = (
  gstNumber: string,
  orgId: string,
  token: string
) => {
  return axios
    .get(
      `https://solo.enriched-api.vayana.com/basic/gstn/v1.0/commonapi/v0.3/search/${gstNumber}`,
      {
        headers: {
          "X-FLYNN-N-USER-TOKEN": token,
          "X-FLYNN-N-ORG-ID": orgId,
          accept: "application/json",
        },
      }
    )
    .then((response): any => response)
    .catch((err) => err);
};
