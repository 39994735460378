import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getAllDueDiligenceDocAction } from "../action/getAllDueDiligenceDocAction";

const initialState: CreateAppData = {
  data: [],
  loading: false,
  error: null,
};

const getAllDueDiligenceDocSlice = createSlice({
  name: "getAllDueDiligenceDocSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDueDiligenceDocAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllDueDiligenceDocAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result.result;
      })
      .addCase(getAllDueDiligenceDocAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getAllDueDiligenceDocReducer = getAllDueDiligenceDocSlice.reducer;
