import { getSessionId } from "../../../utils/tokenHandlers";
import axios from "axios";

export const fetchCorrBank = async (settlementBankId: number) => {
  const sessionId = getSessionId();

  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer_settlement_correspondent_bank/`,
      {
        params: {
          filter: `[('customer_settlement_bank_id', 'in',  [${settlementBankId}])]`,
          query:
            "{bank_name,bank_account_number,sort_code,iban,swift_code,currency_id{currency_code}, customer_settlement_bank_id{bank_name}}",
        },
      },
      {
        params: {
          session_id: sessionId,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
