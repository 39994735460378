import React, { useEffect } from "react";
import { Row, Col, Form, Table, Container } from "react-bootstrap";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useForm } from "react-hook-form";
import {
  MdOutlineModeEditOutline,
  MdOutlineDeleteOutline,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import Button from "../../../../../components/common/Button";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { getAllKeyPersonAction } from "../redux/action/getAllKeyPersonAction";
import { deleteKeyPersonAction } from "../redux/action/deleteKeyPersonAction";
import { getKeyPersonType } from "../../../../apis/listOfValues/getKeyPersonType";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import Header from "../../../../../components/common/Header";
import NavbarVTX from "../../../../../components/common/Navbar";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { getAppAction } from "../redux/action/getAppAction";
import { CPKeyPersonDetailsType } from "./types/KeyPersonDetailsType";

const OpsKeyPersonPage: React.FC = () => {
  const token = getSessionId();
  let { appId } = useParams();
  let customerId = Number(appId);

  const dispatch = useDispatch<AppDispatch>();
  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let stage = getAppSelector.data.stage;
  const allKeyPersonSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllKeyPerson.data
  );

  const deleteKeyPersonSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.deleteKeyPerson
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CPKeyPersonDetailsType>();
  const navigate = useNavigate();

  const [deleteKeyPersonId, setDeleteKeyPersonId] = useState<number>(0);
  const [participantType, setParticipantType] = useState("");
  const [constitutionType, setConstitutionType] = useState("");
  const [countryCode, setCountryCode] = useState<string>();

  useEffect(() => {
    dispatch(getAllKeyPersonAction(customerId));
  }, [deleteKeyPersonSelector]);

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, []);

  const handleAddClick = () => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      if (getAppSelector.data.is_counterparty === true) {
        navigate(`/cp/add/keyperson/${customerId}`);
      } else {
        navigate(`/add-key-person-details/${customerId}`);
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const handleDeleteRow = (keyPersonId: any) => {
    dispatch(deleteKeyPersonAction(keyPersonId));
    setSelectedRowIndex(null);
  };

  const headerState = useSelector(
    (state: RootState) => state.guestPPApp.headerDetails
  );

  const checkNavigate = () => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      if (getAppSelector.data.is_counterparty === true) {
        navigate(`/application-undertaking/${customerId}`);
      } else {
        const keyPersonTypeValues = allKeyPersonSelector.map(
          (keyPerson: { key_person_type_ids: any[] }) =>
            keyPerson.key_person_type_ids.map(
              (val: { lov_display_value: any }) => val.lov_display_value
            )
        );

        // Count the occurrences of "Entity Administrator"
        const entityAdminCount = keyPersonTypeValues.reduce(
          (count: number, nestedArray: string | string[]) => {
            if (nestedArray.includes("Entity Administrator")) {
              return count + 1;
            }
            return count;
          },
          0
        );

        if (entityAdminCount >= 2) {
          // console.log(countryCode, "hello");
          if (countryCode === "India") {
            // console.log("Success");
            if (participantType === "Financier") {
              navigate(`/india/financier/entity/kyc-details/${customerId}`);
            } else if (constitutionType === "proprietorship") {
              navigate(
                `/india/proprietorship/entity/kyc-details/${customerId}`
              );
            } else if (constitutionType === "partnership") {
              navigate(`/india/partnership/entity/kyc-details/${customerId}`);
            } else if (constitutionType === "LLP") {
              navigate(`/india/LLP/entity/kyc-details/${customerId}`);
            } else if (constitutionType === "others") {
              navigate(`/unlisted/entity/kyc-details/${customerId}`);
            } else {
              navigate(`/india/corporate/entity/kyc-details/${customerId}`);
            }
          } else {
            if (participantType === "Financier") {
              navigate(`/foreign/financier/entity/kyc-details/${customerId}`);
            } else {
              navigate(`/foreign/entity/kyc-details/${customerId}`);
            }
          }
        } else {
          // console.log("False");
          alert("Please enter atleast 2 Entity Admin");
        }
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  useEffect(() => {
    if (
      headerState &&
      headerState.data &&
      headerState.data.data &&
      headerState.data.data.result
    ) {
      const headerStateUpdated = headerState.data.data.result;
      if (headerStateUpdated) {
        setParticipantType(headerStateUpdated.business_participant_type);
        setConstitutionType(headerStateUpdated.business_constitution_type);
        setCountryCode(
          headerStateUpdated.business_registration_country_id.name
        );
      }
    }
  }, [headerState]);
  return (
    <Container>
      <NavbarVTX />
      <Header />
      <Form>
        <Row>
          <Col md={20} className="myCol">
            <SectionHeading text="Key Persons/ Authorised officials/ Administrators and Beneficial Owners, etc" />
          </Col>

          <Row>
            <Col md={20} className="text-end">
              <Button
                label="+ Add KEY PERSONS"
                onClick={handleAddClick}
                variant="outline-primary"
              ></Button>
            </Col>
          </Row>
          <Table striped bordered size="sm" className="table-container">
            <thead>
              <tr className="table-header">
                <th colSpan={3}>NAME</th>
                <th colSpan={3}>EMAIL</th>
                <th colSpan={3}>KEY PERSONS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {allKeyPersonSelector.map((keyPerson: any) => (
                <tr>
                  <td colSpan={3}>
                    {keyPerson.first_name} {keyPerson.last_name}
                  </td>
                  <td colSpan={3}>{keyPerson.email}</td>
                  <td colSpan={3}>
                    {keyPerson.key_person_type_ids.map(
                      (val: any) => val.lov_display_value + ", "
                    )}
                  </td>
                  <td>
                    <MdOutlineModeEditOutline
                      style={{ cursor: "pointer" }}
                      className="table-actions"
                      onClick={() => {
                        if (
                          stage === "New / Draft" ||
                          stage === "Sent back to correction"
                        ) {
                          getAppSelector.data.is_counterparty === true
                            ? navigate(
                                `/${customerId}/cp/edit/keyperson/${keyPerson.id}`
                              )
                            : navigate(
                                `/${customerId}/edit-key-person-details/${keyPerson.id}`
                              );
                        } else {
                          alert("Cannot edit application at this stage!");
                        }
                      }}
                    />
                    <MdOutlineRemoveRedEye
                      style={{ cursor: "pointer" }}
                      className="table-actions"
                      onClick={() => {
                        getAppSelector.data.is_counterparty === true
                          ? navigate(
                              `/${customerId}/view-cp-key-person-details/${keyPerson.id}`
                            )
                          : navigate(
                              `/${customerId}/view-key-person-details/${keyPerson.id}`
                            );
                      }}
                    ></MdOutlineRemoveRedEye>
                    <MdOutlineDeleteOutline
                      style={{ cursor: "pointer", color: "red" }}
                      className="table-actions"
                      onClick={() => {
                        if (
                          stage === "New / Draft" ||
                          stage === "Sent back to correction"
                        ) {
                          setSelectedRowIndex(1);
                          setDeleteKeyPersonId(keyPerson.id);
                        } else {
                          alert("Cannot edit application at this stage!");
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={selectedRowIndex !== null}
          onHide={() => setSelectedRowIndex(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to Delete Key Individual?
          </Modal.Body>
          <Modal.Footer>
            <Button
              label="Cancel"
              onClick={() => setSelectedRowIndex(null)}
              variant="outline-primary"
            />
            <Button
              label="Delete"
              onClick={() => handleDeleteRow(deleteKeyPersonId)}
            />
          </Modal.Footer>
        </Modal>
      </Form>
      <Row>
        <Col md={20} className="text-end">
          <Button
            label="Save & Next"
            onClick={() => {
              checkNavigate();
            }}
          ></Button>
        </Col>
      </Row>
    </Container>
  );
};

export default OpsKeyPersonPage;
