import { RouteObject } from "react-router-dom";
import OpsCreateAppPage from "../pages/OpsCreateAppPage";
import OpsBusinessDeatilsPage from "../pages/OpsBusinessDetailsPage";
import AppListPage from "../pages/AppListPage";
import OpsAddAddressPage from "../pages/OpsAddAddressPage";
import OpsEditAddressPage from "../pages/OpsEditAddressPage";
import OpsDashboardPage from "../pages/OpsDashboardPage";
import ApplicationListView from "../pages/ApplicationListView/ApplicationListView";
import OpsCurrencyBankPage from "../pages/OpsCurrencyBankPage";
import OpsAddLocalBankPage from "../pages/OpsAddLocalBankPage";
import OpsEditLocalBankPage from "../pages/OpsEditLocalBankPage";
import OpsKeyPersonPage from "../pages/OpsKeyPersonPage";
import OpsAddKeyPersonDetailsPage from "../pages/OpsAddKeyPersonDetailsPage";
import OpsEditKeyPersonDetailsPage from "../pages/OpsEditKeyPersonDetailsPage";
import OpsOfficeUsePage from "../pages/OpsOfficeUsePage";
import OpsDueDilligence from "../pages/OpsDueDilligence";
import ApplicationListViewCp from "../pages/ApplicationListView/ApplicationListViewCp";
import OpsINCBusinessDetailsPage from "../pages/OpsINCBusinessDetails";
import OpsIFBusinessDetailspage from "../pages/OpsIFBusinessDetails";
import OpsFFBusinessDetailsPage from "../pages/OpsFFBusinessDetailsPage";
import OpsFBusinessDetailsPage from "../pages/OpsFBusinessDetailsPage";
import OpsApplicationUndertaking from "../pages/OpsApplicationUndertaking";
import OpsApplicationSigningPage from "../pages/OpsApplicationSigningPage";
import GuestICEntityKYCPage from "../../../../guestUser/ppApplication/pages/GuestICEntityKYCPage";
import GuestProprietorshipKYCPage from "../../../../guestUser/ppApplication/pages/GuestProprietorshipKYCPage";
import GuestIndiaFinancierKYC from "../../../../guestUser/ppApplication/pages/GuestIndiaFinancierKycPage";
import GuestForeignFinancierKYC from "../../../../guestUser/ppApplication/pages/GuestForeignFinancierKYCPage";
import GuestEntityForeignKYC from "../../../../guestUser/ppApplication/pages/GuestEntityForeignKYCPage";
import GuestEntityPartnershipKYC from "../../../../guestUser/ppApplication/pages/GuestEntityPartnershipKYCPage";
import GuestEntityLLPKYC from "../../../../guestUser/ppApplication/pages/GuestEntityLLPKYCPage";
import GuestKeyIndividualKYC from "../../../../guestUser/ppApplication/pages/GuestKeyIndividualKYC";
import GuestApplicationSubmssionFormPage from "../../../../guestUser/ppApplication/pages/GuestApplicationSubmissionFormPage";
import OpsCPCreateAppPage from "../pages/OpsCPCreateAppPage";
import OpsViewAddressPage from "../pages/OpsViewAddressPage";
import OpsViewKeyPersonPage from "../pages/OpsViewKeyPersonPage";
import ViewKeyIndividual from "../../../../cpUser/pages/ViewKeyIndividual";
import OpsViewBankDetails from "../pages/OpsViewBankDetails";
import GuestEntityUnlistedKYC from "../../../../guestUser/ppApplication/pages/GuestEntityUnlistedKYCPage";

const ppApplicationRoutesConfig: RouteObject[] = [
  {
    path: "ops/create-application",
    element: <OpsCreateAppPage />,
  },
  {
    path: "ops/create-application/cp",
    element: <OpsCPCreateAppPage />,
  },
  {
    path: "/india/corporate/business-details/:appId",
    element: <OpsBusinessDeatilsPage />,
  },
  {
    path: "ops/app-list",
    element: <AppListPage />,
  },
  {
    path: "/add-address/:addressType/:appId",
    element: <OpsAddAddressPage />,
  },
  {
    path: "/edit-address/:appId/:addressType/:address_Id",
    element: <OpsEditAddressPage />,
  },
  {
    path: "/view-address/:appId/:addressType/:address_Id",
    element: <OpsViewAddressPage />,
  },
  {
    path: "ops/dashboard",
    element: <OpsDashboardPage />,
  },
  {
    path: "ops/applicationlistview",
    element: <ApplicationListView />,
  },
  {
    path: "ops/applicationlistviewcp",
    element: <ApplicationListViewCp />,
  },
  {
    path: "/currency-bank-details/:appId",
    element: <OpsCurrencyBankPage />,
  },

  {
    path: "/add-local-bank/:appId",
    element: <OpsAddLocalBankPage />,
  },
  {
    path: "/:appId/edit-local-bank/:bankId",
    element: <OpsEditLocalBankPage />,
  },
  {
    path: "/:appId/view-local-bank/:bankId",
    element: <OpsViewBankDetails />,
  },
  {
    path: "/key-person-details/:appId",
    element: <OpsKeyPersonPage />,
  },
  {
    path: "/add-key-person-details/:appId",
    element: <OpsAddKeyPersonDetailsPage />,
  },
  {
    path: "/:appId/edit-key-person-details/:keyPersonId",
    element: <OpsEditKeyPersonDetailsPage />,
  },
  {
    path: "/:appId/view-key-person-details/:keyPersonId",
    element: <OpsViewKeyPersonPage />,
  },
  {
    path: "/:appId/view-cp-key-person-details/:keyPersonId",
    element: <ViewKeyIndividual />,
  },
  {
    path: "ops/official-use-only/:appId",
    element: <OpsOfficeUsePage />,
  },
  {
    path: "/duedilligence/:appId",
    element: <OpsDueDilligence />,
  },
  {
    path: "/india/non-corporate/business-details/:appId",
    element: <OpsINCBusinessDetailsPage />,
  },
  {
    path: "/india/financier/business-details/:appId",
    element: <OpsIFBusinessDetailspage />,
  },
  {
    path: "/foreign/financier/business-details/:appId",
    element: <OpsFFBusinessDetailsPage />,
  },
  {
    path: "/foreign/business-details/:appId",
    element: <OpsFBusinessDetailsPage />,
  },
  {
    path: "/application-undertaking/:appId",
    element: <OpsApplicationUndertaking />,
  },
  {
    path: "/application-signing/:appId",
    element: <OpsApplicationSigningPage />,
  },
  {
    path: "ops/dashboard/applicationlistview",
    element: <ApplicationListView />,
  },
  {
    path: "ops/dashboard/applicationlistviewcp",
    element: <ApplicationListViewCp />,
  },
  {
    path: "/ApplicationSubmission/:appId",
    element: <GuestApplicationSubmssionFormPage />,
  },

  // KYC pages:
  {
    path: "/india/corporate/entity/kyc-details/:appId",
    element: <GuestICEntityKYCPage />,
  },
  {
    path: "/india/proprietorship/entity/kyc-details/:appId",
    element: <GuestProprietorshipKYCPage />,
  },
  {
    path: "/india/financier/entity/kyc-details/:appId",
    element: <GuestIndiaFinancierKYC />,
  },
  {
    path: "/foreign/financier/entity/kyc-details/:appId",
    element: <GuestForeignFinancierKYC />,
  },
  {
    path: "/foreign/entity/kyc-details/:appId",
    element: <GuestEntityForeignKYC />,
  },
  {
    path: "/unlisted/entity/kyc-details/:appId",
    element: <GuestEntityUnlistedKYC />,
  },
  {
    path: "/india/partnership/entity/kyc-details/:appId",
    element: <GuestEntityPartnershipKYC />,
  },
  {
    path: "/india/llp/entity/kyc-details/:appId",
    element: <GuestEntityLLPKYC />,
  },
  {
    path: "/keyperson/kyc-details/:appId",
    element: <GuestKeyIndividualKYC />,
  },
];

export default ppApplicationRoutesConfig;
