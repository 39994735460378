import React, { useState, useEffect } from "react";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { Container, Row, Col, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import Input from "../../../../../components/common/Input";
import DatePicker from "../../../../../components/common/DatePicker";
import ListingInfoDropdown from "../../../../../components/common/ListingInfoDropdown";
import Button from "../../../../../components/common/Button";
import RegisterWith from "../../../../../components/common/dropdowns/RegisterWith";
import NatureOfBusiness from "../../../../../components/common/dropdowns/NatureOfBusiness";
import FinancierTypeIndia from "../../../../../components/common/dropdowns/FinancierTypeIndia";
import { ForeignBusiness } from "../../../../guestUser/ppApplication/pages/types/ForeignBusinessType";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import FAddressDetails from "../../../../guestUser/ppApplication/pages/components/FAddressDetails";
import RadioButton from "../../../../../components/common/RadioButton";
import CheckBoxes from "../../../../../components/common/CheckBoxes";
import { BusinessDetailsType } from "../pages/types/BusinessDetailsType";
import { updateAppAction } from "../redux/action/updateAppAction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { getAppAction } from "../redux/action/getAppAction";
import {
  ALPHANUMERIC_REGEX,
  LEI_REGEX,
  WEBSITE_REGEX,
} from "../../../../constants/regexPatterns";
import CPBusinessDetailsFields from "./CPBusinessDetailsFields";
import SendBackPdfBtn from "../../../../../components/common/sendBackPdfBtn";
import FinancierTypeFgn from "../../../../../components/common/dropdowns/FinancierTypeFgn";
const FFBusinessDetails: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { appId } = useParams();
  const customerId = Number(appId);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<BusinessDetailsType>({
    shouldUnregister: true,
  });

  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  let stage = state.data.stage;
  const financierType = watch("business_financier_type_fgn");

  const [selectedFinancierTypeValue, setSelectedFinancierTypeValue] = useState<
    string | undefined
  >(undefined);

  const [defaultSelectedListing, setDefaultSelectedListing] = useState<
    number[]
  >([]);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedValues, setSelectedValues] = useState<number[]>([]);

  const handleSelectedValuesChange = (newSelectedValues: number[]) => {
    setSelectedValues(newSelectedValues);
  };

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    // console.log("App Data ----> ", state);

    reset({
      business_registered_with_fgn: state.data.business_registered_with_fgn,
      business_registration_place: state.data.business_registration_place,
      business_listing_info_ids: state.data.business_listing_info_ids,
      business_cin_no: state.data.business_cin_no,
      business_registration_date: state.data.business_registration_date,
      business_commencement_date: state.data.business_commencement_date,
      business_legal_entity_identifier:
        state.data.business_legal_entity_identifier,
      business_website: state.data.business_website,
      business_nature: state.data.business_nature,
      business_iecno: state.data.business_iecno,
      business_nature_activity: state.data.business_nature_activity,
      business_unit_location: state.data.business_unit_location,
      business_statusholder: state.data.business_statusholder,
      business_ckycno: state.data.business_ckycno,
      business_registration_number: state.data.business_registration_number,
      is_msme: state.data.is_msme === true ? "true" : "false",
      is_two_factor: state.data.is_two_factor,
      business_registered_by_fgn: state.data.business_registered_by_fgn,
      business_financier_type_fgn: state.data.business_financier_type_fgn,
      business_registration_certificate_no:
        state.data.business_registration_certificate_no,
      business_license_no: state.data.business_license_no,
      business_issued_date: state.data.business_issued_date,
      business_no_of_years_of_relation_with_cp:
        state.data.business_no_of_years_of_relation_with_cp,
      referred_by_vendor_dealer_id: state.data.referred_by_vendor_dealer_id,
      business_is_cp_related_party: state.data.business_is_cp_related_party,
    });
    setDefaultSelectedListing(state.data.business_listing_info_ids);
  }, [state, reset]);

  const handleFinancierTypeDropdownChange = (value: string) => {
    setSelectedFinancierTypeValue(value);
    // console.warn(value);
  };
  const handleBusinessDetailsSubmit: SubmitHandler<BusinessDetailsType> = (
    updateCustomerData
  ) => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      // console.log(updateCustomerData);
      setShowSuccessAlert(false);
      if (selectedValues.length === 0) {
        // If empty, set business_listing_info_ids to state.data.business_listing_info_ids
        updateCustomerData.business_listing_info_ids =
          state.data.business_listing_info_ids;
      } else {
        // If not empty, set business_listing_info_ids to selectedValues
        updateCustomerData.business_listing_info_ids = selectedValues;
      }
      if (updateCustomerData.is_msme === "true") {
        updateCustomerData.is_msme = true;
      } else {
        updateCustomerData.is_msme = false;
      }
      updateCustomerData.business_nature = "service";
      // console.log("Business Details", updateCustomerData);
      dispatch(updateAppAction({ customerId, updateCustomerData })).then(
        (response) => {
          // console.log("UPDATE RESP", response);
          if (!response.payload.result.error) {
            setShowSuccessAlert(true);
          }
        }
      );
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  return (
    <>
      <Container>
        <Row>
          <DisplayBox display={showSuccessAlert}>
            <AlertPopup
              variant="success"
              message="Business Details Updated Successfully"
            />
          </DisplayBox>
          <Row>
            <Col md="12">
              <SectionHeading text="BUSINESS DETAILS" />
            </Col>
            <Col className="text-end">
              <SendBackPdfBtn />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(handleBusinessDetailsSubmit)}>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                {/* <RegisterWith
                  label="Business Registration With"
                  name="business_registered_with_fgn"
                  register={register}
                  required
                  // placeholder="Enter business registration"
                  requiredMessage="Business Registration is required"
                  error={errors.business_registered_with_fgn?.message}
                  //defaultValue="MCA"
                /> */}
                <Input
                  label="Business Registration With"
                  name="business_registered_with_fgn"
                  register={register}
                  required
                  // regexPattern={ALPHABETS_REGEX}
                  requiredMessage="Business Registration is required"
                  error={errors.business_registered_with_fgn?.message}
                  placeholder="Enter Business Registration"
                />
              </Col>
              <Col sm={6} className="mt-2">
                <Input
                  label="Place of Incorporation"
                  name="business_registration_place"
                  register={register}
                  required
                  // regexPattern={ALPHANUMERIC_REGEX}
                  requiredMessage="Incorporation Place is required"
                  error={errors.business_registration_place?.message}
                  placeholder="Enter place of registration"
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <Input
                  label="Registration No"
                  name="business_registration_number"
                  register={register}
                  required
                  regexPattern={ALPHANUMERIC_REGEX}
                  requiredMessage="Registration number is required"
                  error={errors.business_registration_number?.message}
                  placeholder="Enter Registration number"
                />
              </Col>
              <Col sm={6} className="mt-2">
                <DatePicker
                  label="Date of Incorporation"
                  name="business_registration_date"
                  register={register}
                  required
                  isDateOfBirth={true}
                  requiredMessage="DOI is required"
                  error={errors.business_registration_date?.message}
                  //disabled
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6}>
                {defaultSelectedListing && (
                  <ListingInfoDropdown
                    selectedValues={defaultSelectedListing}
                    onSelectedValuesChange={handleSelectedValuesChange}
                    name="business_listing_info_ids"
                    label="Listing Information"
                    required
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    //defaultValue={state.data.business_listing_info_ids}
                    error={errors.business_listing_info_ids?.message}
                  />
                )}
              </Col>
              <Col sm={6}>
                <DatePicker
                  label="Date of Commencement"
                  name="business_commencement_date"
                  register={register}
                  isDateOfBirth={true}
                  // required
                  // requiredMessage="DOC is required"
                  // error={errors.business_commencement_date?.message}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6}>
                <Input
                  label="Legal Entity Identifier (LEI)"
                  name="business_legal_entity_identifier"
                  register={register}
                  // required
                  regexPattern={LEI_REGEX}
                  // requiredMessage="LEI is required"
                  error={errors.business_legal_entity_identifier?.message}
                  placeholder="Enter LEI"
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="Website"
                  name="business_website"
                  register={register}
                  regexPattern={WEBSITE_REGEX}
                  //regexPattern={/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/}
                  regexMessage="Invalid website URL format"
                  // error={errors.business_website?.message}
                  placeholder="Enter website url"
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <NatureOfBusiness
                  label="Nature of Business"
                  name="business_nature"
                  register={register}
                  disabled
                  // required
                  // requiredMessage="Nature of Business is required"
                  // error={errors.business_nature?.message}
                  defaultValue="service"
                  //disabled
                />
              </Col>
              <Col sm={6} className="mt-2">
                {/* <Dropdown
                  label="Regulator Name"
                  name="business_registered_by_fgn"
                  register={register}
                  options={[
                    {
                      label:
                        "International Financial Services Centers Authority",
                      value: "ifsca",
                    },
                    {
                      label: "Reserve Bank of India",
                      value: "rbi",
                    },
                  ]}
                  required
                  requiredMessage="Regulator name is required"
                  error={errors.business_registered_by_fgn?.message}
                /> */}
                <Input
                  label={"Regulator Name"}
                  name={"business_registered_by_fgn"}
                  register={register}
                  required
                  requiredMessage="Regulator name is required"
                  error={errors.business_registered_by_fgn?.message}
                />
              </Col>
            </Row>
            <Row md={12} className="mt-2">
              <Col sm={6}>
                <FinancierTypeFgn
                  label="Financier Type"
                  name="business_financier_type_fgn"
                  register={register}
                  required
                  requiredMessage="Financier type is required"
                  error={errors.business_financier_type_fgn?.message}
                />

                {financierType === "others" && (
                  <Input
                    label="If others"
                    name="business_financier_type_other"
                    register={register}
                    required
                    // requiredMessage="Please provide additional details"
                    placeholder="Enter Other financier type"
                    // error={errors.business_financier_type_fgn?.message}
                  />
                )}
              </Col>

              <Col sm={6}>
                <Input
                  label="Regulatory License No"
                  // name="business_registration_certificate_no"
                  name="business_license_no"
                  register={register}
                  placeholder="Enter Regulatory License No"
                  required
                  regexPattern={ALPHANUMERIC_REGEX}
                  requiredMessage="Regulatory License no  is required"
                  error={errors.business_license_no?.message}
                />
              </Col>
            </Row>

            <Row md={12} className="mt-2">
              <Col sm={6}>
                <RadioButton
                  label="FCI 2-Factor"
                  name="is_two_factor"
                  register={register}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  required
                  requiredMessage="Two factor selection is required"
                  error={errors.is_two_factor?.message}
                />
              </Col>
              <Col sm={6}>
                <DatePicker
                  label="License Issue Date"
                  name="business_issued_date"
                  register={register}
                  required
                  requiredMessage="Issue date is required"
                  error={errors.business_issued_date?.message}
                  isDateOfBirth
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6}>
                <Input
                  label="Registration Certificate No"
                  name="business_registration_certificate_no"
                  // name="business_license_no"
                  register={register}
                  placeholder="Enter Registration Certificate No"
                  required
                  regexPattern={ALPHANUMERIC_REGEX}
                  requiredMessage="Registration Certificate No  is required"
                  error={errors.business_registration_certificate_no?.message}
                />
              </Col>
              {/* <Col sm={6}> */}
              {/* <DisplayBox
                  // display={
                  //   state.data.business_participant_type === "Seller" &&
                  //   state.data.is_counterparty === false
                  // }
                > */}
              {/* <p>true</p> */}
              {/* <RadioButton
                    label="Is MSMEs ?"
                    name="is_msme"
                    register={register}
                    options={[
                      { label: "Yes", value: "true" },
                      { label: "No", value: "false" },
                    ]}
                    required
                    requiredMessage="Is MSME selection is required"
                    error={errors.is_msme?.message}
                  /> */}
              {/* </DisplayBox> */}
              {/* </Col> */}
            </Row>
            {state.data.is_counterparty === true && (
              <CPBusinessDetailsFields register={register} errors={errors} />
            )}
            <Row>
              <Col className="m-2 text-end">
                <Button label="Save Details" type="submit" />
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default FFBusinessDetails;
