import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getKeyPersonAction } from "../action/getKeyPersonAction";

const initialState: CreateAppData = {
  data: {},
  loading: false,
  error: null,
};

const getKeyPersonSlice = createSlice({
  name: "getKeyPersonSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKeyPersonAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getKeyPersonAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result;
      })
      .addCase(getKeyPersonAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getKeyPersonReducer = getKeyPersonSlice.reducer;
