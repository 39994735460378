import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  DropdownButton,
  Dropdown as ReactDropdown,
  Image,
  Container,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchUploadedDocs } from "../../guestUser/ppApplication/redux/action/fetchUploadedDocs";
import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";
import VerStatusRemarks from "./verStatusRemarks";
import { getVerStatusRemarks } from "../redux/action/getVerStatusRemarks";
import SectionHeading from "../../../components/common/SectionHeading";

const KYCVerification: React.FC = () => {
  // const statusRemarksStore =

  const { appId } = useParams();
  const customerId = Number(appId);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedType, setSelectedType] = useState(" Select Type");
  const [verDocId, setVerDocId] = useState(Number);
  const [typeSelected, setTypeSelected] = useState(false);
  const [selectedName, setSelectedName] = useState(" ");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [docType, setDocType] = useState("");

  const [docObjects, setDocObjects] = useState<
    {
      id: any;
      doc_type: string;
      doc_id: number;
      doc_name: string;
      document_name_fgn: string;
      doc_name_text: string;
    }[]
  >([]);
  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  const isCounterParty = getAppSelector.data.is_counterparty;
  // const handleDropdownChange = async (docType: string) => {
  //   await fetchDocContent(docType);
  //   handleDocTypeSelect(docType);
  //   await handleVerStatusRemarks();
  // };
  const handleDropdownChange = async (
    selectedDocName: string,
    selectedDocType: string,
    selectedDocId: any
  ) => {
    await fetchDocContent(selectedDocName, selectedDocType);
    setSelectedName(selectedDocName);
    // console.log("selected DOc:", selectedDocName);
    const docObject = docObjects.find(
      (doc) =>
        doc.doc_name_text === selectedDocName &&
        doc.doc_type === selectedDocType &&
        doc.id === selectedDocId
    );
    if (docObject) {
      // console.log("id::", selectedDocId);
      // console.log("Doc Obj:", docObject);
      const docId = docObject.doc_id;
      setVerDocId(selectedDocId);

      setDocNameType(docObject.doc_name);
      const docTypee = docObject.doc_type;
      // // console.warn("boom---", docObject.doc_name);
      // console.warn("document id-->", docTypee);
      handleDocTypeSelect(docTypee);
      await handleVerStatusRemarks(selectedDocId);
    }
    // handleDocTypeSelect(selectedDocName);
    // await handleVerStatusRemarks();
  };

  const handleDocTypeSelect = (docType: string) => {
    setSelectedType(docType);
    localStorage.setItem("selectedType", docType);
    // console.warn("here", localStorage.getItem("selectedType"));
  };

  useEffect(() => {
    const fetchDocsData = async () => {
      localStorage.removeItem("selectedType");
      try {
        const result = await dispatch(fetchUploadedDocs(customerId));
        if (result.payload) {
          // console.warn("success");
          // console.warn(result.payload);
          const documents = result.payload.result.result;
          const docObjects = documents.map(
            (document: {
              id: any;
              document_type: any;
              attachment_ids: any[];
              document_name: any;
              document_name_fgn: any;
              document_name_text: any;
            }) => ({
              id: document.id,
              doc_type: document.document_type,
              doc_id: document.attachment_ids[0],
              doc_name: document.document_name,
              document_name_fgn: document.document_name_fgn,
              doc_name_text: document.document_name_text,
            })
          );
          setDocObjects(docObjects);

          // console.warn(`Docs Object for ${customerId}`, docObjects);
        }
      } catch (error) {
        // console.error("An error occurred", error);
      }
    };
    fetchDocsData();
  }, [customerId]);

  const sessionId = getSessionId();
  const [docNameType, setDocNameType] = useState("");
  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");

  const fetchDocContent = async (
    selectedDocName: string,
    selectedDocType: string
  ) => {
    setDataUpdated(false);
    setShowSpinner(true);
    // setSelectedType(docType);
    // setShowSpinner(true);
    // console.log("FDC: ", selectedDocType);
    const docObject = docObjects.find(
      (doc) =>
        doc.doc_name_text === selectedDocName &&
        doc.doc_type === selectedDocType
    );
    if (docObject) {
      const docId = docObject.doc_id;
      setDocNameType(docObject.doc_name);
      // // console.warn("boom---", docObject.doc_name);
      // console.warn("document id-->", docId);
      // console.warn("customer id-->", customerId);
      const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?session_id=${sessionId}`;

      try {
        const response = await axios.get<ArrayBuffer>(apiUrl, {
          responseType: "arraybuffer",
        });

        const dataAsArray = new Uint8Array(response.data);

        if (
          dataAsArray[0] === 0x25 &&
          dataAsArray[1] === 0x50 &&
          dataAsArray[2] === 0x44 &&
          dataAsArray[3] === 0x46
        ) {
          // const pdfBase64 = `data:application/pdf;base64,${btoa(
          //   String.fromCharCode.apply(null, Array.from(dataAsArray))
          // )}`;
          setImageData(apiUrl);
          // console.warn("its a pdf");
          setFileType("pdf");
          // await handleVerStatusRemarks();
        } else {
          const imageBase64 = `data:image/png;base64,${btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          )}`;
          setImageData(imageBase64);
          // console.warn("its a img");
          setFileType("img");
          // await handleVerStatusRemarks();
        }

        setTypeSelected(true);
      } catch (error) {
        setShowSpinner(false);
        setTypeSelected(false);
        // console.error("Error fetching file:", error);
      }

      // console.log("API URL:", apiUrl);
    }
    setShowSpinner(false);
  };

  const handleVerStatusRemarks = async (selectedDocId: any) => {
    try {
      const result = await dispatch(
        getVerStatusRemarks({ customerId, selectedDocId })
      );
      if (result.payload) {
        // console.log("in main file-->", result.payload);
        setTimeout(() => {
          setDataUpdated(true);
        }, 500);
      }
    } catch (error) {
      // console.error("An error occurred", error);
    }
  };

  return (
    <>
      <Row>
        <Col md={6}>
          {isCounterParty === false && (
            <DropdownButton
              id="document-type-dropdown"
              title={selectedType}
              variant="outline-primary"
              style={{
                marginLeft: "15px",
              }}
            >
              {docObjects.map((docObject) => (
                <ReactDropdown.Item
                  key={docObject.doc_id}
                  onClick={() => {
                    setDocType(docObject.doc_type);
                    handleDropdownChange(
                      docObject.doc_name_text,
                      docObject.doc_type,
                      docObject.id
                    );
                  }}
                >
                  {docObject.doc_type} - {docObject.doc_name}{" "}
                  {docObject.document_name_fgn} - {docObject.doc_name_text}
                </ReactDropdown.Item>
              ))}
            </DropdownButton>
          )}
        </Col>
        <Col md={6}>
          <p className="m-1">{selectedName}</p>
        </Col>
      </Row>
      <Row className="m-2">
        {isCounterParty === true ? (
          // <p className="mt-3" style={{ color: "#d64161" }}>
          //   KYC IS NOT APPLICABLE FOR COUNTERPARTY APPLICATIONS...
          // </p>
          <SectionHeading text="KYC IS NOT APPLICABLE FOR COUNTERPARTY APPLICATIONS..." />
        ) : (
          <p>Select Type to view the Document</p>
        )}
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Container>
            {showSpinner ? (
              <Spinner variant="primary" animation="border" />
            ) : fileType === "pdf" ? (
              <iframe
                src={imageData}
                title="Document viewer"
                style={{ width: "550px", height: "550px" }}
              />
            ) : fileType === "img" ? (
              <Image
                src={imageData}
                style={{ width: "550px", border: "solid" }}
              />
            ) : null}
          </Container>
        </Col>
      </Row>
      {dataUpdated ? (
        <VerStatusRemarks selectedDocType={selectedType} verDocId={verDocId} />
      ) : null}
    </>
  );
};

export default KYCVerification;
