import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getAllApp } from "../../apis/getAllApp";

export const getAllAppAction = createAsyncThunk(
  "getAppAction",
  async (currentPage: number) => {
    return getAllApp(getSessionId(), currentPage).then((response: any) => {
      return response.data;
    });
  }
);
