import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { GstAddressType } from "../../apis/types/GstAddressType";
import { createGstAddress } from "../../apis/createGstAddress";

export const createGstAddressAction = createAsyncThunk(
  "createGstAddressAction",
  async (GstAddressDeatils: GstAddressType) => {
    return createGstAddress(GstAddressDeatils, getSessionId()).then(
      (response) => {
        return response.data;
      }
    );
  }
);
