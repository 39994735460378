import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../../components/common/dropdowns/Dropdown";
import Input from "../../../components/common/Input";
import { VerificationDetailsType } from "../redux/types/VerificationDetailsType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import Button from "../../../components/common/Button";
import { updateVerStatusRemarks } from "../redux/action/updateVerStatusRemarks";
import VerSupportingDoc from "./verSupportingDoc";
import VerSupportingDocKeyPerson from "./verSupportingDocKeyPerson";
import { getUserRole } from "../../../utils/tokenHandlers";
import { getAppAction } from "../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import { useParams } from "react-router-dom";
import RadioButton from "../../../components/common/RadioButton";
import { DisplayBox } from "../../../components/containers/DisplayBox";
import AlertPopup from "../../../components/common/Alert/AlertPopup";
interface VerStatusRemarksProps {
  selectedDocType: string;
  verDocId: any;
}
const VerStatusKeyPerson: React.FC<VerStatusRemarksProps> = ({
  selectedDocType,
  verDocId,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
  } = useForm<VerificationDetailsType>({
    shouldUnregister: true,
  });
  const dispatch = useDispatch<AppDispatch>();
  const { appId } = useParams();
  const customerId = Number(appId);
  const userRole = getUserRole();
  const [makerVerStatus, setMakerVerStatus] = useState("");
  const [checkerVerStatus, setCheckerVerStatus] = useState("");
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);
  const state = useSelector(
    (state: RootState) => state.verification.verStatusKeyPerson
  );
  const state1 = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  const appStage = state1.data.stage;

  useEffect(() => {
    if (state) {
      reset({
        api_verification_status:
          state.data.data.result.result[0].api_verification_status,
        field_api_verification_remarks:
          state.data.data.result.result[0].field_api_verification_remarks,
        maker_verification_status:
          state.data.data.result.result[0].maker_verification_status,
        maker_verification_comments:
          state.data.data.result.result[0].maker_verification_comments,
        checker_verification_status:
          state.data.data.result.result[0].checker_verification_status,
        checker_verification_comments:
          state.data.data.result.result[0].checker_verification_comments,
      });
    }
  }, [state]);

  if (
    !state.data ||
    !state.data.data ||
    !state.data.data.result ||
    state.data.data.result.length === 0
  ) {
    return <Row>Select Type to View the Document</Row>;
  }

  // Get the first element from the result array
  const statusRemarksStore = state.data.data.result.result[0];

  // Check if statusRemarksStore is defined
  if (!statusRemarksStore) {
    return <Row>Select Type to View the Document</Row>;
  }

  const docId = verDocId;

  const handleVerStatusRemarks: SubmitHandler<VerificationDetailsType> = async (
    formData
  ) => {
    setShowSaveAlert(false);
    localStorage.setItem("docId", verDocId);

    // console.warn("Submit clicked");
    // console.log("data==>", formData);
    try {
      setShowSpinner(true);
      const result = await dispatch(updateVerStatusRemarks(formData));
      if (result.payload.result === true) {
        // console.warn("in main file-->", result.payload);
        // alert("Data Updated!");
        setShowSaveAlert(true);
      } else {
        setShowSpinner(false);
        // console.warn("Something went wrong");
      }
    } catch (error) {
      setShowSpinner(false);
      // console.error("An error occurred", error);
    }
    setShowSpinner(false);
  };
  const isMaker = userRole == "maker";
  return (
    <>
      <DisplayBox display={showSaveAlert}>
        <AlertPopup message="Data Updated!" variant="success" />
      </DisplayBox>
      {/* <p>{selectedDocType}</p> */}
      <Row className="text-center">
        <p className="mt-3" style={{ color: "#214382" }}>
          DOCUMENT VERIFICATION: {selectedDocType}
        </p>
      </Row>
      <Form onSubmit={handleSubmit(handleVerStatusRemarks)}>
        <Container style={{ width: "600px" }}>
          <Row md={12}>
            {/* <Dropdown
              label="API Verification Status"
              name="api_verification_status"
              defaultValue={statusRemarksStore.api_verification_status}
              register={register}
              // disabled
              error={errors.api_verification_status?.message}
              requiredMessage="API verification status is required"
              options={[
                { label: "Success", value: "Success" },
                { label: "Failure", value: "Failure" },
              ]}
            /> */}
            <RadioButton
              name={"api_verification_status"}
              label={"API Verification Status"}
              options={[
                { label: "Success", value: "Success" },
                { label: "Failure", value: "Failure" },
              ]}
              register={register}
              // defaultValue={statusRemarksStore.maker_verification_status}
              error={errors.api_verification_status?.message}
              // required={userRole == "maker"}
              // requiredMessage="Verification Status is required"
              // disabled={userRole !== "maker"}
              // onChange={(e) => setMakerVerStatus(e.target.value)}
            />
          </Row>
          <Row>
            <Input
              label="API Verification Remarks"
              name="api_verification_remarks"
              register={register}
              defaultValue={statusRemarksStore.api_verification_remarks}
              // disabled
              error={errors.api_verification_remarks?.message}
              requiredMessage="API verification remarks is required"
              placeholder="Enter API verification remarks"
            />
          </Row>
          <Row>
            {/* <Dropdown
              label="Maker Verification Status"
              name="maker_verification_status"
              defaultValue={statusRemarksStore.maker_verification_status}
              register={register}
              error={errors.maker_verification_status?.message}
              required={userRole == "maker"}
              requiredMessage="Verification Status is required"
              options={[
                { label: "Success", value: "Success" },
                { label: "Failure", value: "Failure" },
              ]}
              disabled={userRole !== "maker"}
            /> */}
            <RadioButton
              name={"maker_verification_status"}
              label={"Maker Verification Status"}
              options={[
                { label: "Success", value: "Success" },
                { label: "Failure", value: "Failure" },
              ]}
              register={register}
              // defaultValue={statusRemarksStore.maker_verification_status}
              error={errors.maker_verification_status?.message}
              required={userRole == "maker"}
              requiredMessage="Verification Status is required"
              disabled={userRole !== "maker"}
              onChange={(e) => setMakerVerStatus(e.target.value)}
            />
          </Row>
          <Row>
            <Input
              label="Maker Verification Remarks"
              name="maker_verification_comments"
              defaultValue={statusRemarksStore.maker_verification_comments}
              register={register}
              required={userRole == "maker" && makerVerStatus == "Failure"}
              requiredMessage="Verification Remark is required"
              error={errors.maker_verification_comments?.message}
              disabled={userRole !== "maker"}
              placeholder="Enter maker verification remarks"
            />
          </Row>

          <Row>
            {/* <Dropdown
              label="Checker Verification Status"
              name="checker_verification_status"
              defaultValue={statusRemarksStore.checker_verification_status}
              register={register}
              error={errors.checker_verification_status?.message}
              required={userRole !== "maker"}
              requiredMessage="Verification Status is required"
              options={[
                { label: "Success", value: "Success" },
                { label: "Failure", value: "Failure" },
              ]}
              disabled={userRole === "maker"}
            /> */}
            <RadioButton
              name={"checker_verification_status"}
              label={"Checker Verification Status"}
              options={[
                { label: "Success", value: "Success" },
                { label: "Failure", value: "Failure" },
              ]}
              register={register}
              // defaultValue={statusRemarksStore.maker_verification_status}
              error={errors.checker_verification_status?.message}
              required={userRole !== "maker"}
              requiredMessage="Verification Status is required"
              disabled={userRole === "maker"}
              onChange={(e) => setCheckerVerStatus(e.target.value)}
            />
          </Row>
          <Row>
            <Input
              label="Checker Verification Remarks"
              name="checker_verification_comments"
              defaultValue={statusRemarksStore.checker_verification_comments}
              register={register}
              required={userRole !== "maker" && checkerVerStatus == "Failure"}
              requiredMessage="Verification Remark is required"
              error={errors.checker_verification_comments?.message}
              placeholder="Enter checker verification remarks"
            />
          </Row>
        </Container>
        <Row className="mt-2">
          <Col className="text-end">
            {showSpinner ? (
              <Spinner variant="primary" animation="border" />
            ) : (
              <Button
                type="submit"
                label="Save Details"
                disabled={
                  appStage === "Expired" ||
                  appStage === "Customer Registered" ||
                  appStage === "In Customer Registration"
                }
              />
            )}
          </Col>
        </Row>
      </Form>

      <VerSupportingDocKeyPerson docId={docId} docType={selectedDocType} />
    </>
  );
};

export default VerStatusKeyPerson;
