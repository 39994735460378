import axios from "axios";
import { getSessionId } from "../../../../utils/tokenHandlers";

export const UploadKYCDocFgn = async () => {
  const sessionid = getSessionId();

  const requestData = {
    params: {
      context: {},
      data: {
        document_type: `${localStorage.getItem("docType")}`,
        document_name_fgn: localStorage.getItem("docName"),
        customer_id: Number(localStorage.getItem("customerId")),
        document_name_text: `${localStorage.getItem("fileName")}`,
        attachment_ids: [Number(localStorage.getItem("attachmentid"))],
        customer_key_person_id: "",
        is_keyperson: false,
      },
    },
  };
  //  // console.log("Request Data:", requestData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/create/one/tradexwbench.customer_kyc_document`,
      requestData,
      {
        params: {
          session_id: sessionid,
        },
      }
    )
    .then((response) => {
      //  // console.log("data-->", requestData);
      //  // console.warn("id--->", Number(localStorage.getItem("customerId")));
      return response;
    })
    .catch((error) => {
      // // console.error("second api err-->", error);
    });
};
