import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupportingDocsType } from "../types/VerificationDetailsType";
import { updateSupportingDocStatus } from "../../apis/updateSupportingDocStatus";

type SupportingStatusArgs = {
  docId: number;
  data: SupportingDocsType;
};

export const updateSupportingStatus = createAsyncThunk(
  "updateSupportingStatus",
  async (args: SupportingStatusArgs) => {
    const { docId, data } = args;
    const response = await updateSupportingDocStatus(docId, data);
    return response;
  }
);
