import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../../../components/common/Header";
import NavbarVTX from "../../../../../components/common/Navbar";
import LocalBankDetailsPage from "../../../../guestUser/common/components/BankDetails/LocalBankAccountDetails";
import Button from "../../../../../components/common/Button";
import Currencies from "../../../../guestUser/common/components/Currencies";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getAppAction } from "../redux/action/getAppAction";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { getAllLocalBankAction } from "../redux/action/getAllLocalBankAction";
import CPCurrencies from "../../../../guestUser/common/components/CPCurrencies";

const OpsCurrencyBankPage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const navigate = useNavigate();

  const currencies = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data.currency_ids
  );
  const appDetails = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let stage = appDetails.data.stage;
  const dispatch = useDispatch<AppDispatch>();

  const [selectedCurrencies, setSelectedCurrencies] = useState<number[]>([]);
  const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const allLocalBank = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllLocalBank
  );
  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    dispatch(getAllLocalBankAction(customerId));
  }, []);

  const handleBankNavigate = async () => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      if (allLocalBank.data[0]) {
        navigate(`/key-person-details/${customerId}`);
      } else {
        alert("Warning! You haven't added the Settlement Bank");
        navigate(`/key-person-details/${customerId}`);
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data
  );
  const isCPUser = getAppSelector.is_counterparty;
  const isCPBuyer = getAppSelector.business_participant_type;

  return (
    <>
      <NavbarVTX />
      <Header />
      {showWarningAlert && (
        <AlertPopup message="Please Select currency" variant="warning" />
      )}
      {showErrorAlert && (
        <AlertPopup message="Please Save currencies" variant="warning" />
      )}
      <Container>
        {currencies &&
          (isCPUser ? (
            <CPCurrencies
              defaultValues={currencies}
              setSelectedCurrencies={setSelectedCurrencies}
            />
          ) : (
            <Currencies
              defaultValues={currencies}
              setSelectedCurrencies={setSelectedCurrencies}
            />
          ))}

        {isCPUser && isCPBuyer === "Buyer" ? (
          <></>
        ) : (
          <>
            <LocalBankDetailsPage
              selectedCurrencies={selectedCurrencies}
              setShowWarningAlert={setShowWarningAlert}
              setShowErrorAlert={setShowErrorAlert}
            />

            <Row>
              <Col md={20} className="text-end">
                <Button
                  label="Save & Next"
                  onClick={() => {
                    // navigate(`/key-person-details/${customerId}`);
                    handleBankNavigate();
                  }}
                ></Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default OpsCurrencyBankPage;
