import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getAllLocalBank } from "../../apis/getAllLocalBank";

export const getAllLocalBankAction = createAsyncThunk(
  "getAllLocalBankAction",
  async (customerId: number) => {
    return getAllLocalBank(customerId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
