// PassportExpiryDatePicker.tsx

import React from "react";
import { Col, Row, Form } from "react-bootstrap";

interface PassportExpiryDatePickerProps {
  name: string;
  label: string;
  register: any;
  error?: any;
  disabled?: boolean;
  required?: boolean;
  requiredMessage?: string;
}

const PassportExpiryDatePicker: React.FC<PassportExpiryDatePickerProps> = ({
  name,
  label,
  register,
  error,
  disabled,
  required,
  requiredMessage,
}) => {
  const getFutureDate = () => {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    ); // Set a future date (e.g., 1 day from now)
    const year = futureDate.getFullYear();
    const month = (futureDate.getMonth() + 1).toString().padStart(2, "0");
    const day = futureDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>{" "}
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Control
            type="date"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            min={getFutureDate()}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default PassportExpiryDatePicker;
