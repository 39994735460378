import {
  Action,
  Reducer,
  ReducersMapObject,
  combineReducers,
} from "@reduxjs/toolkit";
import { createAppReducer } from "./createAppSlice";
import { OpsPpAppType } from "../types/OpsPpAppType";
import { getAllAppReducer } from "./getAllAppSlice";
import { getAppReducer } from "./getAppSlice";
import { updateAppReducer } from "./updateAppSlice";
import { createAddressReducer } from "./createAddressSlice";
import { cinFromPanReducer } from "./cinFromPanSlice";
import { addressFromCinReducer } from "./addressFromCinSlice";
import { getAllAddressReducer } from "./getAllAddressSlice";
import { getAddressReducer } from "./getAddressSlice";
import { updateAddressReducer } from "./updateAddressSlice";
import { getEnrichApiTokenReducer } from "./getEnrichApiTokenSlice";
import { createLocalBankReducer } from "./createLocalBankSlice";
import { getAllLocalBankReducer } from "./getAllLocalBankSlice";
import { createManyCorrBankReducer } from "./createManyCorrBankSlice";
import { getLocalBankReducer } from "./getLocalBankSlice";
import { getAllCorrBankReducer } from "./getAllCorrBankSlice";
import { createCorrBankReducer } from "./createCorrBankSlice";
import { updateCorrBankReducer } from "./updateCorrBankSlice";
import { updateLocalBankReducer } from "./updateLocalBankSlice";
import { deleteLocalBankReducer } from "./deleteLocalBankSlice";
import { createKeyPersonReducer } from "./createKeyPersonSlice";
import { getKeyPersonReducer } from "./getKeyPersonSlice";
import { getAllKeyPersonReducer } from "./getAllKeyPersonSlice";
import { deleteKeyPersonReducer } from "./deleteKeyPersonSlice";
import { updateKeyPersonReducer } from "./updateKeyPersonSlice";
import { deleteCorrBankReducer } from "./deleteCorrBankSlice";
import { localBankActiveReducer } from "./localBankActiveSlice";
import { getCorrBanksOfCurrencyReducer } from "./getCorrBanksOfCurrencySlice";
import { deleteAddressReducer } from "./deleteAddressSlice";
import { uploadAttachmentReducer } from "./uploadAttachmentSlice";
import { createDueDiligenceReducer } from "./createDueDiligenceSlice";
import { getAllDueDiligenceDocReducer } from "./getAllDueDiligenceDocSlice";
import { deleteDueDiligenceDocReducer } from "./deleteDueDiligenceDocSlice";
import { getAllApplicationStageReducer } from "./getAllApplicationStageSlice";
import { getCounterPartyReducer } from "./getCounterPartySlice";
import { getParticipantReducer } from "./getParticipantSlice";
import { updateAppListReducer } from "./updateApplicationListviewSlice";

import { updateDueDiligenceDocReducer } from "./updateDueDiligenceDocSlice";
import { getDueDiligenceDocReducer } from "./getDueDiligenceDocSlice";
import { createGstAddressReducer } from "./createGstAddressSlice";
import { getGstAddressReducer } from "./getGstAddressSlice";
import { CreateAppData } from "../types/CreateAppType";
// import { getSearchByateReducer } from "./getSearchByDateSlice";

export const opsPpIndAppReducerMap: ReducersMapObject<OpsPpAppType> = {
  createApp: createAppReducer,
  getAllApp: getAllAppReducer,
  getApp: getAppReducer,
  updateApp: updateAppReducer,
  createAddress: createAddressReducer,
  cinFromPan: cinFromPanReducer,
  addressFromCin: addressFromCinReducer,
  getAllAddress: getAllAddressReducer,
  getAddress: getAddressReducer,
  updateAddress: updateAddressReducer,
  deleteAddress: deleteAddressReducer,
  getEnrichApiToken: getEnrichApiTokenReducer,
  createLocalBank: createLocalBankReducer,
  getAllLocalBank: getAllLocalBankReducer,
  createManyCorrBank: createManyCorrBankReducer,
  getLocalBank: getLocalBankReducer,
  getAllCorrBank: getAllCorrBankReducer,
  createCorrBank: createCorrBankReducer,
  updateCorrBank: updateCorrBankReducer,
  updateLocalBank: updateLocalBankReducer,
  deleteLocalBank: deleteLocalBankReducer,
  createKeyPerson: createKeyPersonReducer,
  getKeyPerson: getKeyPersonReducer,
  getAllKeyPerson: getAllKeyPersonReducer,
  deleteKeyPerson: deleteKeyPersonReducer,
  updateKeyPerson: updateKeyPersonReducer,
  deleteCorrBank: deleteCorrBankReducer,
  isLocalBankFormActive: localBankActiveReducer,
  getCorrBanksOfCurrency: getCorrBanksOfCurrencyReducer,
  uploadAttachment: uploadAttachmentReducer,
  createDueDiligence: createDueDiligenceReducer,
  getAllDueDiligenceDoc: getAllDueDiligenceDocReducer,
  deleteDueDiligenceDoc: deleteDueDiligenceDocReducer,
  getAllAppsStage: getAllApplicationStageReducer,
  getCounterParty: getCounterPartyReducer,
  getParticipant: getParticipantReducer,
  updateApplicationListView: updateAppListReducer,
  updateDueDiligenceDoc: updateDueDiligenceDocReducer,
  getDueDiligenceDoc: getDueDiligenceDocReducer,
  createGstAddress: createGstAddressReducer,
  getGstAddress: getGstAddressReducer,
  // getSearchByDate: function (state: CreateAppData | undefined, action: Action<any>): CreateAppData {
  //   throw new Error("Function not implemented.");
  // }
};
export const opsPpIndAppReducer: Reducer<OpsPpAppType> =
  combineReducers<OpsPpAppType>(opsPpIndAppReducerMap);
