import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

export const uploadSupportingDocument = async (
  docName: string | Blob,
  selectedFile: string | Blob
) => {
  const sessionid = getSessionId();
  const formData = new FormData();

  if (docName) {
    formData.append("name", docName);
  }

  formData.append("type", "binary");
  formData.append("datas", selectedFile);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/upload/image`,
      formData,
      {
        params: {
          session_id: sessionid,
        },
      }
    );
    const { data } = response;
    if (data) {
      const { message, data: responseData } = data;
      if (responseData) {
        const { attachement } = responseData; // Corrected the variable name here
        // console.warn("Message-->", message);
        // console.warn("Attachment==>", attachement);
        localStorage.setItem("attachmentids", attachement);
        // console.warn(sessionid);
      }
      if (message === "Success") {
        // console.log(message);
        // console.log(localStorage.getItem("attachmentids"));
        // handleUploadKYC();
      }
    }
    // console.warn("in api file-->", response);
    return response.data;
  } catch (error) {
    // console.error("An error occurred", error);
    throw error;
  }
};
