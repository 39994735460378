import axios from "axios";
import { UpdateAppData } from "../redux/types/UpdateAppType";
import { BusinessDetailsType } from "../pages/types/BusinessDetailsType";

export const updateApplicationListViewAPI = async (
  customerId: number | null,
  selectedRmId: string,
  employeeId: string,
  rmDate: any,
  sessionId: string | null
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/tradexwbench.customer/${customerId}/`,
      {
        params: {
          context: {},
          data: {
            employee_name: selectedRmId,
            employee_id: employeeId,
            submitted_date: rmDate,
          },
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    );
    // console.log(response);
    return response;
  } catch (err) {
    return err;
  }
};
