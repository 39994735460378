import { createSlice } from "@reduxjs/toolkit";
import { BusinessDetailsData } from "../types/VerBusinessDetailsType";
import { getBusinessDetails } from "../action/getBusinessDetails";

const initialState: BusinessDetailsData = {
  data: null,
  loading: false,
  error: null,
  successfull: false,
};

const verBusinessDetailsSlice = createSlice({
  name: "verBusinessDetailsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBusinessDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        // // console.log("DATA createAppSlice", action);
      })
      .addCase(getBusinessDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const verBusinessDetailsReducer = verBusinessDetailsSlice.reducer;
