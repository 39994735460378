import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { createDueDiligenceDoc } from "../../apis/createDueDiligenceDoc";
import { DueDiligenceType } from "../../pages/types/KycDocType";

export const createDueDiligenceAction = createAsyncThunk(
  "createDueDiligenceAction",
  async (dueDiligenceDetails: DueDiligenceType) => {
    return createDueDiligenceDoc(dueDiligenceDetails, getSessionId()).then(
      (response) => {
        return response.data;
      }
    );
  }
);
