import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getCounterPartyAction } from "../action/getCounterPartyAction";

const initialState: CreateAppData = {
  data: [],
  loading: false,
  error: null,
};

const getCounterPartySlice = createSlice({
  name: "getCounterPartySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCounterPartyAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCounterPartyAction.fulfilled, (state, action) => {
        // console.log("action payload getApp---> ", action);
        state.loading = false;
        state.data = action.payload.result.result;
        // console.log("DATA ---> ", action.payload.result);
      })
      .addCase(getCounterPartyAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getCounterPartyReducer = getCounterPartySlice.reducer;
