import React from "react";
import { useForm } from "react-hook-form";
import NavbarVTX from "../../../../components/common/Navbar";
import Header from "../../../../components/common/Header";
import SectionHeading from "../../../../components/common/SectionHeading";
import { Container, FormCheck, Row, Col, Form } from "react-bootstrap";
import Input from "../../../../components/common/Input";
const GuestDueDilligencePage: React.FC = () => {
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm<FormData>();
  return (
    <>
      <NavbarVTX />
      <Header />

      <Row>
        <Col md={12} className="text-center mt-4" style={{ color: "#214382" }}>
          <SectionHeading text="SIGN APPLICATION FORM" />
        </Col>
      </Row>
      <Form className="justify-content-center">
          <Row>
            <Col sm={5}>
              <Input
                label="Document type"
                name="customer_contact_name"
                register={register}
                required
                requiredMessage="Name is required"
                //error={errors.customer_contact_name?.message}
              />
            </Col>
          </Row>
        </Form>


      <Container />
    </>
  );
};

export default GuestDueDilligencePage;
