import React, { useEffect, useState } from "react";
import { Row, Col, Form, Spinner, Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import Input from "../../../../components/common/Input";
import SectionHeading from "../../../../components/common/SectionHeading";
import { OpsLoginType } from "../../../opsUser/ppApplication/ppIndia/pages/types/OpsloginType";
import { BsArrowRepeat } from "react-icons/bs";
import Button from "../../../../components/common/Button";
import "./Opslogin.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { opsLoginAction } from "../../redux/action/opsLoginAction";
import { doLogin } from "../../../../utils/tokenHandlers";
import { useNavigate } from "react-router-dom";
import { EMAIL_REGEX } from "../../../constants/regexPatterns";
import { MdOutlineRemoveRedEye, MdOutlineVisibilityOff } from "react-icons/md";
import { logoutConCurrLoginAction } from "../../redux/action/logoutConCurrLoginAction";
import { DisplayBox } from "../../../../components/containers/DisplayBox";
import AlertPopup from "../../../../components/common/Alert/AlertPopup";
import { operationsLoginAction } from "../../redux/action/operationLoginAction";

const OpsLogin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaValid, setCaptchaValid] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const [displayLogoutAlert, setDisplayLogoutAlert] = useState(false);
  const [conCurrLoginUid, setConCurrLoginUid] = useState<number>(0);

  function generateCaptcha() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleRefreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setCaptchaInput(""); // Clear the input when refreshing captcha
    setCaptchaValid(true); // Reset captcha validity
  };

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<OpsLoginType>();

  const onSubmit: SubmitHandler<OpsLoginType> = (data) => {
    if (captchaInput !== captcha) {
      setError("captchaInput", {
        type: "manual",
        message: "Invalid Captcha, Try again",
      });
      setValue("captchaInput", "");
      handleRefreshCaptcha();
      return;
    } else {
      setShowSpinner(true);
      // // console.log("Login Details", data);
      dispatch(
        operationsLoginAction({
          username: data.username,
          password: data.password,
        })
      ).then((data) => {
        // console.log("LOGIN RESPONSE", data);
        if (data.payload?.result?.data?.message === "already_logged_in") {
          // console.log(data.payload?.result?.uid);
          setConCurrLoginUid(data.payload?.result?.uid);
          handleModalOpen();
          setShowSpinner(false);
        } else {
          if (data.payload?.result?.code == 401) {
            setError("password", {
              type: "manual",
              message: "Invalid Email or Password",
            });
            setShowSpinner(false);
            setValue("captchaInput", "");
            handleRefreshCaptcha();
            return;
          } else if (data.payload?.result?.code == 423) {
            setError("captchaInput", {
              type: "manual",
              message:
                "Your account has been disabled please contact your administration!",
            });
            setShowSpinner(false);
            setValue("captchaInput", "");
            handleRefreshCaptcha();
            return;
          }
          doLogin(data.payload, () => {
            //  // console.log("login Success");
            navigate("/ops/dashboard");
            setShowSpinner(false);
          });
        }
      });
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setValue("username", "");
  //     setValue("password", "");
  //   }, 500);
  // }, [setValue]);

  const logoutConCurrLogin = async (userId: number) => {
    // console.log(userId);
    setDisplayLogoutAlert(false);
    dispatch(logoutConCurrLoginAction(userId)).then((resp) => {
      // console.log(resp);
      if (resp.payload?.data?.result?.message === "logout") {
        setValue("captchaInput", "");
        handleRefreshCaptcha();
        handleModalClose();
        setDisplayLogoutAlert(true);
      }
    });
  };

  useEffect(() => {
    if (captchaInput.length === 6 && username && password) {
      //  // console.log("True");
      setSubmitDisabled(false);
      setValue("captchaInput", captchaInput);
    }
  }, [captchaInput, captchaInput.length, password, setValue, username]);

  const handleCaptchaChange = async () => {
    // // console.log("Email:", username);
    //  // console.log("Password:", password);
    setValue("username", username);
    setValue("password", password);

    //  // console.log(captchaInput.length);
    // if (captchaInput.length === 5 && username && password) {
    //   // console.log("True");
    //   setSubmitDisabled(false);
    // }
  };

  return (
    <>
      <DisplayBox display={displayLogoutAlert}>
        <AlertPopup
          message="User loggedout from other session"
          variant="success"
        ></AlertPopup>
      </DisplayBox>
      <Row className="justify-content-center">
        <Col md={10}>
          <SectionHeading text="OPERATIONS DETAILS" />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Row className="justify-content-center">
          <Col md={10}>
            <Input
              label="Email"
              name="username"
              register={register}
              // defaultValue={""}
              onChange={(e) => setUsername(e.target.value)}
              required
              regexPattern={EMAIL_REGEX}
              requiredMessage="Username is required"
              error={errors.username?.message}
              placeholder="Enter your username"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={10}>
            <Input
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              register={register}
              required
              // defaultValue={""}
              onChange={(e) => setPassword(e.target.value)}
              requiredMessage="Password is required"
              placeholder="Enter your password"
              error={errors.password?.message}
              // autoComplete="new-email"
            />
            <Col
              md={2}
              style={{
                marginLeft: "89%",
                cursor: "pointer",
                marginTop: "-6%",
                marginBottom: "10px",
              }}
              className="eye-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <MdOutlineVisibilityOff />
              ) : (
                <MdOutlineRemoveRedEye />
              )}
            </Col>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={10}>
            <div
              className="captcha-text"
              style={{ pointerEvents: "none", userSelect: "none" }}
            >
              {captcha}
            </div>
          </Col>
          <Col
            md={6}
            className="refresh-icon"
            style={{ marginTop: "-4.9%", marginLeft: "55%" }}
          >
            <span onClick={handleRefreshCaptcha}>
              <BsArrowRepeat />
            </span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={10}>
            <Input
              label="Captcha"
              name="captchaInput"
              // onChange={handleCaptchaChange}
              register={register}
              required
              placeholder="Enter the characters shown above"
              error={errors.captchaInput?.message}
              onChange={(e) => {
                setCaptchaInput(e.target.value);
                handleCaptchaChange(); // Call your function on captcha change
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={11} className="text-end">
            {showSpinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <Button
                label="Submit"
                type="submit"
                disabled={!captchaValid || submitDisabled}
              ></Button>
            )}
          </Col>
        </Row>
      </Form>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Account Already Logged In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          It looks like this account is already logged in on another tab or
          browser.
          <br />
          Would you like to log out from the other session?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            label="Close"
            onClick={handleModalClose}
          ></Button>
          <Button
            variant="primary"
            label="Yes, Logout"
            onClick={() => {
              logoutConCurrLogin(conCurrLoginUid);
            }}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpsLogin;
