import React from "react";
import { Col, Form, Row } from "react-bootstrap";
interface DropdownProps {
  name: string;
  label: string;
  options: { label: string; value: any }[];
  required?: boolean;
  requiredMessage?: string;
  defaultValue?: string;
  register: any;
  error?: any;
  disabled?: boolean;
  onChange?: (value: string) => void;
}
const Dropdown: React.FC<DropdownProps> = ({
  name,
  label,
  options,
  register,
  required = false,
  requiredMessage = "This field is required",
  defaultValue,
  disabled,
  error,
  onChange,
}) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (onChange) {
      onChange(selectedValue); // Call the onChange function with selected value
    }
  };
  return (
    <Form.Group as={Col}>
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>{" "}
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Select
            className="custom-input form-control"
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={handleSelectChange}
          >
            <option value="">Select an option</option>
            {options.map((option, index) => (
              <option
                key={index}
                value={option.value}
                selected={defaultValue === option.value}
              >
                {option.label}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};
export default Dropdown;
