import { createSlice } from "@reduxjs/toolkit";
import { uploadAttachment } from "../action/uploadAttachment";
import { GuestCreateAppData } from "../types/guestCreateAppTypes";

const initialState: GuestCreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const uploadAttachmentSlice = createSlice({
  name: "auth/uploadAttachmentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadAttachment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadAttachment.fulfilled, (state, action) => {
        // console.log("payload==>", action.payload);
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(uploadAttachment.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const uploadAttachmentReducer = uploadAttachmentSlice.reducer;
