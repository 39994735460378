import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GuestLoginType, GuestLogin } from "../types/GuestLoginType";
import { guestOTP } from "../../apis/guestOTP";

const initialState: GuestLogin = {
  data: null,
  loading: false,
  error: null,
};

export const loginGuestUser = createAsyncThunk(
  "loginGuestUser",
  async ({
    guest_user_name,
    guest_email,
    guest_user_mobile,
    token,
  }: GuestLoginType) => {
    const response = await guestOTP(
      guest_user_name,
      guest_email,
      guest_user_mobile,
      token
    );
    // // console.log(
    //   "email:",
    //   guest_email,
    //   "phone:",
    //   guest_user_mobile,
    //   "name:",
    //   guest_user_mobile
    // );
    return response.data;
  }
);

const guestLoginSlice = createSlice({
  name: "auth/guestLoginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginGuestUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginGuestUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(loginGuestUser.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const guestLoginReducer = guestLoginSlice.reducer;
