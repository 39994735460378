import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";

import { getAllCountry } from "../../feature/apis/country/getAllCountry";
import { getSessionId } from "../../utils/tokenHandlers";

interface PhoneInputWithDropdownProps {
  label: string;
  name: string;
  register: any;
  isdCodeName: string;
  isdCodeRegister: any;
  required?: boolean;
  requiredMessage?: string;
  regexPattern?: RegExp;
  regexMessage?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  defaultValue?: string | number;
  error?: any;
  disabled?: boolean;
  placeholder?: string;
  dropdownDefaultValue?: string;
  inputDefaultValue?: string;
}

interface Country {
  id: number;
  name: string;
  code: string;
  isd_code: string;
  is_active: boolean;
}

const PhoneInputWithDropdown: React.FC<PhoneInputWithDropdownProps> = ({
  label,
  name,
  register,
  isdCodeName,
  isdCodeRegister,
  required = false,
  requiredMessage = "This field is required",
  regexPattern,
  regexMessage = "Invalid Format",
  min,
  max,
  minLength,
  maxLength,
  dropdownDefaultValue, // New prop for dropdown default value
  inputDefaultValue, // New prop for input default value
  error,
  disabled = false,
  placeholder,
}) => {
  const token = getSessionId();
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [dataFetched, setDataFetched] = useState(false);

  const fetchCountriesWithRetry = async (
    token: string,
    retries = 3,
    delay = 1000
  ): Promise<void> => {
    try {
      const response = await getAllCountry(token);
      if (response && response.result && response.result.result) {
        setCountries(response.result.result);
        setDataFetched(true);
        setFetchError(null);
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      if (retries > 0) {
        setTimeout(
          () => fetchCountriesWithRetry(token, retries - 1, delay),
          delay
        );
      } else {
        setFetchError("Failed to fetch countries");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!dataFetched && token) {
      fetchCountriesWithRetry(token);
    }
  }, [token, dataFetched]);

  return (
    <Form.Group as={Col} className="align-items-center">
      <Row className="align-items-center">
        <Form.Label htmlFor={name} column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={3}>
          {loading ? (
            <p>Loading...</p>
          ) : fetchError ? (
            <p className="text-danger">{fetchError}</p>
          ) : (
            <Form.Select
              as="select"
              className={`custom-input form-control ${
                error ? "is-invalid" : ""
              }`}
              {...isdCodeRegister(isdCodeName, {
                required: required ? requiredMessage : false,
              })}
              disabled={disabled}
              defaultValue={dropdownDefaultValue} // Use 'defaultValue' here
            >
              <option value="">ISD</option>
              {countries.map((country, index) => (
                <option key={index} value={country.isd_code}>
                  {country.isd_code}
                </option>
              ))}
            </Form.Select>
          )}
        </Col>
        <Col md={5} style={{ marginLeft: "-3%" }}>
          <Form.Control
            type="text"
            className={`custom-input ${error ? "is-invalid" : ""}`}
            {...register(name, {
              required: required ? requiredMessage : false,
              pattern: {
                value: regexPattern,
                message: regexMessage,
              },
              minLength: {
                value: minLength,
                message: `Minimum length is ${minLength} characters`,
              },
              maxLength: {
                value: maxLength,
                message: `Maximum length is ${maxLength} characters`,
              },
              min: {
                value: min,
                message: `Value must be greater than or equal to ${min}`,
              },
              max: {
                value: max,
                message: `Value must be less than or equal to ${max}`,
              },
              value: inputDefaultValue, // Use the prop for input default value
            })}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default PhoneInputWithDropdown;
