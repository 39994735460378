// CheckBoxes.tsx

import { Col, Form, Row } from "react-bootstrap";
import React, { ChangeEvent } from "react";

interface CheckBoxesProps {
  name: string;
  label: string;
  required?: boolean;
  requiredMessage?: string;
  defaultValues?: string[]; // Use an array for default values
  options?: { label: string; value: string }[];
  register: any; // Use proper type for register function
  error?: any; // Add error prop
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void; // Add onChange prop
}

const CheckBoxes: React.FC<CheckBoxesProps> = ({
  name,
  label,
  register,
  options,
  disabled = false,
  required = false,
  requiredMessage = "This field is required",
  defaultValues = [],
  error,
  onChange, // Include onChange prop
}) => {
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e); // Call the onChange prop if provided
    }
  };

  return (
    <Form.Group as={Col} className="mb-3 mt-3">
      <Row className="align-items-center">
        <Form.Label column md={4} className="col-form-label">
          <span className="float-start">
            <span className="text-danger"></span> {label}
          </span>
        </Form.Label>
        <Col md={8} className="d-flex">
          <Form.Check
            type="checkbox"
            label={""}
            name={name}
            // onChange={handleCheckboxChange}
            //defaultChecked={defaultValues.includes(value)}
            {...register(name, {
              required: requiredMessage,
            })}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default CheckBoxes;
