import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchVerStatusKeyPerson,
  FetchVerStatusRemarksParamsKeyPerson,
} from "../../apis/fetchVerStatusKeyPerson";

export const getVerStatusKeyPerson = createAsyncThunk(
  "getVerStatusKeyPerson",
  async ({
    selectedId,
    selectedDocId,
  }: FetchVerStatusRemarksParamsKeyPerson) => {
    const response = await fetchVerStatusKeyPerson({
      selectedId,
      selectedDocId,
    });
    return response;
  }
);
