import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppAction } from "../redux/action/getAllAppAction";
import { AppDispatch } from "../../../../../redux/store";

const AppListPage: React.FC = () => {
  const state = useSelector((state: any) => state.opsPpIndApp.getAllApp);
  const dispatch = useDispatch<AppDispatch>();
  const currentPage = 1;

  useEffect(() => {
    // Pass the currentPage to the action creator
    dispatch(getAllAppAction(currentPage));
  }, [currentPage, dispatch]);  

  return (
    <>
      <h4>App List</h4>
      {state.loading && <p>Loading!!!!</p>}
      {!state.loading && state.data.length ? (
        <div>
          <ul>
            {state.data.map((customer: any) => (
              <li key={customer.key}>
                {customer.application_uid} {customer.customer_contact_name}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default AppListPage;
