import { RouteObject } from "react-router-dom";
import VerificationPage from "../pages/verificationPage";
import IndividualVerificationPage from "../pages/individualVerificationPage";

const verificationRoutesConfig: RouteObject[] = [
  {
    path: "/verification/business-details/:appId",
    element: <VerificationPage />,
  },
  {
    path: "/verification/individual-details/:appId",
    element: <IndividualVerificationPage />,
  },
];

export default verificationRoutesConfig;
