import "../css/common.css";
import Container from "react-bootstrap/Container";
//import Navbar from "react-bootstrap/Navbar";
import { Col, Row, Spinner } from "react-bootstrap";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchHeaderDetails } from "../../feature/guestUser/ppApplication/redux/action/fetchHeaderDetails";
import Button from "./Button";
import { Stats, stat } from "fs";
import {
  getUserRole,
  getUserUid,
  getUsername,
} from "../../utils/tokenHandlers";
import { LogOut } from "../../feature/auth/apis/logout";
import { logoutConCurrLoginAction } from "../../feature/auth/redux/action/logoutConCurrLoginAction";
const Header: React.FC = () => {
  const { appId } = useParams();
  const customerId = Number(appId);
  const userRole = getUserRole();
  const navigate = useNavigate();
  const username = getUsername();
  // // console.log("UserRole:", userRole);
  // // console.log("Username:", username);
  const dispatch = useDispatch<AppDispatch>();
  const [showSpinner, setShowSpinner] = useState(true);
  const [logoutSpinner, setLogoutSpinner] = useState(false);
  const state = useSelector(
    (state: RootState) => state.guestPPApp.headerDetails
  );
  useEffect(() => {
    const fetchHeaderData = async () => {
      try {
        const result = await dispatch(fetchHeaderDetails(customerId));
        if (result) {
          // // console.warn(result.payload.data.result);
          setShowSpinner(false);
        }
      } catch (error) {
        // // console.error("An error occurred", error);
        setShowSpinner(false);
      }
    };
    fetchHeaderData();
  }, []);

  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }

  const handleLogout = () => {
    // Add your logout logic here
    setLogoutSpinner(true);
    // // console.log("Hello, logout clicked!");
    const userId = getUserUid();
    dispatch(logoutConCurrLoginAction(userId)).then((resp: any) => {
      // // console.log("Logout resp--", resp);
      if (userRole === "guest") {
        navigate("/");
      } else if (userRole === "maker" || userRole === "checker") {
        navigate("/ops/login");
      } else {
        <></>;
      }
      setLogoutSpinner(false);
    });

    // // console.log("LogOut resp--", logOut);
  };

  // const id = state.data.data.business_tax_identification_number;
  // // console.log(id, "..............tin");

  // const state = useSelector((state: RootState) => state);
  // // console.log("Header State ---> ", state);
  return (
    <Container>
      <Row>
        <Col
          md={20}
          className="text-end"
          style={{ marginTop: "-5%", color: "#214382" }}
        >
          {username} as {userRole}
          <span
            style={{ color: "#214382", marginLeft: "2%", cursor: "pointer" }}
            onClick={handleLogout}
          >
            {logoutSpinner ? (
              <Spinner animation="border" variant="primary" size="sm" />
            ) : (
              <>LogOut</>
            )}
          </span>
        </Col>
      </Row>
      <Row className="header-row-1">
        <Col xs="auto" className="header-col form-label text-start">
          <p>WELCOME, {state.data.data.result.customer_contact_name} </p>
        </Col>
        <Col className="header-col form-label text-center">
          <p>
            {state.data.data.result.customer_contact_email} |{" "}
            {state.data.data.result.customer_contact_mobile_country_code} -
            {state.data.data.result.customer_contact_mobile}
          </p>
        </Col>
        <Col xs="auto" className="header-col form-label text-end">
          <p>STATUS- {state.data.data.result.stage}</p>
        </Col>
      </Row>

      <Row className="header-row-2">
        <Col className="header-col form-label">
          <p>{state.data.data.result.business_name}</p>
        </Col>
        <Col className="header-col form-label">
          <p>
            Country-{" "}
            {state.data.data.result.business_registration_country_id.name}
          </p>
        </Col>
        <Col className="header-col form-label">
          <p>ARN- {state.data.data.result.application_uid}</p>
        </Col>
        <Col className="header-col form-label">
          <p>
            {state.data.data.result.business_registration_country_id.name ===
            "India"
              ? `GST - ${state.data.data.result.business_gst}`
              : `TIN - ${state.data.data.result.business_tax_identification_number}`}
          </p>
        </Col>
        <Col className="header-col form-label text-end">
          <p>Register as {state.data.data.result.business_participant_type}</p>
        </Col>
      </Row>
    </Container>
  );
};
export default Header;
