import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { BusinessDetailsType } from "../../pages/types/BusinessDetailsType";
import { updateApplicationListViewAPI } from "../../apis/updateApplicationListView";
// Define the update application action
export const updateApplicationAction = createAsyncThunk(
  "updateApplicationListAction",
  async ({
    customerId,
    selectedRmId,
    employeeId,
    rmDate,
  }: {
    customerId: number | null;
    selectedRmId: string;
    employeeId: string;
    rmDate: any;
  }) => {
    return updateApplicationListViewAPI(
      customerId,
      selectedRmId,
      employeeId,
      rmDate,
      getSessionId()
    ).then((response) => {
      return response; // You can modify this based on your API response structure
    });
  }
);
