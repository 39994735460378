// KeyPersonType.tsx

import { Col, Form, Row } from "react-bootstrap";
import React, { ChangeEvent, useEffect, useState } from "react";
import { getKeyPersonType } from "../../feature/apis/listOfValues/getKeyPersonType";
import { getSessionId } from "../../utils/tokenHandlers";

interface KeyPersonProps {
  name: string;
  label: string;
  options?: { label: string; value: string }[];
  required?: boolean;
  requiredMessage?: string;
  defaultValues?: string[]; // Use an array for default values
  register?: any; // Use proper type for register function
  error?: any; // Add error prop
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void; // Add onChange prop
  selectedKeyPersonType: number[];
  setSelectedKeyPersonType: React.Dispatch<React.SetStateAction<number[]>>;
  setValue: any;
  disabled?: boolean;
}

interface KeyPersonTypeDate {
  id: number;
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

const KeyPersonType: React.FC<KeyPersonProps> = ({
  name,
  label,
  register,
  required = false,
  requiredMessage = "This field is required",
  defaultValues = [],
  error,
  disabled = false,
  setValue,
  selectedKeyPersonType,
  setSelectedKeyPersonType,
}) => {
  const token = getSessionId();

  const [keyPersonTypeValues, setKeyPersonTypeValues] = useState<
    KeyPersonTypeDate[]
  >([]);

  useEffect(() => {
    getKeyPersonType(token).then((response) => {
      setKeyPersonTypeValues(response.result.result);
      setValue(name, selectedKeyPersonType);
    });
  }, []);

  const handleCheckboxChange = (id: number) => {
    const updatedSelectedKeyPersonType = selectedKeyPersonType.includes(id)
      ? selectedKeyPersonType.filter((item) => item !== id)
      : [...selectedKeyPersonType, id];

    setSelectedKeyPersonType(updatedSelectedKeyPersonType);
    setValue(name, updatedSelectedKeyPersonType);
  };
  const onSubmit = () => {
    //// console.log("Selected Checkboxes:", selectedCheckboxes);
    // Handle form submission logic here
  };

  return (
    <Form.Group
      as={Col}
      className="mb-3 mt-3"
      controlId="keyPerson"
      {...register(`key_person_type_ids`, {
        required: required && requiredMessage,
      })}
    >
      <Row className="align-items-center">
        <Form.Label
          column
          // md={2}
          className="col-form-label "
          // style={{ fontSize: "20px" }}
        >
          <span className="float-start">
            <span className="text-danger"></span> {label}
          </span>
        </Form.Label>

        <Col md={12} className="d-flex">
          {selectedKeyPersonType &&
            keyPersonTypeValues &&
            keyPersonTypeValues.map((keyPersonTypeValue) => (
              <Form.Check
                key={keyPersonTypeValue.id}
                id={keyPersonTypeValue.id.toString()}
                type="checkbox"
                label={
                  <span style={{ fontSize: "14.9px" }}>
                    {keyPersonTypeValue.lov_display_value}
                  </span>
                }
                checked={selectedKeyPersonType.includes(keyPersonTypeValue.id)}
                onClick={() => {
                  handleCheckboxChange(keyPersonTypeValue.id);
                }}
                disabled={disabled}
                style={{ marginRight: "10px" }}
              />
            ))}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default KeyPersonType;
