/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getAppAction } from "../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
interface ForeignBusinessVerificationProps {
  referredByName: string;
}

const ForeignBusinessVerification: React.FC<
  ForeignBusinessVerificationProps
> = ({ referredByName }) => {
  const dispatch = useDispatch<AppDispatch>();

  const state = useSelector(
    (state: RootState) => state.verification.verBusinessDetails
  );
  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }

  const businessDetailsStore = state.data.data.result;
  const isCP = businessDetailsStore.is_counterparty;
  // const referredBy = businessDetailsStore.referred_by;

  // useEffect(() => {
  //   if (referredBy) {
  //     // console.log(referredBy);
  //     dispatch(getAppAction(Number(referredBy))).then((resp) => {
  //       // console.warn(resp);
  //     });
  //   }
  // }, [dispatch, referredBy]);

  // Function to handle null values and provide default values
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (referredBy) {
  //       // console.log("Referred By:", referredBy);
  //     }
  //   }, 2000);
  // }, [referredBy]);

  return (
    <>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Business Registration"
            defaultValue={businessDetailsStore.business_registered_with_fgn}
            // {handleNullValue(
            //   businessDetailsStore.business_registered_with,
            //   "N/A" // Default value for Business Registration
            // )}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Place of Registration"
            defaultValue={businessDetailsStore.business_registration_place}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Registration No"
            defaultValue={businessDetailsStore.business_registration_number}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Incorporation Date"
            defaultValue={businessDetailsStore.business_registration_date}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Listing Information"
            defaultValue={businessDetailsStore.business_listing_info_ids
              .map((item: { lov_display_value: string }) =>
                item.lov_display_value
                  .split(" ")
                  .map((word) => word[0].toUpperCase())
                  .join("")
              )
              .join(", ")}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Commencement Date"
            defaultValue={businessDetailsStore.business_commencement_date}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="LEI"
            defaultValue={businessDetailsStore.business_legal_entity_identifier}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Website"
            defaultValue={businessDetailsStore.business_website}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Nature of Business"
            defaultValue={
              businessDetailsStore.business_nature
                ? businessDetailsStore.business_nature.charAt(0).toUpperCase() +
                  businessDetailsStore.business_nature.slice(1)
                : null
            }
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="IEC No"
            defaultValue={businessDetailsStore.business_iecno}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Nature of Activity"
            defaultValue={businessDetailsStore.business_nature_activity}
          />
        </Col>
      </Row>
      {isCP === true && (
        <>
          <Row md={12}>
            <Col md={6}>
              <VerBusinessDetails
                label="Number of Years Relation"
                defaultValue={
                  businessDetailsStore.business_no_of_years_of_relation_with_cp
                }
              />
            </Col>
            <Col md={6}>
              <VerBusinessDetails
                label="Vendor/Dealer Id"
                defaultValue={businessDetailsStore.referred_by_vendor_dealer_id}
              />
            </Col>
          </Row>
          <Row md={12}>
            <Col md={6}>
              <VerBusinessDetails
                label="Is this CP a Related Party?"
                defaultValue={businessDetailsStore.business_is_cp_related_party}
              />
            </Col>

            <Row>
              <Col md={12}>
                <VerBusinessDetails
                  label="Referred By"
                  defaultValue={referredByName}
                />
              </Col>
            </Row>
          </Row>
        </>
      )}
    </>
  );
};

export default ForeignBusinessVerification;
