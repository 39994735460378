import React, { ChangeEvent } from "react";
import { Col, Row, Form } from "react-bootstrap";

interface DatePickerProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  minDate?: string;
  maxDate?: string;
  defaultValue?: Date;
  error?: any;
  disabled?: boolean;
  value?: string;
  isDateOfBirth?: boolean;
  onChange?: (date: string) => void;
  isOUDate?: boolean;
  skipCurrentYear?: boolean; // New prop
}

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage,
  minDate,
  maxDate,
  defaultValue,
  error,
  disabled,
  value,
  isDateOfBirth,
  onChange,
  isOUDate = false,
  skipCurrentYear = false, // New prop default to false
}) => {
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getLastDayOfPreviousYear = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear() - 1;
    return `${year}-12-31`;
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    if (onChange) {
      onChange(selectedDate);
    }
  };

  const computedMaxDate = () => {
    if (skipCurrentYear) {
      return getLastDayOfPreviousYear();
    }
    return isDateOfBirth ? getCurrentDate() : maxDate;
  };

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>{" "}
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Control
            type="date"
            style={
              isOUDate
                ? {
                    color: "#6c757d",
                    backgroundColor: "#e9ecef",
                    pointerEvents: "none",
                  }
                : {}
            }
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            min={minDate}
            max={computedMaxDate()}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            value={value}
            onChange={handleDateChange}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default DatePicker;
