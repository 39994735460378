import React from "react";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
interface IndiaNonCorporateBusinessVerificationProps {
  referredByName: string;
}
const IndiaNonCorporateBusinessVerification: React.FC<
  IndiaNonCorporateBusinessVerificationProps
> = ({ referredByName }) => {
  const state = useSelector(
    (state: RootState) => state.verification.verBusinessDetails
  );
  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }
  const businessDetailsStore = state.data.data.result;
  const isCP = businessDetailsStore.is_counterparty;

  // Function to handle null values and provide default values
  const handleNullValue = (value: null, defaultValue: any) => {
    return value != null ? value : defaultValue;
  };
  return (
    <>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Business Registration"
            defaultValue={businessDetailsStore.business_registered_with}
            // {handleNullValue(
            //   businessDetailsStore.business_registered_with,
            //   "N/A" // Default value for Business Registration
            // )}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Place of Registration"
            defaultValue={businessDetailsStore.business_registration_place}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Registration No"
            defaultValue={businessDetailsStore.business_registration_number}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Incorporation Date"
            defaultValue={businessDetailsStore.business_registration_date}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Listing Information"
            defaultValue={businessDetailsStore.business_listing_info_ids
              .map((item: { lov_display_value: string }) =>
                item.lov_display_value
                  .split(" ")
                  .map((word) => word[0].toUpperCase())
                  .join("")
              )
              .join(", ")}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Commencement Date"
            defaultValue={businessDetailsStore.business_commencement_date}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="LEI"
            defaultValue={businessDetailsStore.business_legal_entity_identifier}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Website"
            defaultValue={businessDetailsStore.business_website}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Nature of Business"
            defaultValue={
              businessDetailsStore.business_nature
                ? businessDetailsStore.business_nature.charAt(0).toUpperCase() +
                  businessDetailsStore.business_nature.slice(1)
                : null
            }
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Import Export Code"
            defaultValue={businessDetailsStore.business_iecno}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="CKYC No"
            defaultValue={businessDetailsStore.business_ckycno}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Business Unit Location"
            defaultValue={businessDetailsStore.business_unit_location}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Star Trading House"
            defaultValue={businessDetailsStore.business_statusholder}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Nature of Activity"
            defaultValue={businessDetailsStore.business_nature_activity}
          />
        </Col>
      </Row>
      {isCP === true && (
        <>
          <Row md={12}>
            <Col md={6}>
              <VerBusinessDetails
                label="Number of Years Relation"
                defaultValue={
                  businessDetailsStore.business_no_of_years_of_relation_with_cp
                }
              />
            </Col>
            <Col md={6}>
              <VerBusinessDetails
                label="Vendor/Dealer Id"
                defaultValue={businessDetailsStore.referred_by_vendor_dealer_id}
              />
            </Col>
          </Row>
          <Row md={12}>
            <Col md={6}>
              <VerBusinessDetails
                label="Is this CP a Related Party?"
                defaultValue={businessDetailsStore.business_is_cp_related_party}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <VerBusinessDetails
                label="Referred By"
                defaultValue={referredByName}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default IndiaNonCorporateBusinessVerification;
