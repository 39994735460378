import React from "react";
import NavbarVTX from "../../../components/common/Navbar";
import { Col, Row } from "react-bootstrap";
import "../../../components/css/common.css";
import IndividualVerification from "../components/individualVerification";
import IndividualsSelection from "../components/individualsSelection";
import Header from "../../../components/common/Header";

const IndividualVerificationPage: React.FC = () => {
  return (
    <>
      <>
        <NavbarVTX />
        <Header />
        <Row style={{ marginLeft: "10px" }} className="mt-3">
          <Col md={6} style={{ height: "100vh", overflowY: "auto" }}>
            <IndividualsSelection />
          </Col>
          <Col md={6} style={{ overflowY: "auto", height: "100vh" }}>
            <IndividualVerification />
          </Col>
        </Row>
      </>
    </>
  );
};

export default IndividualVerificationPage;
