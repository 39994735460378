import React from "react";
import CheckBoxes from "../../../components/common/CheckBoxes";
import { Container, Row, Col, Form } from "react-bootstrap";
import SectionHeading from "../../../components/common/SectionHeading";
import { useForm } from "react-hook-form";

const Currencies: React.FC = () => {
  const { register, handleSubmit } = useForm();
  return (
    <Form>
      <Row>
        <Col md={20} className="text-center mt-4" style={{ color: "#214382" }}>
          <SectionHeading text="CURRENCIES" />
          <Row>
            <Col className="position-static">
              <CheckBoxes
                name="checkboxGroup"
                label=""
                options={[
                  { label: "USD", value: "USD" },
                  { label: "IND", value: "IND" },
                  { label: "GBP", value: "GBP" },
                  { label: "JPR", value: "JPR" },
                  // Add more options as needed
                ]}
                register={register}
                required
                //   error={errors.checkboxGroup?.message}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Currencies;
