// import React from "react";
// import { ListGroup, Dropdown } from "react-bootstrap";
// import {
//   MdOutlineModeEditOutline,
//   MdOutlineCancel,
//   MdOutlineRemoveRedEye,
// } from "react-icons/md";
// import IconInput from "./IconInput";

// interface ListViewProps {
//   items: {
//     label: string;
//     dropdownItems?: string[];
//     selectedDropdownItem?: string | null;
//     defaultValue?: string;
//     onDropdownSelect?: (item: string) => void;
//     onEditClick?: () => void;
//     onCancelClick: () => void;
//     onViewClick: () => void;
//     onClick?: (label: string) => void;
//     variant?: "default" | "success" | "danger" | "warning" | undefined;
//     style?: { backgroundColor: string };
//     required?: boolean;
//     disabled?: boolean;
//   }[];
//   onVariantChange?: (
//     label: string,
//     variant: "default" | "success" | "danger" | "warning"
//   ) => void;
// }

// const KYCListView: React.FC<ListViewProps> = ({ items, onVariantChange }) => {
//   return (
//     <ListGroup>
//       {items.map((itemData, index) => (
//         <ListGroup.Item
//           action
//           onClick={() => itemData.onClick && itemData.onClick(itemData.label)}
//           key={index}
//           className={`list-item ${
//             itemData.variant ? `list-item-${itemData.variant}` : ""
//           }`}
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             ...itemData.style,
//           }}
//         >
//           <span>
//             {itemData.required && (
//               <span className="text-danger form-label">*</span>
//             )}{" "}
//             {itemData.label}
//           </span>
//           {itemData.dropdownItems && (
//             <Dropdown>
//               <Dropdown.Toggle
//                 variant="light"
//                 id={`dropdown-basic-${index}`}
//                 disabled={itemData.disabled}
//               >
//                 {itemData.selectedDropdownItem ||
//                   itemData.defaultValue ||
//                   "Select an option"}
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 {itemData.dropdownItems.map((dropdownItem, dropdownIndex) => (
//                   <Dropdown.Item
//                     key={dropdownIndex}
//                     active={itemData.selectedDropdownItem === dropdownItem}
//                     onClick={() =>
//                       itemData.onDropdownSelect &&
//                       itemData.onDropdownSelect(dropdownItem)
//                     }
//                   >
//                     {dropdownItem}
//                   </Dropdown.Item>
//                 ))}
//               </Dropdown.Menu>
//             </Dropdown>
//           )}
//           <span style={{ display: "flex", alignItems: "center" }}>
//             <IconInput
//               icon={<MdOutlineRemoveRedEye style={{ marginRight: "10px" }} />}
//               onClick={itemData.onViewClick}
//             />
//             {/* <IconInput
//               icon={
//                 <MdOutlineModeEditOutline style={{ marginRight: "10px" }} />
//               }
//               onClick={itemData.onEditClick}
//             /> */}

//             <IconInput
//               icon={<MdOutlineCancel />}
//               onClick={itemData.onCancelClick}
//               //disabled={itemData.disabled}
//             />
//           </span>
//         </ListGroup.Item>
//       ))}
//     </ListGroup>
//   );
// };

// export default KYCListView;import React from "react";
import React, { useEffect, useState } from "react";
import { ListGroup, Dropdown } from "react-bootstrap";
import {
  MdOutlineModeEditOutline,
  MdOutlineCancel,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import IconInput from "./IconInput";
import { fetchEPDDropdown } from "../../feature/apis/listOfValues/getKYCDropdownOptions";

interface ListViewProps {
  items: {
    label: string;
    dropdownItems?: string[];
    selectedDropdownItem?: string | null;
    defaultValue?: string;
    onDropdownSelect?: (item: string) => void;
    onEditClick?: () => void;
    onCancelClick: () => void;
    onViewClick: () => void;
    onClick?: (label: string) => void;
    variant?: "default" | "success" | "danger" | "warning" | undefined;
    style?: { backgroundColor: string };
    required?: boolean;
    disabled?: boolean;
    additionalDropdown?: {
      options: { label: string; value: string }[];
      selectedOption?: { label: string; value: string } | null;
      onDropdownSelect?: (option: { label: string; value: string }) => void;
      disabled?: boolean;
      defaultValue?: { label: string; value: string } | null;
    };
  }[];
  onVariantChange?: (
    label: string,
    variant: "default" | "success" | "danger" | "warning"
  ) => void;
}

const KYCListView: React.FC<ListViewProps> = ({ items, onVariantChange }) => {
  // // const [epdDropdownOptions, setEPDDropdownOptions] = useState<
  //   { label: string; value: string }[]
  // >([]);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string | null;
  }>({});

  const handleDropdownSelect = (
    index: number,
    option: { label: string; value: string }
  ) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [index]: option.value,
    }));
    const additionalDropdown = items[index].additionalDropdown;
    if (additionalDropdown && additionalDropdown.onDropdownSelect) {
      additionalDropdown.onDropdownSelect(option);
    }
  };

  // useEffect(() => {
  //   fetchEPDDropdown().then((options) => setEPDDropdownOptions(options));
  // });

  return (
    <ListGroup>
      {items.map((itemData, index) => (
        <ListGroup.Item
          action
          onClick={() => itemData.onClick && itemData.onClick(itemData.label)}
          key={index}
          className={`list-item ${
            itemData.variant ? `list-item-${itemData.variant}` : ""
          }`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            ...itemData.style,
          }}
        >
          <span>
            {itemData.required && (
              <span className="text-danger form-label">*</span>
            )}{" "}
            {itemData.label}
          </span>
          {itemData.additionalDropdown &&
            itemData.style?.backgroundColor !== "#9ef89e" && ( // Render dropdown only if the style is not #9ef89e
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id={`additional-dropdown-${index}`}
                  disabled={itemData.additionalDropdown.disabled}
                >
                  {selectedOptions[index] !== null &&
                  selectedOptions[index] !== undefined
                    ? itemData.additionalDropdown.options.find(
                        (option) => option.value === selectedOptions[index]
                      )?.label ||
                      itemData.additionalDropdown.defaultValue?.label ||
                      "Select an option"
                    : "Select an option"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {itemData.additionalDropdown.options.map(
                    (option, optionIndex) => (
                      <Dropdown.Item
                        key={optionIndex}
                        active={selectedOptions[index] === option.value}
                        onClick={() => handleDropdownSelect(index, option)}
                      >
                        {option.label}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          {itemData.dropdownItems && (
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id={`dropdown-basic-${index}`}
                disabled={itemData.disabled}
              >
                {itemData.selectedDropdownItem ||
                  itemData.defaultValue ||
                  "Select an option"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {itemData.dropdownItems.map((dropdownItem, dropdownIndex) => (
                  <Dropdown.Item
                    key={dropdownIndex}
                    active={itemData.selectedDropdownItem === dropdownItem}
                    onClick={() =>
                      itemData.onDropdownSelect &&
                      itemData.onDropdownSelect(dropdownItem)
                    }
                  >
                    {dropdownItem}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          <span style={{ display: "flex", alignItems: "center" }}>
            <IconInput
              icon={<MdOutlineRemoveRedEye style={{ marginRight: "10px" }} />}
              onClick={itemData.onViewClick}
            />
            <IconInput
              icon={<MdOutlineCancel />}
              onClick={itemData.onCancelClick}
            />
          </span>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default KYCListView;
