import React from "react";
import { Navbar, Container, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import { getUserRole } from "../../utils/tokenHandlers";
import Button from "./Button";

const NavbarVTX: React.FC = () => {
  const useRole = getUserRole();
  const navigate = useNavigate();
  const checkAndNavigate = async () => {
    if (useRole === "guest") {
      navigate("/");
    } else if (useRole === "maker" || useRole === "checker") {
      navigate("/ops/dashboard");
    } else {
    }
    const handleLogout = () => {
      // Add logic to perform logout, such as clearing authentication tokens
      // and redirecting to the login page.
      // Example: authService.logout();
      // navigate("/login");
    };
  };
  return (
    <>
      <Row>
        <Col>
          <Container>
            <Navbar bg="white" variant="light" style={{ width: "20%" }}>
              <Navbar.Brand>
                <img
                  src="https://vayanatradexchange.com/wp-content/uploads/2022/04/vayana-logo.png"
                  alt="Logo"
                  style={{ maxHeight: "50px" }}
                  onClick={() => {
                    checkAndNavigate();
                  }}
                />
              </Navbar.Brand>
              {/* <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <Button label="Logout" variant="outline-primary"></Button>
                </Navbar.Text>
              </Navbar.Collapse> */}
            </Navbar>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default NavbarVTX;
