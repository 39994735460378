import axios from "axios";

export const getAllCorrBank = (bankId: number, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer_settlement_correspondent_bank`,
      {
        params: {
          filter: `[('customer_settlement_bank_id', 'in',  [${bankId}])]`,
          query:
            "{id,bank_name,bank_account_number,re_enter_bank_account_number,sort_code,iban,swift_code,currency_id{id, currency_code}, customer_settlement_bank_id, is_default_bank}",
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
