interface LoginResponse {
  jsonrpc: string;
  id: null;
  result: {
    session_id: string;
    uid: number;
    name: string;
    username: string;
    partner_display_name: string;
    tradexwbench_employee_id: null;
    business_participant_type: string;
  };
}

export const isLoggedIn = () => {
  let data = localStorage.getItem("data");
  if (data != null) return true;
  else return false;
};

//   //doLogin=> data=>set to localstorage

export const doLogin = (data: LoginResponse, next: () => void) => {
  localStorage.setItem("data", JSON.stringify(data));
  next();
};

//   //doLogout=> remove from localStorage

//   export const doLogout = (next) => {
//     localStorage.removeItem("data");
//     next();
//   };

//get currentUser
export const getCurrentUserDetail = () => {
  if (isLoggedIn()) {
    return JSON.parse(localStorage.getItem("data")!).result.name;
  } else {
    return undefined;
  }
};

export const getSessionId = (): string | null => {
  if (isLoggedIn()) {
    return JSON.parse(localStorage.getItem("data")!).result.session_id;
  } else {
    return null;
  }
};

export const getUserId = () => {
  if (isLoggedIn()) {
    return JSON.parse(localStorage.getItem("data")!).user.userId;
  } else {
    return null;
  }
};

export const getUserUid = () => {
  if (isLoggedIn()) {
    let role = JSON.parse(localStorage.getItem("data")!).result.uid;
    return role;
  } else {
    return null;
  }
};

export const getUserRole = () => {
  if (isLoggedIn()) {
    let role = JSON.parse(localStorage.getItem("data")!).result.role;
    return role;
  } else {
    return null;
  }
};

export const getUsername = () => {
  if (isLoggedIn()) {
    let username = JSON.parse(localStorage.getItem("data")!).result.username;
    // // console.log(username,"..")
    return username;
  } else {
    return null;
  }
};

export const getParticipationRole = () => {
  if (isLoggedIn()) {
    let particpationType = JSON.parse(localStorage.getItem("data")!).result
      .business_participant_type;
    // // console.log(particpationType,".........................")
    return particpationType;
  } else {
    return null;
  }
};
