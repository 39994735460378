import axios from "axios";

export const login = (username: string, password: string) => {
  return (
    axios
      // .post(`${process.env.REACT_APP_BASE_URL}auth/`, {
      .post(`${process.env.REACT_APP_BASE_URL}login/otp/user/`, {
        // params: {
        //   login: username,
        //   password: password,
        //   db: `${process.env.REACT_APP_DB_NAME}`,
        // },
        // Changed as per the Guest Login in order to disable ops login upon 3 failed attempts
        params: {
          email: username,
          otp: password,
          db: `${process.env.REACT_APP_DB_NAME}`,
        },
      })
      .then((response) => response)
      .catch((err) => err)
  );
};
