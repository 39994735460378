import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAppAccessOTP } from "../../apis/getAppAccessOTP";

export const sendAppAccessOTP = createAsyncThunk(
  "sendAppAccessOTP",
  async (guest_user_arn: string) => {
    const response = await getAppAccessOTP(guest_user_arn);
    // // console.log("arn bro-->", guest_user_arn);
    return response;
  }
);
