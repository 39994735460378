import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import { Container, Modal, Spinner, Image } from "react-bootstrap";
import Button from "../../../../../components/common/Button";
import KYCListView from "../../../../../components/common/KYCDocsList";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form, Table } from "react-bootstrap";
import { getKeyPersonDetails } from "../../../../verificationStage/redux/action/getKeyPersonDetails";
import { fetchKeyPersonDocs } from "../../redux/action/fetchKeyPersonDocs";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import axios from "axios";
import { fetchKeyPersonIds } from "../../redux/action/fetchKeyPersonIds";
import { uploadIndividualAttachment } from "../../redux/action/uploadIndividualAttachment";
import { createOneDoc } from "../../redux/action/createOneDoc";
import { deleteDocument } from "../../redux/action/deleteDocument";
import KYCInstructionsBtn from "../../../../../components/common/KYCInstructionsBtn";
import handleFileWithSizeLimit from "../../../../constants/fileSizeLimit";
import { getAppAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
const KeyIndividualKYC: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [image, setImage] = useState<string | Blob>("");
  const [docName, setDocName] = useState<string | null>(null);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [selectedPOIItem, setselectedPOIItem] = useState<string | null>(null);
  const [selectedDropdownItem, setSelecedDropdownItem] = useState<
    string | null
  >(null);
  const [selectedPOAItem, setselectedPOAItem] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [documentId, setDocumentId] = useState<number | null>(null);
  const [buttonText, setButtonText] = useState("Upload");
  const [isProofOfAddressSelected, setIsProofOfAddressSelected] =
    useState(false);
  const [isProofOfIdentitySelected, setIsProofOfIdentitySelected] =
    useState(false);
  const [showKYCList, setShowKYCList] = useState(false);
  const [uploadText, setUploadText] = useState("Upload Front Side");
  const [docType, setDocType] = useState("");
  const [selectedPersonId, setSelectedPersonId] = useState<number | null>(null);
  const [selectedPersonName, setSelectedPersonName] = useState("");
  const [docObjects, setDocObjects] = useState<
    {
      doc_type: string;
      doc_id: any;
      doc_name: string;
      document_name_text: string;
    }[]
  >([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [docUploaded, setDocUploaded] = useState(false);
  const [selectedType, setSelectedType] = useState("Type");
  let isFilePickerVisible = selectedType === "PHT" || selectedType === "OT";
  const handleDocumentSelect = (documentName: string) => {
    setSelectedDocument(documentName);
    setUploading(false);
    setButtonText("Upload");
  };
  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  // Doc delete confirmation modal:
  let stage = getAppSelector.data.stage;

  useEffect(() => {
    dispatch(getAppAction(customerId)).then((respose) => {
      // console.log(respose);
    });
  }, [dispatch, customerId]);

  // const { appId } = useParams();
  // const customerId = Number(appId);
  const [showFrontSpinner, setShowFrontSpinner] = useState(false);
  const [showBackSpinner, setShowBackSpinner] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const docObject: {
    doc_type: string;
    doc_id: any;
    doc_name: string;
    document_name_text: string;
  }[] = [];

  const [selectedDocObject, setSelectedDocObject] = useState<
    null | typeof docObject
  >(null);

  const sessionId = getSessionId();
  const [deleteSpinner, setDeleteSpinner] = useState(false);
  const [selectedPHTIndex, setSelectedPHTIndex] = useState(0);
  const [attachmentIds, setAttachmentIds] = useState<number[]>([]);

  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");
  const [isFunctionRunning, setIsFunctionRunning] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);
  const [viewOtherModalShow, setViewOtherModalShow] = useState(false);

  const handleViewOtherModalClose = () => {
    setViewOtherModalShow(false);
  };
  const [otherFiles, setOtherFiles] = useState<
    Array<{ url: string; type: string }>
  >([]);

  useEffect(() => {
    const getAllKeyPersons = async () => {
      try {
        const result = await dispatch(getKeyPersonDetails(customerId));
        if (result.payload) {
          // console.log("in main file-->", result.payload);
        }
      } catch (error) {
        // console.error("An error occured", error);
      }
    };
    getAllKeyPersons();
  }, [customerId, dispatch]);

  const handleViewModalShow = (documentType: string) => {
    if (documentType === "OT") {
      // Fetch the document content for "Others" type
      fetchDocContent({ docType: "OT", docSide: "" });

      // Show the "Others" modal
      setViewOtherModalShow(true);
    }
  };

  const handleDocType = (selectedItem: string) => {
    setSelectedType(selectedItem);
    localStorage.setItem("docType", selectedItem);
  };

  const [docSide, setDocSide] = useState("");

  const handleDocSide = (docSide: string) => {
    setDocSide(docSide);
  };
  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];

      // Check if the file type is allowed
      const allowedTypes = ["pdf", "jpeg", "png", "jpg"];
      const fileType = selectedFile.name.split(".").pop()?.toLowerCase();

      if (!fileType || !allowedTypes.includes(fileType)) {
        // Display an alert or take appropriate action
        alert(
          "Unsupported file type. Please select a file with one of the following types: pdf, jpeg, png, jpg"
        );
        return;
      }

      setImage(selectedFile);
      localStorage.setItem("fileName", selectedFile.name);
      setDocName(`${docSide}_${selectedFile.name}`);
      setUploadDisabled(false);
      setButtonText("Upload");
    } else {
      // console.warn("No files selected.");
    }
  };

  const handleCreateOneDoc = async (attachmentids: number) => {
    // console.warn("id here--", attachmentids);

    try {
      const result = await dispatch(
        createOneDoc({
          selectedType,
          customerId,
          attachmentids,
          selectedPersonId,
          docName,
          selectedDropdownItem,
        })
      );
      if (result.payload.data.error) {
        alert("Something Went Wrong! Please contact admin");
        setUploading(false);
      } else {
        // console.log(result.payload, "--in main file");
        const newId = result.payload.data.result;

        if (newId !== null) {
          const personId = selectedPersonId;
          const personName = selectedPersonName;
          if (personId && personName) {
            // console.log("in setTImeout");
            setTimeout(() => {
              handleSelect(personId, personName);
              setUploading(false);
              setUploadDisabled(true);
              setIsFunctionRunning(false);
              setSelectedDocument("");
            }, 2000);
          }
          if (selectedDropdownItem) {
          }
        } else {
          // console.warn("Did not get the newId");
          setIsFunctionRunning(false);
        }
      }
    } catch (error) {
      // console.error("An error occurred", error);
      setIsFunctionRunning(false);
    }
  };
  const handleAPI = async () => {
    if (!selectedDocument) {
      alert("Please select Document from the List");
    } else {
      // console.log("docname--->", docName);
      try {
        setIsFunctionRunning(true);
        localStorage.setItem("customerId", `${customerId}`);
        if (docName && image) {
          setUploading(true);
          const response = await dispatch(
            uploadIndividualAttachment({ docName, image })
          );
          if (response.payload.message === "Success") {
            // console.log("in  main file-->", response.payload);
            // console.log("id-->", localStorage.getItem("attachmentids"));

            const attachmentids = Number(localStorage.getItem("attachmentids"));
            if (attachmentids) {
              setDocumentId(attachmentids);
              handleCreateOneDoc(attachmentids);
            }
          }
        }
      } catch (error) {
        setIsFunctionRunning(false);
        // setIsFunctionRunning(false);
        // console.error("An error occurred--:", error);
      }
    }
  };
  const handlePOIDropdownSelect = (item: string) => {
    setselectedPOIItem(item);
    setSelectedDocument(`Proof of Identity - ${item}`);
    setIsProofOfIdentitySelected(true);
    setSelecedDropdownItem(item);
    setDocUploaded(false);
    handleDocSide("FRONT");
  };
  const handlePOIDropdownSelectBack = (item: string) => {
    setselectedPOIItem(item);
    setSelectedDocument(`Proof of Identity - ${item}`);
    setIsProofOfIdentitySelected(true);
    setSelecedDropdownItem(item);
    setDocUploaded(false);
    handleDocSide("BACK");
  };

  const handlePOADropdownSelect = (item: string) => {
    setselectedPOAItem(item);
    setSelectedDocument(`Proof of Address - ${item}`);
    setIsProofOfAddressSelected(true);
    setSelecedDropdownItem(item);
    setDocUploaded(false);
    handleDocSide("FRONT");
  };

  const handlePOADropdownSelectBack = (item: string) => {
    setselectedPOAItem(item);
    setSelectedDocument(`Proof of Address - ${item}`);
    setIsProofOfAddressSelected(true);
    setSelecedDropdownItem(item);
    setDocUploaded(false);
    handleDocSide("BACK");
  };

  const POIdropdownItems = ["PAN", "Others"];
  const POAdropdownItems = [
    "Passport",
    "Driving license",
    `Voter's Identity Card`,
    "Aadhar",
    "Others",
  ];
  const state = useSelector(
    (state: RootState) => state.verification.verIndividualDetails
  );
  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }

  const individualStore: Array<{
    id: number;
    customer_id: string;
    designation: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    nationality_country_id: { name: string };
    email: string;
  }> = state.data.data.result.result;

  const handleSelect = (personId: number, personName: string) => {
    setShowKYCList(false);
    // console.log(`Selected key person ID: ${personId}`);
    setSelectedPersonId(personId);
    // console.log(`Selected Person Name: ${personName}`);
    setSelectedPersonName(personName);
    if (personId) {
      handleUploadedDocs(personId);
      handleAttachmentIds(personId);
    }
  };

  const handleAttachmentIds = async (personId: number) => {
    try {
      const result = await dispatch(
        fetchKeyPersonIds({ customerId, personId })
      );
      if (result.payload.data) {
        const attachmentIdss =
          result.payload.data.result.result[0].customer_key_person_document_ids;
        setAttachmentIds(attachmentIdss);
        // console.warn(attachmentIdss, "--attachmentsids");
      }
    } catch (error) {
      // console.error("An error occured", error);
    }
  };

  const handleUploadedDocs = async (personId: number) => {
    // setDocObjects([]);
    try {
      setDocObjects([]);
      const result = await dispatch(
        fetchKeyPersonDocs({ customerId, personId })
      );
      if (result.payload) {
        // console.log("in main file-->", result.payload);

        const docObjects = result.payload.data.result.result
          .map((item: { customer_key_person_document_ids: any[] }) => {
            return item.customer_key_person_document_ids.map((doc) => ({
              doc_type: doc.document_type,
              doc_id: doc.attachment_ids,
              doc_name: doc.document_name,
              document_name_text: doc.document_name_text,
            }));
          })
          .flat();

        setDocObjects(docObjects);
        // console.log("main--", docObjects);
        // setSelectedDocument("");

        setShowKYCList(true);
      }
    } catch (error) {
      // console.error("An error occured", error);
    }
  };

  const handleDocumentDelete = async ({
    docType,
    docSide,
  }: {
    docType: string;
    docSide: string;
  }) => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      const docObject = docObjects.filter(
        (doc) =>
          doc.doc_type === docType &&
          doc.document_name_text.includes(`${docSide}`)
      );
      // console.log(docObject);
      if (docObject) {
        // console.log("delete docobj--", docObject);
        setSelectedDocObject(docObject);

        setShowDeleteConfirmation(true);
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const handleDeleteDocument = async (docId: number) => {
    // console.log("docId-->", docId);
    try {
      setDeleteSpinner(true);
      const result = await dispatch(deleteDocument(docId));
      if (result.payload) {
        // console.warn(result);
        window.location.reload();
        setDeleteSpinner(false);
        // console.warn("Document deleted");
      } else {
        setDeleteSpinner(false);
        // console.warn("error");
      }
    } catch (error) {
      setDeleteSpinner(false);
      // console.warn("Something went wrong", error);
    }
  };

  const checkNavigate = () => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      navigate(`/application-undertaking/${customerId}`);
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const fetchDocContent = async ({
    docType,
    docSide,
  }: {
    docType: string;
    docSide: string;
  }) => {
    setShowSpinner(true);
    setOtherFiles([]);
    if (docType === "OT") {
      const otherDocObjects = docObjects.filter((doc) => doc.doc_type === "OT");
      const promises = [];
      for (const otherDocObject of otherDocObjects) {
        const docId = otherDocObject.doc_id;
        // console.log(docId);
        // setDocNameType(otherDocObject.doc_name);
        const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}?session_id=${sessionId}`;
        const promise = axios
          .get<ArrayBuffer>(apiUrl, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            const dataAsArray = new Uint8Array(response.data);
            // console.log(apiUrl);
            if (
              dataAsArray[0] === 0x25 &&
              dataAsArray[1] === 0x50 &&
              dataAsArray[2] === 0x44 &&
              dataAsArray[3] === 0x46
            ) {
              // It's a PDF
              setFileType("pdf");
              setOtherFiles((prevFiles) => [
                ...prevFiles,
                { url: apiUrl, type: "pdf" },
              ]);
            } else {
              const imageBase64 = `data:image/png;base64,${btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              )}`;
              setImageData(imageBase64);
              // console.warn("its an img");
              setFileType("img");
              setOtherFiles((prevFiles) => [
                ...prevFiles,
                { url: apiUrl, type: "img" },
              ]);
              setFileType("img");
            }
          })
          .catch((error) => {
            // console.error("Error fetching file:", error);
          });
        promises.push(promise);
      }
      try {
        await Promise.all(promises);
      } catch (error) {
        // console.error("Error fetching files:", error);
      }
      setShowSpinner(false);
      // console.log("Other Document Objects:", otherDocObjects);
    } else {
      const docObject = docObjects.find(
        (doc) =>
          doc.doc_type === docType &&
          doc.document_name_text.includes(`${docSide}`)
      );
      // console.log(docObject, "-here");
      if (docObject) {
        const docId = docObject.doc_id;
        // console.log(docId, "id doc-");
        // setDocNameType(docObject.doc_name);
        const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?session_id=${sessionId}`;

        try {
          const response = await axios.get<ArrayBuffer>(apiUrl, {
            responseType: "arraybuffer",
          });

          const dataAsArray = new Uint8Array(response.data);

          if (
            dataAsArray[0] === 0x25 &&
            dataAsArray[1] === 0x50 &&
            dataAsArray[2] === 0x44 &&
            dataAsArray[3] === 0x46
          ) {
            setImageData(apiUrl);
            // console.warn("its a pdf");
            setFileType("pdf");
          } else {
            const imageBase64 = `data:image/png;base64,${btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`;
            setImageData(imageBase64);
            // console.warn("its a img");
            setFileType("img");
          }

          setShowSpinner(false);
        } catch (error) {
          // console.error("Error fetching file:", error);
        }

        // console.log("API URL:", apiUrl);
      }
    }
  };

  const required = <span className="text-danger form-label">*</span>;

  const disableClicks = isFunctionRunning ? { pointerEvents: "none" } : {};

  return (
    <>
      {isFunctionRunning && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)", // semi-transparent black overlay
            zIndex: 9999,
          }}
        />
      )}
      <Container>
        <Row>
          <Row md={12} className="align-items-center">
            <Col md={10} className="text-start">
              <SectionHeading text="Upload KEY PERSON KYC DOCUMENTS" />
            </Col>
            <Col md={2} className="text-end">
              <KYCInstructionsBtn />
            </Col>
          </Row>
          <Row>
            <Table bordered size="sm" className="table-container">
              <thead>
                <tr className="table-header">
                  <th className="col-md-2 text-center">Name</th>
                  <th className="col-md-2 text-center">Nationality</th>
                  <th className="col-md-2 text-center">Designation</th>
                  <th className="col-md-2 text-center">Email</th>
                  <th className="col-md-2 text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {individualStore.map((person, index) => (
                  <tr key={index}>
                    <td>
                      {person.first_name} {person.last_name}
                    </td>
                    <td>{person.nationality_country_id.name}</td>
                    <td>
                      {person.designation
                        ? person.designation.charAt(0).toUpperCase() +
                          person.designation.slice(1)
                        : ""}
                    </td>
                    <td>{person.email}</td>
                    <td className="text-center">
                      <Button
                        label="Select"
                        variant="primary"
                        onClick={() => {
                          setselectedPOIItem("");
                          setselectedPOAItem("");
                          handleSelect(person.id, person.first_name);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>

          <Row className="mt-3">
            {showKYCList && (
              <>
                <Row md={12} className="text-center m-3">
                  <h5>Upload KYC : {selectedPersonName}</h5>
                </Row>
                <Col md={6}>
                  <KYCListView
                    items={[
                      {
                        label: "Proof of Address (Front Side)",
                        required: true,
                        dropdownItems: POAdropdownItems,
                        selectedDropdownItem: selectedPOAItem,
                        onDropdownSelect: handlePOADropdownSelect,
                        defaultValue: docObjects.some(
                          (doc) => doc.doc_type === "POA" && doc.doc_name
                        )
                          ? docObjects.find((doc) => doc.doc_type === "POA")
                              ?.doc_name
                          : "",
                        disabled: docObjects.some(
                          (doc) => doc.doc_type === "POA" && doc.doc_name
                        ),
                        onCancelClick: () => {
                          handleDocumentDelete({
                            docType: "POA",
                            docSide: "FRONT",
                          });
                        },
                        onViewClick: () => {
                          if (
                            docObjects.some((doc) => doc.doc_type === "POA")
                          ) {
                            handleShow();
                            setDocType("Proof of Address (Front Side)");
                            fetchDocContent({
                              docType: "POA",
                              docSide: "FRONT",
                            });
                          }
                        },
                        onClick: () => {
                          handleDocType("POA");
                          const selectedDoc = docObjects.find(
                            (doc) => doc.doc_type === "POA"
                          );
                          if (selectedDoc) {
                            // console.log(selectedDoc.doc_name);
                          } else {
                            // console.log("Document not found");
                          }
                        },
                        style: {
                          backgroundColor: docObjects.some(
                            (doc) =>
                              doc.doc_type === "POA" &&
                              doc.document_name_text &&
                              doc.document_name_text.includes("FRONT")
                          )
                            ? "#9ef89e"
                            : "",
                        },
                      },
                      {
                        label: "Proof of Address (Back Side)",
                        required: false,
                        dropdownItems: POAdropdownItems,
                        selectedDropdownItem: selectedPOAItem,
                        defaultValue: docObjects.some(
                          (doc) => doc.doc_type === "POA"
                        )
                          ? docObjects.find((doc) => doc.doc_type === "POA")
                              ?.doc_name
                          : "",
                        disabled: true,
                        onCancelClick: () => {
                          handleDocumentDelete({
                            docType: "POA",
                            docSide: "BACK",
                          });
                        },
                        onViewClick: () => {
                          if (
                            docObjects.some((doc) => doc.doc_type === "POA")
                          ) {
                            handleShow();
                            setDocType("Proof of Address (Back Side)");
                            fetchDocContent({
                              docType: "POA",
                              docSide: "BACK",
                            });
                          }
                        },
                        onClick: () => {
                          handleDocType("POA");
                          const selectedDoc = docObjects.find(
                            (doc) => doc.doc_type === "POA"
                          );
                          if (selectedDoc) {
                            // console.log(selectedDoc.doc_name);
                            const docName = selectedDoc.doc_name;
                            handlePOADropdownSelectBack(docName);
                          } else {
                            // console.log("Document not found");
                          }
                        },
                        style: {
                          backgroundColor: docObjects.some(
                            (doc) =>
                              doc.doc_type === "POA" &&
                              doc.document_name_text &&
                              doc.document_name_text.includes("BACK")
                          )
                            ? "#9ef89e"
                            : "",
                        },
                      },
                      {
                        label: "Proof of Identity (Front Side)",
                        required: true,
                        dropdownItems: POIdropdownItems,
                        selectedDropdownItem: selectedPOIItem,
                        onDropdownSelect: handlePOIDropdownSelect,
                        defaultValue: docObjects.some(
                          (doc) => doc.doc_type === "POI"
                        )
                          ? docObjects.find((doc) => doc.doc_type === "POI")
                              ?.doc_name
                          : "",
                        disabled: docObjects.some(
                          (doc) => doc.doc_type === "POI" && doc.doc_name
                        ),
                        onCancelClick: () => {
                          handleDocumentDelete({
                            docType: "POI",
                            docSide: "FRONT",
                          });
                        },
                        onViewClick: () => {
                          if (
                            docObjects.some((doc) => doc.doc_type === "POI")
                          ) {
                            handleShow();
                            setDocType("Proof of Identity (Front Side)");
                            fetchDocContent({
                              docType: "POI",
                              docSide: "FRONT",
                            });
                          }
                        },
                        onClick: () => {
                          handleDocType("POI");
                        },
                        style: {
                          backgroundColor: docObjects.some(
                            (doc) =>
                              doc.doc_type === "POI" &&
                              doc.document_name_text &&
                              doc.document_name_text.includes("FRONT")
                          )
                            ? "#9ef89e"
                            : "",
                        },
                      },
                      {
                        label: "Proof of Identity (Back Side)",
                        required: false,
                        dropdownItems: POIdropdownItems,
                        selectedDropdownItem: selectedPOIItem,
                        onDropdownSelect: handlePOIDropdownSelectBack,

                        defaultValue: docObjects.some(
                          (doc) => doc.doc_type === "POI"
                        )
                          ? docObjects.find((doc) => doc.doc_type === "POI")
                              ?.doc_name
                          : "",
                        disabled: true,
                        onCancelClick: () => {
                          handleDocumentDelete({
                            docType: "POI",
                            docSide: "BACK",
                          });
                        },
                        onViewClick: () => {
                          if (
                            docObjects.some((doc) => doc.doc_type === "POI")
                          ) {
                            handleShow();
                            setDocType("Proof of Identity (Back Side)");
                            fetchDocContent({
                              docType: "POI",
                              docSide: "BACK",
                            });
                          }
                        },

                        onClick: () => {
                          handleDocType("POI");
                          const selectedDoc = docObjects.find(
                            (doc) => doc.doc_type === "POI"
                          );
                          if (selectedDoc) {
                            // console.log(selectedDoc.doc_name);
                            const docName = selectedDoc.doc_name;
                            handlePOIDropdownSelectBack(docName);
                          } else {
                            // console.log("Document not found");
                          }
                        },
                        style: {
                          backgroundColor: docObjects.some(
                            (doc) =>
                              doc.doc_type === "POI" &&
                              doc.document_name_text &&
                              doc.document_name_text.includes("BACK")
                          )
                            ? "#9ef89e"
                            : "",
                        },
                      },
                      {
                        label: "Photo",
                        required: true,
                        onEditClick: () => {},
                        onCancelClick: () => {
                          handleDocumentDelete({
                            docType: "PHT",
                            docSide: "",
                          });
                        },
                        onClick: () => {
                          setSelectedPHTIndex(0);
                          handleDocumentSelect("Photo");
                          handleDocType("PHT");
                          setSelecedDropdownItem("");
                          handleDocSide("");
                        },
                        onViewClick: () => {
                          if (
                            docObjects.some((doc) => doc.doc_type === "PHT")
                          ) {
                            handleShow();
                            setDocType("Photo");
                            fetchDocContent({ docType: "PHT", docSide: "" });
                          }
                        },
                        style: {
                          backgroundColor: docObjects.some(
                            (doc) => doc.doc_type === "PHT"
                          )
                            ? "#9ef89e"
                            : "",
                        },
                      },
                      {
                        label: "Others",
                        onEditClick: () => {},
                        onCancelClick: () => {
                          handleDocumentDelete({
                            docType: "OT",
                            docSide: "",
                          });
                        },
                        onClick: () => {
                          handleDocumentSelect("Others");
                          handleDocType("OT");
                          setSelecedDropdownItem("");
                          handleDocSide("");
                        },
                        onViewClick: () => {
                          if (docObjects.some((doc) => doc.doc_type === "OT")) {
                            //
                            handleViewModalShow("OT");
                          }
                        },
                        style: {
                          backgroundColor: docObjects.some(
                            (doc) => doc.doc_type === "OT"
                          )
                            ? "#9ef89e"
                            : "",
                        },
                      },
                    ]}
                  />
                </Col>
                <Col md={6}>
                  <Col md={12} style={{ marginBottom: "70%" }}>
                    <Row className="text-center">
                      <Col md={12}>
                        {selectedDocument
                          ? selectedDocument + " " + docSide
                          : "Select a Document"}
                      </Col>
                    </Row>{" "}
                    <Col md={12} className="mt-2 mb-2">
                      <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>Upload File:</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleFileWithSizeLimit(e, undefined, handleImage)
                          }
                          ref={fileInputRef}
                          accept="pdf, jpeg, png, jpg"
                        />
                      </Form.Group>
                      <Row className="text-center">
                        <Col md={12}>
                          {selectedFile ? selectedFile.name : " "}
                        </Col>
                      </Row>
                    </Col>
                    <Row className="text-center">
                      <Col md={12}>
                        {uploading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            variant="primary"
                          ></Spinner>
                        ) : (
                          <Button
                            label={buttonText}
                            type="button"
                            onClick={handleAPI}
                            disabled={uploadDisabled}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </>
            )}
          </Row>
          <Row className="mt-3">
            <Col md={12} className="text-end">
              <Button
                label="Save & Next"
                onClick={() => {
                  checkNavigate();
                }}
              ></Button>
            </Col>
          </Row>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document View :: {docType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="text-center">
              {showSpinner ? (
                <Spinner variant="primary" animation="border" />
              ) : //
              fileType === "pdf" ? (
                <iframe
                  src={imageData}
                  title="Document viewer"
                  style={{ width: "400px", height: "300px" }}
                />
              ) : (
                <Image
                  src={imageData}
                  style={{ width: "600px", height: "400px" }}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {showDeleteConfirmation && (
        <Modal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row md={12}>
              <Col>
                {selectedDocObject &&
                  selectedDocObject.map((doc, index) => (
                    <Row key={index} md={12}>
                      <Row>
                        <p className="mt-3" style={{ color: "#d64161" }}>
                          PLEASE NOTE : In case of POA & POI, please Delete both
                          (Front, Back) sides of the Document
                        </p>
                      </Row>
                      <Row>
                        <Col>
                          <SectionHeading text={doc.doc_name}></SectionHeading>
                        </Col>
                      </Row>
                      <p>Click on the Document that you want to Delete :</p>
                      <Col className="text-center">
                        {deleteSpinner ? (
                          <Spinner animation="border" variant="primary" />
                        ) : (
                          <Button
                            label={`Delete ` + doc.document_name_text}
                            onClick={() => handleDeleteDocument(doc.doc_id)}
                          ></Button>
                        )}
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
      <Modal
        show={viewOtherModalShow}
        onHide={handleViewOtherModalClose}
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Others Document View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showSpinner ? (
            <Row>
              <Col className="text-center">
                <Spinner variant="primary" animation="border" />
              </Col>
            </Row>
          ) : (
            otherFiles.map((file, index) => (
              <React.Fragment key={index}>
                <Row>
                  <Col className="text-center">
                    {file.type === "pdf" ? (
                      <iframe
                        src={file.url}
                        title={`Document viewer ${index + 1}`}
                        style={{ width: "400px", height: "300px" }}
                      />
                    ) : (
                      <Image
                        src={file.url}
                        style={{ width: "600px", height: "400px" }}
                        alt="hello"
                      />
                    )}
                  </Col>
                </Row>
                {index !== otherFiles.length - 1 && (
                  <hr style={{ margin: "10px 0" }} />
                )}
              </React.Fragment>
            ))
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default KeyIndividualKYC;
