import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEnrichApiToken } from "../../../../../apis/thirdParty/getEnrichApiToken";
import { getGstDetails } from "../../../../../apis/thirdParty/getGstDetails";

export const getGstDetailsAction = createAsyncThunk(
  "getGstDetailsAction",
  async ({
    gstNumber,
    orgId,
    token,
  }: {
    gstNumber: string;
    orgId: string;
    token: string;
  }) => {
    return getGstDetails(gstNumber, orgId, token).then((response) => {
      // console.log("GST API response", response);
      return response.data;
    });
  }
);
