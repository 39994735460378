import { createSlice } from "@reduxjs/toolkit";
import { BusinessDetailsData } from "../types/VerBusinessDetailsType";
import { getRegisteredAddress } from "../action/getRegisteredAddress";

const initialState: BusinessDetailsData = {
  data: null,
  loading: false,
  error: null,
  successfull: false,
};

const verRegAddressDetailsSlice = createSlice({
  name: "verRegAddressDetailsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRegisteredAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRegisteredAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        // console.log("DATA createAppSlice", action);
      })
      .addCase(getRegisteredAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const verRegAddressDetailsReducer = verRegAddressDetailsSlice.reducer;
