import { Modal } from "react-bootstrap";
import Button from "../../../../../components/common/Button";

interface AuthoriseErrorModalProps {
  showValidationModal: boolean;
  onHide: () => void;
  error: string;
}
export const ValidationErrorModal: React.FC<AuthoriseErrorModalProps> = ({
  showValidationModal,
  onHide,
  error,
}) => {
  return (
    <Modal show={showValidationModal} onHide={onHide}>
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#EA4335",
        }}
      >
        <Modal.Title>Validation Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>{error}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} label="Close" />
      </Modal.Footer>
    </Modal>
  );
};
