import { Reducer, ReducersMapObject, combineReducers } from "@reduxjs/toolkit";
import { guestLoginReducer } from "./guestLoginSlice";
import { GuestAuthState } from "../types/GuestAuthType";
import { guestAppAccessOTPReducer } from "./guestAppAccessOTP";
import { guestSendAppAccessOTPReducer } from "./guestSendAppAccessOTP";
import { opsLoginReducer } from "./opsLoginSlice";

export const authReducerMap: ReducersMapObject<GuestAuthState> = {
  guestLogin: guestLoginReducer,
  guestAppAccess: guestAppAccessOTPReducer,
  guestAppAccessVerify: guestSendAppAccessOTPReducer,
  opsLogin: opsLoginReducer,
};
export const authGuestReducer: Reducer<GuestAuthState> =
  combineReducers<GuestAuthState>(authReducerMap);
