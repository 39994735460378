export function convertDateFormat(inputDate: string): string {
  // Split the input date into day, month, and year
  const [day, month, year] = inputDate.split("/");

  // Create a new Date object with the given values
  const originalDate = new Date(`${month}/${day}/${year}`);

  // Extract year, month, and day from the Date object
  const newYear = originalDate.getFullYear();
  const newMonth = String(originalDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const newDay = String(originalDate.getDate()).padStart(2, "0");

  // Format the result as "yyyy-mm-dd"
  const convertedDate = `${newYear}-${newMonth}-${newDay}`;

  return convertedDate;
}
