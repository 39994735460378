import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { CPKeyPersonDetailsType } from "../../pages/types/KeyPersonDetailsType";
import { CPCreateKeyPerson } from "../../apis/CPCreateKeyPerson";

export const CPCreateKeyPersonAction = createAsyncThunk(
  "createKeyPersonAction",
  async (CPKeyPersonsDetails: CPKeyPersonDetailsType) => {
    return CPCreateKeyPerson(CPKeyPersonsDetails, getSessionId()).then(
      (response) => {
        return response.data;
      }
    );
  }
);
