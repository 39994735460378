import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { createLocalBankAction } from "../action/createLocalBankAction";

const initialState: CreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const createLocalBankSlice = createSlice({
  name: "createLocalBankSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createLocalBankAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLocalBankAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createLocalBankAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const createLocalBankReducer = createLocalBankSlice.reducer;
