import axios from "axios";
import { getSessionId } from "../../../../../utils/tokenHandlers";
export const fieldValidationAPI = (customerId: number, stage: any) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/object/tradexwbench.customer/${customerId}/${stage}`,
      {},
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      // console.log(response);
      return response;
    })
    .catch((err) => err);
};
