import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { createCorrBankAction } from "../action/createCorrBankAction";

const initialState: CreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const createCorrBankSlice = createSlice({
  name: "createCorrBankSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCorrBankAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCorrBankAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createCorrBankAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const createCorrBankReducer = createCorrBankSlice.reducer;
