import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { CreateIndAppType } from "./types/CreateIndAppType";
import Input from "../../../../../components/common/Input";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createAppAction } from "../redux/action/createAppAction";
import SectionHeading from "../../../../../components/common/SectionHeading";
import Button from "../../../../../components/common/Button";
import { useNavigate } from "react-router-dom";
import TaxDetailsIndia from "../components/TaxDetailsIndia";
import TaxDetailsForeign from "../components/TaxDeatilsForeign";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import { parseAddress } from "../../../../../utils/functions/parseAddress";
import { createAddressAction } from "../redux/action/createAddressAction";

import { getCinFromPanAction } from "../redux/action/getCinFromPanAction";
import ParticipantType from "../../../../../components/common/ParticipantType";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import { updateAppAction } from "../redux/action/updateAppAction";
import { getAddressFromCinAction } from "../redux/action/getAddressFromCinAction";
import { convertDateFormat } from "../../../../../utils/functions/convertDateFormat";
import NavbarVTX from "../../../../../components/common/Navbar";
import {
  ALPHABETS_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
} from "../../../../constants/regexPatterns";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import DatePicker from "../../../../../components/common/DatePicker";
import RMDeclarationText from "../components/RMDeclaration";
import CheckBoxes from "../../../../../components/common/CheckBoxes";
import RmNameType from "../../../../../components/common/dropdowns/RmName";
import CurrentDatePicker from "../../../../../components/common/CurrentDatePicker";
import { getCurrentDate } from "../../../../../utils/functions/getCurrentDate";
import { getRmName } from "../../../../apis/listOfValues/getRmName";
import { getSessionId } from "../../../../../utils/tokenHandlers";

const OpsCreateAppPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateIndAppType>({
    shouldUnregister: true,
  });

  const gstinValue = watch("business_gst");
  const selectedRmId = watch("employee_name");
  const [rmData, setRmData] = useState([]);
  const country = watch("business_registration_country_id");
  const isCountryIndia =
    Number(watch("business_registration_country_id")) === 448;
  const isCountryNotIndia =
    Number(watch("business_registration_country_id")) !== 448;

  const constitutionType = watch("business_constitution_type");
  const particiapantRole = watch("business_participant_type");

  const phoneIsd = watch("customer_contact_mobile_country_code");
  const phoneNo = watch("customer_contact_mobile");

  const [showError, setShowError] = useState<string>("");
  const [spinner, setSpinner] = useState<boolean>(false);

  const onSubmit: SubmitHandler<CreateIndAppType> = async (formData) => {
    if (phoneIsd === "+91" && phoneNo.toString().length !== 10) {
      alert("Please enter 10 digit number");
    } else {
      setSpinner(true);
      const data = {
        ...formData,
        employee_declaration_accepted: "True",
        is_counterparty: false,
        stage: "New / Draft",
        business_registration_country_id: Number(
          formData.business_registration_country_id
        ),
        employee_name: Number(formData.employee_name),
      };
      // console.log("data--->", data);
      if (Number(country) === 448) {
        data.business_pan = gstinValue.substring(2, 12);
        data.business_tanno_issuing_authority = "Income Tax Department";
        // data.employee_name = Number(formData.employee_name);
      } else if (Number(country) !== 448) {
        data.is_foreign = true;
        data.business_tax_jurisdiction_country_id = Number(
          formData.business_registration_country_id
        );
        data.business_incorporation_country_id = Number(
          formData.business_registration_country_id
        );
      }

      // console.log(data, "---data");
      dispatch(createAppAction(data)).then((response) => {
        let appId = response.payload.result;
        if (response.payload.error) {
          setShowError(response.payload.error.data.message);
          setSpinner(false);
          return;
        }
        if (isCountryIndia) {
          // console.log("here--", constitutionType);
          if (particiapantRole == "Financier") {
            dispatch(
              getCinFromPanAction({
                pan: gstinValue.substring(2, 12),
                customerId: response.payload.result,
              })
            ).then((response) => {
              if (response.payload.result) {
                // // console.log(
                //   "PLACE of REG",
                //   response.payload.result[0].ROC_Code,
                //   response.payload.result[0].Date_of_Incorporation
                // );
                dispatch(
                  updateAppAction({
                    customerId: appId,
                    updateCustomerData: {
                      is_cin_captured: true,
                      business_cin_no:
                        response.payload.result.result[0].entityId,
                    },
                  })
                );

                dispatch(
                  getAddressFromCinAction({
                    cin: response.payload.result.result[0].entityId,
                    customerId: appId,
                  })
                ).then((addressResponse) => {
                  try {
                    if (addressResponse.payload) {
                      // console.log(addressResponse.payload, "---resp");
                      // navigate(`/india/financier/business-details/${appId}`);
                      // return;
                      let address =
                        addressResponse.payload.result.result
                          .Registered_Address;
                      let parseAddressDetails = parseAddress(address);
                      let addressDetailsData = {
                        customer_id: appId,
                        type: "Registered",
                        ...parseAddressDetails,
                      };
                      dispatch(createAddressAction(addressDetailsData)).then(
                        (res) => {
                          dispatch(
                            updateAppAction({
                              customerId: appId,
                              updateCustomerData: {
                                business_registration_place:
                                  addressResponse.payload.result.result
                                    .ROC_Code,
                                business_registration_date: convertDateFormat(
                                  addressResponse.payload.result.result
                                    .Date_of_Incorporation
                                ),
                              },
                            })
                          ).then(() => {
                            if (appId) {
                              navigate(
                                `/india/financier/business-details/${appId}`
                              );
                            } else {
                              alert("Something went wrong!");
                            }
                          });
                        }
                      );
                    }
                  } catch (err) {
                    if (appId) {
                      navigate(`/india/financier/business-details/${appId}`);
                    } else {
                      alert("Something went wrong!");
                    }
                  }
                });
              } else {
                if (appId) {
                  navigate(`/india/financier/business-details/${appId}`);
                } else {
                  alert("Something went wrong!");
                }
              }
            });
          } else {
            if (
              constitutionType == "LLC" ||
              constitutionType == "LLP" ||
              constitutionType == "PLC"
            ) {
              dispatch(
                getCinFromPanAction({
                  pan: gstinValue.substring(2, 12),
                  customerId: response.payload.result,
                })
              ).then((response) => {
                if (response.payload.result) {
                  // console.log("here--", constitutionType);
                  // // console.log(
                  //   "PLACE of REG",
                  //   response.payload.result[0].ROC_Code,
                  //   response.payload.result[0].Date_of_Incorporation
                  // );
                  dispatch(
                    updateAppAction({
                      customerId: appId,
                      updateCustomerData: {
                        is_cin_captured: true,
                        business_cin_no:
                          response.payload.result.result[0].entityId,
                      },
                    })
                  );

                  dispatch(
                    getAddressFromCinAction({
                      cin: response.payload.result.result[0].entityId,
                      customerId: appId,
                    })
                  ).then((addressResponse) => {
                    try {
                      if (addressResponse.payload) {
                        // console.warn(addressResponse.payload, "---resp");
                        // navigate(`/india/corporate/business-details/${appId}`);
                        // return;
                        let address =
                          addressResponse.payload.result.result
                            .Registered_Address;
                        let parseAddressDetails = parseAddress(address);
                        let addressDetailsData = {
                          customer_id: appId,
                          type: "Registered",
                          ...parseAddressDetails,
                        };
                        dispatch(createAddressAction(addressDetailsData)).then(
                          (res) => {
                            dispatch(
                              updateAppAction({
                                customerId: appId,
                                updateCustomerData: {
                                  business_registration_place:
                                    addressResponse.payload.result.result
                                      .ROC_Code,
                                  business_registration_date: convertDateFormat(
                                    addressResponse.payload.result.result
                                      .Date_of_Incorporation
                                  ),
                                },
                              })
                            ).then(() => {
                              if (appId) {
                                navigate(
                                  `/india/corporate/business-details/${appId}`
                                );
                              } else {
                                alert("Something went wrong!");
                              }
                            });
                          }
                        );
                      }
                    } catch (err) {
                      if (appId) {
                        navigate(`/india/corporate/business-details/${appId}`);
                      } else {
                        alert("Something went wrong!");
                      }
                    }
                  });
                } else {
                  if (appId) {
                    navigate(`/india/corporate/business-details/${appId}`);
                  } else {
                    alert("Something went wrong!");
                  }
                }
              });
            } else {
              if (appId) {
                navigate(`/india/non-corporate/business-details/${appId}`);
              } else {
                alert("Something went wrong!");
              }
            }
          }
        } else {
          if (particiapantRole == "Financier") {
            if (appId) {
              navigate(`/foreign/financier/business-details/${appId}`);
            } else {
              alert("Something went wrong!");
            }
          } else {
            if (appId) {
              navigate(`/foreign/business-details/${appId}`);
            } else {
              alert("Something went wrong!");
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    // Set default value for submitted_date using react-hook-form setValue
    setValue("submitted_date", getCurrentDate());
  }, [setValue]);

  useEffect(() => {
    getRmName(getSessionId()).then((response) => {
      setRmData(response.result.result);
    });
  }, []);

  useEffect(() => {
    if (selectedRmId && rmData.length > 0) {
      const rmDetails: any = rmData.filter((rm: any) => rm.id == selectedRmId);
      // console.log("RM DETAILS", rmDetails);
      setValue("employee_id", rmDetails[0].barcode);
    }
  }, [selectedRmId]);

  return (
    <>
      <Container>
        <NavbarVTX />
        <Row className="justify-content-center">
          <Col md={6}>
            <SectionHeading text="CONTACT PERSON DETAILS" />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="Contact Person Name"
                name="customer_contact_name"
                register={register}
                required
                regexPattern={ALPHABETS_REGEX}
                requiredMessage="Name is required"
                error={errors.customer_contact_name?.message}
                placeholder="Enter Contact Person Name"
              />
              <PhoneInputWithDropdown
                label={"Cellphone"}
                name={"customer_contact_mobile"}
                register={register}
                required
                isdCodeName={"customer_contact_mobile_country_code"}
                isdCodeRegister={register}
                // disabled
                // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
                // dropdownDefaultValue={"+91"}
                // defaultValue={`${localStorage.getItem(
                //   "customer_contact_mobile"
                // )}`}
                error={errors.customer_contact_mobile?.message}
                regexPattern={PHONE_REGEX}
                placeholder="Enter Contact Person Mobile"
              />
              <Input
                label="Contact Person Email"
                type="email"
                name="customer_contact_email"
                register={register}
                required
                requiredMessage="Email is required"
                regexPattern={EMAIL_REGEX}
                regexMessage="Invalid Email Format"
                error={errors.customer_contact_email?.message}
                placeholder="Enter Contact Person Email"
              />
              <Input
                label="Designation"
                type="designation"
                name="designation"
                register={register}
                required
                // placeholder="Enter your Designation"
                requiredMessage="Designation is required"
                error={errors.designation?.message}
                placeholder="Enter Designation"
              />
              <SectionHeading text="BUSINESS DETAILS" />

              <ParticipantType
                label="Participation Role"
                name="business_participant_type"
                register={register}
                required
                error={errors.business_participant_type?.message}
              />

              <CountryDropdown
                label="Business Registration Country"
                name="business_registration_country_id"
                register={register}
                required
                requiredMessage="Please select country"
                error={errors.business_registration_country_id?.message}
              />
              <DisplayBox display={isCountryIndia}>
                <TaxDetailsIndia
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                />
              </DisplayBox>
              <DisplayBox display={isCountryNotIndia}>
                <TaxDetailsForeign
                  register={register}
                  errors={errors}
                  watch={watch}
                />
              </DisplayBox>
              <SectionHeading text="RM DETAILS" />
              <RmNameType
                label="RM Name"
                name="employee_name"
                register={register}
                required
                requiredMessage="RM name is required"
                error={errors.employee_name?.message}
                // disabled
              />
              <Input
                label="Employee ID"
                name="employee_id"
                register={register}
                disabled
                required
                requiredMessage="Employee ID is required"
                error={errors.employee_id?.message}
                placeholder="Enter Employee ID"
                // disabled
              />
              {/* <DatePicker
                label="RM Sign Date"
                name="submitted_date"
                register={register}
                required
                requiredMessage="Date is required"
                error={errors.submitted_date?.message}
              /> */}
              <CurrentDatePicker
                name={"submitted_date"}
                label={"RM Sign Date"}
                register={register}
                // required
                isCurrentDate
                // requiredMessage="Date is required"
                disabled
                // error={errors.submitted_date?.message}
              />
              <RMDeclarationText />
              <CheckBoxes
                label={"I Accept the above"}
                name={"employee_declaration_accepted"}
                register={register}
                required
                requiredMessage="Please accept the Declaration"
                error={errors.employee_declaration_accepted?.message}
              />
              {/* <RadioButton
                label="FTR/ FTNR"
                name="business_is_ftr_ftnr"
                options={ftnrOptions}
                register={register}
                required
                //defaultValue="buyer"
                error={errors.business_is_ftr_ftnr?.message}
              /> */}
              {/* <DisplayBox display={ftnrRadioSelectedValue}>
                <Input
                  label="FTNR Remark"
                  name="business_ftnr_remarks"
                  register={register}
                  error={errors.business_is_ftr_ftnr?.message}
                />
              </DisplayBox> */}
              {showError && <p style={{ color: "red" }}>{showError}</p>}
              <Row>
                <Col md={12} className="mt-3 text-end">
                  {/* <Button
                    label="Reset"
                    type="reset"
                    variant="outline-primary"
                  /> */}
                  {spinner ? (
                    <Spinner variant="primary" animation="border"></Spinner>
                  ) : (
                    <Button label="Create" type="submit" />
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OpsCreateAppPage;
