import { createSlice } from "@reduxjs/toolkit";
import { GuestCreateAppData } from "../types/guestCreateAppTypes";
import { fetchHeaderDetails } from "../action/fetchHeaderDetails";

const initialState: GuestCreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const headerDetailsSlice = createSlice({
  name: "headerDetailsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeaderDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHeaderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        // console.log("DATA createAppSlice", action);
      })
      .addCase(fetchHeaderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const headerDetailsReducer = headerDetailsSlice.reducer;
