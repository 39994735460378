import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { CorrBankType } from "../../../../../guestUser/common/types/GuestCorrBankDetails";
import { createCorrBank } from "../../apis/createCorrBank";

export const createCorrBankAction = createAsyncThunk(
  "createCorrBankAction",
  async (corrBanks: CorrBankType) => {
    return createCorrBank(corrBanks, getSessionId()).then((response) => {
      // console.log("Create Corr bank", response);
      return response.data;
    });
  }
);
