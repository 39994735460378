import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllApp } from "../../apis/getAllApp";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getParticipantAPI } from "../../apis/getParticipants";
import { FilterParams } from "../../pages/ApplicationListView/ApplicationListView";

// export const getParticipantsAction = createAsyncThunk(
//   "getParticipants",
//   async () => {
//     return getParticipant(getSessionId()).then((response: any) => {
//       return response.data;
//     });
//   }
// );

export const getParticipantAction = createAsyncThunk(
  "getParticipants",
  async (filterParams: FilterParams) => {
    return getParticipantAPI(getSessionId(), filterParams).then(
      (response: any) => {
        // console.log("in action file");
        return response.data;
      }
    );
  }
);
