import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getGstAddressAction } from "../action/getGstAddressAction";

const initialState: CreateAppData = {
  data: {},
  loading: false,
  error: null,
};

const getGstAddressSlice = createSlice({
  name: "getGstAddressSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGstAddressAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGstAddressAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result;
      })
      .addCase(getGstAddressAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getGstAddressReducer = getGstAddressSlice.reducer;
