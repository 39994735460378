import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUploadedDocs } from "../../apis/getUploadedDocs";

export const fetchUploadedDocs = createAsyncThunk(
  "fetchUploadedDocs",
  async (customerId: number) => {
    return getUploadedDocs(customerId).then((response: any) => {
      // // console.warn("in action file");
      return response.data;
    });
  }
);
