import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadSupportingDocument } from "../../apis/uploadSupportingDocument";

type SupportingDocsArgs = {
  docName: string;
  selectedFile: string | Blob;
};
export const uploadSupportingDoc = createAsyncThunk(
  "uploadSupportingDoc",
  async (args: SupportingDocsArgs) => {
    const { docName, selectedFile } = args;
    const response = await uploadSupportingDocument(docName, selectedFile);

    return response;
  }
);
