import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import IndProprietorshipKYCDetails from "./components/IndProprietorshipKYCDetails";
import Header from "../../../../components/common/Header";

const GuestProprietorshipKYCPage: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <IndProprietorshipKYCDetails />
    </>
  );
};

export default GuestProprietorshipKYCPage;
