import axios from "axios";

export const getAllAddress = (customerId: number, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer_address`,
      {
        params: {
          filter: `[('customer_id', 'in',  [${customerId}])]`,
          query:
            "{id,customer_id,type,line_1,line_2,city,district,state,pincode,country_id{name},phone_country_code,phone,email,business_billing_gstn, business_billing_preference_email, business_communication_preference_email, phone_country_code}",
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
