import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { updateRegAddrCINType } from "../../pages/types/CreateAddressType";
import { updateAddress } from "../../apis/updateAddress";

export const updateRegAddrCIN = createAsyncThunk(
  "updateRegAddrCIN",
  async ({
    addressId,
    addressDetails,
  }: {
    addressId: number;
    addressDetails: updateRegAddrCINType;
  }) => {
    return updateAddress(addressId, addressDetails, getSessionId()).then(
      (response) => {
        return response.data;
      }
    );
  }
);
