import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getApp } from "../../apis/getApp";

export const getAppAction = createAsyncThunk(
  "getAppAction",
  async (appId: number) => {
    return getApp(appId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
