import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getGstAddress } from "../../apis/getGstAddress";

export const getGstAddressAction = createAsyncThunk(
  "getGstAddressAction",
  async (gstNumber: string) => {
    return getGstAddress(gstNumber, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
