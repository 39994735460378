import React, { ReactNode } from "react";

export type DisplayBoxProps = {
  display: boolean;
  children: ReactNode;
};

export const DisplayBox = ({
  children,
  display = false,
}: DisplayBoxProps): JSX.Element => {
  return <>{display && children}</>;
};
