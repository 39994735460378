import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getConstitutionType } from "../../../feature/apis/listOfValues/getConstitutionType";

interface ConstitutionTypeProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  handleChange?: () => JSX.Element;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
}

interface ConstitutionTypeData {
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

const ConstitutionType: React.FC<ConstitutionTypeProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  handleChange,
  defaultValue,
  error,
  disabled,
}) => {
  const token = getSessionId();

  const [constitutionTypeValues, setConstitutionTypeValues] = useState<
    ConstitutionTypeData[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!dataFetched && token) {
      getConstitutionType(token)
        .then((response) => {
          // console.log("API response:", response);
          if (response && response.result && response.result.result) {
            setConstitutionTypeValues(response.result.result);
            setDataFetched(true); // Data successfully fetched
          } else {
            throw new Error("Invalid response structure");
          }
        })
        .catch((error) => {
          console.error("Error fetching constitution types:", error);
          setFetchError("Failed to fetch constitution types");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, dataFetched]);

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label>
        <Col md={8}>
          {loading ? (
            <p>Loading...</p>
          ) : fetchError ? (
            <p className="text-danger">{fetchError}</p>
          ) : (
            <Form.Select
              as="select"
              className={`custom-input form-control ${
                error ? "is-invalid" : ""
              }`}
              onChange={handleChange}
              {...register(name, {
                required: required ? requiredMessage : false,
              })}
              defaultValue={defaultValue}
              disabled={disabled}
            >
              <option value="">Select Option</option>
              {constitutionTypeValues.map((constitutionTypeValue, index) => (
                <option key={index} value={constitutionTypeValue.lov_value}>
                  {constitutionTypeValue.lov_display_value}
                </option>
              ))}
            </Form.Select>
          )}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default ConstitutionType;
