import { createSlice } from "@reduxjs/toolkit";
import { GuestCreateAppData } from "../types/guestCreateAppTypes";
import { createGuestApplication } from "../action/guestCreateAppAction";

const initialState: GuestCreateAppData = {
  data: null,
  loading: false,
  error: null,
  successfull: false,
};

const guestCreateAppSlice = createSlice({
  name: "guestCreateAppSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createGuestApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGuestApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        // console.log("DATA createAppSlice", action);
      })
      .addCase(createGuestApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const guestCreateAppReducer = guestCreateAppSlice.reducer;
