// SingleCheckBox.tsx

import { Col, Form, Row } from "react-bootstrap";
import React, { ChangeEvent } from "react";

interface SingleCheckBoxProps {
  name: string;
  label: string;
  register: any;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const SingleCheckBox: React.FC<SingleCheckBoxProps> = ({
  name,
  label,
  register,
  defaultChecked = false,
  disabled = false,
  onChange,
  required = false,
}) => {
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Form.Group as={Col} className="mb-3 mt-3">
      <Row className="align-items-center">
        <Form.Label column md={4} className="col-form-label">
          <span className="float-start">
            {required && <span className="text-danger">*</span>} {label}
          </span>
        </Form.Label>
        <Col md={8} className="d-flex">
          <Form.Check
            type="checkbox"
            label={""}
            name={name}
            onChange={handleCheckboxChange}
            defaultChecked={defaultChecked}
            {...register(name, {
              required: required && "This field is required",
            })}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

export default SingleCheckBox;
