import React from "react";
import { InputGroup, Form } from "react-bootstrap";

interface VerBusinessDetailsProps {
  label: string;
  defaultValue: string | string[];
  disabled?: boolean;
}

const VerBusinessDetails: React.FC<VerBusinessDetailsProps> = ({
  label,
  defaultValue,
  disabled = true,
}) => {
  return (
    <InputGroup size="sm" className="mb-3">
      <InputGroup.Text id="inputGroup-sizing-sm" style={{ color: "#214382" }}>
        {label}
      </InputGroup.Text>
      <Form.Control
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"
        defaultValue={defaultValue}
        disabled={disabled}
      />
    </InputGroup>
  );
};

export default VerBusinessDetails;
