import axios from "axios";

export const verifyAppAccessOTP = (
  guest_user_arn: string,
  guest_user_otp: string
) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}login/otp/user`, {
      params: {
        email: `${localStorage.getItem("email_to")}`,
        otp: guest_user_otp,
        application_no: guest_user_arn,
        db: `${process.env.REACT_APP_DB_NAME}`,
      },
    })
    .then((response) => {
      // // console.warn(
      //   "OTP EMAIL::",
      //   localStorage.getItem("email_to"),
      //   "OTP::",
      //   guest_user_otp,
      //   "ARN::",
      //   guest_user_arn
      // );
      return response;
    })
    .catch((err) => err);
};
