import React from "react";
import SectionHeading from "../../../../../components/common/SectionHeading";

const RMDeclarationText = () => {
  return (
    <>
      <SectionHeading text="RM DECLARATION" />
      <ul style={{ color: "#214382" }}>
        <li>
          This account opening form is signed by the customer in my presence.
        </li>
        <li>
          I hereby certify that this account opening / registration form is
          complete in all respects and relevant documents have been obtained as
          per extant KYC guidelines.
        </li>
        <li>
          I have performed due diligence to verify the genuineness of the
          customer.
        </li>
      </ul>
    </>
  );
};

export default RMDeclarationText;
