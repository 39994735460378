import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadFile } from "../../apis/uploadFile";
import { UploadKYC } from "./uploadKYC";

// Define the argument types for the action
type UploadAttachmentArgs = {
  docName: string;
  image: string | Blob;
};

export const uploadAttachment = createAsyncThunk(
  "uploadAttachment",
  async (args: UploadAttachmentArgs, thunkAPI) => {
    // No need to pass 'thunkAPI'
    // Destructure the arguments
    const { docName, image } = args;

    // Call your uploadFile function with the arguments
    const response = await uploadFile(docName, image);
    // // console.warn("in action file==>", response);
    if (response.message === "Success") {
      // console.log("Upload successful making the second dispatch call");
      // // console.log("in action1--", localStorage.getItem("fileName"));
      // Dispatch your second action here
      // const { appId } = useParams();
      // const customerId = Number(appId);
      const secondapi = await thunkAPI.dispatch(UploadKYC()); // Replace 'yourSecondAction' with your actual action
      // console.log("second api call res-->", secondapi);
      if (secondapi.meta.requestStatus === "fulfilled") {
        // console.warn("second api call success");
      } else {
        // console.error("second api call error");
        alert("Something went wrong, Contact Admin");
      }
    }

    return response;
  }
);
