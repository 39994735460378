import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login } from "../../apis/login";
import { Login, LoginData } from "../types/LoginTypes";

const initialState: Login = {
  data: null,
  loading: false,
  error: null,
};

export const loginUser = createAsyncThunk(
  "loginUser",
  async (postData: LoginData) => {
    const response = await login(postData.username, postData.password);
    return response.data;
  }
);

const loginSlice = createSlice({
  name: "auth/loginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        //// console.log(action.payload);
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const loginReducer = loginSlice.reducer;
