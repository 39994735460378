import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import Button from "../../../../../components/common/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import { FormData } from "../../types/GuestBankDetails";
import CorrBankAccountDetailsComponent from "./CorrBankAccountDetails";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import {
  MdOutlineModeEditOutline,
  MdOutlineDeleteOutline,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import BankAccountType from "../../../../../components/common/dropdowns/BankAccountType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { getAllAppAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAllAppAction";
import { getAppAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import { useNavigate, useParams } from "react-router-dom";
import { arraysHaveSameValues } from "../../../../../utils/functions/arraysHavaeSameValues";
import { getAllLocalBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAllLocalBankAction";
import { deleteLocalBankAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/deleteLocalBankAction";
import { setLocalBankFormStatus } from "../../../../opsUser/ppApplication/ppIndia/redux/slice/localBankActiveSlice";
import {
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
  SWIFTCODE_REGEX,
} from "../../../../constants/regexPatterns";

interface LocalBankProps {
  selectedCurrencies?: number[];
  setShowErrorAlert?: Dispatch<SetStateAction<boolean>>;
  setShowWarningAlert?: Dispatch<SetStateAction<boolean>>;
}

const LocalBankDetailsPage: React.FC<LocalBankProps> = ({
  selectedCurrencies,
  setShowWarningAlert,
  setShowErrorAlert,
}) => {
  const navigate = useNavigate();
  let { appId } = useParams();
  let customerId = Number(appId);

  const appDetails = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let stage = appDetails.data.stage;
  const updateAppDetails = useSelector(
    (state: RootState) => state.opsPpIndApp.updateApp
  );

  const allLocalBank = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllLocalBank.data
  );

  const deletedLocalBank = useSelector(
    (state: RootState) => state.opsPpIndApp.deleteLocalBank
  );
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const [showModal, setShowModal] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    null
  );
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [deleteLocalBankId, setDeleteLocalBankId] = useState<number>(0);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAddAddress = () => {
    // Implement logic to add address
    // You can use state or other methods to store the added address data
    closeModal();
  };

  const handleLocalBankDelete = () => {
    dispatch(deleteLocalBankAction(deleteLocalBankId));
    setSelectedRowIndex(null);
  };

  useEffect(() => {
    dispatch(getAppAction(customerId));
    dispatch(getAllLocalBankAction(customerId));
  }, [updateAppDetails, deletedLocalBank]);

  // useEffect(() => {
  //   // console.log("SELECTED CURRENCIES", selectedCurrencies);
  //   // console.log("CURRENCIES IN DB", appDetails.data.currency_ids);
  //   // if(selectedCurrencies )
  // }, [appDetails]);

  const onAddClick = () => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      dispatch(setLocalBankFormStatus(true));
      setShowWarningAlert?.(false);
      setShowErrorAlert?.(false);
      const currenciesArray = Array.isArray(selectedCurrencies)
        ? selectedCurrencies
        : [selectedCurrencies];
      //  // console.log("SELECTED CURRENCIES", currenciesArray);
      //  // console.log("CURRENCIES IN DB", appDetails.data.currency_ids);

      //  // console.log(
      //    arraysHaveSameValues(currenciesArray, appDetails.data.currency_ids)
      //   );
      // // console.log(
      //   arraysHaveSameValues(
      //     selectedCurrencies ?? [],
      //     appDetails.data.currency_ids
      //   )
      // );
      // if (
      //   selectedCurrencies?.length === undefined ||
      //   selectedCurrencies.length === 0
      // ) {
      //   setShowWarningAlert?.(true);
      //   setTimeout(() => {
      //     setShowWarningAlert?.(false);
      //   }, 1000);
      //   // setShowWarningAlert(false);
      // } else if (
      //   !arraysHaveSameValues(
      //     selectedCurrencies === undefined ? [] : selectedCurrencies,
      //     appDetails.data.currency_ids
      //   )
      // ) {
      //   setShowErrorAlert?.(true);
      // } else {
      //   localStorage.setItem("AddClicked", "Yes");
      //   navigate(`/add-local-bank/${customerId}`);
      // }

      if (appDetails.data.currency_ids.length === 0) {
        setShowWarningAlert?.(true);
        // alert("yes");
        setTimeout(() => {
          setShowWarningAlert?.(false);
        }, 1000);
      } else if (
        !arraysHaveSameValues(currenciesArray, appDetails.data.currency_ids)
      ) {
        setShowErrorAlert?.(true);
      } else {
        localStorage.setItem("AddClicked", "Yes");
        navigate(`/add-local-bank/${customerId}`);
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  return (
    <Form>
      <Row>
        <Col md={20} className="bank-account-col">
          <SectionHeading text="BANK ACCOUNT" />
        </Col>
        <Row>
          <Col md={20} className="text-end">
            <Button
              label=" + Add Settlement Bank"
              onClick={onAddClick}
              variant="outline-primary"
            ></Button>
          </Col>
        </Row>
        <Table striped bordered size="sm" style={{ padding: "10%" }}>
          <thead>
            <tr>
              <th style={{ width: "20%" }}>BANK & BRANCH NAME</th>
              <th>ACCOUNT NO</th>
              <th>CURRENCIES</th>
              <th style={{ width: "20%" }}>CORRESPONDENT ACCOUNT</th>
              <th style={{ width: "10%" }}>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {allLocalBank &&
              allLocalBank.map((localBank: any) => (
                <tr>
                  <td>
                    {localBank.bank_name}, {localBank.branch_name}
                  </td>
                  <td>{localBank.bank_account_number}</td>
                  <td>{localBank.currency_id.currency_code}</td>
                  <td>
                    {
                      localBank.customer_settlement_correspondent_bank_ids
                        .length
                    }
                  </td>
                  <td style={{ color: "#214382" }}>
                    <MdOutlineModeEditOutline
                      onClick={() => {
                        if (
                          stage === "New / Draft" ||
                          stage === "Sent back to correction"
                        ) {
                          navigate(
                            `/${customerId}/edit-local-bank/${localBank.id}`
                          );
                        } else {
                          alert("Cannot edit application at this stage!");
                        }
                      }}
                      style={{
                        width: "33px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      // onClick={openModal}
                    ></MdOutlineModeEditOutline>
                    <MdOutlineRemoveRedEye
                      onClick={() =>
                        navigate(
                          `/${customerId}/View-local-bank/${localBank.id}`
                        )
                      }
                      style={{
                        width: "33px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                    ></MdOutlineRemoveRedEye>
                    <MdOutlineDeleteOutline
                      onClick={() => {
                        if (
                          stage === "New / Draft" ||
                          stage === "Sent back to correction"
                        ) {
                          setSelectedRowIndex(1);
                          setDeleteLocalBankId(localBank.id);
                        } else {
                          alert("Cannot edit application at this stage!");
                        }
                      }}
                      style={{
                        width: "33px",
                        height: "24px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    ></MdOutlineDeleteOutline>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Row>
      <Modal size="xl" show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Row>
            <Col md={20} className="mt-4" style={{ color: "#214382" }}>
              <h5>ADD BANK ACCOUNT</h5>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              md={20}
              className="text-center mt-4"
              style={{ color: "#214382" }}
            >
              <h6>LOCAL BANK DETAILS</h6>
            </Col>
          </Row>

          <Form style={{ width: "119%" }}>
            <Row style={{ marginTop: "30px" }}>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Bank name"
                  name="bank_name"
                  register={register}
                  placeholder="Enter bank name"
                  required
                  regexPattern={ALPHABETS_REGEX}
                  requiredMessage="email is required"
                  error={errors.bank_name?.message}
                />
              </Col>
              <Col sm={5}>
                <Input
                  label="Address line 1"
                  name="bank_address_line_1"
                  register={register}
                  regexPattern={ALPHANUMERIC_REGEX}
                  placeholder="Enter address line 1"
                  // required
                  // requiredMessage="email is required"
                  error={errors.bank_address_line_1?.message}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "1px" }}>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Branch name"
                  name="branch_name"
                  register={register}
                  regexPattern={ALPHABETS_REGEX}
                  placeholder="Enter branch name"
                  required
                  requiredMessage="Address is required"
                  error={errors.branch_name?.message}
                />
              </Col>
              <Col sm={5}>
                <Input
                  label="Address line 2"
                  name="bank_address_line_2"
                  register={register}
                  placeholder="Enter address line 2"
                  regexPattern={ALPHANUMERIC_REGEX}
                  // required
                  // requiredMessage="Email is required"
                  error={errors.bank_address_line_2?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Bank account no/IBAN"
                  name="bank_account_number"
                  register={register}
                  placeholder="Enter bank account no/IBAN"
                  required
                  requiredMessage="Address is required"
                  error={errors.bank_account_number?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="District"
                  name="bank_address_district"
                  register={register}
                  placeholder="Enter district"
                  regexPattern={ALPHABETS_REGEX}
                  // required
                  // requiredMessage="city is required"
                  error={errors.bank_address_district?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Re-enter Bank account no/IBAN"
                  name="bank_address_city"
                  register={register}
                  placeholder="Enter re-enter bank account no/IBAN"
                  required
                  requiredMessage="city is required"
                  error={errors.bank_address_city?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="State"
                  name="bank_address_state"
                  register={register}
                  regexPattern={ALPHABETS_REGEX}
                  placeholder="Enter state"
                  // required
                  // requiredMessage="state is required"
                  error={errors.bank_address_state?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Name as per bank account"
                  name="bank_account_name"
                  register={register}
                  regexPattern={ALPHABETS_REGEX}
                  placeholder="Enter name as per bank account"
                  // required
                  // requiredMessage="district is required"
                  error={errors.bank_account_name?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <CountryDropdown
                  label="Country"
                  name="bank_address_country_id"
                  register={register}
                  // required
                  // requiredMessage="pincode is required"
                  error={errors.bank_address_country_id?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Swift code"
                  name="swift_code"
                  register={register}
                  placeholder="Enter swift code"
                  required
                  // regexPattern={SWIFTCODE_REGEX}
                  requiredMessage="country is required"
                  error={errors.swift_code?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="City"
                  name="bank_address_city"
                  register={register}
                  placeholder="Enter city"
                  regexPattern={ALPHABETS_REGEX}
                  // required
                  // requiredMessage="city is required"
                  error={errors.bank_address_city?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <BankAccountType
                  label="Account type"
                  name="account_type"
                  register={register}
                  required
                  requiredMessage="Account type is required"
                  error={errors.account_type?.message}
                />
              </Col>
            </Row>
            <Row>
              Pincode/zipcode
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Pincode/zipcode"
                  name="bank_address_pincode"
                  register={register}
                  // required
                  // requiredMessage="pincode is required"
                  error={errors.bank_address_pincode?.message}
                  regexPattern={PINCODE_REGEX}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Dropdown
                  label="Local bank currency"
                  name="currency_ids"
                  register={register}
                  required
                  requiredMessage="Currency is required"
                  error={errors.currency_ids?.message}
                  options={[]}
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col
              md={20}
              className="text-center mt-4"
              style={{ color: "#214382" }}
            >
              <h6>BANK OFFICIAL DETAILS</h6>
            </Col>
          </Row>
          <Form style={{ width: "119%" }}>
            <Row style={{ marginTop: "30px" }}>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Official Name"
                  name="bank_official_name"
                  register={register}
                  regexPattern={ALPHABETS_REGEX}
                  placeholder="Enter official name"
                  error={errors.bank_official_name?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Official Phone"
                  name="bank_official_phone"
                  register={register}
                  regexPattern={PHONE_REGEX}
                  placeholder="Enter official phone"
                  error={errors.bank_official_phone?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Official email"
                  name="bank_official_email"
                  register={register}
                  regexPattern={EMAIL_REGEX}
                  placeholder="Enter official email"
                  error={errors.bank_official_email?.message}
                />
              </Col>
              <Col sm={5} className="d-flex align-items-center">
                <Input
                  label="Official Mobile"
                  name="bank_official_mobile"
                  register={register}
                  regexPattern={PHONE_REGEX}
                  placeholder="Enter official mobile"
                  error={errors.bank_official_mobile?.message}
                />
              </Col>
            </Row>
          </Form>
          <CorrBankAccountDetailsComponent />
        </Modal.Body>
        <Row
          style={{ marginTop: "1px", fontSize: "14px", color: "#214382" }}
          className="mt-5"
        >
          <Col>
            <label>
              <input type="radio" />
              We undertake and confirm that the above-mentioned details are of
              our Working capital account, or
            </label>
          </Col>
        </Row>
        <Row
          style={{ marginTop: "1px", fontSize: "14px", color: "#214382" }}
          className="mt-3"
        >
          <Col>
            <label>
              <input type="radio" />
              We undertake and confirm that we do not avail any working capital
              facility from any Bank, NBFC, or other financial institutions. The
              above details are of our operative account for carrying out day to
              day operations of our firm/ company.
            </label>
          </Col>
        </Row>

        <Modal.Footer>
          <Button label="Cancel" onClick={closeModal} />
          <Button label="Add" onClick={handleAddAddress} />
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={selectedRowIndex !== null}
        onHide={() => setSelectedRowIndex(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete Local bank?</Modal.Body>
        <Modal.Footer>
          <Button label="Cancel" onClick={() => setSelectedRowIndex(null)} />
          <Button label="Delete" onClick={handleLocalBankDelete} />
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default LocalBankDetailsPage;
