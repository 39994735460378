import { RouteObject } from "react-router-dom";
import GuestCreateAppPage from "../../common/pages/GuestCreateAppPage";
import GuestUserLoginPage from "../../../auth/pages/GuestUserLoginPage";
import GuestINCBusinessDetailsPage from "../pages/GuestINCBusinessDetailsPage";
import GuestCurrenciesBankKeyList from "../pages/GuestCurreciesBankKeyList";
import AddKeyIndividual from "../../common/components/AddKeyIndividuals/AddKeyIndividual";
import GuestApplicationSigningPage from "../../../opsUser/ppApplication/ppIndia/pages/OpsApplicationSigningPage";
import GuestApplicationUndertaking from "../../../opsUser/ppApplication/ppIndia/pages/OpsApplicationUndertaking";
import GuestApplicationSubmssionFormPage from "../pages/GuestApplicationSubmissionFormPage";
import GuestIFBusinessDetailspage from "../pages/GuestIFBusinessDetailsPage";
import GuestICEntityKYCPage from "../pages/GuestICEntityKYCPage";
import GuestVerificationPage from "../pages/GuestVerificationPage";
import GuestProprietorshipKYCPage from "../pages/GuestProprietorshipKYCPage";
import LocalBankDetailsPage from "../../common/components/BankDetails/LocalBankAccountDetails";
import GuestDueDilligencePage from "../pages/GuestDueDilligencePage";
import GuestAddKeyIndividual from "../pages/GuestAddKeyIndividual";
import GuestKeyIndividualPage from "../pages/GuestKeyIndividualPage";
import FBusinessDetails from "../../../opsUser/ppApplication/ppIndia/components/FBusinessDetails";
import FFBusinessDetails from "../../../opsUser/ppApplication/ppIndia/components/FFBusinessDetails";
import GuestIndiaFinancierKYC from "../pages/GuestIndiaFinancierKycPage";
import GuestForeignFinancierKYC from "../pages/GuestForeignFinancierKYCPage";
import GuestEntityForeignKYC from "../pages/GuestEntityForeignKYCPage";
import GuestEntityPartnershipKYC from "../pages/GuestEntityPartnershipKYCPage";
import GuestKeyIndividualKYC from "../pages/GuestKeyIndividualKYC";
import GuestEntityLLPKYC from "../pages/GuestEntityLLPKYCPage";
import Opsloginpage from "../../../auth/pages/Opsloginpage";

const guestAppRoutesConfig: RouteObject[] = [
  // moved ops login here to not let guest users go yo "/" route in case of session expiry
  {
    path: "/ops/login",
    element: <Opsloginpage />,
  },
  {
    path: "/guest/create-application",
    element: <GuestCreateAppPage />,
  },
  // {
  //   path: "/guest/india/non-corporate/business-details/:appId",
  //   element: <GuestINCBusinessDetailsPage />,
  // },
  // {
  //   path: "/guest/india/financier/business-details/:appId",
  //   element: <GuestIFBusinessDetailspage />,
  // },
  {
    path: "/guest/india/corporate/entity/kyc-details/:appId",
    element: <GuestICEntityKYCPage />,
  },
  {
    path: "/guest/india/proprietorship/entity/kyc-details/:appId",
    element: <GuestProprietorshipKYCPage />,
  },
  {
    path: "/guest/india/financier/entity/kyc-details/:appId",
    element: <GuestIndiaFinancierKYC />,
  },
  {
    path: "/guest/foreign/financier/entity/kyc-details/:appId",
    element: <GuestForeignFinancierKYC />,
  },
  {
    path: "/guest/foreign/entity/kyc-details/:appId",
    element: <GuestEntityForeignKYC />,
  },
  {
    path: "/guest/partnership/entity/kyc-details/:appId",
    element: <GuestEntityPartnershipKYC />,
  },
  {
    path: "/guest/llp/entity/kyc-details/:appId",
    element: <GuestEntityLLPKYC />,
  },
  {
    path: "/guest/india/verification-details",
    element: <GuestVerificationPage />,
  },
  {
    path: "/guest/currencies-bank-key-list",
    element: <GuestCurrenciesBankKeyList />,
  },
  {
    path: "/guest/LocalBankAccountDetails",
    element: <LocalBankDetailsPage />,
  },
  {
    path: "/guest/KeyIndividualList",
    element: <GuestKeyIndividualPage />,
  },
  {
    path: "/guest/AddKeyIndividual",
    element: <AddKeyIndividual />,
  },
  {
    path: "/guest/applicationsigning/:appId",
    element: <GuestApplicationSigningPage />,
  },
  {
    path: "/guest/ApplicationiUndertaking",
    element: <GuestApplicationUndertaking />,
  },
  {
    path: "/guest/ApplicationSubmission",
    element: <GuestApplicationSubmssionFormPage />,
  },
  {
    path: "/guest/KeyPersonIndividual",
    element: <GuestKeyIndividualPage />,
  },
  {
    path: "/guest/applicationundertaking",
    element: <GuestApplicationUndertaking />,
  },
  {
    path: "/guest/applicationsubmissionform",
    element: <GuestApplicationSubmssionFormPage />,
  },
  {
    path: "/guest/applicationsigningform",
    element: <GuestApplicationSigningPage />,
  },
  {
    path: "/guest/duedilligence",
    element: <GuestDueDilligencePage />,
  },
  {
    path: "/guest/addkeyindividuals",
    element: <GuestAddKeyIndividual />,
  },
  // {
  //   path: "/guest/ForiegnBusiness",
  //   element: <FBusinessDetails />,
  // },
  // {
  //   path: "/guest/ForiegnFinancierBusiness",
  //   element: <FFBusinessDetails />,
  // },
  {
    path: "/keyperson/kyc-details/:appId",
    element: <GuestKeyIndividualKYC />,
  },
];

export default guestAppRoutesConfig;
