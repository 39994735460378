import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row, Spinner, Image } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../../components/common/dropdowns/Dropdown";
import Input from "../../../components/common/Input";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import Button from "../../../components/common/Button";
import { SupportingDocsType } from "../redux/types/VerificationDetailsType";
import KYCListView from "../../../components/common/KYCDocsList";
import { uploadSupportingDoc } from "../redux/action/uploadSupportingDocAction";
import { updateSupportingStatus } from "../redux/action/updateSupportingStatus";
import { getSessionId } from "../../../utils/tokenHandlers";
import axios from "axios";
import { deleteDocument } from "../../guestUser/ppApplication/redux/action/deleteDocument";
import { useParams } from "react-router-dom";
import { getAppAction } from "../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import { DisplayBox } from "../../../components/containers/DisplayBox";
import AlertPopup from "../../../components/common/Alert/AlertPopup";
import { getVerStatusKeyPerson } from "../redux/action/getVerStatusKeyPerson";

interface verSupportingDocProps {
  docId: number;
  docType: string;
}

const VerSupportingDocKeyPerson: React.FC<verSupportingDocProps> = ({
  docId,
  docType,
}) => {
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<SupportingDocsType>({
    shouldUnregister: true,
  });

  const dispatch = useDispatch<AppDispatch>();
  const [showSaveSpinner, setShowSaveSpinner] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [docName, setDocName] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [attachmentids, setAttachmentids] = useState<string | null>(null);
  const [showUploadSpinner, setShowUploadSpinner] = useState(false);
  const [showModalSpinner, setShowModalSpinner] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const [showDataUpdateSuccess, setShowDataUpdateSuccess] = useState(false);
  const sessionId = getSessionId();
  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");
  const { appId } = useParams();
  const customerId = Number(appId);
  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);
  const state1 = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  const appStage = state1.data.stage;
  const state = useSelector(
    (state: RootState) => state.verification.verStatusKeyPerson
  );
  const keyPersonId = state.data.data.result.result[0].customer_key_person_id;
  if (!state.data || !state.data.data) {
    return null;
  }

  const statusRemarksStore = state.data.data.result.result[0];
  const handleAPI = async () => {
    setShowUploadSuccess(false);
    localStorage.removeItem("attachmentids");
    if (!selectedFile) {
      alert("Please select a file before uploading.");
      return;
    }

    // console.log("Selected File:", selectedFile.name);
    // console.log("File properties:", selectedFile);

    if (docName && selectedFile) {
      setShowUploadSpinner(true);

      // console.warn("done");
      try {
        const response = await dispatch(
          uploadSupportingDoc({ docName, selectedFile })
        );
        if (response.payload.message === "Success") {
          // console.log("in main file-->", response.payload);
          const attachmentids = localStorage.getItem("attachmentids");
          setAttachmentids(attachmentids);
          setShowUploadSpinner(false);
          // alert("Document Uploaded!");
          const data = {
            maker_verification_attachment_ids:
              attachmentids !== null ? [parseInt(attachmentids, 10)] : [],
          };
          let selectedId = keyPersonId;
          let selectedDocId = docId;
          dispatch(updateSupportingStatus({ docId, data })).then((res) => {
            dispatch(getVerStatusKeyPerson({ selectedId, selectedDocId })).then(
              (resp) => {
                setShowUploadSuccess(true);
              }
            );
          });

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
            // setButtonText("Upload");
          }
        }
      } catch (error) {
        setShowUploadSpinner(false);

        // console.error("An error occurred", error);
      }
    } else {
      setShowUploadSpinner(false);

      // console.warn("Something went wrong");
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setSelectedFile(selectedFile);
    if (selectedFile) {
      setDocName(selectedFile.name);
    }
  };

  const handleSupportingDocStatus: SubmitHandler<SupportingDocsType> = async (
    formData
  ) => {
    setShowDataUpdateSuccess(false);
    const data = {
      ...formData,
      maker_verification_attachment_ids:
        attachmentids !== null ? [parseInt(attachmentids, 10)] : [],
    };
    // console.warn("data =", data);
    try {
      setShowSaveSpinner(true);
      const result = await dispatch(updateSupportingStatus({ docId, data }));
      if (result.payload) {
        // console.log("in main file", result.payload);
        setShowSaveSpinner(false);
        // alert("Data Updated!");
        let selectedDocId = docId;
        let selectedId = keyPersonId;
        dispatch(getVerStatusKeyPerson({ selectedId, selectedDocId }));
        setShowDataUpdateSuccess(true);
        // window.location.reload();
      }
    } catch (error) {
      setShowSaveSpinner(false);
      // console.error("An error occured", error);
    }
  };

  const handleDocumentClick = async () => {
    setShowUpload(true);
  };

  const handleDocumentDelete = async () => {
    const docId = statusRemarksStore.maker_verification_attachment_ids[0];
    if (docId) {
      try {
        const result = await dispatch(deleteDocument(docId));
        if (result.payload) {
          // console.warn(result.payload);
          // console.log("docid--", docId);
          window.location.reload();
          // console.warn("Document deleted");
        } else {
          // console.warn("error");
        }
      } catch (error) {
        // console.warn("Something went wrong", error);
      }
    } else {
      // console.warn("else block");
    }
  };

  // view supporting document
  const handleDocumentView = async () => {
    const docId = statusRemarksStore.maker_verification_attachment_ids[0];

    // console.log("docid-->", docId);
    const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?session_id=${sessionId}`;
    try {
      setShowModalSpinner(true);
      const response = await axios.get<ArrayBuffer>(apiUrl, {
        responseType: "arraybuffer",
      });

      const dataAsArray = new Uint8Array(response.data);

      if (
        dataAsArray[0] === 0x25 &&
        dataAsArray[1] === 0x50 &&
        dataAsArray[2] === 0x44 &&
        dataAsArray[3] === 0x46
      ) {
        // const pdfBase64 = `data:application/pdf;base64,${btoa(
        //   String.fromCharCode.apply(null, Array.from(dataAsArray))
        // )}`;
        setImageData(apiUrl);
        // console.warn("its a pdf");
        setFileType("pdf");
      } else {
        setShowModalSpinner(true);
        const imageBase64 = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
        setImageData(imageBase64);
        // console.warn("its a img");
        setFileType("img");
      }

      // setShowSpinner(false);
      setShowModalSpinner(false);
    } catch (error) {
      // console.error("Error fetching file:", error);
      setShowModalSpinner(false);
    }
    // console.log("API URL:", apiUrl);
  };

  return (
    <>
      {/* <p>{keyPersonId}</p> */}
      <Row>
        <DisplayBox display={showUploadSuccess}>
          <AlertPopup variant="success" message="Document Uploaded!" />
        </DisplayBox>
        <DisplayBox display={showDataUpdateSuccess}>
          <AlertPopup variant="success" message="Data Updated!" />
        </DisplayBox>
        <Row className="m-2">
          <KYCListView
            items={[
              {
                label: "Supporting Document",
                required: false,
                onCancelClick: () => {
                  handleDocumentDelete();
                },
                onViewClick: () => {
                  handleShow();
                  handleDocumentView();
                },
                onClick: () => {
                  handleDocumentClick();
                },
                style: {
                  backgroundColor:
                    statusRemarksStore.maker_verification_attachment_ids
                      .length !== 0
                      ? "#9ef89e"
                      : "",
                },
              },
            ]}
          />
        </Row>
        <Row className="m-2">
          {showUpload ? (
            <>
              {/* <Form.Control id="fileInput" type="file" /> */}
              <Row>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Control
                    type="file"
                    multiple
                    onChange={handleFileChange} // Call handleFileChange when a file is selected
                    ref={fileInputRef}
                    accept="pdf, jpeg, png, jpg"
                  />
                </Form.Group>
              </Row>
              <Row className="text-center">
                <Col md={12}>
                  {showUploadSpinner ? (
                    <Spinner
                      animation="border"
                      role="status"
                      variant="primary"
                    ></Spinner>
                  ) : (
                    <Button
                      label="Upload"
                      type="button"
                      onClick={handleAPI}
                      // disabled={uploadDisabled}
                      disabled={
                        appStage === "Expired" ||
                        appStage === "Customer Registered" ||
                        appStage === "In Customer Registration"
                      }
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : null}
          <Form>
            <Row md={12}>
              <Dropdown
                label="Supporting Document Status"
                name="maker_verification_attachment_status"
                defaultValue={
                  statusRemarksStore.maker_verification_attachment_status
                }
                register={register}
                // disabled
                // required
                // error={errors.maker_verification_attachment_status?.message}
                // requiredMessage="API verification status is required"
                options={[
                  { label: "Success", value: "Success" },
                  { label: "Failure", value: "Failure" },
                ]}
              />
            </Row>
            <Row>
              <Input
                label="Supporting Document Remarks"
                name="maker_verification_attachment_comments"
                register={register}
                // required
                defaultValue={
                  statusRemarksStore.maker_verification_attachment_comments
                }
                // disabled
                // error={errors.maker_verification_attachment_comments?.message}
                // requiredMessage="API verification remarks is required"
              />
            </Row>
            <Row>
              <Col className="text-end">
                {showSaveSpinner ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <Button
                    type="submit"
                    label="Save Details"
                    onClick={handleSubmit(handleSupportingDocStatus)}
                    disabled={
                      appStage === "Expired" ||
                      appStage === "Customer Registered" ||
                      appStage === "In Customer Registration"
                    }
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Row>
      </Row>
      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="text-center">
              {showModalSpinner ? (
                <Spinner variant="primary" animation="border" />
              ) : //
              fileType === "pdf" ? (
                <iframe
                  src={imageData}
                  title="Document viewer"
                  style={{ width: "400px", height: "300px" }}
                />
              ) : (
                <Image
                  src={imageData}
                  style={{ width: "600px", height: "400px" }}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerSupportingDocKeyPerson;
