import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { createKeyPersonAction } from "../action/createKeyPersonAction";

const initialState: CreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const createKeyPersonSlice = createSlice({
  name: "createKeyPersonSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createKeyPersonAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createKeyPersonAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createKeyPersonAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const createKeyPersonReducer = createKeyPersonSlice.reducer;
