import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getAllKeyPerson } from "../../apis/getAllKeyPerson";

export const getAllKeyPersonAction = createAsyncThunk(
  "getAllKeyPersonAction",
  async (customerId: number) => {
    return getAllKeyPerson(customerId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
