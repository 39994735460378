import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../../../../opsUser/ppApplication/ppIndia/redux/types/CreateAppType";
import { UploadKYC } from "../action/uploadKYC";

const initialState: CreateAppData = {
  data: null,
  loading: false,
  error: null,
};

const uploadKYCSlice = createSlice({
  name: "auth/uploadKYCSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UploadKYC.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UploadKYC.fulfilled, (state, action) => {
        // console.log("payload==>", action.payload);
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(UploadKYC.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const uploadKYCReducer = uploadKYCSlice.reducer;
