import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { createManyCorrBank } from "../../apis/createManyCorrBank";
import { CorrBankType } from "../../../../../guestUser/common/types/GuestCorrBankDetails";

export const createManyCorrBankAction = createAsyncThunk(
  "createManyCorrBankAction",
  async (corrBanks: CorrBankType[]) => {
    return createManyCorrBank(corrBanks, getSessionId()).then((response) => {
      // console.log("Corr Bank response", response);
      return response.data;
    });
  }
);
