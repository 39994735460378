import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Button from "./Button";
import { downloadInstructions } from "../../feature/apis/instructionsBtn/downloadInstructions";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getSendBackDocid } from "../../feature/opsUser/ppApplication/ppIndia/apis/getSendBackDocId";
import { downloadSendBackComments } from "../../feature/opsUser/ppApplication/ppIndia/apis/downloadSendBackComments";

const SendBackPdfBtn: React.FC = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  let stage = state.data.stage;
  let appNumber = state.data.application_uid;
  const handleSendBackDownload = async () => {
    // // console.log("Send Back Btn Clicked");
    try {
      setShowSpinner(true);
      try {
        const getId = getSendBackDocid(appNumber).then((resp) => {
          //  // console.log(resp.data.result.result[0].id, "--here");
          const docId = resp.data.result.result[0].id;
          if (docId) {
            const downloadPDF = downloadSendBackComments(docId).then(() => {
              setShowSpinner(false);
            });
          } else {
            setShowSpinner(false);
          }
        });
      } catch (Err) {
        //  // console.log("err", Err);
        setShowSpinner(false);
      }
      //   await downloadInstructions();
      //   setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      //  // console.error("Failed to download instructions:", error);
    }
  };

  return (
    <>
      {stage === "Sent back to correction" && (
        <Row className=" mt-2">
          <Col>
            {showSpinner ? (
              <Spinner animation="border" variant="primary" size="sm" />
            ) : (
              <Button
                label="Download Send Back Comments"
                variant="outline-primary"
                onClick={() => {
                  handleSendBackDownload();
                }}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default SendBackPdfBtn;
