import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCommunicationAddress } from "../../apis/fetchCommunicationAddress";

export const getCommunicationAddress = createAsyncThunk(
  "getCommunicationAddress",
  async (customerId: number) => {
    const response = await fetchCommunicationAddress(customerId);
    // console.log("in comm addr action file-->", response);
    return response;
  }
);
