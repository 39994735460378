import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchVerStatusRemarks,
  FetchVerStatusRemarksParams,
} from "../../apis/fetchVerStatusRemarks";

export const getVerStatusRemarks = createAsyncThunk(
  "getVerStatusRemarks",
  async ({ customerId, selectedDocId }: FetchVerStatusRemarksParams) => {
    const response = await fetchVerStatusRemarks({ customerId, selectedDocId });
    return response;
  }
);
