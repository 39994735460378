// RadioButtons.tsx
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

interface RadioButtonsProps {
  name: string;
  label: string;
  options: { label: string; value: any }[];
  required?: boolean;
  requiredMessage?: string;
  defaultValue?: any;
  register: any; // Use proper type for register function
  error?: any; // Add error prop
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const RadioButton: React.FC<RadioButtonsProps> = ({
  name,
  label,
  options,
  register,
  required = false,
  requiredMessage = "This field is required",
  defaultValue,
  error,
  onChange,
  disabled = false,
}) => {
  return (
    <Form.Group as={Col} className="">
      <Row className="align-items-center">
        <Form.Label column md={4} className="col-form-label">
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
          </span>
        </Form.Label>
        <Col md={8} className="d-flex">
          {options.map((option, index) => (
            <Form.Check
              key={index}
              className="form-label"
              inline
              label={option.label}
              type="radio"
              name={name}
              value={option.value}
              //defaultChecked={option.value === defaultValue}
              {...register(name, {
                required: required ? requiredMessage : false,
              })}
              defaultValue={defaultValue}
              disabled={disabled}
              onChange={onChange}
            />
          ))}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default RadioButton;
