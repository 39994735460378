import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../utils/tokenHandlers";
import { SendBackComments } from "../types/VerificationDetailsType";
import { updateComments } from "../../apis/updateComments";

export const updateSendBackComments = createAsyncThunk(
  "updateSendBackComments",
  async ({
    customerId,
    commentsData,
  }: {
    customerId: number;
    commentsData: SendBackComments;
  }) => {
    return updateComments(customerId, commentsData, getSessionId()).then(
      (response: { data: any }) => {
        // console.log("response ---> ", response);
        return response.data;
      }
    );
  }
);
