import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CheckBoxes from "../../../../components/common/CheckBoxes";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import SectionHeading from "../../../../components/common/SectionHeading";
import { useForm, SubmitHandler } from "react-hook-form";
import { getAllCurrencies } from "../../../apis/currency/getAllCurrencies";
import { getSessionId } from "../../../../utils/tokenHandlers";
import Button from "../../../../components/common/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { updateAppAction } from "../../../opsUser/ppApplication/ppIndia/redux/action/updateAppAction";
import { useNavigate, useParams } from "react-router-dom";
import AlertPopup from "../../../../components/common/Alert/AlertPopup";
import { getCPCurrencies } from "../../../apis/currency/getCPCurrencies";

interface CurrencyProps {
  required?: boolean;
  requiredMessage?: string;
  defaultValues: number[];
  error?: any; // Add error prop
  setSelectedCurrencies: Dispatch<SetStateAction<number[]>>;
}

interface CurrencyData {
  id: number;
  sr_no: number;
  currency_name: string;
  currency_code: string;
  master_status: string;
  transaction_status: string;
}

type FormValues = {
  currency_ids: number[];
};

const CPCurrencies: React.FC<CurrencyProps> = ({
  defaultValues: initialDefaultValues,
  setSelectedCurrencies,
}) => {
  const token = getSessionId();
  let { appId } = useParams();
  let customerId = Number(appId);
  const updateState = useSelector(
    (state: RootState) => state.opsPpIndApp.updateApp
  );
  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp.data
  );
  let stage = getAppSelector.stage;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { register, watch } = useForm<FormValues>();
  const currency_ids = watch("currency_ids");
  setSelectedCurrencies(currency_ids);
  const [allCurrencies, setAllCurrencies] = useState<CurrencyData[]>([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [submitLabel, setSubmitLabel] = useState("Save Currencies");
  const defaultValues = initialDefaultValues || [];

  // Determine if the "Save" button should be enabled
  const isSaveDisabled = !currency_ids || !dataRetrieved;
  const isCPBuyer = getAppSelector.business_participant_type;

  // const isSaveDisabled = currency_ids.length === 0;

  // useEffect(() => {
  //   if (updateState.data && updateState.data.result) {
  //     setShowSuccessAlert(true);
  //   }
  // }, [updateState]);

  const handleFormSubmit = () => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      setShowSpinner(true);
      //  // console.log("SELECTED CURRENCIES", currency_ids);
      //  // console.log("Currency CustomerId", customerId);
      setShowSuccessAlert(false);
      setShowWarningAlert(false);

      const currencyIdsArray = Array.isArray(currency_ids)
        ? currency_ids.map(Number)
        : [Number(currency_ids)];

      //  // console.warn(currencyIdsArray, ":chk");

      dispatch(
        updateAppAction({
          customerId: customerId,
          updateCustomerData: { currency_ids: currencyIdsArray },
        })
      ).then((response: any) => {
        //  // console.warn(response, ":chk");
        let result = response;
        // // console.log("here", result);
        if (result.payload.result.error) {
          alert(result.payload.result.error.data.message);
          setShowSpinner(false);
        } else {
          setShowSpinner(false);
          setShowSuccessAlert(true);
          const referredBy_id = getAppSelector.referred_by;
          getCPCurrencies(referredBy_id);
          if (isCPBuyer === "Buyer") {
            navigate(`/key-person-details/${customerId}`);
            setShowSpinner(false);
          }
        }
      });
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  useEffect(() => {
    // .log("referredBy_id:", getAppSelector.referred_by);

    const referredBy_id = getAppSelector.referred_by;
    const isCPBuyer = getAppSelector.business_participant_type;

    // Check if referredBy_id is defined before making the API call
    if (referredBy_id !== undefined && referredBy_id !== null) {
      if (isCPBuyer === "Buyer") {
        setSubmitLabel("Save & Next");
      }
      getCPCurrencies(referredBy_id)
        .then((response) => {
          if (response && response.result && response.result.result[0]) {
            setAllCurrencies(response.result.result[0].currency_ids);
            setSelectedCurrencies(defaultValues);
            setDataRetrieved(true);
            // // console.log(allCurrencies, "allcurrs");
          } else {
            //  // console.error("Invalid response format:", response);
          }
        })
        .catch((error) => {
          //  // console.error("Error fetching CPCurrencies:", error);
          // Handle errors as needed
        });
    }
  }, []);

  return (
    <>
      {showWarningAlert && (
        <AlertPopup message="Please Select a Currency" variant="warning" />
      )}
      {showSuccessAlert && (
        <AlertPopup
          message="Currencies updated Successfully!"
          variant="success"
        />
      )}
      <Form>
        <Row>
          <Col
            md={20}
            className="text-center mt-4"
            style={{ color: "#214382" }}
          >
            <SectionHeading text="CURRENCIES" />
            <Row>
              <Col className="position-static">
                <Form>
                  {allCurrencies.map((currency, index) => (
                    <Form.Check
                      key={index}
                      inline
                      // onClick={handleCurrencyClick()}
                      label={currency.currency_code}
                      type="checkbox"
                      value={currency.id}
                      defaultChecked={defaultValues.includes(currency.id)}
                      {...register("currency_ids", {
                        shouldUnregister: false,
                        required: "Atlest One Currency should be selected",
                      })}
                    />
                  ))}
                </Form>
                <Row>
                  <Col md={20} className="text-end">
                    {showSpinner ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <Button
                        label={submitLabel}
                        onClick={handleFormSubmit}
                        disabled={isSaveDisabled}
                        // variant="outline-primary"
                      ></Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CPCurrencies;
