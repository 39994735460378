import { BrowserRouter, Routes, Route } from "react-router-dom";
import mainRoutesConfig from "./routes/mainRoutesConfig";
import axios from "axios";

// // Create an instance of Axios
// //const axiosInstance = axios.create();

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // If the response is successful, pass it through
    // // console.log("INTER RES ---> ", response);
    if (
      response &&
      response.data &&
      response.data.error &&
      response.data.error.code === 100
    ) {
      // Redirect the user to the login page
      // // console.log("Session expired. Redirecting to login page.");
      window.location.href = "/"; // Adjust the URL as needed
    }
    return response;
  }
  // (error) => {
  //   // Check if the error is due to session expiration
  //   // console.log("INTER RES ---> ", error);
  //   if (
  //     error.response &&
  //     error.response.data &&
  //     error.response.data.error &&
  //     error.response.data.error.code === 100
  //   ) {
  //     // Redirect the user to the login page
  //     // console.log("Session expired. Redirecting to login page.");
  //     window.location.href = "/login"; // Adjust the URL as needed
  //   }

  //   // If it's not a session expiration error, pass the error through
  //   return Promise.reject(error);
  // }
);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {mainRoutesConfig.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
