import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getCorrBanksOfCurrency } from "../../apis/getCorrBanksOfCurrency";

export const getCorrBanksOfCurrencyAction = createAsyncThunk(
  "getCorrBanksOfCurrencyAction",
  async ({
    customerId,
    currencyId,
  }: {
    customerId: number;
    currencyId: number;
  }) => {
    return getCorrBanksOfCurrency(customerId, currencyId, getSessionId()).then(
      (response: any) => {
        // // console.log("CORR BANK CURRENCY COUNT", response.data.result.count);
        const currencyCount = response.data.result.count;
        localStorage.setItem("currencyCount", currencyCount);

        return response.data;
      }
    );
  }
);
