import { createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../../apis/login";
import { opsLogin } from "../../apis/opsLogin";

export const operationsLoginAction = createAsyncThunk(
  "operationsLoginAction",
  async ({ username, password }: { username: string; password: string }) => {
    return opsLogin(username, password).then((response) => {
      return response.data;
    });
  }
);
