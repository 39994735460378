import { createSlice } from "@reduxjs/toolkit";
import { CreateAppData } from "../types/CreateAppType";
import { getDueDiligenceDocAction } from "../action/getDueDiligenceDocAction";

const initialState: CreateAppData = {
  data: [],
  loading: false,
  error: null,
};

const getDueDiligenceDocSlice = createSlice({
  name: "getDueDiligenceDocSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDueDiligenceDocAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDueDiligenceDocAction.fulfilled, (state, action) => {
        state.loading = false;
        // console.log("DUE DOC DETAILS", action);
        state.data = action.payload.result;
      })
      .addCase(getDueDiligenceDocAction.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.payload;
      });
  },
});

export const getDueDiligenceDocReducer = getDueDiligenceDocSlice.reducer;
