import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { updateApplication } from "../../apis/updateApplication";
import { BusinessDetailsType } from "../../pages/types/BusinessDetailsType";
import { updateStage } from "../../apis/updateStage";
import { fieldValidationAPI } from "../../apis/fieldValidationAPI";

export const fieldValidationAction = createAsyncThunk(
  "fieldValidationAction",
  async ({ customerId, stage }: { customerId: number; stage: any }) => {
    return fieldValidationAPI(customerId, stage).then(
      (response: { data: any }) => {
        // console.log("response ---> ", response);
        return response.data;
      }
    );
  }
);
