import React from "react";
import { Container, Row, Col } from "react-bootstrap";

interface SectionHeadingProps {
  text: string;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ text }) => {
  return (
    <Container className="text-center mt-3 mb-1">
      <Row>
        <Col>
          <h5 className="section-heading" style={{ color: "#214382" }}>
            {text}
          </h5>
        </Col>
      </Row>
    </Container>
  );
};

export default SectionHeading;
