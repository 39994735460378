import axios from "axios";
import { KeyPersonDetailsType } from "../pages/types/KeyPersonDetailsType";

export const updateKeyPerson = (
  keyPersonId: number,
  keyPersonDetails: KeyPersonDetailsType,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/tradexwbench.customer_key_person/${keyPersonId}`,
      {
        params: {
          context: {},
          data: keyPersonDetails,
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
