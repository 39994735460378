import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { KeyPersonDetailsType } from "../../pages/types/KeyPersonDetailsType";
import { createKeyPerson } from "../../apis/createKeyPerson";

export const createKeyPersonAction = createAsyncThunk(
  "createKeyPersonAction",
  async (keyPersonDetails: KeyPersonDetailsType) => {
    return createKeyPerson(keyPersonDetails, getSessionId()).then(
      (response) => {
        return response.data;
      }
    );
  }
);
