import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

interface ListOfValueMaster {
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

interface ListOfValueResponse {
  jsonrpc: string;
  id: null;
  result: {
    count: number;
    prev: null;
    current: number;
    next: number;
    total_pages: number;
    result: ListOfValueMaster[];
  };
}

export const fetchPOADropdown = async () => {
  const sessionID = getSessionId();
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.list_of_value_master`,
      {
        params: {
          filter:
            "[('lov_type','in',['ENTITY_PROOF_DOCUMENT_NAME']),('is_active', '=', 'true'), '|', '|', '|',('sr_no', '=', '3'), ('sr_no', '=', '6'), ('sr_no', '=', '2'), ('sr_no', '=', '20')]",
          query: "{sr_no,lov_type,lov_display_value,lov_value,is_active}",
          order: "sr_no asc",
        },
      },
      {
        params: {
          session_id: `${sessionID}`,
        },
      }
    );
    const data: ListOfValueResponse = response.data;
    return data.result.result.map((item) => ({
      label: item.lov_display_value,
      value: item.lov_value,
    }));
  } catch (error) {
    //  // console.error("Error fetching dropdown options:", error);
    return [];
  }
};
