import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { updateApplication } from "../../apis/updateApplication";
import { BusinessDetailsType } from "../../pages/types/BusinessDetailsType";
import { updateStage } from "../../apis/updateStage";

export const updateStageAction = createAsyncThunk(
  "updateStageAction",
  async ({ customerId, stage }: { customerId: number; stage: string }) => {
    return updateStage(customerId, stage).then((response: { data: any }) => {
      // console.log("response ---> ", response);
      return response.data;
    });
  }
);
