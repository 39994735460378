import { createAsyncThunk } from "@reduxjs/toolkit";
import { createOneDocAPI } from "../../apis/createOneDocAPI";

type createOneDocArgs = {
  selectedType: string;
  customerId: number;
  attachmentids: number;
  selectedPersonId: number | null;
  docName: string | null;
  selectedDropdownItem: string | null;
};

export const createOneDoc = createAsyncThunk(
  "createOneDoc",
  async (args: createOneDocArgs) => {
    const {
      selectedType,
      customerId,
      attachmentids,
      selectedPersonId,
      docName,
      selectedDropdownItem,
    } = args;
    const response = await createOneDocAPI(
      selectedType,
      customerId,
      attachmentids,
      selectedPersonId,
      docName,
      selectedDropdownItem
    );
    return response;
  }
);
