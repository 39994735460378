import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../components/common/Input";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import BankAccountType from "../../../../../components/common/dropdowns/BankAccountType";
import { BankDetailsType } from "./types/BankDetailsType";
import LocalBankCurrencies from "../../../../../components/common/dropdowns/LocalBankCurrencies";
import Button from "../../../../../components/common/Button";
import CorrBankAccountDetails from "../../../../guestUser/common/components/BankDetails/CorrBankAccountDetails";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createLocalBankAction } from "../redux/action/createLocalBankAction";
import { useState, useEffect } from "react";
import { getLocalBankAction } from "../redux/action/getLocalBankAction";
import { getAllCorrBankAction } from "../redux/action/getAllCorrBankAction";
import EditCorrBankAccountDetails from "../../../../guestUser/common/components/BankDetails/EditCorrBankAccountDetails";
import { updateLocalBank } from "../apis/updateLocalBank";
import { updateLocalBankAction } from "../redux/action/updateLocalBankAction";
import SectionHeading from "../../../../../components/common/SectionHeading";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import {
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  EMAIL_REGEX,
  NON_INDIAN_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
  SWIFTCODE_REGEX,
} from "../../../../constants/regexPatterns";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import BankAccountTypeWOEEFC from "../../../../../components/common/dropdowns/BankAccountTypeWOEEFC";
import RadioButton from "../../../../../components/common/RadioButton";
import BankRadioButton from "../../../../../components/common/BankDeclaration";
import BankAccountTypeWOthers from "../../../../../components/common/dropdowns/BankAccountTypeWOthers";

const OpsEditLocalBankPage: React.FC = () => {
  let { appId, bankId } = useParams();
  let localBankId = Number(bankId);
  let customerId = Number(appId);

  const dispatch = useDispatch<AppDispatch>();

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let role = getAppSelector.data.business_participant_type;
  const localBankDetails = useSelector(
    (state: RootState) => state.opsPpIndApp.getLocalBank.data
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm<BankDetailsType>({ shouldUnregister: true });

  // const [corrBanks, setCorrBanks] = useState([]);
  const [showLocalBankSuccess, setShowLocalBankSuccess] = useState(false);
  const accountNumber = watch("bank_account_number");
  const confirmAccountNumber = watch("re_enter_bank_account_number");
  const [showSpinner, setShowSpinner] = useState(false);
  const localCurrency = watch("currency_id");
  const [hideEEFC, setHideEEFC] = useState(false);
  const bankAccountType = watch("account_type");
  const isCountryIndia = Number(watch("bank_address_country_id")) === 448;
  useEffect(() => {
    reset({
      bank_name: localBankDetails.bank_name,
      bank_account_name: localBankDetails.bank_account_name,
      branch_name: localBankDetails.branch_name,
      bank_account_number: localBankDetails.bank_account_number,
      re_enter_bank_account_number:
        localBankDetails.re_enter_bank_account_number,
      swift_code: localBankDetails.swift_code,
      ifsc_code: localBankDetails.ifsc_code,
      // account_type: localBankDetails.account_type,
      bank_address_line_1: localBankDetails.bank_address_line_1,
      bank_address_line_2: localBankDetails.bank_address_line_2,
      bank_address_pincode:
        localBankDetails.bank_address_pincode === "0"
          ? ""
          : localBankDetails.bank_address_pincode,
      bank_address_state: localBankDetails.bank_address_state,
      bank_address_city: localBankDetails.bank_address_city,
      bank_address_country_id: localBankDetails.bank_address_country_id,
      bank_address_district: localBankDetails.bank_address_district,
      bank_official_email: localBankDetails.bank_official_email,
      bank_official_mobile: localBankDetails.bank_official_mobile,
      bank_official_name: localBankDetails.bank_official_name,
      bank_official_phone: localBankDetails.bank_official_phone,
      phone_country_code: localBankDetails.phone_country_code,
      currency_id: localBankDetails.currency_id,
      // account_type: localBankDetails.account_type,
      // other_account_type: localBankDetails.other_account_type,
      is_default_bank: localBankDetails.is_default_bank,
      undertaking_confirm: localBankDetails.undertaking_confirm,
    });
    setTimeout(() => {
      reset({
        bank_address_country_id: localBankDetails.bank_address_country_id,
        // account_type: localBankDetails.account_type,
        undertaking_confirm: localBankDetails.undertaking_confirm,
      });
    }, 500);
  }, [localBankDetails]);

  // useEffect(() => {
  //   if (localBankDetails.currency_id && localCurrency) {
  //     setTimeout(() => {
  //       reset({
  //         // bank_address_country_id: localBankDetails.bank_address_country_id,
  //         account_type: localBankDetails.account_type,
  //         // undertaking_confirm: localBankDetails.undertaking_confirm,
  //       });
  //       // console.warn(localBankDetails.account_type);
  //       const accountType = localBankDetails.account_type;
  //       setValue("account_type", accountType);
  //     }, 1000);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(getLocalBankAction(localBankId));
  }, []);

  const countryId = watch("bank_address_country_id");

  useEffect(() => {
    if (localCurrency == 12) {
      // console.log("true");
      setHideEEFC(true);
    } else {
      // console.log("false");
      setHideEEFC(false);
    }
    setTimeout(() => {
      setValue("account_type", localBankDetails.account_type);
      setValue("other_account_type", localBankDetails.other_account_type);
    }, 1000);
  }, [localBankDetails.account_type, localCurrency, reset, setValue]);

  const handleIfscCodeChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    // setIfscCode(value);
    setValue("ifsc_code", value);
  };

  const handleSwiftCodeChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    setValue("swift_code", value);
  };

  const handleBankDetailsSubmit: SubmitHandler<BankDetailsType> = (data) => {
    if (accountNumber !== confirmAccountNumber) {
      setError("re_enter_bank_account_number", {
        type: "manual",
        message: "Account Number do not match",
      });
      return;
    }
    setShowSpinner(true);

    const bankDetails = {
      ...data,
      bank_address_pincode: Number(data.bank_address_pincode),
      // undertaking_confirm: declarationValue,
      // bank_address_country_id: data.bank_address_country_id === 0 ? "false" : Number(data.bank_address_country_id),
      currency_id: Number(data.currency_id),
    };
    // console.log(data.bank_address_country_id);
    const country = data.bank_address_country_id;
    // console.warn(countryId);
    if (!data.bank_address_country_id) {
      bankDetails.bank_address_country_id = false;
    } else {
      bankDetails.bank_address_country_id = Number(
        data.bank_address_country_id
      );
    }

    // console.log("bank--", bankDetails);
    dispatch(
      updateLocalBankAction({ localBankId, updateLocalBankData: bankDetails })
    ).then((response) => {
      if (response.payload.result.error) {
        alert(response.payload.result.error.data.message);
        setShowSpinner(false);
      } else {
        // alert("Bank Updated!");
        setShowLocalBankSuccess(true);
        setTimeout(() => {
          setShowLocalBankSuccess(false);
        }, 2000);
        setShowSpinner(false);
      }
    });
  };

  return (
    <>
      <Container>
        <NavbarVTX />
        <Header />
        <Row>
          <Row>
            <Row>
              <Col md={20} className="mt-4" style={{ color: "#214382" }}>
                <SectionHeading text="EDIT SETTLEMENT BANK ACCOUNT" />
              </Col>
            </Row>
            <DisplayBox display={showLocalBankSuccess}>
              <AlertPopup
                variant="success"
                message="Bank Updated Successfully!"
              />
            </DisplayBox>
          </Row>
          <Row>
            <Row>
              <Col md={20} className="bank-account-col">
                <SectionHeading text="LOCAL BANK DETAILS" />
              </Col>
            </Row>

            <Form
              style={{ width: "119%" }}
              onSubmit={handleSubmit(handleBankDetailsSubmit)}
            >
              <Row style={{ marginTop: "30px" }}>
                <Col sm={6} className="d-flex align-items-center">
                  <LocalBankCurrencies
                    label="Local Bank Currency"
                    name="currency_id"
                    register={register}
                    required
                    requiredMessage="Currency is required"
                    error={errors.currency_id?.message}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Bank name"
                    name="bank_name"
                    register={register}
                    placeholder="Enter bank name"
                    required
                    regexPattern={ALPHABETS_REGEX}
                    requiredMessage="Bank Name is required"
                    error={errors.bank_name?.message}
                  />
                </Col>
                <Col sm={6}>
                  {getAppSelector.data.is_counterparty === true && (
                    <p style={{ fontSize: "smaller", color: "red" }}>
                      Following fields are not applicable for Counter Party
                      Applications
                    </p>
                  )}
                  <Input
                    label="Address line 1"
                    name="bank_address_line_1"
                    register={register}
                    placeholder="Enter address line 1"
                    // regexPattern={ALPHANUMERIC_REGEX}
                    // required
                    // requiredMessage="email is required"
                    error={errors.bank_address_line_1?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "1px" }}>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Branch name"
                    name="branch_name"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter branch name"
                    required
                    requiredMessage="Address is required"
                    error={errors.branch_name?.message}
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    label="Address line 2"
                    name="bank_address_line_2"
                    register={register}
                    // regexPattern={ALPHANUMERIC_REGEX}
                    placeholder="Enter address line 2"
                    // required
                    // requiredMessage="Email is required"
                    error={errors.bank_address_line_2?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Bank account no/IBAN"
                    type="password"
                    name="bank_account_number"
                    register={register}
                    placeholder="Enter bank account no/IBAN"
                    required
                    requiredMessage="Address is required"
                    error={errors.bank_account_number?.message}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="District/City Area"
                    name="bank_address_district"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter district"
                    // required
                    // requiredMessage="city is required"
                    error={errors.bank_address_district?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Re-enter Bank account no/IBAN"
                    // type="password"
                    name="re_enter_bank_account_number"
                    register={register}
                    placeholder="Enter re-enter bank account no/IBAN"
                    required
                    requiredMessage="Bank account number is required"
                    error={errors.re_enter_bank_account_number?.message}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="State/County"
                    name="bank_address_state"
                    register={register}
                    placeholder="Enter state"
                    regexPattern={ALPHABETS_REGEX}
                    // required
                    // requiredMessage="state is required"
                    error={errors.bank_address_state?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Name as per bank account"
                    name="bank_account_name"
                    register={register}
                    placeholder="Enter name as per bank account"
                    // required
                    // requiredMessage="Bank account name is required"
                    error={errors.bank_account_name?.message}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <CountryDropdown
                    label="Country"
                    name="bank_address_country_id"
                    register={register}
                    // required
                    // requiredMessage="pincode is required"
                    error={errors.bank_address_country_id?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="IFSC/Sort Code/ABA"
                    name="ifsc_code"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter IFSC/Sort code/ABA"
                    required
                    requiredMessage="IFSC/Sort code/ABA is required"
                    error={errors.ifsc_code?.message}
                    onChange={handleIfscCodeChange}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="City/Town/Locality"
                    name="bank_address_city"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    // required
                    // requiredMessage="city is required"
                    error={errors.city?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Swift code / BIC"
                    name="swift_code"
                    register={register}
                    // regexPattern={SWIFTCODE_REGEX}
                    placeholder="Enter swift code"
                    required
                    requiredMessage="country is required"
                    error={errors.swift_code?.message}
                    onChange={handleSwiftCodeChange}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Pin Code/ Postal Code/ Zip"
                    name="bank_address_pincode"
                    register={register}
                    regexPattern={
                      isCountryIndia ? PINCODE_REGEX : NON_INDIAN_REGEX
                    }
                    placeholder="Enter pincode/zipcode"
                    // required
                    // requiredMessage="email is required"
                    error={errors.bank_address_pincode?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  {role === "Financier" ? (
                    <BankAccountTypeWOthers
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                    />
                  ) : hideEEFC ? (
                    <BankAccountTypeWOEEFC
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                    />
                  ) : (
                    <BankAccountType
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                    />
                  )}
                </Col>
                {bankAccountType === "Others" && (
                  <Input
                    label={"If Others,"}
                    name={"other_account_type"}
                    register={register}
                    placeholder="Please Specify Account Type"
                    required
                    error={errors.other_account_type?.message}
                  />
                )}
              </Row>

              <Row>
                <Col md={20} className="bank-account-col">
                  <SectionHeading text="BANK OFFICIALS DETAILS" />
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                {getAppSelector.data.is_counterparty === true && (
                  <p style={{ fontSize: "smaller", color: "red" }}>
                    Following fields are not applicable for Counter Party
                    Applications
                  </p>
                )}
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official Name"
                    name="bank_official_name"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter official name"
                    error={errors.bank_official_name?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  {/* <Input
                    label="Official Phone"
                    name="bank_official_phone"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    placeholder="Enter official phone"
                    error={errors.bank_official_phone?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  /> */}
                  <PhoneInputWithDropdown
                    label="Official Phone"
                    name="bank_official_phone"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    isdCodeName={"phone_country_code"}
                    isdCodeRegister={register}
                    placeholder="Enter official phone"
                    error={errors.bank_official_phone?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                    // required
                    // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official email"
                    name="bank_official_email"
                    register={register}
                    regexPattern={EMAIL_REGEX}
                    placeholder="Enter official email"
                    error={errors.bank_official_email?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  {/* <Input
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    placeholder="Enter official mobile"
                    error={errors.bank_official_mobile?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  /> */}
                  <PhoneInputWithDropdown
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    isdCodeName={"phone_country_code"}
                    isdCodeRegister={register}
                    placeholder="Enter official mobile"
                    error={errors.bank_official_mobile?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                    // required
                    // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
                  />
                  {/* <Input
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    placeholder="Enter official mobile"
                    error={errors.bank_official_mobile?.message}
                  /> */}
                </Col>
              </Row>
              {/* <Col
                sm={12}
                style={{ fontSize: "86%", color: "#214382", marginTop: "3%" }}
              >
                <Form.Group
                  controlId="undertaking_confirm"
                  // defaultValue="No"
                  // defaultChecked
                >
                  <Form.Check
                    type="radio"
                    // defaultChecked={
                    //   localBankDetails.undertaking_confirm === "Yes"
                    //     ? true
                    //     : false
                    // }
                    label="We undertake and confirm that the above-mentioned details are of
                our Working capital account, or  "
                    value="Yes"
                    name="undertaking_confirm"
                    onChange={(e) => setDeclarationValue(e.target.value)}
                  />
                  <Form.Check
                    // defaultValue={localBankDetails.undertaking_confirm}
                    // defaultChecked={
                    //   localBankDetails.undertaking_confirm === "No"
                    //     ? true
                    //     : false
                    // }
                    type="radio"
                    label="We undertake and confirm that we do not avail any working
                    capital facility from any Bank, NBFC, or other financial
                    institutions. The above details are of our operative account for
                    carrying out day to day operations of our firm/ company."
                    value="No"
                    name="undertaking_confirm"
                    onChange={(e) => setDeclarationValue(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {declarationError && (
                <p className="m-2" style={{ color: "#d64161" }}>
                  Please accept the Declaration
                </p>
              )} */}
              <Row>
                {/* <RadioButton
                  label="Declaration"
                  name="undertaking_confirm"
                  register={register}
                  required
                  requiredMessage="Please Select the above "
                  error={errors.is_default_bank?.message}
                  options={[
                    {
                      label:
                        "We undertake and confirm that the above-mentioned details are of  our Working capital account, or",
                      value: "Yes",
                    },
                    {
                      label:
                        "We undertake and confirm that we do not avail any working capital facility from any Bank, NBFC, or other financial institutions. The above details are of our operative account for carrying out day to day operations of our firm/ company.",
                      value: "No",
                    },
                  ]}
                /> */}
                {role !== "Financier" && (
                  <BankRadioButton
                    label={""}
                    required
                    requiredMessage="Please select the Declaration"
                    error={errors.undertaking_confirm?.message}
                    name={"undertaking_confirm"}
                    register={register}
                    options={[
                      {
                        label:
                          "We undertake and confirm that the above-mentioned details are of  our Working capital account, or",
                        value: "Yes",
                      },
                      {
                        label:
                          "We undertake and confirm that we do not avail any working capital facility from any Bank, NBFC, or other financial institutions. The above details are of our operative account for carrying out day to day operations of our firm/ company.",
                        value: "No",
                      },
                    ]}
                  />
                )}
              </Row>

              <Row>
                <Col md={20} className="text-end">
                  {showSpinner ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <Button label="Update Details" type="submit"></Button>
                  )}
                </Col>
              </Row>
            </Form>
            <EditCorrBankAccountDetails />
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default OpsEditLocalBankPage;
