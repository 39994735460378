import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import EntityPartnershipKYC from "./components/EntityPartnershipKYC";
import Header from "../../../../components/common/Header";

const GuestEntityPartnershipKYC: React.FC = () => {
  return (
    <>
      <NavbarVTX />
      <Header />
      <EntityPartnershipKYC />
    </>
  );
};

export default GuestEntityPartnershipKYC;
