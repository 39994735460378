import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchKeyPersonDetails } from "../../apis/fetchKeyPersonDetails";

export const getKeyPersonDetails = createAsyncThunk(
  "getKeyPersonDetails",
  async (customerId: number) => {
    const response = await fetchKeyPersonDetails(customerId);
    // // console.warn("in action file--", response);
    return response;
  }
);
