import axios from "axios";

export const getCorrBanksOfCurrency = (
  customerId: number,
  currencyId: number,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer_settlement_correspondent_bank`,
      {
        params: {
          filter: `[('customer_settlement_bank_id.customer_id', 'in', [${customerId}]), ('currency_id', 'in', [${currencyId}]), ('is_default_bank', '=', 'true')]`,
          query:
            "{id,bank_name,bank_account_number,re_enter_bank_account_number,sort_code,iban,swift_code,currency_id{currency_code},is_default_bank, customer_settlement_bank_id{ customer_id }}",
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
