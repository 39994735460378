import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

export const getCinFromPan = (pan: string) => {
  const sessionid = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}get/cin/details?session_id=${sessionid}`,

      {
        params: {
          karza_key: "2frub1Pqjf2FzgDb",
          pan_number: `${pan}`,
        },
      }
    )
    .then((response): any => response)
    .catch((err) => err);
};
