import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { BankDetailsType } from "../../pages/types/BankDetailsType";
import { createLocalBank } from "../../apis/createLocalBank";
import { CorrBankType } from "../../../../../guestUser/common/types/GuestCorrBankDetails";
import { createManyCorrBankAction } from "./createManyCorrBankAction";

export const createLocalBankAction = createAsyncThunk(
  "createLocalBankAction",
  async (
    {
      bankDetails,
      corrBanks,
    }: {
      bankDetails: BankDetailsType;
      corrBanks: CorrBankType[];
    },
    thunkAPI
  ) => {
    return createLocalBank(bankDetails, getSessionId()).then((response) => {
      // console.log("Local Bank Response", response);
      return response.data;
    });
  }
);
