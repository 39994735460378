import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "../css/common.css";

interface BankRadioButtonProps {
  label: string;
  name: string;
  register: any; // Use proper type for register function
  required?: boolean;
  requiredMessage?: string;
  options: { label: string; value: string }[];
  error?: any; // Add error prop
  disabled?: boolean;
}

const BankRadioButton: React.FC<BankRadioButtonProps> = ({
  label,
  name,
  register,
  required = false,
  requiredMessage = "This field is required",
  options,
  error,
  disabled = false,
}) => {
  return (
    <Form.Group className="mt-3">
      <Row className="align-items-center">
        {/* <Form.Label htmlFor={name} className="col-md-4">
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>
            :
          </span>
        </Form.Label> */}
        <Col>
          {options.map((option) => (
            <div key={option.value} className="form-check form-check-inline">
              <input
                type="radio"
                id={option.value}
                name={name}
                value={option.value}
                {...register(name, {
                  required: required ? requiredMessage : false,
                })}
                className={`form-check-input ${
                  error ? "is-invalid" : ""
                } custom-radio`}
                disabled={disabled}
              />
              <label
                htmlFor={option.value}
                className="form-check-label"
                style={{ color: "#214382" }}
              >
                {option.label}
              </label>
            </div>
          ))}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default BankRadioButton;
