import React, { useEffect, useState } from "react";
import {
  Row,
  Form,
  Col,
  DropdownButton,
  Dropdown as ReactDropdown,
  Container,
  Spinner,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux/store";
import { getKeyPersonDetails } from "../redux/action/getKeyPersonDetails";
import { fetchIndividualDocs } from "../redux/action/fetchIndividualDocs";
import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getVerStatusKeyPerson } from "../redux/action/getVerStatusKeyPerson";
import VerStatusKeyPerson from "./verStatusKeyPerson";
import SectionHeading from "../../../components/common/SectionHeading";

const IndividualsSelection: React.FC = () => {
  const { appId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const customerId = Number(appId);
  const [docDataLoaded, setDocDataLoaded] = useState(false);

  const [selectedName, setSelectedName] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState(Number);
  const [selectedType, setSelectedType] = useState(" Select Document");
  const [docType, setDocType] = useState("");
  const [docNameType, setDocNameType] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [verDocId, setVerDocId] = useState(Number);
  const [selectedDocIndex, setSelectedDocIndex] = useState<number | null>(null);

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  const isCounterParty = getAppSelector.data.is_counterparty;

  const [docObjects, setDocObjects] = useState<
    {
      id: any;
      doc_type: string;
      doc_id: number;
      doc_name: string;
      doc_name_text: string;
    }[]
  >([]);

  const handleDropdownChange = async (
    selectedDocName: string,
    selectedDocType: string,
    selectedDocId: any
  ) => {
    // setSelectedType("Select Document");
    await fetchDocContent(selectedDocName, selectedDocType);
    const docObject = docObjects.find(
      (doc) =>
        doc.doc_name_text === selectedDocName &&
        doc.doc_type === selectedDocType &&
        doc.id === selectedDocId
    );
    if (docObject) {
      // console.log("id::", selectedDocId);
      const docId = docObject.doc_id;
      setVerDocId(selectedDocId);
      setDocNameType(docObject.doc_name);
      const docTypee = docObject.doc_type;
      // // console.warn("boom---", docObject.doc_name);
      // console.warn("document id-->", docTypee);
      handleDocTypeSelect(docTypee);
      await handleVerStatusRemarks(selectedDocId);
    }
    // handleDocTypeSelect(selectedDocName);
    // await handleVerStatusRemarks();
  };

  const handleDocTypeSelect = (docType: string) => {
    setSelectedType(docType);
    localStorage.setItem("selectedType", docType);
    // console.warn("heres", localStorage.getItem("selectedType"));
  };

  useEffect(() => {
    const getKeyPersonNames = async () => {
      try {
        const result = await dispatch(getKeyPersonDetails(customerId));
        if (result.payload) {
          // console.warn("in main file-->", result.payload);
        } else {
          // console.warn("Something went wrong");
        }
      } catch (error) {
        // console.error("An error occurred", error);
      }
    };
    getKeyPersonNames();
  }, [customerId, dispatch]);

  useEffect(() => {
    const fetchDocsData = async () => {
      localStorage.removeItem("selectedType");
      if (selectedId !== null) {
        try {
          const result = await dispatch(
            fetchIndividualDocs({ customerId, selectedId })
          );
          if (result.payload) {
            // // console.log(
            //   "in main file-->",
            //   result.payload.data.result.result[0].customer_key_person_document_ids
            // );
            const documents = await result.payload.data.result.result[0]
              .customer_key_person_document_ids;
            const docObjects = documents.map(
              (document: {
                id: any;
                document_type: any;
                attachment_ids: any[];
                document_name: any;
                document_name_text: any;
              }) => ({
                id: document.id,
                doc_type: document.document_type,
                doc_id: document.attachment_ids,
                doc_name: document.document_name,
                doc_name_text: document.document_name_text,
              })
            );
            setDocObjects(docObjects);

            // console.warn(`Doc obj for ${customerId} ${selectedId}`, docObjects);
          } else {
            // console.warn("Something went wrong!");
          }
          setDocDataLoaded(true);
        } catch (error) {
          // console.error("An error occured", error);
        }
      }
    };
    fetchDocsData();
  }, [customerId, dispatch, selectedId]);

  const state = useSelector(
    (state: RootState) => state.verification.verIndividualDetails
  );

  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }

  const individualStore: Array<{
    id: number;
    customer_id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
  }> = state.data.data.result.result;

  const sessionId = getSessionId();

  const fetchDocContent = async (
    selectedDocName: string,
    selectedDocType: string
  ) => {
    setDataUpdated(false);
    setShowSpinner(true);
    // setSelectedType(docType);
    // setShowSpinner(true);
    const docObject = docObjects.find(
      (doc) =>
        doc.doc_name_text === selectedDocName &&
        doc.doc_type === selectedDocType
    );
    if (docObject) {
      const docId = docObject.doc_id;
      setDocNameType(docObject.doc_name);
      // // console.warn("boom---", docObject.doc_name);
      // console.warn("document id-->", docId);
      // console.warn("customer id-->", customerId);
      const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?session_id=${sessionId}`;

      try {
        const response = await axios.get<ArrayBuffer>(apiUrl, {
          responseType: "arraybuffer",
        });

        const dataAsArray = new Uint8Array(response.data);

        if (
          dataAsArray[0] === 0x25 &&
          dataAsArray[1] === 0x50 &&
          dataAsArray[2] === 0x44 &&
          dataAsArray[3] === 0x46
        ) {
          // const pdfBase64 = `data:application/pdf;base64,${btoa(
          //   String.fromCharCode.apply(null, Array.from(dataAsArray))
          // )}`;
          setImageData(apiUrl);
          // console.warn("its a pdf");
          setFileType("pdf");
          // await handleVerStatusRemarks();
        } else {
          const imageBase64 = `data:image/png;base64,${btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          )}`;
          setImageData(imageBase64);
          // console.warn("its a img");
          setFileType("img");
          // await handleVerStatusRemarks();
        }

        // setTypeSelected(true);
      } catch (error) {
        setShowSpinner(false);
        // setTypeSelected(false);
        // console.error("Error fetching file:", error);
      }

      // console.log("API URL:", apiUrl);
    }
    setShowSpinner(false);
  };

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedType("Select Document");
    setDocDataLoaded(false);
    const value = event.target.value;
    setSelectedName(value);
    if (value) {
      const selectedIndividual = individualStore.find(
        (individual) =>
          `${individual.first_name} ${individual.last_name}` === value
      );
      if (selectedIndividual) {
        const selectedId = selectedIndividual.id;
        setSelectedId(selectedId);
        // console.log("selected id:", selectedId);
        // console.log("Selected Name:", value);
        // if (selectedId) {
        //   // console.log("SelectdeId--", selectedId);
        //   // await fetchDocsData();
        // } else {
        //   // console.warn("hello");
        // }
      }
    }
  };

  const handleVerStatusRemarks = async (selectedDocId: any) => {
    try {
      const result = await dispatch(
        getVerStatusKeyPerson({ selectedId, selectedDocId })
      );
      if (result.payload) {
        // console.log("in main file--", result.payload);
        setTimeout(() => {
          setDataUpdated(true);
        }, 500);
      }
    } catch (error) {
      // console.error("An error occured", error);
    }
  };

  return (
    <>
      {isCounterParty === false && (
        <Form>
          <Form.Select value={selectedName || ""} onChange={handleSelectChange}>
            <option value="">Select an Individual</option>
            {individualStore.map((individual) => (
              <option
                key={individual.id}
                value={`${individual.first_name} ${individual.last_name}`}
              >
                {`${individual.first_name} ${individual.last_name} `}
              </option>
            ))}
          </Form.Select>
        </Form>
      )}

      <Row className="mt-3">
        {isCounterParty === false && (
          <Col md={6}>
            {docDataLoaded ? ( // Render the document type dropdown only when data is loaded
              <DropdownButton
                id="document-type-dropdown"
                title={selectedType}
                variant="outline-primary"
                style={{
                  marginLeft: "15px",
                }}
              >
                {docObjects.map((docObject) => (
                  <ReactDropdown.Item
                    key={docObject.doc_id}
                    onClick={() => {
                      setDocType(docObject.doc_type);
                      handleDropdownChange(
                        docObject.doc_name_text,
                        docObject.doc_type,
                        docObject.id
                      );
                    }}
                  >
                    {docObject.doc_type} - {docObject.doc_name} -{" "}
                    {docObject.doc_name_text}
                  </ReactDropdown.Item>
                ))}
              </DropdownButton>
            ) : (
              // Render a loading spinner while data is being fetched
              <>
                {isCounterParty === true ? (
                  <p className="mt-3" style={{ color: "#d64161" }}>
                    KYC IS NOT APPLICABLE FOR COUNTERPARTY APPLICATIONS...
                  </p>
                ) : (
                  // <SectionHeading text="KYC IS NOT APPLICABLE FOR COUNTERPARTY APPLICATIONS..." />
                  <>
                    <p>Select an Individual</p>
                    {/* {// console.log("isCounterParty is not true:", isCounterParty)} */}
                  </>
                )}
              </>
              // "Select an Individual"
            )}
          </Col>
        )}
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <Container>
            {showSpinner ? (
              <Spinner variant="primary" animation="border" />
            ) : fileType === "pdf" ? (
              <iframe
                src={imageData}
                title="Document viewer"
                style={{ width: "550px", height: "550px" }}
              />
            ) : fileType === "img" ? (
              <Image
                src={imageData}
                style={{ width: "550px", border: "solid" }}
              />
            ) : null}
          </Container>
        </Col>
      </Row>
      {dataUpdated ? (
        <VerStatusKeyPerson
          selectedDocType={selectedType}
          verDocId={verDocId}
        />
      ) : null}
    </>
  );
};

export default IndividualsSelection;
