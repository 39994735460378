import axios from "axios";

export const getAddress = (addressId: number, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/one/tradexwbench.customer_address/${addressId}`,
      {
        params: {
          filter: `[]`,
          query:
            "{id,customer_id,type,line_1,line_2,city,district,state,pincode,country_id,phone_country_code,phone,email,business_billing_gstn, business_billing_preference_email, business_communication_preference_email, phone_country_code}",
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
