import { Reducer, ReducersMapObject, combineReducers } from "@reduxjs/toolkit";
import { verBusinessDetailsReducer } from "./verBusinessDetailsSlice";
import { verBusinessDetailsAppType } from "../types/verificationAppType";
import { verRegAddressDetailsReducer } from "./verRegAddressDetailsSlice";
import { verCommAddressDetailsReducer } from "./verCommAddressDetailsSlice";
import { verLocalBankDetailsReducer } from "./verLocalBankDetailsSlice";
import { verCorrBankDetailsReducer } from "./verCorrBankDetailsSlice";
import { verIndividualDetailsReducer } from "./verIndividualDetailsSlice";
import { verStatusRemarksReducer } from "./verStatusRemarksSlice";
import { verStatusKeyPersonReducer } from "./verStatusKeyPersonSlice";

export const verificationAppReducerMap: ReducersMapObject<verBusinessDetailsAppType> =
  {
    verBusinessDetails: verBusinessDetailsReducer,
    verRegAddressDetails: verRegAddressDetailsReducer,
    verCommAddressDetails: verCommAddressDetailsReducer,
    verLocalBankDetails: verLocalBankDetailsReducer,
    verCorrBankDetails: verCorrBankDetailsReducer,
    verIndividualDetails: verIndividualDetailsReducer,
    verStatusRemarks: verStatusRemarksReducer,
    verStatusKeyPerson: verStatusKeyPersonReducer,
  };

export const verificationAppReducer: Reducer<verBusinessDetailsAppType> =
  combineReducers<verBusinessDetailsAppType>(verificationAppReducerMap);
