import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import FieldsVerification from "./fieldsVerification";
import KYCVerification from "./kycVerification";
import OtherDocsVerification from "./otherDocsVerification";

const DocumentsVerification: React.FC = () => {
  const [activeTab, setActiveTab] = useState("documents");

  const handleTabSelect = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        id="fill-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="documents" title="Documents"></Tab>
        <Tab eventKey="otherDocs" title="Other Docs"></Tab>
        <Tab eventKey="fields" title="Fields"></Tab>
      </Tabs>

      {activeTab === "documents" && <KYCVerification />}
      {activeTab === "fields" && <FieldsVerification />}
      {activeTab === "otherDocs" && <OtherDocsVerification />}
    </>
  );
};

export default DocumentsVerification;
