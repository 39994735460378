import axios from "axios";

export const deleteCorrBank = (
  corrBankId: number,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/delete/one/tradexwbench.customer_settlement_correspondent_bank/${corrBankId}`,
      {},
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
