import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import SectionHeading from "../../../../../components/common/SectionHeading";
import DatePicker from "../../../../../components/common/DatePicker";
import Button from "../../../../../components/common/Button";
import RegisterWith from "../../../../../components/common/dropdowns/RegisterWith";
import BusinessStatusHolder from "../../../../../components/common/dropdowns/BusinessStatusHolder";
import BusinessUnitLocation from "../../../../../components/common/dropdowns/BusinessUnitLocation";
import NatureOfBusiness from "../../../../../components/common/dropdowns/NatureOfBusiness";
import TextArea from "../../../../../components/common/Textarea";
import { useNavigate, useParams } from "react-router-dom";
import CommonHeader from "../../../../../components/common/CommonHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { fetchHeaderDetails } from "../../../../guestUser/ppApplication/redux/action/fetchHeaderDetails";
import RadioButton from "../../../../../components/common/RadioButton";
import { BusinessDetailsType } from "../pages/types/BusinessDetailsType";
import { updateAppAction } from "../redux/action/updateAppAction";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { getAppAction } from "../redux/action/getAppAction";
import { stat } from "fs";
import {
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  CKYC_REGEX,
  LEI_REGEX,
  NATURE_ACTIVITY_REGEX,
  WEBSITE_REGEX,
} from "../../../../constants/regexPatterns";
import CPBusinessDetailsFields from "./CPBusinessDetailsFields";
import SendBackPdfBtn from "../../../../../components/common/sendBackPdfBtn";

const INCBusinessDetails: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { appId } = useParams();
  const customerId = Number(appId);
  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
  } = useForm<BusinessDetailsType>({
    shouldUnregister: true,
  });

  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  let stage = state.data.stage;

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  //const [selectedValues, setSelectedValues] = useState<number[]>([]);

  const onSaveBusinessDetails: SubmitHandler<BusinessDetailsType> = (
    updateCustomerData
  ) => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      // console.log("Business Details:", updateCustomerData);
      setShowSuccessAlert(false);
      updateCustomerData.business_listing_info_ids = [630];
      if (updateCustomerData.is_msme === "true") {
        updateCustomerData.is_msme = true;
      } else {
        updateCustomerData.is_msme = false;
      }
      dispatch(updateAppAction({ customerId, updateCustomerData })).then(
        (response) => {
          if (response.payload.result) {
            setShowSuccessAlert(true);
          }
        }
      );
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    // console.log("App Data ----> ", state);

    reset({
      business_registered_with: state.data.business_registered_with,
      business_registration_place: state.data.business_registration_place,
      business_listing_info_ids: state.data.business_listing_info_ids,
      business_cin_no: state.data.business_cin_no,
      business_registration_date: state.data.business_registration_date,
      business_commencement_date: state.data.business_commencement_date,
      business_legal_entity_identifier:
        state.data.business_legal_entity_identifier,
      business_website: state.data.business_website,
      business_nature: state.data.business_nature,
      business_iecno: state.data.business_iecno,
      business_nature_activity: state.data.business_nature_activity,
      business_unit_location: state.data.business_unit_location,
      business_statusholder: state.data.business_statusholder,
      business_ckycno: state.data.business_ckycno,
      business_registration_number: state.data.business_registration_number,
      is_msme: state.data.is_msme === true ? "true" : "false",
      business_no_of_years_of_relation_with_cp:
        state.data.business_no_of_years_of_relation_with_cp,
      referred_by_vendor_dealer_id: state.data.referred_by_vendor_dealer_id,
      business_is_cp_related_party: state.data.business_is_cp_related_party,
    });
    setTimeout(() => {
      reset({
        business_statusholder: state.data.business_statusholder,
      });
    }, 500);
    //setDefaultSelectedListing(state.data.business_listing_info_ids);
  }, [state, reset]);

  return (
    <>
      <Container>
        <Row>
          <Row>
            <Col>
              {/* <CommonHeader
                name={formData.customer_contact_name}
                email={formData.customer_contact_email}
                mobile={formData.customer_contact_mobile}
                stage={formData.stage}
                businessName={formData.business_name}
                arn={formData.application_uid}
                gstno={formData.business_gst}
                // tanno={formData.business_tanno} //use only for foreign cust
                role={formData.business_participant_type}
              /> */}
            </Col>
          </Row>
          <DisplayBox display={showSuccessAlert}>
            <AlertPopup
              variant="success"
              message="Business Details Updated Successfully"
            />
          </DisplayBox>
          <Row>
            <Col md="12">
              <SectionHeading text="BUSINESS DETAILS" />
            </Col>
            <Col className="text-end">
              <SendBackPdfBtn />
            </Col>
          </Row>
          <Form>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <RegisterWith
                  label="Business Registration"
                  name="business_registered_with"
                  register={register}
                  required
                  requiredMessage="Business Registration is required"
                  error={errors.business_registered_with?.message}
                />
              </Col>
              <Col sm={6} className="mt-2">
                <Input
                  label="Place of Incorporation"
                  name="business_registration_place"
                  register={register}
                  required
                  // regexPattern={ALPHABETS_REGEX}
                  requiredMessage="Incorporation Place is required"
                  error={errors.business_registration_place?.message}
                  placeholder="Enter place of Incorporation"
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <Input
                  label="Registration No"
                  name="business_registration_number"
                  register={register}
                  required
                  regexPattern={ALPHANUMERIC_REGEX}
                  requiredMessage="Registration number is required"
                  error={errors.business_registration_number?.message}
                  placeholder="Enter registration number"
                />
              </Col>
              <Col sm={6} className="mt-2">
                <DatePicker
                  label="Date of Incorporation"
                  name="business_registration_date"
                  register={register}
                  isDateOfBirth={true}
                  required
                  requiredMessage="DOI is required"
                  error={errors.business_registration_date?.message}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6}>
                <Input
                  label="Listing Information"
                  name="business_listing_info_ids"
                  register={register}
                  required
                  requiredMessage="Listing Information is required"
                  error={errors.business_listing_info_ids?.message}
                  placeholder="Enter Listing Information"
                  defaultValue="Unlisted"
                  disabled
                />
              </Col>
              <Col sm={6}>
                <DatePicker
                  label="Date of Commencement"
                  name="business_commencement_date"
                  register={register}
                  isDateOfBirth={true}
                  // required
                  requiredMessage="date is required"
                  //defaultValue={state.data.business_commencement_date}
                  error={errors.business_commencement_date?.message}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6}>
                <Input
                  label="Legal Entity Identifier (LEI)"
                  name="business_legal_entity_identifier"
                  register={register}
                  regexPattern={LEI_REGEX}
                  // required
                  // requiredMessage="LEI is required"
                  error={errors.business_legal_entity_identifier?.message}
                  placeholder="Enter LEI"
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="Website"
                  name="business_website"
                  register={register}
                  //regexPattern={/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/}
                  regexMessage="Invalid website URL format"
                  error={errors.business_website?.message}
                  placeholder="Enter website url"
                  regexPattern={WEBSITE_REGEX}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <NatureOfBusiness
                  label="Nature of Business"
                  name="business_nature"
                  register={register}
                  required
                  requiredMessage="Nature of Business is required"
                  error={errors.business_nature?.message}
                />
              </Col>
              <Col sm={6} className="mt-2">
                <Input
                  label="Import/ Export Registration No"
                  name="business_iecno"
                  register={register}
                  required
                  regexPattern={ALPHANUMERIC_REGEX}
                  requiredMessage="IEC is required"
                  error={errors.business_iecno?.message}
                  placeholder="Enter IEC"
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <TextArea
                  label="Nature of Activity"
                  name="business_nature_activity"
                  register={register}
                  required
                  regexPattern={NATURE_ACTIVITY_REGEX}
                  requiredMessage="Nature of activity is required"
                  error={errors.business_nature_activity?.message}
                  placeholder="Enter nature of activity"
                />
              </Col>
              <Col sm={6} className="mt-2">
                <BusinessUnitLocation
                  label="Business Unit Location"
                  name="business_unit_location"
                  register={register}
                  required
                  requiredMessage="Business unit location is required"
                  error={errors.business_unit_location?.message}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <Input
                  label="CKYC No"
                  name="business_ckycno"
                  register={register}
                  regexPattern={CKYC_REGEX}
                  error={errors.business_ckycno?.message}
                  placeholder="Enter CKYC"
                />
              </Col>
              <Col sm={6} className="mt-2">
                <DisplayBox
                  display={state.data.business_participant_type === "Seller"}
                >
                  <BusinessStatusHolder
                    label="Star Export House"
                    name="business_statusholder"
                    register={register}
                    required
                    defaultValue={state.data.business_statusholder}
                    error={errors.business_statusholder?.message}
                    // disabled={
                    //   state.data.business_participant_type == "Seller"
                    // }
                  />
                </DisplayBox>
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6}>
                <DisplayBox
                  display={
                    state.data.business_participant_type === "Seller" &&
                    state.data.is_counterparty === false
                  }
                >
                  {/* <p>true</p> */}
                  <RadioButton
                    label="Is MSME ?"
                    name="is_msme"
                    register={register}
                    options={[
                      { label: "Yes", value: "true" },
                      { label: "No", value: "false" },
                    ]}
                    required
                    requiredMessage="Is MSME selection is required"
                    error={errors.is_msme?.message}
                    // onChange={() => {
                    //   // console.log("value-", register);
                    // }}
                  />
                </DisplayBox>
              </Col>
            </Row>
            {state.data.is_counterparty === true && (
              <CPBusinessDetailsFields register={register} errors={errors} />
            )}
            <Row>
              <Col className="m-2 text-end">
                <Button
                  label="Save Details"
                  type="button"
                  // variant="outline-primary"
                  onClick={handleSubmit(onSaveBusinessDetails)}
                />
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default INCBusinessDetails;
