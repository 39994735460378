import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getAddress } from "../../apis/getAddress";

export const getAddressAction = createAsyncThunk(
  "getAddressAction",
  async (addressId: number) => {
    return getAddress(addressId, getSessionId()).then((response: any) => {
      // console.log("Get Address Response", response);
      return response.data;
    });
  }
);
