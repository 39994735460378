import React, { ChangeEvent } from "react";

const handleFileWithSizeLimit = (
  e: ChangeEvent<HTMLInputElement>,
  maxSize = 10 * 1024 * 1024,
  callback: (event: ChangeEvent<HTMLInputElement>) => void
) => {
  const selectedFiles = e.target.files;

  if (selectedFiles) {
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (file.size > maxSize) {
        alert(`File size for ${file.name} exceeds the limit.`);
        e.target.value = "";
        return;
      }
    }
  }

  callback(e);
};

export default handleFileWithSizeLimit;
