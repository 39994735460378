import React from "react";

interface IconComponentProps {
  icon: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const IconInput: React.FC<IconComponentProps> = ({
  icon,
  style = {
    fontSize: "20px",
    cursor: "pointer",
  },
  onClick,
}) => {
  return (
    <span
      style={{ ...style, cursor: "pointer", fontSize: "20px" }}
      onClick={onClick}
    >
      {icon}
    </span>
  );
};
export default IconInput;
