import React from "react";
import { Col, Row, Form } from "react-bootstrap";

interface CurrentDatePickerProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  minDate?: string;
  maxDate?: string;
  isCurrentDate?: boolean; // New prop
  error?: any;
  disabled?: boolean;
}

const CurrentDatePicker: React.FC<CurrentDatePickerProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage,
  minDate,
  maxDate,
  isCurrentDate,
  error,
  disabled,
}) => {
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}&nbsp;
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>{" "}
            :
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Control
            type="date"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            min={minDate}
            max={maxDate}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            defaultValue={isCurrentDate ? getCurrentDate() : undefined}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default CurrentDatePicker;
