import axios from "axios";
import { getSessionId } from "../../../../../utils/tokenHandlers";

export const getSendBackDocid = (appNumber: string) => {
  const sessionid = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/ir.attachment`,
      {
        params: {
          filter: `[('name','=','Instruction_${appNumber}.pdf')]`,
          query: "{id}",
        },
      },
      {
        params: {
          session_id: `${sessionid}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
