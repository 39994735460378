import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { DueDiligenceType } from "../../pages/types/KycDocType";
import { updateDueDiligenceDoc } from "../../apis/updateDueDiligenceDoc";

export const updateDueDiligenceDocAction = createAsyncThunk(
  "updateDueDiligenceDocAction",
  async ({
    docId,
    dueDiligenceDetails,
  }: {
    docId: number;
    dueDiligenceDetails: DueDiligenceType;
  }) => {
    return updateDueDiligenceDoc(
      docId,
      dueDiligenceDetails,
      getSessionId()
    ).then((response) => {
      // console.log("DUE UPDATE", response);
      return response.data;
    });
  }
);
