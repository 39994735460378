import React from "react";
import NavbarVTX from "../../../../components/common/Navbar";
import Header from "../../../../components/common/Header";
import Input from "../../../../components/common/Input";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import Dropdown from "../../../../components/common/dropdowns/Dropdown";
import CountryDropdown from "../../../../components/common/CountryDropdown";
import DatePicker from "../../../../components/common/DatePicker";
import Button from "../../../../components/common/Button";
import CheckBoxes from "../../../../components/common/CheckBoxes";

const GuestAddKeyIndividual: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const genderOptions = [
    { label: "Female", value: "female" },
    { label: "Male", value: "male" },
    { label: "Others", value: "others" },
  ];

  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Col
            md={20}
            className="text-center mt-4"
            style={{ color: "#214382" }}
          >
            <h5>ADD KEY PERSON</h5>
          </Col>
        </Row>
        <Row
          style={{ marginLeft: "-48%", color: "#214382", fontSize: "small" }}
        >
          <Col>
            {/* <CheckBoxesComponent
              name="checkboxGroup"
              label=""
              options={[
                { label: "Key Person", value: "key person" },
                { label: "Authorised official", value: "authorised official" },
                {
                  label: "Entity Administrator",
                  value: "Entity Administrator",
                },
                { label: "Beneficial Owner", value: "Beneficial Owner" },
                { label: "Others", value: "others" },
                // Add more options as needed
              ]}
              register={register}
              required
              //   error={errors.checkboxGroup?.message}
            /> */}
          </Col>
        </Row>
        <Form>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Dropdown
                label="Designation"
                name="business_reg"
                register={register}
                required
                requiredMessage="Registration name is required"
                options={[]} // error={errors.business_reg?.message}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Nationality"
                name="business_registration_place"
                register={register}
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 1"
                name="business_registration_place"
                register={register}
                placeholder="Enter Address line 1"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="First name"
                name="business_reg"
                register={register}
                placeholder="Enter first name"
                required
                requiredMessage="Registration name is required" // error={errors.business_reg?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="PAN/TAX Id"
                name="business_registration_place"
                register={register}
                placeholder="Enter Pan/tax"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 2"
                name="business_registration_place"
                register={register}
                placeholder="Enter Address line 2"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Middle name"
                name="business_reg"
                register={register}
                placeholder="Enter middle name"
                required
                requiredMessage="Registration name is required" // error={errors.business_reg?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Passport No"
                name="business_registration_place"
                register={register}
                placeholder="Enter passport no"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 3"
                name="business_registration_place"
                register={register}
                placeholder="Enter Address line 3"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Last name"
                name="business_reg"
                register={register}
                placeholder="Enter last name"
                required
                requiredMessage="Registration name is required" // error={errors.business_reg?.message}
              />
            </Col>
            <Col sm={4}>
              <DatePicker
                label="Passport Expiry"
                name="business_registration_place"
                register={register}
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="City/Town/Village"
                name="business_registration_place"
                register={register}
                placeholder="Enter city/town/village"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Mother name"
                name="business_reg"
                register={register}
                placeholder="Enter mother name"
                required
                requiredMessage="Registration name is required" // error={errors.business_reg?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Employee Id"
                name="business_registration_place"
                register={register}
                placeholder="Enter employee id"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="District/City"
                name="business_registration_place"
                register={register}
                placeholder="Enter district/city"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Dropdown
                label="Gender"
                name="business_reg"
                register={register}
                required
                requiredMessage="Registration name is required" // error={errors.business_reg?.message}
                options={genderOptions}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="DIN/DPIN"
                name="business_registration_place"
                register={register}
                placeholder="Enter DIN/DPIN"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="State/Country"
                name="business_registration_place"
                register={register}
                placeholder="Enter state/country"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <DatePicker
                label="Date of birth"
                name="business_reg"
                register={register}
                required
                requiredMessage="Registration name is required" // error={errors.business_reg?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Ckyc no"
                name="business_registration_place"
                register={register}
                placeholder="Enter ckyc"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Country"
                name="business_registration_place"
                register={register}
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Is politically?"
                name="business_reg"
                register={register}
                placeholder="Enter is politically?"
                required
                requiredMessage="Registration name is required" // error={errors.business_reg?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Guardian name"
                name="business_registration_place"
                register={register}
                placeholder="Enter guardian name"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Pincode"
                name="business_registration_place"
                register={register}
                placeholder="Enter pincode"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Is minor?"
                name="business_reg"
                register={register}
                placeholder="Enter is minor?"
                required
                requiredMessage="Registration name is required" // error={errors.business_reg?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Guardian relationship"
                name="business_registration_place"
                register={register}
                placeholder="Enter guardian relationship"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Email"
                name="business_registration_place"
                register={register}
                placeholder="Enter email"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}></Col>
            <Col sm={4}>
              <Input
                label="Ownership %"
                name="business_registration_place"
                register={register}
                placeholder="Enter ownership %"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Tele"
                name="business_registration_place"
                register={register}
                placeholder="Enter telephone"
                required
                requiredMessage="Registration place is required"
                // error={errors.business_registration_place?.message}
              />
            </Col>
          </Row>
        </Form>
      </Container>
      <Row>
        <Col md={15} className="text-end">
          <Button label="Submit"></Button>
        </Col>
      </Row>
    </>
  );
};

export default GuestAddKeyIndividual;
