import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getSessionId } from "../../../utils/tokenHandlers";
import { getIndustryType } from "../../../feature/apis/listOfValues/getIndustryType";
import { getAllAddress } from "../../../feature/opsUser/ppApplication/ppIndia/apis/getAllAddress";
import { useParams } from "react-router-dom";

interface AddressTypeProps {
  name: string;
  label: string;
  register: any;
  required?: boolean;
  requiredMessage?: string;
  handleChange?: () => JSX.Element;
  defaultValue?: string;
  error?: string;
}

interface AddressTypeData {
  id: number;
  type: string;
  line_1: string;
  line_2: string;
  city: string;
  district: string;
  state: string;
  pincode: string;
  country_id: any;
  phone_country_code?: string;
  phone?: string;
  email?: string;
  business_billing_gstn?: string;
  business_communication_preference_email?: string;
  business_billing_preference_email?: string;
}

const DefaultShippingAddress: React.FC<AddressTypeProps> = ({
  name,
  label,
  register,
  required,
  requiredMessage = "This field is required",
  handleChange,
  defaultValue,
  error,
}) => {
  const token = getSessionId();
  const { appId } = useParams();
  const customId = Number(appId);

  const [shippingAddress, setShippingAddress] = useState<AddressTypeData[]>([]);

  useEffect(() => {
    getAllAddress(customId, token).then((response) => {
      setShippingAddress(response.data.result.result);
    });
  }, []);

  return (
    <Form.Group as={Col} className="mb-3">
      <Row className="align-items-center">
        <Form.Label column md={4}>
          <span className="float-end">
            {label}
            <span className="text-danger form-label">
              {required ? "*" : ""}
            </span>{" "}
          </span>
        </Form.Label>
        <Col md={8}>
          <Form.Select
            as="select"
            className={`custom-input form-control ${error ? "is-invalid" : ""}`}
            onChange={handleChange}
            {...register(name, {
              required: required ? requiredMessage : false,
            })}
            defaultValue={defaultValue}
          >
            <option value="">Select Option</option>
            {shippingAddress.map((address, index) => (
              <option key={index} value={address.id}>
                {`${address.line_1} ${address.line_2} ${address.city} ${address.state} ${address.country_id.name}`}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4}></Col>
        <Col md={8}>{error && <p className="m-0 err-msg">{error}</p>}</Col>
      </Row>
    </Form.Group>
  );
};

export default DefaultShippingAddress;
