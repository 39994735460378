import axios from "axios";

interface ResultArray {
  id: number;
  name: string;
  code: string;
  isd_code: string;
  is_active: boolean;
}

interface Result {
  count: number;
  prev: null;
  current: number;
  next: number;
  total_pages: number;
  result: Array<ResultArray>;
}

interface AllCountryResponse {
  jsonrpc: string;
  id: null;
  result: Result;
}

export const getAllCountry = (sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.country_master`,
      {
        params: {
          filter: "[]",
          all_pages: true,
          query: "{id,name,code,isd_code,is_active}",
        },
      },
      {
        params: {
          session_id: `${sessionId}`,
        },
      }
    )
    .then((response): AllCountryResponse => response.data)
    .catch((err) => err);
};
