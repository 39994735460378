import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { CreateAddressType } from "../../pages/types/CreateAddressType";
import { updateAddress } from "../../apis/updateAddress";
import { KeyPersonDetailsType } from "../../pages/types/KeyPersonDetailsType";
import { updateKeyPerson } from "../../apis/updateKeyPerson";

export const updateKeyPersonAction = createAsyncThunk(
  "updateKeyPersonAction",
  async ({
    keyPersonId,
    keyPersonDetails,
  }: {
    keyPersonId: number;
    keyPersonDetails: KeyPersonDetailsType;
  }) => {
    return updateKeyPerson(keyPersonId, keyPersonDetails, getSessionId()).then(
      (response) => {
        return response.data;
      }
    );
  }
);
