import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { getAllCorrBank } from "../../apis/getAllCorrBank";

export const getAllCorrBankAction = createAsyncThunk(
  "getAllCorrBankAction",
  async (customerId: number) => {
    return getAllCorrBank(customerId, getSessionId()).then((response: any) => {
      return response.data;
    });
  }
);
