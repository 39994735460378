import { createSlice } from "@reduxjs/toolkit";
import { UpdateAppData } from "../types/UpdateAppType";
import { updateLocalBankAction } from "../action/updateLocalBankAction";

const initialState: UpdateAppData = {
  data: {},
  loading: false,
  error: null,
};

const updateLocalBankSlice = createSlice({
  name: "updateLocalBankSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateLocalBankAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLocalBankAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateLocalBankAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const updateLocalBankReducer = updateLocalBankSlice.reducer;
