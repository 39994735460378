import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { MdAddCircleOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import NavbarVTX from "../../../../../components/common/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { getCounterPartyAction } from "../redux/action/getCounterPartyAction";
import { getParticipantAction } from "../redux/action/getParticipantsAction";
import {
  getUserRole,
  getUserUid,
  getUsername,
} from "../../../../../utils/tokenHandlers";
import { LogOut } from "../../../../auth/apis/logout";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import { getRmName } from "../apis/getAllRmName";
import { getBankAccountType } from "../../../../apis/listOfValues/getBankAccountType";
import { logoutConCurrLoginAction } from "../../../../auth/redux/action/logoutConCurrLoginAction";
import SectionHeading from "../../../../../components/common/SectionHeading";

const OpsDashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const userRole = getUserRole();
  const userName = getUsername();
  // const dispatch = useDispatch<AppDispatch>();
  const [logoutSpinner, setLogoutSpinner] = useState(false);

  const handleCPEyeButtonClick = () => {
    // dispatch(getCounterPartyAction({}));
    navigate("applicationlistviewcp");
    localStorage.setItem("viewClicked", "true");

    // // console.log(getCounterPartyAction,"hii imhere")
  };
  const handlePPEyeButtonClick = () => {
    // dispatch(getParticipantAction({}));
    navigate("applicationlistview");
    localStorage.setItem("viewClicked", "true");
  };

  const handleLogout = () => {
    // Add your logout logic here
    setLogoutSpinner(true);
    // console.log("Hello, logout clicked!");
    const userId = getUserUid();
    dispatch(logoutConCurrLoginAction(userId)).then((resp: any) => {
      // console.log("Logout resp--", resp);
      if (userRole === "guest") {
        navigate("/");
      } else if (userRole === "maker" || userRole === "checker") {
        navigate("/ops/login");
      } else {
        <></>;
      }
      setLogoutSpinner(false);
    });

    // // console.log("LogOut resp--", logOut);
  };

  useEffect(() => {
    const sessionId = getSessionId();
    getBankAccountType(sessionId);
  }, []);

  return (
    <>
      <Row>
        <Col className="m-2">
          <NavbarVTX></NavbarVTX>
        </Col>
        <Col
          md={11}
          className="text-end"
          style={{ marginTop: "-5%", color: "#214382" }}
        >
          {userName} as {userRole}
          <span
            style={{ color: "#214382", marginLeft: "2%", cursor: "pointer" }}
            onClick={handleLogout}
          >
            {logoutSpinner ? (
              <Spinner animation="border" variant="primary" size="sm" />
            ) : (
              <>LogOut</>
            )}
          </span>
        </Col>
      </Row>

      {/* <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md={4}>
            <Card style={{ width: "18rem" }} className="text-center">
              <Card.Body style={{ backgroundColor: "#dadada" }}>
                <Card.Title style={{ color: "#214382" }}>
                  Participant Forms
                </Card.Title>
                <Row>
                  {userRole === "maker" && (
                    <>
                      <Col>
                        <MdAddCircleOutline
                          style={{
                            width: "33px",
                            height: "24px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => navigate("/ops/create-application")}
                        />
                      </Col>
                    </>
                  )}

                  <Col>
                    <MdOutlineRemoveRedEye
                      style={{
                        width: "33px",
                        height: "24px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={handlePPEyeButtonClick}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ width: "18rem" }} className="text-center">
              <Card.Body style={{ backgroundColor: "#dadada" }}>
                <Card.Title style={{ color: "#214382" }}>
                  Counterparty Forms
                </Card.Title>
                <Row>
                  {userRole === "maker" && (
                    <Col>
                      <MdAddCircleOutline
                        style={{
                          width: "33px",
                          height: "24px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/ops/create-application/cp")}
                      />
                    </Col>
                  )}

                  <Col>
                    <MdOutlineRemoveRedEye
                      style={{
                        width: "33px",
                        height: "24px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={handleCPEyeButtonClick}
                      //onClick={() => navigate("applicationlistview")}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ width: "18rem" }} className="text-center">
              <Card.Body style={{ backgroundColor: "#dadada" }}>
                <Card.Title className="" style={{ color: "#214382" }}>
                  Service Requests
                </Card.Title>
                <Row>
                  <Col>
                    <MdAddCircleOutline
                      style={{
                        width: "33px",
                        height: "24px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                  <Col>
                    <MdOutlineRemoveRedEye
                      style={{
                        width: "33px",
                        height: "24px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
      <SectionHeading text="OPERATIONS DASHBOARD" />
      <Container className="mt-4">
        <Row className="justify-content-center" md={12}>
          <Col md={4}>
            <Card style={{ width: "20rem" }} className="text-center">
              <Card.Body style={{ backgroundColor: "#ccffcc" }}>
                <Card.Title style={{ color: "#214382" }}>
                  PARTICIPANT FORMS
                </Card.Title>
                <Row className="mt-3">
                  {userRole === "maker" && (
                    <>
                      <Col>
                        <MdAddCircleOutline
                          style={{
                            width: "33px",
                            height: "24px",
                            color: "#214382",
                            cursor: "pointer",
                          }}
                          onClick={() => navigate("/ops/create-application")}
                          title="Create new Participant"
                        />
                      </Col>
                    </>
                  )}

                  <Col>
                    <MdOutlineRemoveRedEye
                      style={{
                        width: "33px",
                        height: "24px",
                        color: "green",
                        cursor: "pointer",
                      }}
                      onClick={handlePPEyeButtonClick}
                      title="View all Participants"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ width: "20rem" }} className="text-center">
              <Card.Body style={{ backgroundColor: "#e6e6fa" }}>
                <Card.Title style={{ color: "#214382" }}>
                  COUNTERPARTY FORMS
                </Card.Title>
                <Row className="mt-3">
                  {userRole === "maker" && (
                    <Col>
                      <MdAddCircleOutline
                        style={{
                          width: "33px",
                          height: "24px",
                          color: "#214382",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/ops/create-application/cp")}
                        title="Create new Counter-Party"
                      />
                    </Col>
                  )}

                  <Col>
                    <MdOutlineRemoveRedEye
                      style={{
                        width: "33px",
                        height: "24px",
                        color: "green",
                        cursor: "pointer",
                      }}
                      onClick={handleCPEyeButtonClick}
                      //onClick={() => navigate("applicationlistview")}
                      title="View all Counter-Parties"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ width: "20rem" }} className="text-center">
              <Card.Body style={{ backgroundColor: "#e9d8fd" }}>
                <Card.Title className="" style={{ color: "#214382" }}>
                  SERVICE REQUESTS
                </Card.Title>
                <Row className="mt-3">
                  {userRole === "maker" && (
                    <Col>
                      <MdAddCircleOutline
                        style={{
                          width: "33px",
                          height: "24px",
                          color: "#214382",
                          // cursor: "pointer",
                        }}
                        // onClick={() => navigate("/service-request/create-new")}
                        // title="Create new Service request"
                      />
                    </Col>
                  )}
                  <Col>
                    <MdOutlineRemoveRedEye
                      style={{
                        width: "33px",
                        height: "24px",
                        color: "green",
                        // cursor: "pointer",
                      }}
                      // onClick={() => navigate("/service-request/view-all")}
                      // title="View all Service requests"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OpsDashboardPage;
