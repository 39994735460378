import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionId } from "../../../../../../utils/tokenHandlers";
import { createAddress } from "../../apis/createAddress";
import { CreateAddressType } from "../../pages/types/CreateAddressType";

export const createAddressAction = createAsyncThunk(
  "createAddressAction",
  async (addressDeatils: CreateAddressType, { dispatch }) => {
    return createAddress(addressDeatils, getSessionId()).then((response) => {
      // console.log("Create Address response", response);
      return response.data;
    });
  }
);
