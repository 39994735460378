import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchRegisteredAddress } from "../../apis/fetchRegisteredAddress";

export const getRegisteredAddress = createAsyncThunk(
  "getRegisteredAddress",
  async (customerId: number) => {
    const response = await fetchRegisteredAddress(customerId);
    // console.log("in reg address action file-->", response);
    return response;
  }
);
