import { RouteObject } from "react-router-dom";
import authRoutesConfig from "../feature/auth/routes/authRoutesConfig";
import ppApplicationRoutesConfig from "../feature/opsUser/ppApplication/ppIndia/routes/ppApplicationRoutesConfig";
import guestAppRoutesConfig from "../feature/guestUser/ppApplication/routes/guestAppRouteConfig";
import verificationRoutesConfig from "../feature/verificationStage/routes/verificationRouteConfig";
import cpApplicationRoutesConfig from "../feature/cpUser/routes/cpApplicationRoutesConfig";

const mainRoutesConfig: RouteObject[] = [
  ...authRoutesConfig,
  ...ppApplicationRoutesConfig,
  ...guestAppRoutesConfig,
  ...verificationRoutesConfig,
  ...cpApplicationRoutesConfig,
];

export default mainRoutesConfig;
